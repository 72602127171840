import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { ReqItemComponent } from '../req-item';
import { ReqStatusActionsComponent } from '../req-status';
import ReqsFilterComponent from '../../../reqs-filter';
import './styles.less';
import TablePagination from '../../../../semantic-ui/components/table-pagination';
import { AuctionComponent } from '../auction';
import { Timer } from '../timer';

export const ReqWrapper = ({
	reqs,
	onActionButtonClick,
	onModalOpen,
	pagination,
	isReqsLoading,
	onAuctionInfoChange,
	onKeyDown,
	isTimerVisible,
}) => {
	return (
		<>
			{onModalOpen && (
				<>
					<div className="req-wrapper__header">
						<button type="button" className="req-wrapper__button" onClick={onModalOpen}>
							Создать заявку
						</button>
						{isTimerVisible && <Timer />}
					</div>
				</>
			)}
			{isTimerVisible && !onModalOpen && (
				<div className="req-wrapper__header req-wrapper__header_with-one-item">
					<Timer />
				</div>
			)}
			<div className="req-wrapper">
				{reqs?.length > 0 ? (
					<div className="req-wrapper__reqs">
						{reqs.map(item => {
							return (
								<div key={item.id} className="req-wrapper__reqs-items">
									<div className="req-wrapper__reqs-item">
										<ReqItemComponent item={item} />
									</div>
									<div className="req-wrapper__reqs-status">
										{item.auction ? (
											<AuctionComponent
												actions={item.actions}
												auction={item.auction}
												onActionButtonClick={onActionButtonClick}
												offer={item.offer}
												reqId={item.id}
												onAuctionInfoChange={onAuctionInfoChange}
												onKeyDown={onKeyDown}
												isDateFieldDisabled={item.points?.length > 2}
											/>
										) : (
											<ReqStatusActionsComponent
												status={item.status}
												actions={item.actions}
												onActionButtonClick={onActionButtonClick}
												reqId={item.id}
											/>
										)}
									</div>
								</div>
							);
						})}
						{pagination && <TablePagination pagination={pagination} isShort />}
					</div>
				) : (
					<div className="req-wrapper__reqs">
						<div className="req-wrapper__empty-list">
							{isReqsLoading ? (
								<div className="req-wrapper__loader">
									<Loader active inline />
								</div>
							) : (
								<div className="req-wrapper__empty-list-text">Заявки не найдены</div>
							)}
						</div>
					</div>
				)}

				<div className="req-wrapper__filter">
					<ReqsFilterComponent />
				</div>
			</div>
		</>
	);
};

ReqWrapper.propTypes = {
	reqs: PropTypes.array.isRequired,
	onActionButtonClick: PropTypes.func.isRequired,
	onModalOpen: PropTypes.func,
	pagination: PropTypes.shape({
		currentPage: PropTypes.number,
		totalPages: PropTypes.number.isRequired,
		total: PropTypes.number,
		from: PropTypes.number,
		to: PropTypes.number,
		isNextItem: PropTypes.bool,
		isPrevItem: PropTypes.bool,
		isFirstItem: PropTypes.bool,
		isLastItem: PropTypes.bool,
	}),
	isReqsLoading: PropTypes.bool.isRequired,
	onAuctionInfoChange: PropTypes.func.isRequired,
	onKeyDown: PropTypes.func.isRequired,
	isTimerVisible: PropTypes.bool.isRequired,
};
