import React from 'react';
import PropTypes from 'prop-types';
import { TABLE_INFO_COLUMNS_NAMES } from '../../constants';
import { SimpleTableComponent } from '../../../../components/simple-table';
import { UserRoleForm } from '../user-role-form';
import { RulesForm } from '../rules-form';

export const UserRoleContent = ({
	userRoleInfo,
	isUserRoleInfoLoading,
	isUserRoleFormOpen,
	tableColumns,
	tableRows,
	onCheckboxChange,
	formValues,
}) => {
	return isUserRoleFormOpen ? (
		<UserRoleForm
			isUserRoleInfoLoading={isUserRoleInfoLoading}
			tableColumns={tableColumns}
			tableRows={tableRows}
			onCheckboxChange={onCheckboxChange}
			formValues={formValues}
		/>
	) : (
		<div>
			<RulesForm
				isDisabled
				isUserRoleInfoLoading={isUserRoleInfoLoading}
				tableColumns={tableColumns}
				tableRows={tableRows}
				formValues={formValues}
			/>
			<SimpleTableComponent
				rows={userRoleInfo}
				isTableDataLoading={isUserRoleInfoLoading}
				columns={TABLE_INFO_COLUMNS_NAMES}
			/>
		</div>
	);
};

UserRoleContent.propTypes = {
	userRoleInfo: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
				PropTypes.number,
			]).isRequired,
		}),
	),
	isUserRoleInfoLoading: PropTypes.bool.isRequired,
	isUserRoleFormOpen: PropTypes.bool.isRequired,
	tableColumns: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])))
		.isRequired,
	tableRows: PropTypes.objectOf(
		PropTypes.shape({ title: PropTypes.string.isRequired, actions: PropTypes.arrayOf(PropTypes.string) }),
	),
	onCheckboxChange: PropTypes.func.isRequired,
	formValues: PropTypes.object,
};
