export const SET_IS_PROFILE_DROPDOWN_SHOWN = 'right-header-content/SET_IS_PROFILE_DROPDOWN_SHOWN';
export const setIsProfileDropdownShownAction = payload => ({
	type: SET_IS_PROFILE_DROPDOWN_SHOWN,
	payload,
});

export const SET_IS_NOTIFICATION_DROPDOWN_SHOWN = 'right-header-content/SET_IS_NOTIFICATION_DROPDOWN_SHOWN';
export const setIsNotificationDropdownShownAction = payload => ({
	type: SET_IS_NOTIFICATION_DROPDOWN_SHOWN,
	payload,
});

export const SET_IS_CHATS_LIST_SHOWN = 'right-header-content/SET_IS_CHATS_LIST_SHOWN';
export const setIsChatsListShownAction = payload => ({
	type: SET_IS_CHATS_LIST_SHOWN,
	payload,
});

export const SET_USER_DATA = 'right-header-content/SET_USER_DATA';
export const setUserDataAction = payload => ({
	type: SET_USER_DATA,
	payload,
});

export const GET_NOTIFICATIONS_LIST = 'right-header-content/GET_NOTIFICATIONS_LIST';
export const getNotificationsListAction = () => ({
	type: GET_NOTIFICATIONS_LIST,
});

export const SET_NOTIFICATIONS_LIST = 'right-header-content/SET_NOTIFICATIONS_LIST';
export const setNotificationsListAction = payload => ({
	type: SET_NOTIFICATIONS_LIST,
	payload,
});

export const SET_IS_NOTIFICATIONS_LIST_LOADING = 'right-header-content/SET_IS_NOTIFICATIONS_LIST_LOADING';
export const setIsNotificationsListLoadingAction = payload => ({
	type: SET_IS_NOTIFICATIONS_LIST_LOADING,
	payload,
});

export const MARK_NOTIFICATION = 'right-header-content/MARK_NOTIFICATION';
export const markNotificationAction = payload => ({
	type: MARK_NOTIFICATION,
	payload,
});
