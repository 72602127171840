import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const selectEmailVerifyDomain = state => state.emailVerifyContainerReducer || initialState;

export const selectProfileModalDomain = state => state.profileModalReducer || initialState;

export const getUserInfoSelector = () => createSelector(selectProfileModalDomain, ({ userInfo }) => userInfo);

export const getEmailVerifyInfoSelector = () =>
	createSelector(selectEmailVerifyDomain, ({ emailVerifyInfo }) => emailVerifyInfo);
