/* eslint-disable react/prop-types */
import React from 'react';
import { Provider } from 'react-redux';
import './App.less';
import { ConnectedRouter } from 'connected-react-router';
import moment from 'moment';
import { createBrowserHistory } from 'history';
import configureStore from './store/createStore';
import { NotificationList } from './components/notifications-list';
import { Auth } from './components/auth';
import { Router } from './components/router';

const history = createBrowserHistory();

const store = configureStore({}, history);
moment.locale('ru');

function App() {
	return (
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<NotificationList />
				<Auth />
				<Router />
			</ConnectedRouter>
		</Provider>
	);
}

export default App;
