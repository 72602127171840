/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { useHistory, useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';

import { getReqInfoAction, resetStateAction, sendCargoPickupFormAction } from './actions';
import { getActiveReqIdSelector, getIsReqInfoLoadingSelector, getIsModalShownSelector } from './selectors';
import { FORM_NAME } from './constants';
import { removeModalQuery } from '../../utils/removeModalQuery';

import { CargoPickupForm } from './components/cargo-pickup-form';

const CargoPickupWrapper = ({
	onGetReqInfo,
	isReqInfoLoading,
	onResetState,
	isModalShown,
	activeReqId,
	onFormSubmit,
	onSendCargoPickupForm,
}) => {
	useInjectSaga({ key: 'cargoPickupModalSaga', saga });
	useInjectReducer({ key: 'cargoPickupModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		return () => onResetState();
	}, []);

	useEffect(() => {
		if (activeReqId) onGetReqInfo(activeReqId);
	}, [activeReqId]);

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('mode');
		query.delete('id');
		query.delete('tab');

		onResetState();
		history.push(`${pathname}?${query}`);
	};

	const handleFormSubmit = async () => {
		await onFormSubmit(FORM_NAME);
		onSendCargoPickupForm({ redirect: handleModalClose, searchQuery: removeModalQuery(query.toString()) });
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleFormSubmit();
		}
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText="Форма забора груза"
			navItems={[]}
			modalContent={<CargoPickupForm />}
			style={{ width: '752px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'cargo-pickup' }]}
			footerButtons={
				<ModalFooterButtons
					leftButtons={[]}
					rightButtons={[
						{
							onClick: () => handleFormSubmit(),
							text: 'Груз забран',
							color: 'primary',
							disabled: isReqInfoLoading,
						},
						{
							onClick: handleModalClose,
							text: 'Закрыть',
							color: 'secondary',
						},
					]}
				/>
			}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	isReqInfoLoading: getIsReqInfoLoadingSelector(),
	isModalShown: getIsModalShownSelector(),
	activeReqId: getActiveReqIdSelector(),
});

const mapDispatchToProps = {
	onGetReqInfo: getReqInfoAction,
	onResetState: resetStateAction,
	onFormSubmit: submit,
	onSendCargoPickupForm: sendCargoPickupFormAction,
};

const CargoPickupModal = connect(mapStateToProps, mapDispatchToProps)(CargoPickupWrapper);

CargoPickupWrapper.propTypes = {
	onGetReqInfo: PropTypes.func.isRequired,
	isReqInfoLoading: PropTypes.bool.isRequired,
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	activeReqId: PropTypes.string,
	onFormSubmit: PropTypes.func.isRequired,
	onSendCargoPickupForm: PropTypes.func.isRequired,
};

export default CargoPickupModal;
