export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const FORM_NAME = 'car-change-modal-name';

export const ADD_CAR_FORM_NAME = 'add-car-form-name';

export const ADD_TRAILER_FORM_NAME = 'add-trailer-form-name';

export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';

export const REQUIRED_FIELDS = ['car_id'];

export const FORM_FIELDS = ['car_id', 'trailer_id'];

export const ADD_CAR_FORM_REQUIRED_FIELDS = ['name', 'number'];

export const ADD_CAR_FORM_FIELDS = ['name', 'number', 'vin'];

export const ADD_TRAILER_FORM_REQUIRED_FIELDS = ['name', 'number'];

export const ADD_TRAILER_FORM_FIELDS = ['name', 'number', 'vin'];
