/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { change, reduxForm, submit } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { ModalWindow } from '../../../../semantic-ui/components/modal-window';
import { LoginModalView } from './login-modal-view';
import { LOGIN_FORM_NAME, LOGIN_MODAL_STYLE } from '../../constants';
import { authorizeUserAction, setIdOfOpenModalAction } from '../../actions';
import {
	getHasLoginErrorSelector,
	getIdOfOpenModalSelector,
	getIsUserAuthorizingSelector,
	getLoginErrorMessageSelector,
} from '../../selectors';
import { loginValidate } from '../../utils';

const LoginModalWrapper = ({
	idOfOpenModal,
	onSetIdOfOpenModal,
	onFormSubmit,
	onAuthorizeUser,
	loginErrorMessage,
	hasLoginError,
	isUserAuthorizing,
	onFieldChange,
}) => {
	const history = useHistory();

	const { search } = useLocation();

	const query = new URLSearchParams(search);

	useEffect(() => {
		onFieldChange(LOGIN_FORM_NAME, 'email', query.get('login'));
		onFieldChange(LOGIN_FORM_NAME, 'password', query.get('password'));
	}, []);

	const handleForgotModalOpen = () => {
		onSetIdOfOpenModal('forgot');
	};

	const handleFormSubmit = async () => {
		await onFormSubmit(LOGIN_FORM_NAME);
		onAuthorizeUser(history.push);
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleFormSubmit();
		}
	};

	return (
		<ModalWindow
			isModalShown={idOfOpenModal === 'login'}
			onKeyDown={handleKeyDown}
			modalContent={
				<LoginModalView
					onForgotModalOpen={handleForgotModalOpen}
					onFormSubmit={handleFormSubmit}
					loginErrorMessage={loginErrorMessage}
					hasLoginError={hasLoginError}
					isUserAuthorizing={isUserAuthorizing}
				/>
			}
			style={LOGIN_MODAL_STYLE}
		/>
	);
};

const withForm = reduxForm({
	form: LOGIN_FORM_NAME,
	enableReinitialize: true,
	onSubmit: loginValidate,
})(LoginModalWrapper);

const mapStateToProps = createStructuredSelector({
	idOfOpenModal: getIdOfOpenModalSelector(),
	loginErrorMessage: getLoginErrorMessageSelector(),
	hasLoginError: getHasLoginErrorSelector(),
	isUserAuthorizing: getIsUserAuthorizingSelector(),
});

const mapDispatchToProps = {
	onFieldChange: change,
	onSetIdOfOpenModal: setIdOfOpenModalAction,
	onFormSubmit: submit,
	onAuthorizeUser: authorizeUserAction,
};

LoginModalWrapper.propTypes = {
	onSetIdOfOpenModal: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	onAuthorizeUser: PropTypes.func.isRequired,
	loginErrorMessage: PropTypes.string.isRequired,
	hasLoginError: PropTypes.bool.isRequired,
	isUserAuthorizing: PropTypes.bool.isRequired,
	idOfOpenModal: PropTypes.oneOf(['login', 'forgot']).isRequired,
	onFieldChange: PropTypes.func.isRequired,
};

const LoginModal = connect(mapStateToProps, mapDispatchToProps)(withForm);

export { LoginModal };
