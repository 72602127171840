/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import Logo from '../../../images/logo.png';
import LogoMini from '../../../images/logo-mini.png';
import { MENU_ICONS } from '../../constants';
import ChevronDownIcon from '../../../images/chevron-down.svg';
import LinkIcon from '../../../icons/link-icon.svg';

const SidebarComponent = ({ sideBarData, onMenuItemClick, onLogoClick }) => (
	<div className="sidebar">
		<div className="sidebar__logo-container">
			<img className="sidebar__logo-container__logo" src={Logo} alt="logo" onClick={onLogoClick} />
			<img className="sidebar__logo-container__logo-mini" src={LogoMini} alt="logo" onClick={onLogoClick} />
		</div>
		<div className="sidebar__container">
			{sideBarData.map(({ text, route, id, isActive, isSubmenuOpen, submenu, count }) => {
				return (
					<div key={id} className="sidebar__item">
						<div
							className={`sidebar__item-container${isActive ? ' sidebar__item-container_active' : ''}`}
							onClick={() => onMenuItemClick(id, Boolean(submenu?.length), route)}
						>
							<div className="sidebar__item-content">
								<div className="sidebar__icon-wrapper">
									<img src={MENU_ICONS[id]} alt={id} />
								</div>
								<span className="sidebar__text">{text}</span>
								{count && (
									<span className={`${isActive ? 'sidebar__count_active ' : ''}sidebar__count`}>
										{count}
									</span>
								)}
							</div>
							{Boolean(submenu?.length) && (
								<img
									className={`sidebar__chevron-icon ${isSubmenuOpen ? 'up' : 'down'}`}
									src={ChevronDownIcon}
									alt="chevron-down-icon"
								/>
							)}
						</div>
						{Boolean(isSubmenuOpen) && (
							<div className="sidebar__submenu">
								<ul className="sidebar__submenu-list">
									{submenu.map(({ text, route, id, isActive, modal }) => (
										<li
											className={`sidebar__submenu-item${
												isActive ? ' sidebar__submenu-item_active' : ''
											}`}
											key={id}
											onClick={() => onMenuItemClick(id, false, route, modal)}
										>
											<img className="" src={MENU_ICONS[id] ?? LinkIcon} alt={id} />
											<span className="sidebar__submenu-item__text">{text}</span>
										</li>
									))}
								</ul>
							</div>
						)}
					</div>
				);
			})}
		</div>
	</div>
);

SidebarComponent.propTypes = {
	sideBarData: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			isActive: PropTypes.bool.isRequired,
			isSubmenuOpen: PropTypes.bool.isRequired,
			text: PropTypes.string.isRequired,
			route: PropTypes.string,
			subRoutes: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.string.isRequired,
					isActive: PropTypes.bool.isRequired,
					text: PropTypes.string.isRequired,
					route: PropTypes.string,
				}),
			),
		}),
	).isRequired,
	onMenuItemClick: PropTypes.func.isRequired,
	onLogoClick: PropTypes.func.isRequired,
};

export { SidebarComponent };
