export const formatTime = seconds => {
	const hours = Math.floor(seconds / 3600);

	const minutes = Math.floor((seconds % 3600) / 60);

	const secs = seconds % 60;

	const pad = num => (num < 10 ? `0${num}` : String(num));

	return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
};
