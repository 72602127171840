import React from 'react';
import PropTypes from 'prop-types';
import { SimpleTableComponent } from '../../../../components/simple-table';
import { DETAIL_TABLE_COLUMNS_NAMES } from '../../constants';
import { DetailForm } from '../detail-form';

export const DetailModalContent = ({ detailInfo, isDetailFormOpen, isDetailInfoLoading }) => {
	return isDetailFormOpen ? (
		<DetailForm />
	) : (
		<SimpleTableComponent
			rows={detailInfo}
			isTableDataLoading={isDetailInfoLoading}
			columns={DETAIL_TABLE_COLUMNS_NAMES}
		/>
	);
};

DetailModalContent.propTypes = {
	detailInfo: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.shape({
					name: PropTypes.string,
					role: PropTypes.string,
				}),
			]).isRequired,
		}),
	),
	isDetailFormOpen: PropTypes.bool.isRequired,
	isDetailInfoLoading: PropTypes.bool.isRequired,
};
