import { RESET_STATE, SET_REQ_INFO, SET_IS_REQ_INFO_LOADING, SET_IS_FORM_SENDING } from './actions';

export const initialState = {
	isReqInfoLoading: true,
	reqInfo: null,
	isFormSending: false,
};

const cargoDeliveryModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_REQ_INFO:
			return {
				...state,
				reqInfo: payload,
			};
		case SET_IS_REQ_INFO_LOADING:
			return {
				...state,
				isReqInfoLoading: payload,
			};
		case SET_IS_FORM_SENDING:
			return {
				...state,
				isFormSending: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default cargoDeliveryModalReducer;
