import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';
import moment from 'moment';
import { Icon } from 'semantic-ui-react';

const Header = ({ decreaseMonth, increaseMonth, monthDate, seatMonth }) => {
	const handleMonthIncrease = () => {
		seatMonth(moment(monthDate).add(1, 'M'));
		increaseMonth();
	};

	const handleMonthDecrease = () => {
		seatMonth(moment(monthDate).subtract(1, 'M'));
		decreaseMonth();
	};

	return (
		<div className="custom-datepicker-header">
			<div onClick={handleMonthDecrease} className="custom-datepicker-header__button">
				<Icon name="angle double left" />
			</div>
			<span className="custom-datepicker-header__current-month">
				{monthDate.toLocaleString('ru', {
					month: 'long',
					year: 'numeric',
				})}
			</span>
			<div onClick={handleMonthIncrease} className="custom-datepicker-header__button">
				<Icon name="angle double right" />
			</div>
		</div>
	);
};

Header.propTypes = {
	decreaseMonth: PropTypes.func.isRequired,
	increaseMonth: PropTypes.func.isRequired,
	monthDate: PropTypes.instanceOf(Date).isRequired,
	seatMonth: PropTypes.func.isRequired,
};

export { Header };
