import React from 'react';
import './styles.less';

const CompanyValidateView = () => {
	return (
		<div className="company-validate">
			<div className="company-validate__overlay" />
			<div className="company-validate__content">
				<div>
					<h2 className="company-validate__title company-validate__title_secondary">Спасибо</h2>
					<h2 className="company-validate__title company-validate__title_primary">за регистрацию</h2>
				</div>
				<p className="company-validate__text">
					Для доступа в систему Вам необходимо пройти процедуру валидации и аккредитации. Для подробной
					информации обратитесь по номеру телефона +7 495 136 22 00 или почте Hello@mak-logic.ru
				</p>
			</div>
		</div>
	);
};

export { CompanyValidateView };
