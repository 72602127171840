export const CHANGE_CAR = 'car-change-modal/CHANGE_CAR';
export const changeCarAction = payload => ({
	type: CHANGE_CAR,
	payload,
});

export const SET_IS_CAR_CHANGING = 'car-change-modal/SET_IS_CAR_CHANGING';
export const setIsCarChangingAction = payload => ({
	type: SET_IS_CAR_CHANGING,
	payload,
});

export const RESET_STATE = 'car-change-modal/RESET_STATE';
export const resetStateAction = payload => ({
	type: RESET_STATE,
	payload,
});

export const GET_REQUEST_INFO = 'car-change-modal/GET_REQUEST_INFO';
export const getRequestInfoAction = payload => ({
	type: GET_REQUEST_INFO,
	payload,
});

export const SET_REQUEST_INFO = 'car-change-modal/SET_REQUEST_INFO';
export const setRequestInfoAction = payload => ({
	type: SET_REQUEST_INFO,
	payload,
});

export const SET_IS_REQUEST_INFO_LOADING = 'car-change-modal/SET_IS_REQUEST_INFO_LOADING';
export const setIsRequestInfoLoadingAction = payload => ({
	type: SET_IS_REQUEST_INFO_LOADING,
	payload,
});

export const GET_CARS = 'car-change-modal/GET_CARS';
export const getCarsAction = payload => ({
	type: GET_CARS,
	payload,
});

export const SET_CARS = 'car-change-modal/SET_CARS';
export const setCarsAction = payload => ({
	type: SET_CARS,
	payload,
});

export const SET_TRAILERS = 'car-change-modal/SET_TRAILERS';
export const setTrailersAction = payload => ({
	type: SET_TRAILERS,
	payload,
});

export const SET_IS_CARS_LOADING = 'car-change-modal/SET_IS_CARS_LOADING';
export const setIsCarsLoadingAction = payload => ({
	type: SET_IS_CARS_LOADING,
	payload,
});

export const CREATE_CAR = 'car-change-modal/CREATE_CAR';
export const createCarAction = payload => ({
	type: CREATE_CAR,
	payload,
});

export const SET_IS_CAR_SAVING = 'car-change-modal/SET_IS_CAR_SAVING';
export const setIsCarSavingAction = payload => ({
	type: SET_IS_CAR_SAVING,
	payload,
});

export const GET_TRAILERS_LIST = 'car-change-modal/GET_TRAILERS_LIST';
export const getTrailersListAction = payload => ({
	type: GET_TRAILERS_LIST,
	payload,
});

export const SET_TRAILERS_LIST = 'car-change-modal/SET_TRAILERS_LIST';
export const setTrailersListAction = payload => ({
	type: SET_TRAILERS_LIST,
	payload,
});

export const SET_IS_TRAILERS_LIST_LOADING = 'car-change-modal/SET_IS_TRAILERS_LIST_LOADING';
export const setIsTrailersListLoadingAction = payload => ({
	type: SET_IS_TRAILERS_LIST_LOADING,
	payload,
});

export const CREATE_TRAILER = 'car-change-modal/CREATE_TRAILER';
export const createTrailerAction = payload => ({
	type: CREATE_TRAILER,
	payload,
});

export const SET_IS_TRAILER_SAVING = 'car-change-modal/SET_IS_TRAILER_SAVING';
export const setIsTrailerSavingAction = payload => ({
	type: SET_IS_TRAILER_SAVING,
	payload,
});

export const GET_TONNAGES = 'car-change-modal/GET_TONNAGES';
export const getTonnagesAction = id => ({
	type: GET_TONNAGES,
	id,
});

export const SET_TONNAGES = 'car-change-modal/SET_TONNAGES';
export const setTonnagesAction = payload => ({
	type: SET_TONNAGES,
	payload,
});

export const SET_IS_TONNAGES_LOADING = 'car-change-modal/SET_IS_TONNAGES_LOADING';
export const setIsTonnagesLoadingAction = payload => ({
	type: SET_IS_TONNAGES_LOADING,
	payload,
});
