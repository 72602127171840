import { SubmissionError } from 'redux-form';
import { FIELDS_FOR_VALIDATE } from './constants';

export const transformToValidationErrors = errors => {
	return Object.entries(errors).reduce((result, [key, value]) => {
		return {
			...result,
			[key]: value[0],
		};
	}, {});
};

export const validate = values => {
	FIELDS_FOR_VALIDATE.forEach(key => {
		if (!values?.[key]) {
			throw new SubmissionError({ [key]: 'Заполните это поле' });
		}
	});
};
