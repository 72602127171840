import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';

const MarkerCell = ({ rowData, columnId }) => {
	return (
		// eslint-disable-next-line
		<a target="_blank" rel="noreferrer noopener" href={rowData[columnId]} className="marker-cell">
			<span className="marker-cell__text" />
		</a>
	);
};

MarkerCell.propTypes = {
	rowData: PropTypes.object.isRequired,
	columnId: PropTypes.string.isRequired,
};

export { MarkerCell };
