import React from 'react';
import { PageContentWithNav } from '../../components/page-content-with-nav';
import { HOME_PAGE_LIST } from './constants';
import { HomeRouter } from '../../components/home-router';
import { filterMenuByRules } from './utils';
import { getFromStorage } from '../../components/sidebar/utils';

const HomePage = () => {
	const rules = getFromStorage('rules');

	return (
		<PageContentWithNav
			pageNavigationItems={filterMenuByRules(HOME_PAGE_LIST, rules)}
			pageContent={<HomeRouter />}
		/>
	);
};

export default HomePage;
