export const GET_SPECIFY_FLIGHT = 'specify-flight/GET_SPECIFY_FLIGHT';
export const getSpecifyFlightAction = payload => ({
	type: GET_SPECIFY_FLIGHT,
	payload,
});

export const SET_SPECIFY_FLIGHT_INFO = 'specify-flight/SET_SPECIFY_FLIGHT_INFO';
export const setSpecifyFlightInfoAction = payload => ({
	type: SET_SPECIFY_FLIGHT_INFO,
	payload,
});

export const SET_IS_SPECIFY_FLIGHT_INFO_LOADING = 'specify-flight/SET_IS_SPECIFY_FLIGHT_INFO_LOADING';
export const setIsSpecifyFlightInfoLoadingAction = payload => ({
	type: SET_IS_SPECIFY_FLIGHT_INFO_LOADING,
	payload,
});

export const SEND_FLIGHT = 'specify-flight/SEND_FLIGHT';
export const sendFlightAction = payload => ({
	type: SEND_FLIGHT,
	payload,
});

export const SET_IS_FORM_SENDING = 'specify-flight/SET_IS_FORM_SENDING';
export const setIsFormSendingAction = payload => ({
	type: SET_IS_FORM_SENDING,
	payload,
});
