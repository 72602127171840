export const CREATE_ADDRESS = 'addresses-modals/CREATE_ADDRESS';
export const createAddressAction = payload => ({
	type: CREATE_ADDRESS,
	payload,
});

export const GET_ADDRESS_INFO = 'addresses-modals/GET_ADDRESS_INFO';
export const getAddressInfoAction = payload => ({
	type: GET_ADDRESS_INFO,
	payload,
});

export const SET_ADDRESS_INFO = 'addresses-modals/SET_ADDRESS_INFO';
export const setAddressInfoAction = payload => ({
	type: SET_ADDRESS_INFO,
	payload,
});

export const SET_IS_ADDRESS_INFO_LOADING = 'addresses-modals/SET_IS_ADDRESS_INFO_LOADING';
export const setIsAddressInfoLoadingAction = payload => ({
	type: SET_IS_ADDRESS_INFO_LOADING,
	payload,
});

export const DELETE_ADDRESS = 'addresses-modals/DELETE_ADDRESS';
export const deleteAddressAction = payload => ({
	type: DELETE_ADDRESS,
	payload,
});

export const UPDATE_ADDRESS = 'addresses-modals/UPDATE_ADDRESS';
export const updateAddressAction = payload => ({
	type: UPDATE_ADDRESS,
	payload,
});

export const SET_IS_ADDRESS_UPDATING = 'addresses-modals/SET_IS_ADDRESS_UPDATING';
export const setIsAddressUpdatingAction = payload => ({
	type: SET_IS_ADDRESS_UPDATING,
	payload,
});

export const SET_IS_ADDRESS_DELETING = 'addresses-modals/SET_IS_ADDRESS_DELETING';
export const setIsAddressDeletingAction = payload => ({
	type: SET_IS_ADDRESS_DELETING,
	payload,
});

export const RESET_STATE = 'addresses-modals/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const SET_ADDRESS_SEARCH_VALUE = 'addresses-modals/SET_ADDRESS_SEARCH_VALUE';
export const setAddressSearchValueAction = payload => ({
	type: SET_ADDRESS_SEARCH_VALUE,
	payload,
});

export const GET_ADDRESSES_OPTIONS = 'addresses-modals/GET_ADDRESSES_OPTIONS';
export const getAddressesOptionsAction = payload => ({
	type: GET_ADDRESSES_OPTIONS,
	payload,
});

export const SET_ADDRESSES_OPTIONS = 'addresses-modals/SET_ADDRESSES_OPTIONS';
export const setAddressesOptionsAction = payload => ({
	type: SET_ADDRESSES_OPTIONS,
	payload,
});

export const SET_IS_ADDRESSES_OPTIONS_LOADING = 'addresses-modals/SET_IS_ADDRESSES_OPTIONS_LOADING';
export const setIsAddressesOptionsLoadingAction = payload => ({
	type: SET_IS_ADDRESSES_OPTIONS_LOADING,
	payload,
});

export const GET_CONTACTS = 'addresses-modals/GET_CONTACTS';
export const getContactsAction = payload => ({
	type: GET_CONTACTS,
	payload,
});

export const SET_CONTACTS = 'addresses-modals/SET_CONTACTS';
export const setContactsAction = payload => ({
	type: SET_CONTACTS,
	payload,
});

export const SET_CONTACTS_SEARCH_VALUE = 'addresses-modals/SET_CONTACTS_SEARCH_VALUE';
export const setContactsSearchValueAction = payload => ({
	type: SET_CONTACTS_SEARCH_VALUE,
	payload,
});

export const SET_IS_ADDRESS_CREATING = 'addresses-modals/SET_IS_ADDRESS_CREATING';
export const setIsAddressCreatingAction = payload => ({
	type: SET_IS_ADDRESS_CREATING,
	payload,
});

export const GET_PARTNER_ADDRESS_INFO = 'addresses-modals/GET_PARTNER_ADDRESS_INFO';
export const getPartnerAddressInfoAction = payload => ({
	type: GET_PARTNER_ADDRESS_INFO,
	payload,
});

export const CREATE_PARTNER_ADDRESS = 'addresses-modals/CREATE_PARTNER_ADDRESS';
export const createPartnerAddressAction = payload => ({
	type: CREATE_PARTNER_ADDRESS,
	payload,
});

export const UPDATE_PARTNER_ADDRESS = 'addresses-modals/UPDATE_PARTNER_ADDRESS';
export const updatePartnerAddressAction = payload => ({
	type: UPDATE_PARTNER_ADDRESS,
	payload,
});

export const DELETE_PARTNER_ADDRESS = 'addresses-modals/DELETE_PARTNER_ADDRESS';
export const deletePartnerAddressAction = payload => ({
	type: DELETE_PARTNER_ADDRESS,
	payload,
});
