export const SET_ID_OF_OPEN_MODAL = 'login/SET_ID_OF_OPEN_MODAL';
export const setIdOfOpenModalAction = payload => ({
	type: SET_ID_OF_OPEN_MODAL,
	payload,
});

export const RESET_STATE = 'login/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const AUTHORIZE_USER = 'login/AUTHORIZE_USER';
export const authorizeUserAction = payload => ({
	type: AUTHORIZE_USER,
	payload,
});

export const SET_IS_USER_AUTHORIZING = 'login/SET_IS_USER_AUTHORIZING';
export const setIsUserAuthorizingAction = payload => ({
	type: SET_IS_USER_AUTHORIZING,
	payload,
});

export const SET_LOGIN_ERROR_MESSAGE = 'login/SET_LOGIN_ERROR_MESSAGE';
export const setLoginErrorMessageAction = payload => ({
	type: SET_LOGIN_ERROR_MESSAGE,
	payload,
});

export const SUBMIT_FORGOT_FORM = 'login/SUBMIT_FORGOT_FORM';
export const submitForgotFormAction = payload => ({
	type: SUBMIT_FORGOT_FORM,
	payload,
});

export const SET_IS_FORGOT_FORM_SUBMITTING = 'login/SET_IS_FORGOT_FORM_SUBMITTING';
export const setIsForgotFormSubmittingAction = payload => ({
	type: SET_IS_FORGOT_FORM_SUBMITTING,
	payload,
});
