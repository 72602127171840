import {
	RESET_STATE,
	SET_ACCORDION_INDEX,
	SET_ANALYTICS,
	SET_ANALYTICS_ACCORDION_INDEX,
	SET_FILTER_OPTIONS,
} from './actions';

export const initialState = {
	accordionIndex: 0,
	filterOptions: {},
	analytics: null,
	analyticsAccordionIndex: [],
};

const reqsFilterReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_ACCORDION_INDEX:
			return {
				...state,
				accordionIndex: payload,
			};
		case SET_FILTER_OPTIONS:
			return {
				...state,
				filterOptions: payload,
			};
		case SET_ANALYTICS:
			return {
				...state,
				analytics: payload,
			};
		case SET_ANALYTICS_ACCORDION_INDEX:
			return {
				...state,
				analyticsAccordionIndex: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default reqsFilterReducer;
