import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { Field } from 'redux-form';
import { LoginField } from '../../../../semantic-ui/components/login-field';

export const ResetPasswordView = ({ onConfirmButtonClick, isResetPasswordFormSubmitting }) => {
	return (
		<div className="reset-password">
			<header className="reset-password__header">
				<div className="reset-password__header-text">
					<h3 className="reset-password__title">Сброс пароля</h3>
					<h5 className="reset-password__text">Пожалуйста, введите новый пароль</h5>
				</div>
			</header>
			<div className="reset-password__field">
				<Field name="password" type="password" component={LoginField} label="Новый пароль" />
			</div>
			<div className="reset-password__field">
				<Field
					name="password_confirmation"
					type="password"
					component={LoginField}
					label="Подтверждение пароля"
				/>
			</div>
			<Button
				className="reset-password__submit-button"
				onClick={onConfirmButtonClick}
				loading={isResetPasswordFormSubmitting}
				disabled={isResetPasswordFormSubmitting}
			>
				Сбросить пароль
			</Button>
		</div>
	);
};

ResetPasswordView.propTypes = {
	onConfirmButtonClick: PropTypes.func.isRequired,
	isResetPasswordFormSubmitting: PropTypes.bool.isRequired,
};
