import { RESET_STATE, SET_AUDIT_DATA, SET_IS_AUDIT_DATA_LOADING } from './actions';

export const initialState = {
	auditData: null,
	isAuditDataLoading: false,
};

const auditComponentReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_AUDIT_DATA:
			return {
				...state,
				auditData: payload,
			};
		case SET_IS_AUDIT_DATA_LOADING:
			return {
				...state,
				isAuditDataLoading: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default auditComponentReducer;
