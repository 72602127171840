import { CustomModalCell } from '../../components/custom-modal-cell';

export const PARTNER_FORM_NAME = 'partner-form';

export const REQUIRED_FIELDS = ['name', 'inn', 'ogrn', 'address'];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const PARTNER_TABLE_COLUMNS_NAMES = [{ id: 'title' }, { id: 'value', customComponent: CustomModalCell }];

export const PARTNER_TABLE_ROWS_NAMES = {
	inn: 'ИНН',
	ogrn: 'ОГРН',
	address: 'Юридический адрес',
	author: 'Автор',
};
