import React from 'react';
import './styles.less';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormInputField } from '../../../../../semantic-ui/components/form-input-field';
import { FormSelect } from '../../../../../semantic-ui/components/form-select';

export const PartnerFormView = ({
	partnersFormOptions,
	isPartnersFormOptionsLoading,
	onPartnersFormSearchChange,
	onPartnerChange,
	partnersFormSearchValue,
}) => {
	return (
		<div className="partner-form">
			<section className="partner-form__section">
				<div className="partner-form__row">
					<span className="partner-form__text partner-form__text_right partner-form__text_bold">
						Справочник
					</span>
					<div className="partner-form__field">
						<Field
							name="select"
							type="text"
							component={FormSelect}
							autoComplete="off"
							placeholder="Введите ИНН/ОГРН или название организации"
							icon="search"
							options={partnersFormOptions}
							loading={isPartnersFormOptionsLoading}
							noResultsMessage="Неизвестная организация"
							onSearchChange={onPartnersFormSearchChange}
							onItemChange={onPartnerChange}
							searchValue={partnersFormSearchValue}
						/>
					</div>
				</div>
				<div className="partner-form__row">
					<span className="partner-form__header-text">
						Вы можете заполнить реквизиты вручную или выбрать из справочника.
					</span>
				</div>
				<div className="partner-form__row">
					<span className="partner-form__text partner-form__text_right">Название организации</span>
					<div className="partner-form__field">
						<Field
							name="name"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите название организации"
						/>
					</div>
				</div>
				<div className="partner-form__row">
					<span className="partner-form__text partner-form__text_right">ИНН</span>
					<div className="partner-form__field">
						<Field
							name="inn"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите ИНН"
						/>
					</div>
				</div>
				<div className="partner-form__row">
					<span className="partner-form__text partner-form__text_right">ОГРН</span>
					<div className="partner-form__field">
						<Field
							name="ogrn"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите ОГРН"
						/>
					</div>
				</div>
				<div className="partner-form__row">
					<span className="partner-form__text partner-form__text_right">Юридический адрес организации</span>
					<div className="partner-form__field">
						<Field
							name="address"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Юридический адрес организации"
						/>
					</div>
				</div>
			</section>
		</div>
	);
};

PartnerFormView.propTypes = {
	partnersFormOptions: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string,
			data: PropTypes.shape({
				title: PropTypes.string.isRequired,
				address: PropTypes.string.isRequired,
				deleted: PropTypes.bool,
			}),
		}),
	).isRequired,
	isPartnersFormOptionsLoading: PropTypes.bool.isRequired,
	onPartnersFormSearchChange: PropTypes.func.isRequired,
	onPartnerChange: PropTypes.func.isRequired,
	partnersFormSearchValue: PropTypes.string.isRequired,
};
