/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { getFormValues, isInvalid } from 'redux-form';
import { initialState } from './reducer';
import { FORM_NAME } from './constants';

export const selectSpecifyFlightModalDomain = state => state.specifyFlightModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getIsFlightInfoLoadingSelector = () =>
	createSelector(selectSpecifyFlightModalDomain, ({ isFlightInfoLoading }) => isFlightInfoLoading);

export const getIsFormSendingSelector = () =>
	createSelector(selectSpecifyFlightModalDomain, ({ isFormSending }) => isFormSending);

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'specify_flight');
	});

export const getDataForRequestSelector = () => createSelector(getFormValues(FORM_NAME), formValues => formValues);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);

export const getFormInitialValuesSelector = () =>
	createSelector(
		selectSpecifyFlightModalDomain,
		getFormValues(FORM_NAME),
		({ flightInfo, isFlightInfoLoading }, formValues) => {
			if (isFlightInfoLoading) {
				return {};
			}

			return {
				flight: formValues?.flight || flightInfo?.flight || '',
			};
		},
	);
