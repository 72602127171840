export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const NAV_ITEMS = {
	'Сборный груз': 'combined',
	'Отдельная машина': 'car',
	'Срочная заявка': 'express',
	Авиа: 'avia',
};

export const NAV_ITEMS_KEYS = Object.keys(NAV_ITEMS);
