import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { Field } from 'redux-form';
import './styles.less';
import { FormSelectField } from '../../../../semantic-ui/components/form-select-field';

export const DriverChangeModalForm = ({ isDriversLoading, driversOptions }) => {
	return (
		<div className="driver-change-modal-form__wrapper">
			<Grid className="driver-change-modal-form">
				<Grid.Row className="driver-change-modal-form__row">
					<Grid.Column width={6} className="driver-change-modal-form__column">
						Водитель
					</Grid.Column>
					<Grid.Column width={7} className="driver-change-modal-form__column">
						<Field
							name="driver_id"
							type="text"
							component={FormSelectField}
							autoComplete="off"
							placeholder="Выберите водителя"
							options={driversOptions}
							loading={isDriversLoading}
							noResultsMessage="Неизвестный водитель"
							search
							dropdownClassName="static"
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
};

DriverChangeModalForm.propTypes = {
	isDriversLoading: PropTypes.bool.isRequired,
	driversOptions: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.number.isRequired,
			text: PropTypes.string.isRequired,
			value: PropTypes.number.isRequired,
		}),
	).isRequired,
};
