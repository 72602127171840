export const validate = (documents, files) => {
	const result = {
		isValid: true,
		errors: {},
	};

	documents.forEach(document => {
		if (document.is_required && !files[document.key]?.length) {
			result.isValid = false;
			result.errors[document.key] = 'Необходимо выбрать файл';
		}
	});

	return result;
};

export const transformToValidationErrors = errors => {
	return Object.entries(errors).reduce((prev, [key, error]) => {
		if (!prev[key]) {
			prev[key] = {};
		}

		prev[key] = error;

		return prev;
	}, {});
};
