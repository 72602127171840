/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { getFormValues, isInvalid } from 'redux-form';
import { initialState } from './reducer';
import { ADD_CAR_FORM_NAME, ADD_TRAILER_FORM_NAME, DATE_TIME_FORMAT, FORM_NAME } from './constants';

export const carChangeModalDomain = state => state.carChangeModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getIsAddCarModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'add-car-modal');
	});

export const getIsAddTrailerModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(
			!isEmpty(query) && (query?.modal === 'add-trailer-modal' || query?.modal === 'add-trailer-to-main-modal'),
		);
	});

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(
			!isEmpty(query) &&
				['car_change', 'add-trailer-modal', 'add-car-modal', 'add-trailer-to-main-modal'].includes(
					query?.modal,
				),
		);
	});

export const getDataForSaveSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues => {
		return { ...formValues, trailer_id: formValues.trailer_id === 'none' ? null : formValues.trailer_id };
	});

export const getReqNameSelector = () =>
	createSelector(carChangeModalDomain, ({ requestInfo }) => {
		return `Заявка #${requestInfo?.id || '-'} от ${
			requestInfo?.created_at ? moment(requestInfo.created_at).format(DATE_TIME_FORMAT) : '-'
		}`;
	});

export const getModalStatusesSelector = () =>
	createSelector(carChangeModalDomain, ({ requestInfo }) => {
		const collectStatuses = [];

		if (requestInfo?.tab && !isEmpty(requestInfo?.tab)) {
			collectStatuses.push(requestInfo?.tab);
		}
		if (requestInfo?.status && !isEmpty(requestInfo?.status)) {
			collectStatuses.push(requestInfo?.status);
		}

		return collectStatuses;
	});

export const getIsButtonsDisabledSelector = () =>
	createSelector(
		carChangeModalDomain,
		({ isCarChanging, isRequestInfoLoading }) => isCarChanging || isRequestInfoLoading,
	);

export const getIsCarsLoadingSelector = () =>
	createSelector(carChangeModalDomain, ({ isCarsLoading }) => isCarsLoading);

export const getCarsOptionsSelector = () => createSelector(carChangeModalDomain, ({ cars }) => cars);

export const getTrailersOptionsSelector = () => createSelector(carChangeModalDomain, ({ trailers }) => trailers);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);

export const getAddCarFormIsInvalidSelector = () =>
	createSelector(isInvalid(ADD_CAR_FORM_NAME), isInvalid => isInvalid);

export const getAddTrailerFormIsInvalidSelector = () =>
	createSelector(isInvalid(ADD_TRAILER_FORM_NAME), isInvalid => isInvalid);

export const getAddCarFormInitialValuesSelector = () =>
	createSelector(carChangeModalDomain, () => {
		return {
			trailer: 'none',
			tonnage_id: 'none',
		};
	});

export const getAddCarForCreateValuesSelector = () =>
	createSelector(getFormValues(ADD_CAR_FORM_NAME), formValues => ({
		...formValues,
		trailer_id: formValues?.trailer === 'none' ? null : formValues?.trailer,
		tonnage_id: formValues?.tonnage_id === 'none' ? null : formValues?.tonnage_id,
	}));

export const getTrailersListSelector = () => createSelector(carChangeModalDomain, ({ trailersList }) => trailersList);

export const getIsTrailersListLoadingSelector = () =>
	createSelector(carChangeModalDomain, ({ isTrailersListLoading }) => isTrailersListLoading);

export const getAddTrailerFormInitialValuesSelector = () =>
	createSelector(carChangeModalDomain, () => {
		return {
			tonnage_id: 'none',
		};
	});

export const getAddTrailerForCreateValuesSelector = () =>
	createSelector(getFormValues(ADD_TRAILER_FORM_NAME), formValues => ({
		...formValues,
		tonnage_id: formValues.tonnage_id === 'none' ? null : formValues.tonnage_id,
	}));

export const getIsCarSavingSelector = () => createSelector(carChangeModalDomain, ({ isCarSaving }) => isCarSaving);

export const getIsTrailerSavingSelector = () =>
	createSelector(carChangeModalDomain, ({ isTrailerSaving }) => isTrailerSaving);

export const getTonnagesSelector = () => createSelector(carChangeModalDomain, ({ tonnages }) => tonnages);

export const getIsTonnagesLoadingSelector = () =>
	createSelector(carChangeModalDomain, ({ isTonnagesLoading }) => isTonnagesLoading);
