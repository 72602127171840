/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { getFormValues, isInvalid } from 'redux-form';
import moment from 'moment';
import { initialState } from './reducer';
import { DATE_FORMAT, DRIVER_ROLE_NUMBER, DRIVER_TABLE_ROWS_NAMES, FORM_NAME, TABLE_ROWS_NAMES } from './constants';
import { checkEquality, normalizePhone } from './utils';

export const selectUsersModalDomain = state => state.usersModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getUserInfoSelector = () =>
	createSelector(selectUsersModalDomain, ({ userInfo }) => {
		if (!userInfo) {
			return [];
		}

		const isDriver = userInfo.role?.id === DRIVER_ROLE_NUMBER;

		const data = {
			company: {
				name: userInfo.company?.name || '-',
				role: userInfo.company?.type.name || '-',
				id: userInfo.company?.id || '',
			},
			role: userInfo.role?.name || '-',
			post: userInfo.post || '-',
			...(isDriver
				? {
						passport_series: userInfo.passport_series || '-',
						passport_number: userInfo.passport_number || '-',
						passport_date_issue: userInfo.passport_date_issue
							? moment(userInfo.passport_date_issue).format(DATE_FORMAT)
							: '-',
						passport_issuing_authonity: userInfo.passport_issuing_authonity || '-',
						driver_license_number: userInfo.driver_license_number || '-',
				  }
				: {}),
			email: userInfo.email || '-',
			phone: `${userInfo.phone}${userInfo.extension ? ` ,${userInfo.extension}` : ''}` || '-',
			isNotify: userInfo.is_notify ? 'Да' : 'Нет',
			author: {
				name: `${userInfo.user?.last_name || '-'} ${userInfo.user?.first_name || '-'}`,
				role: userInfo.user?.role.name || '-',
			},
		};

		const rowsNames = isDriver ? DRIVER_TABLE_ROWS_NAMES : TABLE_ROWS_NAMES;

		return Object.entries(data).reduce((result, [key, value]) => {
			return [...result, { id: key, title: rowsNames[key], value }];
		}, []);
	});

export const getIsUserInfoLoadingSelector = () =>
	createSelector(
		selectUsersModalDomain,
		({ isUserInfoLoading, isUserDeleting, isUserSaving }) => isUserInfoLoading || isUserDeleting || isUserSaving,
	);

export const getUserNameSelector = () =>
	createSelector(selectUsersModalDomain, ({ userInfo }) => {
		return userInfo?.first_name && userInfo?.last_name ? `${userInfo?.first_name} ${userInfo?.last_name}` : '-';
	});

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'user');
	});

export const getIsAuditTabShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'user' && query?.tab === 'audit');
	});

export const getActiveUserIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => (query?.modal === 'user' ? query?.id : null));

export const getIsUserAddFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'user' && query?.mode === 'add');
	});

export const getIsUserEditFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'user' && Boolean(query?.id) && query?.mode === 'edit');
	});

export const getStatusToggleFormSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues => {
		return {
			isNotifyTodo: !formValues?.is_notify,
			isNotifyTodoCompany: !formValues?.is_notify || !formValues?.is_notify_todo,
		};
	});

export const getFormInitialValuesSelector = () =>
	createSelector(selectUsersModalDomain, getFormValues(FORM_NAME), ({ userInfo, isUserInfoLoading }, formValues) => {
		if (isUserInfoLoading) {
			return {};
		}

		if (!userInfo) {
			const isNotify = formValues?.is_notify ?? true;

			const isNotifyTodo = isNotify ? formValues?.is_notify_todo : false;

			const isNotifyTodoCompany = isNotifyTodo ? formValues?.is_notify_todo_company : false;

			return {
				...formValues,
				company: 'none',
				is_notify: isNotify,
				is_notify_todo: isNotifyTodo,
				is_notify_todo_company: isNotifyTodoCompany,
			};
		}

		const isNotify = checkEquality('is_notify', userInfo, formValues);

		const isNotifyTodo = isNotify ? checkEquality('is_notify_todo', userInfo, formValues) : false;

		const isNotifyTodoCompany = isNotifyTodo
			? checkEquality('is_notify_todo_company', userInfo, formValues)
			: false;

		return {
			company: formValues?.company || userInfo.company?.id || 'none',
			role: formValues?.role || userInfo.role?.id || 'none',
			first_name: formValues?.first_name || userInfo.first_name || '',
			last_name: formValues?.last_name || userInfo.last_name || '',
			middle_name: formValues?.middle_name || userInfo.middle_name || '',
			passport_series: formValues?.passport_series || userInfo.passport_series || '',
			passport_number: formValues?.passport_number || userInfo.passport_number || '',
			passport_date_issue: formValues?.passport_date_issue || userInfo.passport_date_issue || '',
			passport_issuing_authonity:
				formValues?.passport_issuing_authonity || userInfo.passport_issuing_authonity || '',
			driver_license_number: formValues?.driver_license_number || userInfo.driver_license_number || '',
			post: formValues?.post || userInfo.post || '',
			email: formValues?.email || userInfo.email || '',
			phone: normalizePhone(formValues?.phone) || normalizePhone(userInfo.phone) || '',
			extension: formValues?.extension || userInfo.extension || '',
			password: formValues?.password || userInfo.password || '',
			redirect_ids: formValues?.redirect_ids || userInfo.redirect_ids || [],
			is_notify: isNotify,
			is_notify_todo: isNotifyTodo,
			is_notify_todo_company: isNotifyTodoCompany,
		};
	});

export const getDataForCreateOrUpdateUserSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues => {
		const isDriver = formValues?.role === DRIVER_ROLE_NUMBER;

		if (isDriver) {
			const {
				is_notify,
				is_notify_todo,
				is_notify_todo_company,
				redirect_ids,
				password,
				password_confirmation,
				...driverFormValues
			} = formValues;

			return {
				...driverFormValues,
				company_id: formValues.company === 'none' ? null : formValues.company,
				...(formValues?.role ? { role_id: formValues.role } : {}),
			};
		}

		return {
			...formValues,
			company_id: formValues.company === 'none' ? null : formValues.company,
			redirect_ids: formValues.redirect_ids?.length < 1 ? null : formValues.redirect_ids,
			...(formValues?.role ? { role_id: formValues.role } : {}),
		};
	});

export const getIsDriverSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues => formValues?.role === DRIVER_ROLE_NUMBER);

export const getOptionsListSelector = () =>
	createSelector(selectUsersModalDomain, ({ companiesList, usersRolesList }) => ({
		companies: companiesList,
		usersRoles: usersRolesList,
	}));

export const getIsOptionsListLoadingSelector = () =>
	createSelector(selectUsersModalDomain, ({ isUsersRolesListLoading, isCompaniesListLoading }) => ({
		companies: isCompaniesListLoading,
		usersRoles: isUsersRolesListLoading,
	}));

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);

export const getIsUsersListLoadingSelector = () =>
	createSelector(selectUsersModalDomain, ({ isUsersListLoading }) => isUsersListLoading);

export const getUsersListSelector = () => createSelector(selectUsersModalDomain, ({ usersList }) => usersList);
