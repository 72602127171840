import { RESET_STATE, SET_CARS_LIST, SET_IS_CARS_LIST_LOADING, SET_PAGINATION, SET_TABLE_SEARCH } from './actions';

export const initialState = {
	carsList: null,
	isCarsListLoading: false,
	pagination: null,
	tableSearch: '',
};

const carsContainerReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_CARS_LIST:
			return {
				...state,
				carsList: payload,
			};
		case SET_IS_CARS_LIST_LOADING:
			return {
				...state,
				isCarsListLoading: payload,
			};
		case SET_PAGINATION:
			return {
				...state,
				pagination: payload,
			};
		case SET_TABLE_SEARCH:
			return {
				...state,
				tableSearch: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default carsContainerReducer;
