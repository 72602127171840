/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ReqsListTimerView } from './timer-view';
import { getTimerSelector } from '../../selectors';
import { formatTime } from '../../utils';

const TimerWrapper = ({ timer }) => {
	const [time, setTime] = useState(timer.time);

	useEffect(() => {
		setTime(timer.time);
	}, [timer]);

	useEffect(() => {
		const interval = setInterval(() => {
			setTime(prevTime => {
				if (prevTime <= 1) {
					clearInterval(interval);
					return 0;
				}

				return prevTime - 1;
			});
		}, 1000);

		return () => clearInterval(interval);
	}, [time]);

	return <ReqsListTimerView text={timer.text} time={formatTime(time)} />;
};

const mapStateToProps = createStructuredSelector({
	timer: getTimerSelector(),
});

TimerWrapper.propTypes = {
	timer: PropTypes.shape({
		time: PropTypes.number.isRequired,
		text: PropTypes.string.isRequired,
	}).isRequired,
};

const Timer = connect(mapStateToProps)(TimerWrapper);

export { Timer };
