/* eslint-disable no-mixed-spaces-and-tabs */
import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { notificationInit } from '../../modules/notifications/actions';
import { getUsersListRequest } from '../../api/requests';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { DISMISS_TIME } from '../../api/constants';
import { GET_USERS_LIST, setIsUsersListLoadingAction, setPaginationAction, setUsersListAction } from './actions';
import { errorHandler } from '../../api/utils';

export function* getUsersListSaga({ payload: searchQuery }) {
	try {
		yield put(setIsUsersListLoadingAction(true));
		const { data, meta, message, toast } = yield call(getUsersListRequest, searchQuery);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setUsersListAction(
					data?.map(user => ({
						...user,
						link: `/users?${searchQuery ? `${searchQuery}&` : ''}modal=user&id=${user.id}`,
					})),
				),
			);

			if (meta) {
				yield put(
					setPaginationAction({
						current_page: meta.current_page,
						per_page: meta.per_page,
						from: meta.from,
						last_page: meta.last_page,
						total: meta.total,
					}),
				);
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsUsersListLoadingAction(false));
	}
}

export default function* usersContainerSaga() {
	yield takeEvery(GET_USERS_LIST, getUsersListSaga);
}
