/* eslint-disable no-mixed-spaces-and-tabs */
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { stopSubmit, touch } from 'redux-form';
import { notificationInit } from '../../modules/notifications/actions';
import { CONTACT_FORM_NAME, REQUEST_ERROR_MESSAGE, REQUIRED_FIELDS } from './constants';
import {
	createContactRequest,
	deleteContactRequest,
	getContactInfoRequest,
	updateContactRequest,
} from '../../api/requests';
import {
	CREATE_CONTACT,
	DELETE_CONTACT,
	GET_CONTACT_INFO,
	UPDATE_CONTACT,
	setContactInfoAction,
	setIsContactDeletingAction,
	setIsContactInfoLoadingAction,
	setIsContactUpdatingAction,
} from './actions';
import { transformToValidationErrors } from './utils';
import { getContactFormValuesSelector, getFormIsInvalidSelector } from './selectors';
import { DISMISS_TIME } from '../../api/constants';
import { getContactsSelector, getRawPaginationSelector } from '../contacts/selectors';
import { setContactsAction, setPaginationAction } from '../contacts/actions';
import { errorHandler } from '../../api/utils';
import { removeModalQuery } from '../../utils/removeModalQuery';

export function* getContactInfoSaga({ payload: { addressId, contactId } }) {
	try {
		yield put(setIsContactInfoLoadingAction(true));

		const { data, message, toast } = yield call(getContactInfoRequest, addressId, contactId);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}
			yield put(setContactInfoAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsContactInfoLoadingAction(false));
	}
}

export function* createContactSaga({ payload: { redirect, resetState, addressId, searchQuery } }) {
	try {
		yield put(setIsContactUpdatingAction(true));

		const dataForSave = yield select(getContactFormValuesSelector());

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const { data, errors, message, toast } = yield call(createContactRequest, dataForSave, addressId);

		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link
							? {
									link: `${toast?.link}${
										removeModalQuery(searchQuery) ? `&${removeModalQuery(searchQuery)}` : ''
									}`,
							  }
							: {}),
					}),
				);
			}

			yield put(setContactInfoAction(data));

			const contacts = yield select(getContactsSelector());

			yield put(
				setContactsAction([
					{
						...data,
						name: `${data.last_name || ''} ${data.first_name || ''} ${data.middle_name || ''}`,
						link: `${window.location.pathname}?${searchQuery ? `${searchQuery}&` : ''}contactId=${data.id}`,
					},
					...contacts.map(contact => ({
						...contact,
						is_default: data.is_default ? false : contact.is_default,
					})),
				]),
			);

			const pagination = yield select(getRawPaginationSelector());

			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total + 1 }));
			}

			resetState();
			redirect('cancel');
		} else {
			if (errors) {
				yield put(touch(CONTACT_FORM_NAME, ...REQUIRED_FIELDS));
				yield put(stopSubmit(CONTACT_FORM_NAME, transformToValidationErrors(errors)));
			}

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsContactUpdatingAction(false));
	}
}

export function* updateContactSaga({ payload: { contactId, addressId, redirect, searchQuery } }) {
	try {
		yield put(setIsContactUpdatingAction(true));

		const dataForSave = yield select(getContactFormValuesSelector());

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const { data, errors, message, toast } = yield call(updateContactRequest, dataForSave, addressId, contactId);
		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link
							? {
									link: `${toast?.link}${
										removeModalQuery(searchQuery) ? `&${removeModalQuery(searchQuery)}` : ''
									}`,
							  }
							: {}),
					}),
				);
			}

			yield put(setContactInfoAction(data));

			const contacts = yield select(getContactsSelector());

			const updatedContact = {
				...data,
				name: `${data.last_name || ''} ${data.first_name || ''} ${data.middle_name || ''}`,
				link: `${window.location.pathname}?${searchQuery ? `${searchQuery}&` : ''}contactId=${data.id}`,
			};

			yield put(
				setContactsAction(
					contacts.map(detail => {
						return detail.id === data.id
							? updatedContact
							: { ...detail, is_default: data.is_default ? false : detail.is_default };
					}),
				),
			);

			redirect();
		} else {
			if (errors) {
				yield put(touch(CONTACT_FORM_NAME, ...REQUIRED_FIELDS));
				yield put(stopSubmit(CONTACT_FORM_NAME, transformToValidationErrors(errors)));
			}
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsContactUpdatingAction(false));
	}
}

export function* deleteContactSaga({ payload: { addressId, redirect, contactId } }) {
	try {
		yield put(setIsContactDeletingAction(true));

		const { data, message, toast } = yield call(deleteContactRequest, addressId, contactId);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const contacts = yield select(getContactsSelector());

			yield put(setContactsAction(contacts.filter(({ id }) => id !== data.id)));

			const pagination = yield select(getRawPaginationSelector());

			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total - 1 }));
			}

			redirect();
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsContactDeletingAction(false));
	}
}

export default function* contactModalSaga() {
	yield takeEvery(GET_CONTACT_INFO, getContactInfoSaga);
	yield takeEvery(CREATE_CONTACT, createContactSaga);
	yield takeEvery(UPDATE_CONTACT, updateContactSaga);
	yield takeEvery(DELETE_CONTACT, deleteContactSaga);
}
