import Login from '../../containers/login';
import RegistrationModal from '../../containers/registration';
import ResetPassword from '../../containers/reset-password';

export const pageList = {
	registrationPage: {
		id: 'registration-page',
		path: '/register',
		title: 'Регистрация',
		component: RegistrationModal,
		exact: false,
	},
	resetPasswordPage: {
		id: 'reset-password-page',
		path: '/password/reset/:token',
		title: 'Сброс пароля',
		component: ResetPassword,
		exact: false,
	},
	loginPage: {
		id: 'login-page',
		path: '/login',
		title: 'Авторизация',
		component: Login,
		exact: false,
	},
};
