import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { NotificationsListView } from './notifications-list-view';
import { markNotificationAction, setNotificationsListAction } from '../../actions';
import { getIsNotificationsListLoadingSelector, getNotificationsSelector } from '../../selectors';

const NotificationsListWrapper = ({
	onSetNotifications,
	notifications,
	isNotificationsListLoading,
	onMarkNotification,
}) => {
	const history = useHistory();

	const handleCloseNotification = id => {
		const newNotifications = notifications.filter(item => item.id !== id);

		onSetNotifications(newNotifications);
		onMarkNotification(id);
	};

	const handleLinkClick = (link, id) => {
		if (!link) return;

		handleCloseNotification(id);
		history.push(link);
	};

	return (
		<NotificationsListView
			onCloseNotification={handleCloseNotification}
			notifications={notifications}
			isNotificationsListLoading={isNotificationsListLoading}
			onLinkClick={handleLinkClick}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	notifications: getNotificationsSelector(),
	isNotificationsListLoading: getIsNotificationsListLoadingSelector(),
});

const mapDispatchToProps = {
	onSetNotifications: setNotificationsListAction,
	onMarkNotification: markNotificationAction,
};

NotificationsListWrapper.propTypes = {
	onSetNotifications: PropTypes.func.isRequired,
	notifications: PropTypes.array.isRequired,
	isNotificationsListLoading: PropTypes.bool.isRequired,
	onMarkNotification: PropTypes.func.isRequired,
};

const NotificationsList = connect(mapStateToProps, mapDispatchToProps)(NotificationsListWrapper);

export { NotificationsList };
