/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { NAV_PAGE_LIST, TABLE_COLUMNS } from './constants';
import { TableComponent } from '../../components/table-component';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import {
	getUsersListSelector,
	getIsUsersListLoadingSelector,
	getPaginationSelector,
	getTableSearchSelector,
} from './selectors';
import { getUsersListAction, resetStateAction, setTableSearchAction } from './actions';
import { PageContentWithNav } from '../../components/page-content-with-nav';
import { getIsAvailableUsersButtonsSelector } from '../profile/selectors';
import { removeModalQuery } from '../../utils/removeModalQuery';

const UsersContainerWrapper = ({
	onGetUsersList,
	usersList,
	isUsersListLoading,
	pagination,
	onResetState,
	tableSearch,
	onSetTableSearch,
	isAvailable,
}) => {
	useInjectSaga({ key: 'usersContainerSaga', saga });
	useInjectReducer({ key: 'usersContainerReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		onGetUsersList(removeModalQuery(query.toString()));

		return () => onResetState();
	}, []);

	useEffect(() => {
		if (query.has('search')) {
			onSetTableSearch(query.get('search').toString());
		}
	}, []);

	const handleAddButtonClick = () => {
		query.set('modal', 'user');
		query.set('mode', 'add');

		history.push(`${pathname}?${query}`);
	};

	const handleSearch = ({ key }) => {
		if (key === 'Enter') {
			query.set('page', 1);

			if (tableSearch) {
				query.set('search', tableSearch);
			} else {
				query.delete('search');
			}

			history.push(`${pathname}?${query}`);
			onGetUsersList(query.toString());
		}
	};

	const handleSearchInputChange = ({ target: { value } }) => {
		onSetTableSearch(value);
	};

	const handleSearchInputClear = () => {
		onSetTableSearch('');

		query.delete('search');
		history.push(`${pathname}?${query}`);
		onGetUsersList(query.toString());
	};

	const handlePageChange = (_, { activePage }) => {
		query.set('page', activePage);
		history.push(`${pathname}?${query}`);
		onGetUsersList(query.toString());
	};

	const handleSelectChange = (_, { value }) => {
		query.set('page', 1);
		if (value === 'all') {
			query.delete('per_page');
		} else {
			query.set('per_page', value);
		}
		history.push(`${pathname}?${query}`);
		onGetUsersList(query.toString());
	};

	return (
		<PageContentWithNav
			pageNavigationItems={NAV_PAGE_LIST}
			pageContent={
				<TableComponent
					headerText="Пользователи"
					onAddButtonClick={isAvailable.create ? handleAddButtonClick : null}
					columns={TABLE_COLUMNS}
					rows={usersList}
					pagination={
						!pagination
							? null
							: { ...pagination, onPageChange: handlePageChange, onSelectChange: handleSelectChange }
					}
					onSearch={handleSearch}
					isTableDataLoading={isUsersListLoading}
					searchInputValue={tableSearch}
					onSearchInputChange={handleSearchInputChange}
					onSearchInputClear={handleSearchInputClear}
				/>
			}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	usersList: getUsersListSelector(),
	isUsersListLoading: getIsUsersListLoadingSelector(),
	pagination: getPaginationSelector(),
	tableSearch: getTableSearchSelector(),
	isAvailable: getIsAvailableUsersButtonsSelector(),
});

const mapDispatchToProps = {
	onGetUsersList: getUsersListAction,
	onResetState: resetStateAction,
	onSetTableSearch: setTableSearchAction,
};

const UsersContainer = connect(mapStateToProps, mapDispatchToProps)(UsersContainerWrapper);

UsersContainerWrapper.propTypes = {
	onGetUsersList: PropTypes.func.isRequired,
	usersList: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			role: PropTypes.string.isRequired,
			post: PropTypes.string.isRequired,
			phone: PropTypes.string.isRequired,
			email: PropTypes.string.isRequired,
			link: PropTypes.string.isRequired,
		}),
	),
	isUsersListLoading: PropTypes.bool.isRequired,
	pagination: PropTypes.shape({
		currentPage: PropTypes.number,
		totalPages: PropTypes.number.isRequired,
		total: PropTypes.number,
		from: PropTypes.number,
		to: PropTypes.number,
		isNextItem: PropTypes.bool,
		isPrevItem: PropTypes.bool,
		isFirstItem: PropTypes.bool,
		isLastItem: PropTypes.bool,
	}),
	onResetState: PropTypes.func.isRequired,
	onSetTableSearch: PropTypes.func.isRequired,
	tableSearch: PropTypes.string.isRequired,
	isAvailable: PropTypes.objectOf(PropTypes.bool),
};

export default UsersContainer;
