import { SubmissionError } from 'redux-form';
import { REQUIRED_DRIVER_FIELDS_FOR_VALIDATE, DRIVER_ROLE_NUMBER, REQUIRED_FIELDS_FOR_VALIDATE } from './constants';
import { goToElement } from '../../utils/goToElement';

export const transformToValidationErrors = errors => {
	return Object.entries(errors).reduce((result, [key, value]) => {
		if (key === 'role_id') {
			result.role = value;
		}

		result[key] = value[0];

		return result;
	}, {});
};

export const normalizePhone = value => {
	if (!value) {
		return value;
	}

	if (value === '+') {
		return '';
	}

	const onlyNums = value.replace(/[^\d]/g, '');

	if (onlyNums[0] !== '7' && onlyNums.length === 1) {
		return `+7${onlyNums}`;
	}

	if (onlyNums.length <= 4) {
		return `+7${onlyNums.slice(1, 4)}`;
	}

	if (onlyNums.length <= 7) {
		return `+7(${onlyNums.slice(1, 4)})${onlyNums.slice(4)}`;
	}

	if (onlyNums.length <= 9) {
		return `+7(${onlyNums.slice(1, 4)})${onlyNums.slice(4, 7)}-${onlyNums.slice(7)}`;
	}

	return `+7(${onlyNums.slice(1, 4)})${onlyNums.slice(4, 7)}-${onlyNums.slice(7, 9)}-${onlyNums.slice(9, 11)}`;
};

export const validate = values => {
	const isDriver = values?.role === DRIVER_ROLE_NUMBER;

	const fields = isDriver ? REQUIRED_DRIVER_FIELDS_FOR_VALIDATE : REQUIRED_FIELDS_FOR_VALIDATE;

	fields.forEach(key => {
		if (!values?.[key]) {
			goToElement(key);
			throw new SubmissionError({ [key]: 'Заполните это поле' });
		}

		if (key === 'email') {
			if (values[key] && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[key])) {
				throw new SubmissionError({ [key]: 'Не валидный email' });
			}
		}
	});
};

export const checkEquality = (property, userInfo, formValues) => {
	let value = Boolean(userInfo?.[property]);

	if (
		Boolean(userInfo?.[property]) !== formValues?.[property] &&
		formValues?.[property] !== undefined &&
		formValues?.[property] !== null
	) {
		value = formValues?.[property];
	}

	return value;
};
