export const CREATE_DATE_CARGO_COLLECTION = 'date-change-modal/CREATE_DATE_CARGO_COLLECTION';
export const createDateCargoCollectionAction = payload => ({
	type: CREATE_DATE_CARGO_COLLECTION,
	payload,
});

export const SET_IS_DATE_CARGO_COLLECTION_CREATING = 'date-change-modal/SET_IS_DATE_CARGO_COLLECTION_CREATING';
export const setIsDateCargoCollectionCreatingAction = payload => ({
	type: SET_IS_DATE_CARGO_COLLECTION_CREATING,
	payload,
});

export const RESET_STATE = 'date-change-modal/RESET_STATE';
export const resetStateAction = payload => ({
	type: RESET_STATE,
	payload,
});

export const GET_REQUEST_INFO = 'date-change-modal/GET_REQUEST_INFO';
export const getRequestInfoAction = payload => ({
	type: GET_REQUEST_INFO,
	payload,
});

export const SET_REQUEST_INFO = 'date-change-modal/SET_REQUEST_INFO';
export const setRequestInfoAction = payload => ({
	type: SET_REQUEST_INFO,
	payload,
});

export const SET_IS_REQUEST_INFO_LOADING = 'date-change-modal/SET_IS_REQUEST_INFO_LOADING';
export const setIsRequestInfoLoadingAction = payload => ({
	type: SET_IS_REQUEST_INFO_LOADING,
	payload,
});
