/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { change, reduxForm, submit } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { ModalWindow } from '../../../../semantic-ui/components/modal-window';
import ModalFooterButtons from '../../../../semantic-ui/components/modal-window/modal-footer-buttons';
import { RegistrationFormView } from './registration-form-view';
import {
	getCompaniesOptionsSelector,
	getIsRegistrationFormOpenSelector,
	getIsCompaniesLoadingSelector,
	getIsUserCreatingSelector,
	getCompaniesSelector,
	getCompaniesSearchValueSelector,
} from '../../selectors';
import { createUserAction, getCompaniesAction, setCompaniesSearchValueAction } from '../../actions';
import { REGISTRATION_FORM_NAME, REGISTRATION_MODAL_STYLE } from '../../constants';
import { validate } from '../../utils';

const RegistrationFormWrapper = ({
	isRegistrationFormOpen,
	onFormSubmit,
	onCreateUser,
	isUserCreating,
	companiesOptions,
	isCompaniesLoading,
	onGetCompanies,
	companies,
	onFieldChange,
	companiesSearchValue,
	onSetCompaniesSearchValue,
}) => {
	const history = useHistory();

	const handleRegistrationFormClose = () => history.push('/login');

	const handleConfirmButtonClick = async () => {
		await onFormSubmit(REGISTRATION_FORM_NAME);
		onCreateUser(history.push);
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleConfirmButtonClick();
		}
	};

	const handleCompanySearchChange = ({ target: { value } }) => {
		onSetCompaniesSearchValue(value);
		onGetCompanies();
	};

	const handleCompanyChange = value => {
		const currentCompany = companies.find(({ data }) => data.inn === value);

		if (currentCompany) {
			onFieldChange(REGISTRATION_FORM_NAME, 'company.name', currentCompany.value);
			onFieldChange(REGISTRATION_FORM_NAME, 'company.inn', currentCompany.data?.inn);
			onFieldChange(REGISTRATION_FORM_NAME, 'company.ogrn', currentCompany.data?.ogrn);
			onFieldChange(REGISTRATION_FORM_NAME, 'company.address', currentCompany.data?.address?.value);
			onSetCompaniesSearchValue(currentCompany.value);
		}
	};

	return (
		<div>
			<ModalWindow
				isModalShown={isRegistrationFormOpen}
				onKeyDown={handleKeyDown}
				headerText="Регистрация"
				modalContent={
					<RegistrationFormView
						companiesOptions={companiesOptions}
						isCompaniesLoading={isCompaniesLoading}
						onCompanySearchChange={handleCompanySearchChange}
						onCompanyChange={handleCompanyChange}
						companiesSearchValue={companiesSearchValue}
					/>
				}
				style={REGISTRATION_MODAL_STYLE}
				actionButtons={[{ type: 'close', onClick: handleRegistrationFormClose, text: 'registration' }]}
				footerButtons={
					<ModalFooterButtons
						rightButtons={[
							{
								onClick: handleConfirmButtonClick,
								text: 'Регистрация',
								color: 'info-revers',
								disabled: isUserCreating,
								loading: isUserCreating,
							},
							{ onClick: handleRegistrationFormClose, text: 'Отмена', color: 'secondary' },
						]}
					/>
				}
			/>
		</div>
	);
};

const withForm = reduxForm({
	form: REGISTRATION_FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
})(RegistrationFormWrapper);

const mapStateToProps = createStructuredSelector({
	isRegistrationFormOpen: getIsRegistrationFormOpenSelector(),
	isUserCreating: getIsUserCreatingSelector(),
	companiesOptions: getCompaniesOptionsSelector(),
	companies: getCompaniesSelector(),
	isCompaniesLoading: getIsCompaniesLoadingSelector(),
	companiesSearchValue: getCompaniesSearchValueSelector(),
});

const mapDispatchToProps = {
	onFormSubmit: submit,
	onCreateUser: createUserAction,
	onGetCompanies: getCompaniesAction,
	onFieldChange: change,
	onSetCompaniesSearchValue: setCompaniesSearchValueAction,
};

RegistrationFormWrapper.propTypes = {
	isRegistrationFormOpen: PropTypes.bool.isRequired,
	isUserCreating: PropTypes.bool.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	onCreateUser: PropTypes.func.isRequired,
	companiesOptions: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string,
			data: PropTypes.shape({
				title: PropTypes.string.isRequired,
				address: PropTypes.string.isRequired,
				deleted: PropTypes.bool,
			}),
		}),
	).isRequired,
	isCompaniesLoading: PropTypes.bool.isRequired,
	onGetCompanies: PropTypes.func.isRequired,
	companies: PropTypes.array.isRequired, //
	onFieldChange: PropTypes.func.isRequired,
	companiesSearchValue: PropTypes.string.isRequired,
	onSetCompaniesSearchValue: PropTypes.func.isRequired,
};

const RegistrationForm = connect(mapStateToProps, mapDispatchToProps)(withForm);

export { RegistrationForm };
