import {
	RESET_STATE,
	SET_USERS_ROLES_LIST,
	SET_IS_USERS_ROLES_LIST_LOADING,
	SET_PAGINATION,
	SET_TABLE_SEARCH,
} from './actions';

export const initialState = {
	usersRolesList: null,
	isUsersRolesListLoading: false,
	pagination: null,
	tableSearch: '',
};

const usersRolesContainerReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_USERS_ROLES_LIST:
			return {
				...state,
				usersRolesList: payload,
			};
		case SET_IS_USERS_ROLES_LIST_LOADING:
			return {
				...state,
				isUsersRolesListLoading: payload,
			};
		case SET_PAGINATION:
			return {
				...state,
				pagination: payload,
			};
		case SET_TABLE_SEARCH:
			return {
				...state,
				tableSearch: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default usersRolesContainerReducer;
