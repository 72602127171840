import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SideBar } from '../../components/sidebar';
import { Header } from '../../components/header';
import { Layout } from './components/layout';
import { Content } from './components/content';
import { Container } from './components/container';
import ProfileModal from '../profile';
import { NO_AUTH_PAGES, PAGE_LINKS } from './constants';
import RightHeaderContent from '../right-header-content';
import { getLocalStorageItem, setLocalStorageItem } from '../../utils/localStorage';
import { ContentRouter } from '../../components/content-router';
import TrailersModal from '../trailers-modal';
import DetailModal from '../details-modal';
import CarsModal from '../cars-modal';
import AddressModal from '../address-modal';
import ContactModal from '../contact-modal';
import PeriodsDictionaryModal from '../dictionaries/periods';
import CompaniesTypesDictionaryModal from '../dictionaries/companies-types';
import TypesDictionaryModal from '../dictionaries/types';
import ModesDictionaryModal from '../dictionaries/modes';
import StatusesDictionaryModal from '../dictionaries/statuses';
import CompaniesModal from '../dictionaries/companies-modal';
import WaysModal from '../ways-modal';
import PartnerModal from '../partner-modal';
import UsersRolesModal from '../users-roles-modal';
import UsersModal from '../users-modal';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from '../profile/reducer';
import { getUserInfoAction, setUserRulesAction } from '../profile/actions';
import CarrierChangeModal from '../carrier-change-modal';
import DateCargoCollectionModal from '../date-cargo-collection-modal';
import RequestModal from '../request-modal';
import ReqsDetailsModal from '../reqs-details-modal';
import DriverChangeModal from '../driver-change-modal';
import { AlertModal } from '../alert-modal';
import CarChangeModal from '../car-change-modal';
import RefusalReasonModal from '../refusal-reason-modal';
import CargoPickupModal from '../cargo-pickup-modal/index';
import FeaturesDictionaryModal from '../dictionaries/features';
import DocumentAttachmentForm from '../document-attachment-form';
import CargoDeliveryModal from '../cargo-delivery-modal';
import { HeaderSearch } from '../header-search';
import TonnagesDictionaryModal from '../dictionaries/tonnages';
import RatesDictionaryModal from '../dictionaries/rates';
import SpecifyFlightModal from '../specify-flight-modal';
import { getIsCompanyValidateSelector, getIsEmailVerifySelector } from './selectors';
import DeliveryStatusesDictionaryModal from '../dictionaries/delivery-statuses';

export const MainWrapper = ({ onSetUserRules, onGetUserInfo, isEmailVerify, isCompanyValidate }) => {
	useInjectReducer({ key: 'profileModalReducer', reducer });

	const history = useHistory();

	const isProtectedRouts = isEmailVerify && isCompanyValidate;

	useEffect(() => {
		const token = getLocalStorageItem('user');

		const rules = getLocalStorageItem('rules');

		const isNotAuthPage =
			history.location.pathname.includes('/password/reset') || NO_AUTH_PAGES.includes(history.location.pathname);

		if ((!token || !rules) && !isNotAuthPage) {
			setLocalStorageItem('last_url', window.location.href);
			history.push('/login');
		}

		if (rules) {
			onSetUserRules(rules);
			onGetUserInfo(history);
		}
	}, []);

	useEffect(() => {
		const isNotAuthPage =
			history.location.pathname.includes('/password/reset') || NO_AUTH_PAGES.includes(history.location.pathname);

		const isEmailVerifyPage = history.location.pathname.includes('/email/verify');

		if (!isEmailVerify && !isNotAuthPage && !isEmailVerifyPage) {
			history.push('/email/verify');
		}

		const isCompanyVerifyPage = history.location.pathname.includes('/company/validate');

		if (isEmailVerify && !isCompanyValidate && !isNotAuthPage && !isCompanyVerifyPage) {
			history.push('/company/validate');
		}
	}, [isEmailVerify, isCompanyValidate]);

	return (
		<>
			<ProfileModal />
			<CompaniesModal />
			<AlertModal />
			<Layout>
				<SideBar />
				<Content>
					<Header
						searchInput={<HeaderSearch />}
						links={PAGE_LINKS}
						rightSideContent={<RightHeaderContent />}
					/>
					<Container>
						<ContentRouter isProtectedRouts={isProtectedRouts} />
					</Container>
				</Content>
			</Layout>
			{isProtectedRouts && (
				<>
					<TrailersModal />
					<DetailModal />
					<CargoPickupModal />
					<CargoDeliveryModal />
					<SpecifyFlightModal />
					<CarsModal />
					<AddressModal />
					<ContactModal />
					<PeriodsDictionaryModal />
					<CompaniesTypesDictionaryModal />
					<DeliveryStatusesDictionaryModal />
					<TypesDictionaryModal />
					<FeaturesDictionaryModal />
					<ModesDictionaryModal />
					<RatesDictionaryModal />
					<TonnagesDictionaryModal />
					<StatusesDictionaryModal />
					<WaysModal />
					<PartnerModal />
					<UsersRolesModal />
					<UsersModal />
					<CarrierChangeModal />
					<DateCargoCollectionModal />
					<DriverChangeModal />
					<RequestModal />
					<ReqsDetailsModal />
					<CarChangeModal />
					<RefusalReasonModal />
					<DocumentAttachmentForm />
				</>
			)}
		</>
	);
};

const mapStateToProps = createStructuredSelector({
	isCompanyValidate: getIsCompanyValidateSelector(),
	isEmailVerify: getIsEmailVerifySelector(),
});

const mapDispatchToProps = {
	onSetUserRules: setUserRulesAction,
	onGetUserInfo: getUserInfoAction,
};

const Main = connect(mapStateToProps, mapDispatchToProps)(MainWrapper);

MainWrapper.propTypes = {
	onSetUserRules: PropTypes.func.isRequired,
	onGetUserInfo: PropTypes.func.isRequired,
	isEmailVerify: PropTypes.bool.isRequired,
	isCompanyValidate: PropTypes.bool.isRequired,
};

export { Main };
