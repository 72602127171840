export const REGISTRATION_FORM_NAME = 'registration-form';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const TOGGLE_MODAL_STYLE = { width: '505px', height: '272px' };

export const REGISTRATION_MODAL_STYLE = { width: '900px' };

export const REQUIRED_FIELDS = [
	'company.name',
	'company.inn',
	'company.ogrn',
	'company.address',
	'contact.last_name',
	'contact.first_name',
	'contact.phone',
	'contact.email',
	'user.post',
	'user.last_name',
	'user.first_name',
	'user.email',
	'user.phone',
	'user.password',
	'user.password_confirmation',
];
