import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';

export const Status = ({ status: { name, color, description }, isFullwidth, isModalHeader }) => {
	if (description) {
		return (
			<div
				className={`status status_${color}${isFullwidth ? ' status_fullwidth' : ''}${
					isModalHeader ? ' status_nowrap' : ''
				}`}
			>
				{name}
				{isModalHeader ? ' | ' : <hr className={`status__divider status__divider_${color}`} />}
				{description}
			</div>
		);
	}

	return <div className={`status status_${color}${isFullwidth ? ' status_fullwidth' : ''}`}>{name}</div>;
};

Status.propTypes = {
	status: PropTypes.shape({
		name: PropTypes.string,
		color: PropTypes.string,
		description: PropTypes.string,
	}).isRequired,
	isFullwidth: PropTypes.bool,
	isModalHeader: PropTypes.bool,
};
