import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import {
	GET_DOCUMENTS,
	SEND_FORM,
	setDocumentsAction,
	setFormErrorsAction,
	setIsDocumentsLoadingAction,
	setIsFormSendingAction,
} from './actions';
import { errorHandler } from '../../api/utils';
import {
	getDocumentsRequest,
	setDocumentsRequest,
	getPointDocumentsRequest,
	setPointDocumentsRequest,
} from '../../api/requests';
import { DISMISS_TIME } from '../../api/constants';
import { notificationInit } from '../../modules/notifications/actions';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { transformToValidationErrors } from './utils';
import { getDataForSaveSelector } from './selectors';

export function* getDocumentsSaga({ payload: { id, pointId } }) {
	try {
		yield put(setIsDocumentsLoadingAction(true));

		const { data, message, toast } = pointId
			? yield call(getPointDocumentsRequest, id, pointId, '?receive=all')
			: yield call(getDocumentsRequest, id, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setDocumentsAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsDocumentsLoadingAction(false));
	}
}

export function* setDocumentsSaga({ payload: { id, pointId, redirect } }) {
	try {
		yield put(setIsFormSendingAction(true));

		const dataForSave = yield select(getDataForSaveSelector());

		const { data, message, toast, errors } = pointId
			? yield call(setPointDocumentsRequest, id, pointId, dataForSave)
			: yield call(setDocumentsRequest, id, dataForSave);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			redirect();
		} else {
			if (errors) {
				yield put(setFormErrorsAction(transformToValidationErrors(errors)));
			}
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsFormSendingAction(false));
	}
}

export default function* documentAttachmentModalSaga() {
	yield takeEvery(GET_DOCUMENTS, getDocumentsSaga);
	yield takeEvery(SEND_FORM, setDocumentsSaga);
}
