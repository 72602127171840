import { RESET_STATE, SET_DETAILS, SET_DETAILS_SEARCH_VALUE, SET_IS_DETAILS_LOADING, SET_PAGINATION } from './actions';

export const initialState = {
	isDetailsLoading: false,
	details: [],
	detailsSearchValue: '',
	pagination: null,
};

const detailsReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_DETAILS:
			return {
				...state,
				details: payload,
			};
		case SET_IS_DETAILS_LOADING:
			return {
				...state,
				isDetailsLoading: payload,
			};
		case SET_DETAILS_SEARCH_VALUE:
			return {
				...state,
				detailsSearchValue: payload,
			};
		case SET_PAGINATION:
			return {
				...state,
				pagination: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default detailsReducer;
