import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getTabsSelector } from './selectors';
import { PageContentWithNav } from '../../components/page-content-with-nav';
import ReqsMineContainer from '../reqs-mine';

const ReqsCargoContainerWrapper = ({ tabs }) => {
	return <PageContentWithNav pageNavigationItems={tabs} pageContent={<ReqsMineContainer />} />;
};

ReqsCargoContainerWrapper.propTypes = {
	tabs: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = createStructuredSelector({
	tabs: getTabsSelector(),
});

const ReqsCargoContainer = connect(mapStateToProps)(ReqsCargoContainerWrapper);

export default ReqsCargoContainer;
