/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { getFormValues, isInvalid } from 'redux-form';
import { initialState } from './reducer';
import { FORM_NAME, TRAILER_TABLE_ROWS_NAMES } from './constants';

export const selectTrailersModalDomain = state => state.trailersModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getTrailerInfoSelector = () =>
	createSelector(selectTrailersModalDomain, ({ trailerInfo }) => {
		if (!trailerInfo) {
			return [];
		}

		const data = {
			number: trailerInfo.number || '-',
			vin: trailerInfo.vin || '-',
			tonnage: trailerInfo.tonnage?.name ? trailerInfo.tonnage?.name : '-',
			author: {
				name: `${trailerInfo.user?.last_name || '-'} ${trailerInfo.user?.first_name || '-'}`,
				role: trailerInfo.user?.role.name || '-',
			},
		};

		return Object.entries(data).reduce((result, [key, value]) => {
			return [...result, { id: key, title: TRAILER_TABLE_ROWS_NAMES[key], value }];
		}, []);
	});

export const getIsTrailerInfoLoadingSelector = () =>
	createSelector(
		selectTrailersModalDomain,
		({ isTrailerInfoLoading, isTrailerDeleting, isTrailerSaving }) =>
			isTrailerInfoLoading || isTrailerDeleting || isTrailerSaving,
	);

export const getTrailerNameSelector = () =>
	createSelector(selectTrailersModalDomain, ({ trailerInfo }) => trailerInfo?.name || '-');

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'trailer');
	});

export const getActiveTrailerIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => (query?.modal === 'trailer' ? query?.id : null));

export const getIsTrailerAddFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'trailer' && query?.mode === 'add');
	});

export const getIsTrailerEditFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'trailer' && Boolean(query?.id) && query?.mode === 'edit');
	});

export const getIsAuditTabShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'trailer' && query?.tab === 'audit');
	});

export const getFormInitialValuesSelector = () =>
	createSelector(selectTrailersModalDomain, ({ trailerInfo }) => {
		if (!trailerInfo) {
			return {
				tonnage_id: 'none',
			};
		}

		return {
			number: trailerInfo.number,
			vin: trailerInfo.vin,
			name: trailerInfo.name,
			tonnage_id: trailerInfo.tonnage?.id ? String(trailerInfo.tonnage?.id) : 'none',
		};
	});

export const getDataForSaveTrailerSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues => ({
		...formValues,
		tonnage_id: formValues.tonnage_id === 'none' ? null : formValues.tonnage_id,
	}));

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);

export const getTonnagesSelector = () => createSelector(selectTrailersModalDomain, ({ tonnages }) => tonnages);

export const getIsTonnagesLoadingSelector = () =>
	createSelector(selectTrailersModalDomain, ({ isTonnagesLoading }) => isTonnagesLoading);
