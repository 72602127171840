import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { ProfileForm } from '../profile-form';
import { PROFILE_TABLE_COLUMNS_NAMES } from '../../constants';
import { SimpleTableComponent } from '../../../../components/simple-table';

export const ProfileContent = ({
	userInfo,
	isUserInfoLoading,
	isProfileFormOpen,
	usersList,
	isUsersListLoading,
	isDisabled,
	onCheckboxChange,
}) => {
	return isProfileFormOpen ? (
		<ProfileForm
			isUserInfoLoading={isUserInfoLoading}
			usersList={usersList}
			isUsersListLoading={isUsersListLoading}
			isDisabled={isDisabled}
			onCheckboxChange={onCheckboxChange}
		/>
	) : (
		<SimpleTableComponent
			rows={userInfo}
			isTableDataLoading={isUserInfoLoading}
			columns={PROFILE_TABLE_COLUMNS_NAMES}
		/>
	);
};

ProfileContent.propTypes = {
	userInfo: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.shape({
					name: PropTypes.string,
					role: PropTypes.string,
					id: PropTypes.number,
				}),
			]).isRequired,
		}),
	),
	isUserInfoLoading: PropTypes.bool.isRequired,
	isProfileFormOpen: PropTypes.bool.isRequired,
	usersList: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			key: PropTypes.number.isRequired,
			value: PropTypes.number.isRequired,
		}),
	).isRequired,
	isUsersListLoading: PropTypes.bool.isRequired,
	isDisabled: PropTypes.objectOf(PropTypes.bool).isRequired,
	onCheckboxChange: PropTypes.func.isRequired,
};
