export const SET_ACCORDION_INDEX = 'reqs-filter/SET_ACCORDION_INDEX';
export const setAccordionIndexAction = payload => ({
	type: SET_ACCORDION_INDEX,
	payload,
});

export const SET_FILTER_OPTIONS = 'reqs-filter/SET_FILTER_OPTIONS';
export const setFilterOptionsAction = payload => ({
	type: SET_FILTER_OPTIONS,
	payload,
});

export const SET_ANALYTICS = 'reqs-filter/SET_ANALYTICS';
export const setAnalyticsAction = payload => ({
	type: SET_ANALYTICS,
	payload,
});

export const RESET_STATE = 'reqs-filter/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const SET_ANALYTICS_ACCORDION_INDEX = 'reqs-filter/SET_ANALYTICS_ACCORDION_INDEX';
export const setAnalyticsAccordionIndexAction = payload => ({
	type: SET_ANALYTICS_ACCORDION_INDEX,
	payload,
});
