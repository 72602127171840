import {
	SET_IS_CHATS_LIST_SHOWN,
	SET_IS_NOTIFICATIONS_LIST_LOADING,
	SET_IS_NOTIFICATION_DROPDOWN_SHOWN,
	SET_IS_PROFILE_DROPDOWN_SHOWN,
	SET_NOTIFICATIONS_LIST,
	SET_USER_DATA,
} from './actions';

export const initialState = {
	isProfileDropdownShown: false,
	isNotificationDropdownShown: false,
	isChatsListShown: false,
	isNotificationsListLoading: false,
	notificationsList: [],
	userData: null,
};

const rightHeaderContentReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_IS_PROFILE_DROPDOWN_SHOWN:
			return {
				...state,
				isProfileDropdownShown: payload,
			};
		case SET_IS_NOTIFICATION_DROPDOWN_SHOWN:
			return {
				...state,
				isNotificationDropdownShown: payload,
			};
		case SET_USER_DATA:
			return {
				...state,
				userData: payload,
			};
		case SET_NOTIFICATIONS_LIST:
			return {
				...state,
				notificationsList: payload,
			};
		case SET_IS_NOTIFICATIONS_LIST_LOADING:
			return {
				...state,
				isNotificationsListLoading: payload,
			};
		case SET_IS_CHATS_LIST_SHOWN:
			return {
				...state,
				isChatsListShown: payload,
			};
		default:
			return state;
	}
};

export default rightHeaderContentReducer;
