import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { InfoWrapper } from './components/info-wrapper';
import { getInformationAction, resetStateAction } from './actions';

const InformationContainerWrapper = ({ onGetInformation, onResetState }) => {
	useInjectSaga({ key: 'informationContainerSaga', saga });
	useInjectReducer({ key: 'informationContainerReducer', reducer });

	useEffect(() => {
		onGetInformation();

		return () => onResetState();
	}, []);

	return <InfoWrapper />;
};

const mapDispatchToProps = {
	onGetInformation: getInformationAction,
	onResetState: resetStateAction,
};

const InformationContainer = connect(null, mapDispatchToProps)(InformationContainerWrapper);

InformationContainerWrapper.propTypes = {
	onGetInformation: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
};

export default InformationContainer;
