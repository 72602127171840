export const GET_AUDIT_DATA = 'audit/GET_AUDIT_DATA';
export const getAuditDataAction = payload => ({
	type: GET_AUDIT_DATA,
	payload,
});

export const SET_AUDIT_DATA = 'audit/SET_AUDIT_DATA';
export const setAuditDataAction = payload => ({
	type: SET_AUDIT_DATA,
	payload,
});

export const SET_IS_AUDIT_DATA_LOADING = 'audit/SET_IS_AUDIT_DATA_LOADING';
export const setIsAuditDataLoadingAction = payload => ({
	type: SET_IS_AUDIT_DATA_LOADING,
	payload,
});

export const RESET_STATE = 'audit/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});
