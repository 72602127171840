import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AccountsInfoView } from './accounts-info-view';
import { getAccountsInfoSelector, getIsInformationLoadingSelector } from '../../selectors';

export const AccountsInfo = ({ accountsInfo, isInformationLoading }) => {
	return <AccountsInfoView accountsInfo={accountsInfo} isInformationLoading={isInformationLoading} />;
};

const mapStateToProps = createStructuredSelector({
	accountsInfo: getAccountsInfoSelector(),
	isInformationLoading: getIsInformationLoadingSelector(),
});

const AccountsInfoComponent = connect(mapStateToProps)(AccountsInfo);

AccountsInfo.propTypes = {
	accountsInfo: PropTypes.arrayOf(
		PropTypes.shape({
			count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			link: PropTypes.string,
			text: PropTypes.string,
		}),
	).isRequired,
	isInformationLoading: PropTypes.bool.isRequired,
};

export { AccountsInfoComponent };
