import { CustomCell } from '../../components/custom-cell';

export const TABLE_COLUMNS = [
	{ name: '#', id: 'id', width: '113px', minWidth: '113px', customComponent: CustomCell },
	{ name: 'Название', id: 'name', width: '390px', customComponent: CustomCell },
	{ name: 'Кол. пользователей', id: 'user_count' },
];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const NAV_PAGE_LIST = [{ text: 'Роли пользователей', href: '/users_roles' }];
