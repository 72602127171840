import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CustomModalCell = ({ rowData }) => {
	if (rowData.id?.includes('link') && rowData.value.target) {
		return (
			<a
				target={rowData.value.target}
				rel="noreferrer noopener"
				className="custom-modal-cell__link"
				href={rowData.value.url}
			>
				{rowData.value.text}
			</a>
		);
	}

	if (rowData.id?.includes('link') && !rowData.value.target) {
		return (
			<Link className="custom-modal-cell__link" to={rowData.value.url}>
				{rowData.value.text}
			</Link>
		);
	}

	if (rowData.id === 'author') {
		return (
			<span className="custom-modal-cell__author">
				<b>{rowData.value.name}</b>
				<small>
					<i>{rowData.value.role}</i>
				</small>
			</span>
		);
	}

	return rowData.value;
};

CustomModalCell.propTypes = {
	rowData: PropTypes.object.isRequired,
	columnId: PropTypes.string.isRequired,
};

export { CustomModalCell };
