import {
	RESET_STATE,
	SET_PARTNERS_OPTIONS,
	SET_PARTNER_INFO,
	SET_PARTNER_SEARCH_VALUE,
	SET_IS_PARTNERS_OPTIONS_LOADING,
	SET_IS_PARTNER_DELETING,
	SET_IS_PARTNER_INFO_LOADING,
	SET_IS_PARTNER_UPDATING,
} from './actions';

export const initialState = {
	isPartnerInfoLoading: false,
	isPartnersOptionsLoading: false,
	isPartnerDeleting: false,
	isPartnerUpdating: false,
	partnerInfo: [],
	partnerSearchValue: '',
	partnersOptions: [],
};

const partnerModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_PARTNER_INFO:
			return {
				...state,
				partnerInfo: payload,
			};
		case SET_IS_PARTNER_INFO_LOADING:
			return {
				...state,
				isPartnerInfoLoading: payload,
			};
		case SET_PARTNER_SEARCH_VALUE:
			return {
				...state,
				partnerSearchValue: payload,
			};
		case SET_PARTNERS_OPTIONS:
			return {
				...state,
				partnersOptions: payload,
			};
		case SET_IS_PARTNERS_OPTIONS_LOADING:
			return {
				...state,
				isPartnersOptionsLoading: payload,
			};
		case SET_IS_PARTNER_DELETING:
			return {
				...state,
				isPartnerDeleting: payload,
			};
		case SET_IS_PARTNER_UPDATING:
			return {
				...state,
				isPartnerUpdating: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default partnerModalReducer;
