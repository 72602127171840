import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { TABLE_COLUMNS } from './constants';
import { TableComponent } from '../../components/table-component';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { getTrailersListAction, resetStateAction, setTableSearchAction } from './actions';
import {
	getIsTrailersListLoadingSelector,
	getPaginationSelector,
	getTableSearchSelector,
	getTrailersListSelector,
} from './selectors';
import { getIsAvailableTrailersButtonsSelector } from '../profile/selectors';
import { removeModalQuery } from '../../utils/removeModalQuery';

const TrailersContainerWrapper = ({
	onGetTrailersList,
	trailersList,
	isTrailersListLoading,
	pagination,
	onResetState,
	tableSearch,
	onSetTableSearch,
	isAvailable,
}) => {
	useInjectSaga({ key: 'trailersContainerSaga', saga });
	useInjectReducer({ key: 'trailersContainerReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		onGetTrailersList(removeModalQuery(query.toString()));

		return onResetState();
	}, []);

	useEffect(() => {
		if (query.has('search')) {
			onSetTableSearch(query.get('search').toString());
		}
	}, []);

	const handleAddButtonClick = () => {
		query.set('modal', 'trailer');
		query.set('mode', 'add');

		history.push(`${pathname}?${query}`);
	};

	const handleSearch = ({ key }) => {
		if (key === 'Enter') {
			query.set('page', 1);

			if (tableSearch) {
				query.set('search', tableSearch);
			} else {
				query.delete('search');
			}

			history.push(`${pathname}?${query}`);
			onGetTrailersList(query.toString());
		}
	};

	const handleSearchInputChange = ({ target: { value } }) => {
		onSetTableSearch(value);
	};

	const handleSearchInputClear = () => {
		onSetTableSearch('');

		query.delete('search');
		history.push(`${pathname}?${query}`);
		onGetTrailersList(query.toString());
	};

	const handlePageChange = (_, { activePage }) => {
		query.set('page', activePage);
		history.push(`${pathname}?${query}`);
		onGetTrailersList(query.toString());
	};

	const handleSelectChange = (_, { value }) => {
		query.set('page', 1);
		if (value === 'all') {
			query.delete('per_page');
			history.push(`${pathname}?${query}`);
		} else {
			query.set('per_page', value);
			history.push(`${pathname}?${query}`);
		}
		onGetTrailersList(query.toString());
	};

	return (
		<>
			<TableComponent
				headerText="Прицепы"
				onAddButtonClick={isAvailable.create ? handleAddButtonClick : null}
				columns={TABLE_COLUMNS}
				rows={trailersList}
				pagination={
					!pagination
						? null
						: { ...pagination, onPageChange: handlePageChange, onSelectChange: handleSelectChange }
				}
				onSearch={handleSearch}
				isTableDataLoading={isTrailersListLoading}
				searchInputValue={tableSearch}
				onSearchInputChange={handleSearchInputChange}
				onSearchInputClear={handleSearchInputClear}
			/>
		</>
	);
};

const mapStateToProps = createStructuredSelector({
	trailersList: getTrailersListSelector(),
	isTrailersListLoading: getIsTrailersListLoadingSelector(),
	pagination: getPaginationSelector(),
	tableSearch: getTableSearchSelector(),
	isAvailable: getIsAvailableTrailersButtonsSelector(),
});

const mapDispatchToProps = {
	onGetTrailersList: getTrailersListAction,
	onResetState: resetStateAction,
	onSetTableSearch: setTableSearchAction,
};

const TrailersContainer = connect(mapStateToProps, mapDispatchToProps)(TrailersContainerWrapper);

TrailersContainerWrapper.propTypes = {
	onGetTrailersList: PropTypes.func.isRequired,
	trailersList: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			name: PropTypes.string.isRequired,
			number: PropTypes.string.isRequired,
			vin: PropTypes.string.isRequired,
			link: PropTypes.string.isRequired,
		}),
	),
	isTrailersListLoading: PropTypes.bool.isRequired,
	pagination: PropTypes.shape({
		currentPage: PropTypes.number,
		totalPages: PropTypes.number.isRequired,
		total: PropTypes.number,
		from: PropTypes.number,
		to: PropTypes.number,
		isNextItem: PropTypes.bool,
		isPrevItem: PropTypes.bool,
		isFirstItem: PropTypes.bool,
		isLastItem: PropTypes.bool,
	}),
	onResetState: PropTypes.func.isRequired,
	onSetTableSearch: PropTypes.func.isRequired,
	tableSearch: PropTypes.string.isRequired,
	isAvailable: PropTypes.objectOf(PropTypes.bool),
};

export default TrailersContainer;
