import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TableComponent } from '../../../../components/table-component';
import { FormCheckboxField } from '../../../../semantic-ui/components/form-checkbox-field';

export const RulesForm = ({
	isDisabled,
	isUserRoleInfoLoading,
	tableColumns,
	tableRows,
	onCheckboxChange,
	formValues,
}) => {
	const renderCheckboxField = (name, key, action) => (
		<Field
			name={name}
			component={FormCheckboxField}
			toggle
			type="checkbox"
			disabled={isDisabled}
			input={{
				value: formValues?.rules?.[key]?.[action],
				onChange: e => onCheckboxChange(e, key, action),
			}}
		/>
	);

	const tableFields = Object.entries(tableRows).map(([key, value]) => {
		const fields = value.actions.reduce(
			(result, action) => ({
				...result,
				[action]: renderCheckboxField(`rules.${key}.${action}`, key, action),
			}),
			{},
		);

		return {
			id: key,
			name: value.title,
			...fields,
		};
	});

	return (
		<TableComponent
			columns={tableColumns}
			rows={tableFields}
			isTableDataLoading={isUserRoleInfoLoading}
			isWithoutHeader
			isWithoutFooter
			isWithoutBoldFirstColumn
		/>
	);
};

RulesForm.propTypes = {
	isDisabled: PropTypes.bool,
	isUserRoleInfoLoading: PropTypes.bool.isRequired,
	tableColumns: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])))
		.isRequired,
	tableRows: PropTypes.objectOf(
		PropTypes.shape({ title: PropTypes.string.isRequired, actions: PropTypes.arrayOf(PropTypes.string) }),
	),
	onCheckboxChange: PropTypes.func,
	formValues: PropTypes.object,
};
