import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { FormSelectField } from '../../../../semantic-ui/components/form-select-field';
import './styles.less';
import StatusButton from '../../../../components/status-button';
import { ANALYTICS_FORM_NAME } from '../../constants';
import { CustomAccordion } from '../../../../semantic-ui/components/accordion';
import { CityItem } from './components/city-item';

export const ReqAnalyticsComponent = ({
	onSearchAnalyticsButtonClick,
	formOptions,
	onAnalyticsFieldChange,
	analytics,
	analyticsAccordionIndex,
	onAnalyticsAccordionOpen,
	onCityCheckboxChange,
}) => {
	return (
		<div className="req-analytics-component">
			{analytics && analytics.length > 0 ? (
				<CustomAccordion
					styled
					accordionIndex={analyticsAccordionIndex}
					onAccordionOpen={onAnalyticsAccordionOpen}
					accordeonItems={analytics.map(item => ({
						title: item.name,
						isTitleIcon: true,
						HeaderComponent: (
							<CityItem
								onAccordionOpen={onAnalyticsAccordionOpen}
								item={item}
								onCityCheckboxChange={() => onCityCheckboxChange(item)}
								key={item.id}
							/>
						),
						isWithSubAccordion: true,
						...(item.cities && {
							Component: item.cities.map(item => (
								<CityItem
									item={item}
									isSubItem
									onCityCheckboxChange={() => onCityCheckboxChange(item)}
									key={item.id}
								/>
							)),
						}),
					}))}
					isSubAccordion
				/>
			) : (
				<Grid>
					<Grid.Row className="req-analytics-component__row">
						<Grid.Column width={16}>
							<span className="req-analytics-component__row-label">
								Для расчета аналитики необходимо выбрать регион отгрузки
							</span>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="req-analytics-component__row">
						<Grid.Column width={16}>
							<Field
								name="region_from"
								type="text"
								component={FormSelectField}
								autoComplete="off"
								search
								dropdownClassName="static"
								noResultsMessage="Не найдено"
								placeholder="Откуда"
								options={formOptions}
								onChange={onAnalyticsFieldChange}
								isWithoutSelectOnBlur
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="req-analytics-component__row">
						<Grid.Column width={16}>
							<div className="req-analytics-component__row-button-wrapper">
								<StatusButton
									button={{ name: 'Поиск', color: 'success' }}
									onClick={onSearchAnalyticsButtonClick}
									width="105px"
								/>
							</div>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			)}
		</div>
	);
};

const ReqAnalytics = reduxForm({
	form: ANALYTICS_FORM_NAME,
	enableReinitialize: true,
})(ReqAnalyticsComponent);

ReqAnalyticsComponent.propTypes = {
	onSearchAnalyticsButtonClick: PropTypes.func.isRequired,
	formOptions: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		}),
	).isRequired,
	onAnalyticsFieldChange: PropTypes.func.isRequired,
	analytics: PropTypes.arrayOf(
		PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array])),
	),
	analyticsAccordionIndex: PropTypes.arrayOf(PropTypes.number).isRequired,
	onAnalyticsAccordionOpen: PropTypes.func.isRequired,
	onCityCheckboxChange: PropTypes.func.isRequired,
};

export { ReqAnalytics };
