import React from 'react';
import PropTypes from 'prop-types';
import { SimpleTableComponent } from '../../../../components/simple-table';
import { ADDRESSES_TABLE_COLUMNS_NAMES } from '../../constants';
import { AddressForm } from '../address-form';

export const AddressModalContent = ({ addressInfo, isAddressFormOpen, isAddressInfoLoading }) => {
	return isAddressFormOpen ? (
		<AddressForm />
	) : (
		<SimpleTableComponent
			rows={addressInfo}
			isTableDataLoading={isAddressInfoLoading}
			columns={ADDRESSES_TABLE_COLUMNS_NAMES}
		/>
	);
};

AddressModalContent.propTypes = {
	addressInfo: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.shape({
					text: PropTypes.string,
					url: PropTypes.string,
					name: PropTypes.string,
					role: PropTypes.string,
				}),
			]).isRequired,
		}),
	),
	isAddressFormOpen: PropTypes.bool.isRequired,
	isAddressInfoLoading: PropTypes.bool.isRequired,
};
