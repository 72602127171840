/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { initialState } from './reducer';

export const selectDeliveryStatusesDictionaryDomain = state => state.deliveryStatusesDictionaryReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getIsDictionaryDataLoadingSelector = () =>
	createSelector(selectDeliveryStatusesDictionaryDomain, ({ isDictionaryDataLoading }) => isDictionaryDataLoading);

export const getDictionaryDataSelector = () =>
	createSelector(selectDeliveryStatusesDictionaryDomain, ({ dictionaryData }) => {
		if (!dictionaryData || dictionaryData.length === 0) {
			return [];
		}

		return dictionaryData;
	});

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'delivery_statuses');
	});
