/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';
import { Popup } from 'semantic-ui-react';

export const StatusIndicator = ({ status, description, position }) => {
	return (
		<>
			{description ? (
				<Popup
					content={
						<div
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{ __html: description }}
						/>
					}
					trigger={<div className={`status-indicator status-indicator_${status}`} />}
					position={position}
					size="mini"
					style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
					inverted
				/>
			) : (
				<div className={`status-indicator status-indicator_${status}`} />
			)}
		</>
	);
};

StatusIndicator.propTypes = {
	status: PropTypes.string.isRequired,
	description: PropTypes.string,
	position: PropTypes.string,
};
