import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import {
	GET_DICTIONARY_DATA,
	setDictionaryDataAction,
	setIsDictionaryDataLoadingAction,
	setPaginationAction,
} from './actions';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { getCompaniesDictionaryRequest } from '../../../api/requests';
import { DISMISS_TIME } from '../../../api/constants';
import { notificationInit } from '../../../modules/notifications/actions';
import { errorHandler } from '../../../api/utils';

export function* getDictionaryDataSaga({ payload: searchQuery }) {
	try {
		yield put(setIsDictionaryDataLoadingAction(true));
		const { data, meta, message, toast } = yield call(getCompaniesDictionaryRequest, searchQuery);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setDictionaryDataAction(
					data?.map(item => ({
						id: item.id,
						name: item.name || '-',
						inn: item.inn || '-',
						ogrn: item.ogrn || '-',
						contact: {
							phone: item.contact?.phone || '-',
							last_name: item.contact?.last_name || '-',
							first_name: item.contact?.first_name || '-',
						},
						link: `/companies?${searchQuery ? `${searchQuery}&` : ''}modal=company&id=${item.id}`,
					})),
				),
			);

			if (meta) {
				yield put(
					setPaginationAction({
						current_page: meta.current_page,
						per_page: meta.per_page,
						from: meta.from,
						last_page: meta.last_page,
						total: meta.total,
					}),
				);
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsDictionaryDataLoadingAction(false));
	}
}

export default function* companiesDictionarySaga() {
	yield takeEvery(GET_DICTIONARY_DATA, getDictionaryDataSaga);
}
