import { call, debounce, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { stopSubmit, touch } from 'redux-form';
import { notificationInit } from '../../modules/notifications/actions';
import { DETAIL_FORM_NAME, REQUEST_ERROR_MESSAGE, REQUIRED_FIELDS } from './constants';
import {
	createDetailRequest,
	deleteDetailRequest,
	getDetailInfoRequest,
	getDetailsBySearchRequest,
	updateDetailRequest,
} from '../../api/requests';
import {
	CREATE_DETAIL,
	DELETE_DETAIL,
	GET_DETAILS_OPTIONS,
	GET_DETAIL_INFO,
	UPDATE_DETAIL,
	setDetailInfoAction,
	setDetailsOptionsAction,
	setIsDetailDeletingAction,
	setIsDetailInfoLoadingAction,
	setIsDetailUpdatingAction,
	setIsDetailsOptionsLoadingAction,
} from './actions';
import { transformToValidationErrors } from './utils';
import { getDetailFormValuesSelector, getDetailSearchValueSelector, getFormIsInvalidSelector } from './selectors';
import { DISMISS_TIME } from '../../api/constants';
import { setDetailsAction, setPaginationAction } from '../details/actions';
import { getDetailsSelector, getRawPaginationSelector } from '../details/selectors';
import { errorHandler } from '../../api/utils';

export function* getDetailInfoSaga({ payload: id }) {
	try {
		yield put(setIsDetailInfoLoadingAction(true));

		const { data, message, toast } = yield call(getDetailInfoRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setDetailInfoAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsDetailInfoLoadingAction(false));
	}
}

export function* createDetailSaga({ payload: { redirect, resetState, searchQuery } }) {
	try {
		yield put(setIsDetailUpdatingAction(true));

		const dataForSave = yield select(getDetailFormValuesSelector());

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const { data, errors, message, toast } = yield call(createDetailRequest, dataForSave);

		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}` } : {}),
					}),
				);
			}

			yield put(setDetailInfoAction(data));

			const details = yield select(getDetailsSelector());

			yield put(
				setDetailsAction([
					{
						...data,
						link: `/home/details?${searchQuery ? `${searchQuery}&` : ''}modal=detail&id=${data.id}`,
					},
					...details.map(detail => ({ ...detail, is_default: data.is_default ? false : detail.is_default })),
				]),
			);

			const pagination = yield select(getRawPaginationSelector());

			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total + 1 }));
			}

			resetState();
			redirect();
		} else {
			if (errors) {
				yield put(touch(DETAIL_FORM_NAME, ...REQUIRED_FIELDS));
				yield put(stopSubmit(DETAIL_FORM_NAME, transformToValidationErrors(errors)));
			}

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsDetailUpdatingAction(false));
	}
}

export function* updateDetailSaga({ payload: { id, redirect, searchQuery } }) {
	try {
		yield put(setIsDetailUpdatingAction(true));

		const dataForSave = yield select(getDetailFormValuesSelector());

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const { data, errors, message, toast } = yield call(updateDetailRequest, id, dataForSave);
		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}` } : {}),
					}),
				);
			}

			const details = yield select(getDetailsSelector());

			const updatedDetail = {
				...data,
				link: `/home/details?${searchQuery ? `${searchQuery}&` : ''}modal=detail&id=${data.id}`,
			};

			yield put(
				setDetailsAction(
					details.map(detail => {
						return detail.id === data.id
							? updatedDetail
							: { ...detail, is_default: data.is_default ? false : detail.is_default };
					}),
				),
			);

			yield put(setDetailInfoAction(data));

			redirect();
		} else {
			if (errors) {
				yield put(touch(DETAIL_FORM_NAME, ...REQUIRED_FIELDS));
				yield put(stopSubmit(DETAIL_FORM_NAME, transformToValidationErrors(errors)));
			}
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsDetailUpdatingAction(false));
	}
}

export function* deleteDetailSaga({ payload: { id, redirect } }) {
	try {
		yield put(setIsDetailDeletingAction(true));

		const { data, message, toast } = yield call(deleteDetailRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const details = yield select(getDetailsSelector());

			yield put(setDetailsAction(details.filter(({ id }) => id !== data.id)));

			const pagination = yield select(getRawPaginationSelector());

			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total - 1 }));
			}

			redirect();
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsDetailDeletingAction(false));
	}
}

export function* getDetailsSaga() {
	try {
		yield put(setIsDetailsOptionsLoadingAction(true));

		const searchQuery = yield select(getDetailSearchValueSelector());

		const response = yield call(getDetailsBySearchRequest, searchQuery);

		if (response?.suggestions) {
			yield put(setDetailsOptionsAction(response.suggestions));
		} else {
			throw new Error(response?.message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsDetailsOptionsLoadingAction(false));
	}
}

export default function* detailModalPageSaga() {
	yield takeEvery(GET_DETAIL_INFO, getDetailInfoSaga);
	yield takeEvery(CREATE_DETAIL, createDetailSaga);
	yield takeEvery(UPDATE_DETAIL, updateDetailSaga);
	yield takeEvery(DELETE_DETAIL, deleteDetailSaga);
	yield debounce(300, GET_DETAILS_OPTIONS, getDetailsSaga);
}
