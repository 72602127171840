export const GET_USERS_LIST = 'users/GET_USERS_LIST';
export const getUsersListAction = payload => ({
	type: GET_USERS_LIST,
	payload,
});

export const SET_USERS_LIST = 'users/SET_USERS_LIST';
export const setUsersListAction = payload => ({
	type: SET_USERS_LIST,
	payload,
});

export const SET_PAGINATION = 'users/SET_PAGINATION';
export const setPaginationAction = payload => ({
	type: SET_PAGINATION,
	payload,
});

export const SET_IS_USERS_LIST_LOADING = 'users/SET_IS_USERS_LIST_LOADING';
export const setIsUsersListLoadingAction = payload => ({
	type: SET_IS_USERS_LIST_LOADING,
	payload,
});
export const SET_TABLE_SEARCH = 'users/SET_TABLE_SEARCH';
export const setTableSearchAction = payload => ({
	type: SET_TABLE_SEARCH,
	payload,
});

export const RESET_STATE = 'users/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});
