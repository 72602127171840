import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';

export const CargoPickupBadge = ({ text }) => {
	return <div className="cargo-pickup-badge">{text}</div>;
};

CargoPickupBadge.propTypes = {
	text: PropTypes.string.isRequired,
};
