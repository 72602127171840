import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { searchReqsAction, setFoundReqsAction, setSearchValueAction } from './actions';
import { getFoundReqsSelector, getSearchValueSelector } from './selectors';
import { FormSelect } from '../../semantic-ui/components/form-select';

const HeaderSearchWrapper = ({ onSearchReqs, foundReqs, onSetSearchValue, searchValue, onSetFoundReqs }) => {
	useInjectSaga({ key: 'headerSearchSaga', saga });
	useInjectReducer({ key: 'headerSearchReducer', reducer });

	const { search, pathname } = useLocation();

	const history = useHistory();

	const query = new URLSearchParams(search);

	const handleSearchChange = ({ target: { value } }) => {
		onSetSearchValue(value);
	};

	const handleKeyDown = e => {
		e.preventDefault();

		if (searchValue) {
			onSearchReqs(searchValue);
		} else {
			onSetFoundReqs([]);
		}
	};

	const handleChange = id => {
		query.set('modal', 'reqs_details');
		query.set('id', id);

		history.push(`${pathname}?${query}`);
	};

	return (
		<form onSubmit={handleKeyDown} className="header__search-field">
			<FormSelect
				type="text"
				autoComplete="off"
				noResultsMessage="Не найдено"
				placeholder="Для поиска введите ID и нажмите ввод"
				options={foundReqs}
				onSearchChange={handleSearchChange}
				isWithoutSelectOnBlur
				onKeyDown={handleKeyDown}
				onItemChange={handleChange}
				icon="search"
			/>
		</form>
	);
};

const mapStateToProps = createStructuredSelector({
	foundReqs: getFoundReqsSelector(),
	searchValue: getSearchValueSelector(),
});

const mapDispatchToProps = {
	onSearchReqs: searchReqsAction,
	onSetSearchValue: setSearchValueAction,
	onSetFoundReqs: setFoundReqsAction,
};

HeaderSearchWrapper.propTypes = {
	onSearchReqs: PropTypes.func.isRequired,
	onSetSearchValue: PropTypes.func.isRequired,
	onSetFoundReqs: PropTypes.func.isRequired,
	searchValue: PropTypes.string.isRequired,
	foundReqs: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			data: PropTypes.shape({
				title: PropTypes.string.isRequired,
				address: PropTypes.string.isRequired,
				isBold: PropTypes.bool.isRequired,
			}),
		}),
	).isRequired,
};

const HeaderSearch = connect(mapStateToProps, mapDispatchToProps)(HeaderSearchWrapper);

export { HeaderSearch };
