/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { getFormValues, isInvalid } from 'redux-form';
import { initialState } from './reducer';
import { FORM_NAME, CAR_TABLE_ROWS_NAMES } from './constants';

export const selectCarsModalDomain = state => state.carsModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getCarInfoSelector = () =>
	createSelector(selectCarsModalDomain, ({ carInfo }) => {
		if (!carInfo) {
			return [];
		}

		const data = {
			number: carInfo.number || '-',
			vin: carInfo.vin || '-',
			tonnage: carInfo.tonnage?.name ? carInfo.tonnage?.name : '-',
			trailer: carInfo.trailer?.name || '-',
			author: {
				name: `${carInfo.user?.last_name || '-'} ${carInfo.user?.first_name || '-'}`,
				role: carInfo.user?.role.name || '-',
			},
		};

		return Object.entries(data).reduce((result, [key, value]) => {
			return [...result, { id: key, title: CAR_TABLE_ROWS_NAMES[key], value }];
		}, []);
	});

export const getIsCarInfoLoadingSelector = () =>
	createSelector(
		selectCarsModalDomain,
		({ isCarInfoLoading, isCarDeleting, isCarSaving }) => isCarInfoLoading || isCarDeleting || isCarSaving,
	);

export const getCarNameSelector = () => createSelector(selectCarsModalDomain, ({ carInfo }) => carInfo?.name || '-');

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'car');
	});

export const getIsAuditTabShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'car' && query?.tab === 'audit');
	});

export const getActiveCarIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => (query?.modal === 'car' ? query?.id : null));

export const getIsCarAddFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'car' && query?.mode === 'add');
	});

export const getIsCarEditFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'car' && Boolean(query?.id) && query?.mode === 'edit');
	});

export const getFormInitialValuesSelector = () =>
	createSelector(selectCarsModalDomain, ({ carInfo }) => {
		if (!carInfo) {
			return {
				trailer: 'none',
				tonnage_id: 'none',
			};
		}

		return {
			number: carInfo.number,
			vin: carInfo.vin,
			name: carInfo.name,
			trailer: carInfo.trailer?.id || 'none',
			tonnage_id: carInfo.tonnage?.id ? String(carInfo.tonnage?.id) : 'none',
		};
	});

export const getDataForCreateOrUpdateCarSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues => ({
		...formValues,
		trailer_id: formValues.trailer === 'none' ? null : formValues.trailer,
		tonnage_id: formValues.tonnage_id === 'none' ? null : formValues.tonnage_id,
	}));

export const getTrailersListSelector = () => createSelector(selectCarsModalDomain, ({ trailersList }) => trailersList);

export const getIsTrailersListLoadingSelector = () =>
	createSelector(selectCarsModalDomain, ({ isTrailersListLoading }) => isTrailersListLoading);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);

export const getTonnagesSelector = () => createSelector(selectCarsModalDomain, ({ tonnages }) => tonnages);

export const getIsTonnagesLoadingSelector = () =>
	createSelector(selectCarsModalDomain, ({ isTonnagesLoading }) => isTonnagesLoading);
