/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { reduxForm, reset, submit } from 'redux-form';
import PropTypes from 'prop-types';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { FORM_NAME } from './constants';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import {
	getActiveCarIdSelector,
	getCarInfoSelector,
	getCarNameSelector,
	getFormInitialValuesSelector,
	getIsAuditTabShownSelector,
	getIsCarAddFormOpenSelector,
	getIsCarEditFormOpenSelector,
	getIsCarInfoLoadingSelector,
	getIsModalShownSelector,
	getIsTonnagesLoadingSelector,
	getIsTrailersListLoadingSelector,
	getTonnagesSelector,
	getTrailersListSelector,
} from './selectors';
import {
	createCarAction,
	deleteCarAction,
	getCarInfoAction,
	getTonnagesAction,
	getTrailersListAction,
	resetStateAction,
	updateCarAction,
} from './actions';
import { CarContent } from './components/car-content';
import { removeModalQuery } from '../../utils/removeModalQuery';
import { getIsAvailableCarsButtonsSelector } from '../profile/selectors';
import { validate } from './utils';
import { setWindowInfoAction } from '../alert-modal/actions';
import { Audit } from '../../components/audit';

const CarsModalWrapper = ({
	onGetCarInfo,
	isCarInfoLoading,
	isCarEditFormOpen,
	isCarAddFormOpen,
	carInfo,
	carName,
	onResetState,
	onDeleteCar,
	isModalShown,
	activeCarId,
	onCreateCar,
	onUpdateCar,
	trailersList,
	isTrailersListLoading,
	onGetTrailersList,
	onClearForm,
	isAvailable,
	onFormSubmit,
	onSetWindowInfo,
	isAuditTabShown,
	tonnages,
	onGetTonnages,
	isTonnagesLoading,
}) => {
	useInjectSaga({ key: 'carsModalSaga', saga });
	useInjectReducer({ key: 'carsModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		if (isModalShown) onGetTonnages();
	}, [isModalShown]);

	useEffect(() => {
		if (activeCarId) onGetCarInfo(activeCarId);
	}, [activeCarId]);

	useEffect(() => {
		if (isCarEditFormOpen || isCarAddFormOpen) {
			onGetTrailersList();
		}
	}, [isCarEditFormOpen, isCarAddFormOpen]);

	const handleCarFormOpenButtonClick = mode => {
		if (activeCarId) {
			query.set('id', activeCarId);
		} else {
			query.delete('id');
		}

		if (mode === 'edit') {
			query.set('mode', 'edit');
		}

		if (mode === 'cancel') {
			query.delete('mode');
			onClearForm();
		}

		if (mode === 'cancel' && !activeCarId) {
			onResetState();
			onClearForm();
			query.delete('modal');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('mode');
		query.delete('id');
		query.delete('tab');

		onResetState();
		onClearForm();
		history.push(`${pathname}?${query}`);
	};

	const handleNavItemClick = tab => {
		if (tab === 'info') {
			query.delete('tab');
		}

		if (tab === 'audit') {
			query.set('tab', 'audit');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleCarDelete = () => {
		onSetWindowInfo({
			type: 'delete',
			title: 'Вы уверены?',
			text: 'Вы не сможете это отменить',
			button: {
				type: 'success',
				text: 'Да, удалить',
				onClick: () => onDeleteCar({ id: activeCarId, redirect: handleModalClose }),
			},
		});
	};

	const handleCarFormSubmit = async () => {
		await onFormSubmit(FORM_NAME);

		if (activeCarId) {
			onUpdateCar({
				id: activeCarId,
				redirect: () => handleCarFormOpenButtonClick('cancel'),
				searchQuery: removeModalQuery(query.toString()),
			});
			return;
		}

		onCreateCar({
			redirect: handleModalClose,
			resetState: onResetState,
			searchQuery: removeModalQuery(query.toString()),
		});
	};

	const handleAddTrailerButtonClick = () => {
		history.push('/home/cars?modal=trailer&mode=add');
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleCarFormSubmit();
		}
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText={
				isCarEditFormOpen || isCarAddFormOpen
					? `${isCarEditFormOpen ? 'Редактор машины' : 'Новая машина'}`
					: carName
			}
			navItems={
				isCarAddFormOpen || isCarEditFormOpen
					? []
					: [
							{
								id: 'info',
								text: 'Информация',
								onClick: () => handleNavItemClick('info'),
								isActive: !isAuditTabShown,
							},
							{
								id: 'audit',
								text: 'Изменения',
								onClick: () => handleNavItemClick('audit'),
								isActive: isAuditTabShown,
							},
					  ]
			}
			modalContent={
				!isAuditTabShown ? (
					<CarContent
						carInfo={carInfo}
						isCarInfoLoading={isCarInfoLoading}
						isCarFormOpen={isCarEditFormOpen || isCarAddFormOpen}
						trailersList={trailersList}
						isTrailersListLoading={isTrailersListLoading}
						onAddTrailerButtonClick={handleAddTrailerButtonClick}
						tonnages={tonnages}
						isTonnagesLoading={isTonnagesLoading}
					/>
				) : (
					<Audit entityName="cars" entityId={activeCarId} />
				)
			}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'car' }]}
			footerButtons={
				<ModalFooterButtons
					leftButtons={
						!(isCarEditFormOpen || isCarAddFormOpen) && isAvailable.delete && !isAuditTabShown
							? [
									{
										onClick: handleCarDelete,
										text: 'Удалить',
										color: 'warning',
										disabled: isCarInfoLoading,
									},
							  ]
							: []
					}
					rightButtons={
						isCarEditFormOpen || isCarAddFormOpen
							? [
									{
										onClick: handleCarFormSubmit,
										text: 'Сохранить',
										color: 'primary',
										disabled: isCarInfoLoading,
									},
									{
										onClick: () => handleCarFormOpenButtonClick('cancel'),
										text: 'Отмена',
										color: 'secondary',
										disabled: isCarInfoLoading,
									},
							  ]
							: [
									...(isAvailable.update && !isAuditTabShown
										? [
												{
													onClick: () => handleCarFormOpenButtonClick('edit'),
													text: 'Изменить',
													color: 'primary',
													disabled: isCarInfoLoading,
												},
										  ]
										: []),
									{
										onClick: handleModalClose,
										text: 'Закрыть',
										color: 'secondary',
									},
							  ]
					}
				/>
			}
		/>
	);
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
})(CarsModalWrapper);

const mapStateToProps = createStructuredSelector({
	carInfo: getCarInfoSelector(),
	isCarInfoLoading: getIsCarInfoLoadingSelector(),
	isCarAddFormOpen: getIsCarAddFormOpenSelector(),
	isCarEditFormOpen: getIsCarEditFormOpenSelector(),
	carName: getCarNameSelector(),
	isModalShown: getIsModalShownSelector(),
	activeCarId: getActiveCarIdSelector(),
	initialValues: getFormInitialValuesSelector(),
	trailersList: getTrailersListSelector(),
	isTrailersListLoading: getIsTrailersListLoadingSelector(),
	isAvailable: getIsAvailableCarsButtonsSelector(),
	isAuditTabShown: getIsAuditTabShownSelector(),
	tonnages: getTonnagesSelector(),
	isTonnagesLoading: getIsTonnagesLoadingSelector(),
});

const mapDispatchToProps = {
	onGetCarInfo: getCarInfoAction,
	onDeleteCar: deleteCarAction,
	onResetState: resetStateAction,
	onCreateCar: createCarAction,
	onUpdateCar: updateCarAction,
	onGetTrailersList: getTrailersListAction,
	onClearForm: () => reset(FORM_NAME),
	onFormSubmit: submit,
	onSetWindowInfo: setWindowInfoAction,
	onGetTonnages: getTonnagesAction,
};

const CarsModal = connect(mapStateToProps, mapDispatchToProps)(withForm);

CarsModalWrapper.propTypes = {
	onGetCarInfo: PropTypes.func.isRequired,
	carInfo: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]).isRequired,
		}),
	),
	isCarInfoLoading: PropTypes.bool.isRequired,
	isCarEditFormOpen: PropTypes.bool.isRequired,
	isCarAddFormOpen: PropTypes.bool.isRequired,
	carName: PropTypes.string.isRequired,
	onDeleteCar: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	activeCarId: PropTypes.string,
	onCreateCar: PropTypes.func.isRequired,
	onUpdateCar: PropTypes.func.isRequired,
	trailersList: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			key: PropTypes.string.isRequired,
		}),
	),
	isTrailersListLoading: PropTypes.bool.isRequired,
	onGetTrailersList: PropTypes.func.isRequired,
	onClearForm: PropTypes.func.isRequired,
	isAvailable: PropTypes.objectOf(PropTypes.bool),
	onFormSubmit: PropTypes.func.isRequired,
	onSetWindowInfo: PropTypes.func.isRequired,
	isAuditTabShown: PropTypes.bool.isRequired,
	onGetTonnages: PropTypes.func.isRequired,
	isTonnagesLoading: PropTypes.bool.isRequired,
	tonnages: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
};

export default CarsModal;
