import {
	RESET_STATE,
	SET_COMPANIES,
	SET_COMPANY_TYPE,
	SET_IS_REGISTRATION_FORM_OPEN,
	SET_IS_COMPANIES_LOADING,
	SET_IS_COMPANY_TOGGLE_OPEN,
	SET_IS_USER_CREATING,
	SET_COMPANIES_SEARCH_VALUE,
} from './actions';

export const initialState = {
	isCompanyToggleOpen: true,
	isRegistrationFormOpen: false,
	isUserCreating: false,
	isCompaniesLoading: false,
	companyType: 2,
	companies: [],
	companiesSearchValue: '',
};

const registrationReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_IS_COMPANY_TOGGLE_OPEN:
			return {
				...state,
				isCompanyToggleOpen: payload,
			};
		case SET_IS_REGISTRATION_FORM_OPEN:
			return {
				...state,
				isRegistrationFormOpen: payload,
			};
		case SET_COMPANY_TYPE:
			return {
				...state,
				companyType: payload,
			};
		case SET_IS_USER_CREATING:
			return {
				...state,
				isUserCreating: payload,
			};
		case SET_IS_COMPANIES_LOADING:
			return {
				...state,
				isCompaniesLoading: payload,
			};
		case SET_COMPANIES:
			return {
				...state,
				companies: payload,
			};
		case SET_COMPANIES_SEARCH_VALUE:
			return {
				...state,
				companiesSearchValue: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default registrationReducer;
