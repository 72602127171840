import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { GET_DICTIONARY_DATA, setDictionaryDataAction, setIsDictionaryDataLoadingAction } from './actions';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { getModesDictionaryRequest } from '../../../api/requests';
import { DISMISS_TIME } from '../../../api/constants';
import { notificationInit } from '../../../modules/notifications/actions';
import { errorHandler } from '../../../api/utils';

export function* getDictionaryDataSaga() {
	try {
		yield put(setIsDictionaryDataLoadingAction(true));

		const { data, message, toast } = yield call(getModesDictionaryRequest);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setDictionaryDataAction(data.map(item => ({ id: item.id, text: item.name }))));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsDictionaryDataLoadingAction(false));
	}
}

export default function* modesDictionarySaga() {
	yield takeEvery(GET_DICTIONARY_DATA, getDictionaryDataSaga);
}
