import { CustomModalCell } from '../../components/custom-modal-cell';

export const FORM_NAME = 'trailers-form';
export const CAR_FORM_NAME = 'user-role-form';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const TRAILER_TABLE_COLUMNS_NAMES = [{ id: 'title' }, { id: 'value', customComponent: CustomModalCell }];

export const TRAILER_TABLE_ROWS_NAMES = {
	number: 'Гос. номер',
	vin: 'Vin номер',
	tonnage: 'Грузоподъемность',
	author: 'Автор',
};

export const FIELDS_FOR_VALIDATE = ['name', 'number'];

export const FORM_FIELDS = ['name', 'number', 'vin'];
