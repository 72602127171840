export const GET_WAY_INFO = 'ways-modal/GET_WAY_INFO';
export const getWayInfoAction = id => ({
	type: GET_WAY_INFO,
	id,
});

export const SET_WAY_INFO = 'ways-modal/SET_WAY_INFO';
export const setWayInfoAction = payload => ({
	type: SET_WAY_INFO,
	payload,
});

export const SET_IS_WAY_INFO_LOADING = 'ways-modal/SET_IS_WAY_INFO_LOADING';
export const setIsWayInfoLoadingAction = payload => ({
	type: SET_IS_WAY_INFO_LOADING,
	payload,
});

export const DELETE_WAY = 'ways-modal/DELETE_WAY';
export const deleteWayAction = payload => ({
	type: DELETE_WAY,
	payload,
});

export const CREATE_WAY = 'ways-modal/CREATE_WAY';
export const createWayAction = payload => ({
	type: CREATE_WAY,
	payload,
});

export const UPDATE_WAY = 'ways-modal/UPDATE_WAY';
export const updateWayAction = payload => ({
	type: UPDATE_WAY,
	payload,
});

export const SET_IS_WAY_SAVING = 'ways-modal/SET_IS_WAY_SAVING';
export const setIsWaySavingAction = payload => ({
	type: SET_IS_WAY_SAVING,
	payload,
});

export const SET_IS_WAY_DELETING = 'ways-modal/SET_IS_WAY_DELETING';
export const setIsWayDeletingAction = payload => ({
	type: SET_IS_WAY_DELETING,
	payload,
});

export const RESET_STATE = 'ways-modal/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const SET_IS_ADDRESSES_LOADING = 'ways-modal/SET_IS_ADDRESSES_LOADING';
export const setIsLoadingAddressesAction = payload => ({
	type: SET_IS_ADDRESSES_LOADING,
	payload,
});

export const GET_ADDRESSES = 'ways-modal/GET_ADDRESSES';
export const getAddressesAction = payload => ({
	type: GET_ADDRESSES,
	payload,
});

export const SET_ADDRESSES_SEARCH_VALUE = 'ways-modal/SET_ADDRESSES_SEARCH_VALUE';
export const setAddressesSearchValueAction = payload => ({
	type: SET_ADDRESSES_SEARCH_VALUE,
	payload,
});

export const SET_ADDRESSES = 'ways-modal/SET_ADDRESSES';
export const setAddressesAction = payload => ({
	type: SET_ADDRESSES,
	payload,
});

export const GET_MODES = 'ways-modal/GET_MODES';
export const getModesAction = () => ({
	type: GET_MODES,
});

export const SET_IS_MODES_LOADING = 'ways-modal/SET_IS_MODES_LOADING';
export const setIsModesLoadingAction = payload => ({
	type: SET_IS_MODES_LOADING,
	payload,
});

export const SET_MODES = 'ways-modal/SET_MODES';
export const setModesAction = payload => ({
	type: SET_MODES,
	payload,
});
