/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { initialState } from './reducer';

export const selectPeriodsDictionaryDomain = state => state.periodsDictionaryReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getIsDictionaryDataLoadingSelector = () =>
	createSelector(selectPeriodsDictionaryDomain, ({ isDictionaryDataLoading }) => isDictionaryDataLoading);

export const getDictionaryDataSelector = () =>
	createSelector(selectPeriodsDictionaryDomain, ({ dictionaryData }) => {
		if (!dictionaryData || dictionaryData.length === 0) {
			return [];
		}

		return dictionaryData;
	});

export const getDictionaryDataAsObjectSelector = () =>
	createSelector(selectPeriodsDictionaryDomain, ({ dictionaryData }) => {
		if (!dictionaryData || dictionaryData.length === 0) {
			return {};
		}

		return dictionaryData.reduce((result, el) => {
			return {
				...result,
				[el.text]: el.id,
			};
		}, {});
	});
export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'periods');
	});
