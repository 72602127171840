export const RESET_STATE = 'email-verify-container/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const GET_FORM_INFO = 'email-verify-container/GET_FORM_INFO';
export const getFormInfoAction = payload => ({
	type: GET_FORM_INFO,
	payload,
});

export const SET_FORM_INFO = 'email-verify-container/SET_FORM_INFO';
export const setFormInfoAction = payload => ({
	type: SET_FORM_INFO,
	payload,
});

export const EMAIL_RESEND = 'email-verify-container/EMAIL_RESEND';
export const emailResendAction = payload => ({
	type: EMAIL_RESEND,
	payload,
});

export const EMAIL_VERIFY = 'email-verify-container/EMAIL_VERIFY';
export const emailVerifyAction = payload => ({
	type: EMAIL_VERIFY,
	payload,
});
