import {
	RESET_STATE,
	SET_CONTACTS,
	SET_CONTACTS_SEARCH_VALUE,
	SET_IS_CONTACTS_LOADING,
	SET_PAGINATION,
} from './actions';

export const initialState = {
	contacts: [],
	contactsSearchValue: '',
	pagination: null,
	isContactsLoading: false,
};

const contactsReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_CONTACTS:
			return {
				...state,
				contacts: payload,
			};
		case SET_IS_CONTACTS_LOADING:
			return {
				...state,
				isContactsLoading: payload,
			};
		case SET_CONTACTS_SEARCH_VALUE:
			return {
				...state,
				contactsSearchValue: payload,
			};
		case SET_PAGINATION:
			return {
				...state,
				pagination: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default contactsReducer;
