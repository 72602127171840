import {
	RESET_STATE,
	SET_DRIVERS,
	SET_IS_DRIVERS_LOADING,
	SET_IS_DRIVER_CHANGING,
	SET_IS_REQUEST_INFO_LOADING,
	SET_REQUEST_INFO,
} from './actions';

export const initialState = {
	isDriverChanging: false,
	isDriversLoading: false,
	isRequestInfoLoading: false,
	requestInfo: null,
	drivers: [],
};

const driverChangeModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_IS_DRIVER_CHANGING:
			return {
				...state,
				isDriverChanging: payload,
			};
		case SET_IS_REQUEST_INFO_LOADING:
			return {
				...state,
				isRequestInfoLoading: payload,
			};
		case SET_REQUEST_INFO:
			return {
				...state,
				requestInfo: payload,
			};
		case SET_DRIVERS:
			return {
				...state,
				drivers: payload,
			};
		case SET_IS_DRIVERS_LOADING:
			return {
				...state,
				isDriversLoading: payload,
			};
		case RESET_STATE:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default driverChangeModalReducer;
