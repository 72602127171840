/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { destroy, submit } from 'redux-form';
import PropTypes from 'prop-types';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { FORM_NAME } from './constants';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import {
	getActiveUserIdSelector,
	getUserInfoSelector,
	getUserNameSelector,
	getIsUserAddFormOpenSelector,
	getIsUserEditFormOpenSelector,
	getIsUserInfoLoadingSelector,
	getIsModalShownSelector,
	getIsAuditTabShownSelector,
} from './selectors';
import {
	createUserAction,
	deleteUserAction,
	getUserInfoAction,
	resetStateAction,
	updateUserAction,
	getUsersRolesListAction,
	getUsersListAction,
} from './actions';
import { UserContent } from './components/user-content';
import { removeModalQuery } from '../../utils/removeModalQuery';
import { getIsAvailableUsersButtonsSelector } from '../profile/selectors';
import { setWindowInfoAction } from '../alert-modal/actions';
import { Audit } from '../../components/audit';

const UsersModalWrapper = ({
	onGetUserInfo,
	isUserInfoLoading,
	isUserEditFormOpen,
	isUserAddFormOpen,
	userInfo,
	userName,
	onResetState,
	onDeleteUser,
	isModalShown,
	activeUserId,
	onCreateUser,
	onUpdateUser,
	onGetUsersRolesList,
	isAvailable,
	onFormSubmit,
	onSetWindowInfo,
	onResetForm,
	isAuditTabShown,
	onGetUsersList,
}) => {
	useInjectSaga({ key: 'usersModalSaga', saga });
	useInjectReducer({ key: 'usersModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		if (activeUserId) {
			onGetUserInfo(activeUserId);
		}
	}, [activeUserId]);

	useEffect(() => {
		if (isUserEditFormOpen || isUserAddFormOpen) {
			onGetUsersRolesList();
			onGetUsersList();
		}
	}, [isUserEditFormOpen, isUserAddFormOpen]);

	const handleUserFormOpenButtonClick = mode => {
		if (activeUserId) {
			query.set('id', activeUserId);
		} else {
			query.delete('id');
		}

		if (mode === 'edit') {
			query.set('mode', 'edit');
		}

		if (mode === 'cancel') {
			onResetForm(FORM_NAME);
			query.delete('mode');
		}

		if (mode === 'cancel' && !activeUserId) {
			onResetState();
			onResetForm(FORM_NAME);
			query.delete('modal');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('mode');
		query.delete('id');
		query.delete('tab');

		onResetState();
		onResetForm(FORM_NAME);
		history.push(`${pathname}?${query}`);
	};

	const handleNavItemClick = tab => {
		if (tab === 'info') {
			query.delete('tab');
		}

		if (tab === 'audit') {
			query.set('tab', 'audit');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleUserDelete = () => {
		onSetWindowInfo({
			type: 'delete',
			title: 'Вы уверены?',
			text: 'Вы не сможете это отменить',
			button: {
				type: 'success',
				text: 'Да, удалить',
				onClick: () => onDeleteUser({ id: activeUserId, redirect: handleModalClose }),
			},
		});
	};

	const handleUserFormSubmit = async () => {
		await onFormSubmit(FORM_NAME);

		if (activeUserId) {
			onUpdateUser({
				id: activeUserId,
				redirect: () => handleUserFormOpenButtonClick('cancel'),
				searchQuery: removeModalQuery(query.toString()),
			});
			return;
		}

		onCreateUser({
			redirect: handleModalClose,
			resetState: () => {
				onResetState();
				onResetForm(FORM_NAME);
			},
			searchQuery: removeModalQuery(query.toString()),
		});
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleUserFormSubmit();
		}
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText={
				isUserEditFormOpen || isUserAddFormOpen
					? `${isUserEditFormOpen ? 'Редактор пользователя' : 'Новый пользователь'}`
					: userName
			}
			navItems={
				isUserAddFormOpen || isUserEditFormOpen
					? []
					: [
							{
								id: 'info',
								text: 'Информация',
								onClick: () => handleNavItemClick('info'),
								isActive: !isAuditTabShown,
							},
							{
								id: 'audit',
								text: 'Изменения',
								onClick: () => handleNavItemClick('audit'),
								isActive: isAuditTabShown,
							},
					  ]
			}
			modalContent={
				!isAuditTabShown ? (
					<UserContent
						userInfo={userInfo}
						isUserInfoLoading={isUserInfoLoading}
						isUserFormOpen={isUserEditFormOpen || isUserAddFormOpen}
					/>
				) : (
					<Audit entityName="users" entityId={activeUserId} />
				)
			}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'user_modal' }]}
			footerButtons={
				<ModalFooterButtons
					leftButtons={
						!(isUserEditFormOpen || isUserAddFormOpen) && isAvailable.delete && !isAuditTabShown
							? [
									{
										onClick: handleUserDelete,
										text: 'Удалить',
										color: 'warning',
										disabled: isUserInfoLoading,
									},
							  ]
							: []
					}
					rightButtons={
						isUserEditFormOpen || isUserAddFormOpen
							? [
									{
										onClick: handleUserFormSubmit,
										text: 'Сохранить',
										color: 'primary',
										disabled: isUserInfoLoading,
									},
									{
										onClick: () => handleUserFormOpenButtonClick('cancel'),
										text: 'Отмена',
										color: 'secondary',
										disabled: isUserInfoLoading,
									},
							  ]
							: [
									...(isAvailable.update && !isAuditTabShown
										? [
												{
													onClick: () => handleUserFormOpenButtonClick('edit'),
													text: 'Изменить',
													color: 'primary',
													disabled: isUserInfoLoading,
												},
										  ]
										: []),
									{
										onClick: handleModalClose,
										text: 'Закрыть',
										color: 'secondary',
									},
							  ]
					}
				/>
			}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	userInfo: getUserInfoSelector(),
	isUserInfoLoading: getIsUserInfoLoadingSelector(),
	isUserAddFormOpen: getIsUserAddFormOpenSelector(),
	isUserEditFormOpen: getIsUserEditFormOpenSelector(),
	userName: getUserNameSelector(),
	isModalShown: getIsModalShownSelector(),
	activeUserId: getActiveUserIdSelector(),
	isAvailable: getIsAvailableUsersButtonsSelector(),
	isAuditTabShown: getIsAuditTabShownSelector(),
});

const mapDispatchToProps = {
	onGetUserInfo: getUserInfoAction,
	onDeleteUser: deleteUserAction,
	onResetState: resetStateAction,
	onCreateUser: createUserAction,
	onUpdateUser: updateUserAction,
	onGetUsersRolesList: getUsersRolesListAction,
	onFormSubmit: submit,
	onSetWindowInfo: setWindowInfoAction,
	onGetUsersList: getUsersListAction,
	onResetForm: destroy,
};

const UsersModal = connect(mapStateToProps, mapDispatchToProps)(UsersModalWrapper);

UsersModalWrapper.propTypes = {
	onGetUserInfo: PropTypes.func.isRequired,
	userInfo: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
			]),
		}),
	),
	isUserInfoLoading: PropTypes.bool.isRequired,
	isUserEditFormOpen: PropTypes.bool.isRequired,
	isUserAddFormOpen: PropTypes.bool.isRequired,
	userName: PropTypes.string.isRequired,
	onDeleteUser: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	activeUserId: PropTypes.string,
	onCreateUser: PropTypes.func.isRequired,
	onUpdateUser: PropTypes.func.isRequired,
	onGetUsersRolesList: PropTypes.func.isRequired,
	isAvailable: PropTypes.objectOf(PropTypes.bool),
	onFormSubmit: PropTypes.func.isRequired,
	onSetWindowInfo: PropTypes.func.isRequired,
	onResetForm: PropTypes.func.isRequired,
	isAuditTabShown: PropTypes.bool.isRequired,
	onGetUsersList: PropTypes.func.isRequired,
};

export default UsersModal;
