export const GET_PARTNERS = 'partners/GET_PARTNERS';
export const getPartnersAction = payload => ({
	type: GET_PARTNERS,
	payload,
});

export const SET_PARTNERS = 'partners/SET_PARTNERS';
export const setPartnersAction = payload => ({
	type: SET_PARTNERS,
	payload,
});

export const SET_IS_PARTNERS_LOADING = 'partners/SET_IS_PARTNERS_LOADING';
export const setIsPartnersLoadingAction = payload => ({
	type: SET_IS_PARTNERS_LOADING,
	payload,
});

export const SET_PARTNERS_SEARCH_VALUE = 'partners/SET_PARTNERS_SEARCH_VALUE';
export const setPartnersSearchValueAction = payload => ({
	type: SET_PARTNERS_SEARCH_VALUE,
	payload,
});

export const SET_PAGINATION = 'partners/SET_PAGINATION';
export const setPaginationAction = payload => ({
	type: SET_PAGINATION,
	payload,
});

export const RESET_STATE = 'partners/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});
