import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { InfoIcon } from '../../../../components/info-icon';

const WaysCustomCell = ({ rowData, columnId }) => {
	if (columnId === 'id') {
		return (
			<div className="address-cell__id">
				<InfoIcon popupContent={`№${rowData[columnId]}`} link={rowData.link} />
			</div>
		);
	}
	if (columnId === 'address_from') {
		const date_from = rowData.date_from ? new Date(rowData.date_from).toLocaleDateString('ru-RU') : '---';

		return (
			<div className="address-cell">
				<div className="address-cell__address-name">{rowData.address_from}</div>
				<div className="address-cell__date">{date_from}</div>
			</div>
		);
	}

	if (columnId === 'name') {
		return (
			<div className="address-cell__name">
				<Link className="address-cell__link" to={rowData.link}>
					{rowData[columnId]}
				</Link>
			</div>
		);
	}

	const date_to = rowData.date_to ? new Date(rowData.date_to).toLocaleDateString('ru-RU') : '---';

	return (
		<div className="address-cell">
			<div className="address-cell__address-name">{rowData.address_to}</div>
			<div className="address-cell__date">{date_to}</div>
		</div>
	);
};

WaysCustomCell.propTypes = {
	rowData: PropTypes.object.isRequired,
	columnId: PropTypes.string.isRequired,
};

export { WaysCustomCell };
