import {
	RESET_STATE,
	SET_COMPANIES,
	SET_COMPANIES_SEARCH_VALUE,
	SET_COMPANIES_TYPES,
	SET_COMPANY_INFO,
	SET_IS_COMPANIES_LOADING,
	SET_IS_COMPANIES_TYPES_LOADING,
	SET_IS_COMPANY_INFO_LOADING,
	SET_IS_COMPANY_SAVING,
} from './actions';

export const initialState = {
	companyInfo: null,
	isCompanyInfoLoading: false,
	isCompanySaving: false,
	companiesTypes: [],
	isCompaniesTypesLoading: false,
	isCompaniesLoading: false,
	companies: [],
	companiesSearchValue: '',
};

const companiesModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_COMPANY_INFO:
			return {
				...state,
				companyInfo: payload,
			};
		case SET_IS_COMPANY_INFO_LOADING:
			return {
				...state,
				isCompanyInfoLoading: payload,
			};
		case SET_IS_COMPANY_SAVING:
			return {
				...state,
				isCompanySaving: payload,
			};
		case SET_COMPANIES_TYPES:
			return {
				...state,
				companiesTypes: payload,
			};
		case SET_IS_COMPANIES_TYPES_LOADING:
			return {
				...state,
				isCompaniesTypesLoading: payload,
			};
		case SET_IS_COMPANIES_LOADING:
			return {
				...state,
				isCompaniesLoading: payload,
			};
		case SET_COMPANIES:
			return {
				...state,
				companies: payload,
			};
		case SET_COMPANIES_SEARCH_VALUE:
			return {
				...state,
				companiesSearchValue: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default companiesModalReducer;
