/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Fragment } from 'react';
import './styles.less';
import { Field, FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import { FormSelectField } from '../../../../../semantic-ui/components/form-select-field';
import { FormInputField } from '../../../../../semantic-ui/components/form-input-field';
import { KgIcon, PieceIcon, PriceIcon, VolumeIcon } from '../../../../../components/text-icon';
import { FormTextAreaField } from '../../../../../semantic-ui/components/form-textarea-field';
import { FormCheckboxField } from '../../../../../semantic-ui/components/form-checkbox-field';
import { CustomDatePicker } from '../../../../../semantic-ui/components/custom-datepicker';
import WeightIcon from '../../../../../components/icons/weight-icon.svg';
import CountIcon from '../../../../../components/icons/count-icon.svg';
import SizeIcon from '../../../../../components/icons/size-icon.svg';
import { PointsComponent } from '../../points-component';
import { normalizeNumber } from '../../../../../utils/normalizeNumber';

export const RequestFormView = ({
	partners,
	onPartnerChange,
	addresses,
	onAddressChange,
	contacts,
	types,
	modes,
	features,
	isPrr,
	onPartnerSearchChange,
	partnerSearchValue,
	onAddPartnerButtonClick,
	onAddAddressButtonClick,
	onAddContactButtonClick,
	onContactChange,
	onPointChange,
	onAddressSearchChange,
	addressSearchValue,
	onContactSearchChange,
	contactSearchValue,
	onPointDelete,
	onPointAdd,
	isValidationCheckboxDisabled,
	onTypeChange,
}) => {
	return (
		<div className="req-form">
			<section className="req-form__section">
				<h5 className="req-form__header-text">Информация о грузоотправителе</h5>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Грузоотправитель</span>
					<div className="req-form__field req-form__field_with-button">
						<div className="req-form__field-wrapper">
							<Field
								name="points.0.partner.id"
								type="text"
								component={FormSelectField}
								autoComplete="off"
								placeholder="Выберите грузоотправителя"
								options={partners[0]}
								loading={false}
								noResultsMessage="Неизвестная организация"
								search
								onChange={value => onPartnerChange(value, 0)}
								onSearchChange={value => onPartnerSearchChange(value, 0)}
								searchValue={partnerSearchValue[0]}
								isWithoutSelectOnBlur
							/>
						</div>
						<div className="req-form__add-button" onClick={() => onAddPartnerButtonClick(0)} />
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Адрес</span>
					<div className="req-form__field req-form__field_with-button">
						<div className="req-form__field-wrapper">
							<Field
								name="points.0.address.id"
								type="text"
								component={FormSelectField}
								autoComplete="off"
								placeholder="Выберите адрес"
								options={addresses[0]}
								loading={false}
								noResultsMessage="Неизвестный адрес"
								onChange={value => onAddressChange(value, 0)}
								search
								onSearchChange={value => onAddressSearchChange(value, 0)}
								searchValue={addressSearchValue[0]}
								isWithoutSelectOnBlur
							/>
						</div>
						<div className="req-form__add-button" onClick={() => onAddAddressButtonClick(0)} />
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Дата отгрузки</span>
					<div className="req-form__field req-form__date-field req-form__date-field_with-time">
						<Field
							name="points.0.date"
							type="text"
							component={CustomDatePicker}
							autoComplete="off"
							onChange={value => onPointChange(value, 0, 'date')}
							placeholder=". . / . . / . ."
							dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
						/>
						<div className="req-form__time-fields">
							<span className="req-form__text req-form__text_compact">Время отгрузки</span>
							<div className="req-form__field req-form__field_with-time">
								<Field
									name="points.0.time_from"
									type="time"
									component={FormInputField}
									autoComplete="off"
									placeholder="00:00"
									isWithoutErrorText
								/>
							</div>
							<span className="req-form__time-fields-separator">-</span>
							<div className="req-form__field req-form__field_with-time">
								<Field
									name="points.0.time_to"
									type="time"
									component={FormInputField}
									autoComplete="off"
									placeholder="00:00"
									isWithoutErrorText
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Контактное лицо</span>
					<div className="req-form__field req-form__field_with-button">
						<div className="req-form__field-wrapper">
							<Field
								name="points.0.contact.id"
								type="text"
								component={FormSelectField}
								autoComplete="off"
								placeholder="Выберите контактное лицо"
								options={contacts[0]}
								loading={false}
								noResultsMessage="Неизвестный контакт"
								onChange={value => onContactChange(value, 0)}
								search
								onSearchChange={value => onContactSearchChange(value, 0)}
								searchValue={contactSearchValue[0]}
								isWithoutSelectOnBlur
							/>
						</div>
						<div className="req-form__add-button" onClick={() => onAddContactButtonClick(0)} />
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right" />
					<div className="req-form__checkbox">
						<Field name="is_prr" component={FormCheckboxField} toggle type="checkbox" />
					</div>
					<span className="req-form__label">Требуются погрузочно разгрузочные работы (ПРР)</span>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Примечание к ПРР</span>
					<div className="req-form__field">
						<Field
							name="description"
							type="text"
							component={FormTextAreaField}
							autoComplete="off"
							placeholder="Укажите примечание к ПРР"
							width="100%"
							resize="vertical"
							disabled={!isPrr.isPrr}
						/>
					</div>
				</div>
			</section>
			<section className="req-form__section">
				<h5 className="req-form__header-text">Информация о грузе</h5>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Характер груза</span>
					<div className="req-form__field">
						<Field
							name="type"
							type="text"
							component={FormSelectField}
							autoComplete="off"
							search
							placeholder="Характер груза"
							options={types}
							loading={false}
							noResultsMessage=""
							isWithoutSelectOnBlur
							onChange={onTypeChange}
						/>
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Температурный режим</span>
					<div className="req-form__field">
						<Field
							name="mode"
							type="text"
							component={FormSelectField}
							autoComplete="off"
							search
							placeholder="Температурный режим"
							options={modes}
							loading={false}
							noResultsMessage=""
							isWithoutSelectOnBlur
						/>
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right" />
					<div className="req-form__field req-form__field_with-checkbox">
						<div className="req-form__checkbox-wrapper">
							<div className="req-form__checkbox">
								<Field
									name="is_thermo_check"
									component={FormCheckboxField}
									toggle
									type="checkbox"
									disabled={isValidationCheckboxDisabled}
								/>
							</div>
							<span className="req-form__checkbox-label">Наличие Термочека</span>
						</div>
						<div className="req-form__checkbox-wrapper">
							<div className="req-form__checkbox">
								<Field
									name="is_validated"
									component={FormCheckboxField}
									toggle
									type="checkbox"
									disabled={isValidationCheckboxDisabled}
								/>
							</div>
							<span className="req-form__checkbox-label">Наличие Валидации</span>
						</div>
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Заявленная стоимость груза</span>
					<div className="req-form__field">
						<Field
							name="cost"
							type="text"
							normalize={normalizeNumber}
							component={FormInputField}
							autoComplete="off"
							icon={PriceIcon}
							placeholder="Укажите заявленную стоимость груза"
						/>
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Габаритные характеристики</span>
					<div className="req-form__row-with-icons">
						<div className="req-form__column">
							<div className="req-form__cargo-field">
								<img src={WeightIcon} alt="weight-icon" />
								<div className="req-form__field_small">
									<Field
										name="weight"
										type="text"
										normalize={normalizeNumber}
										component={FormInputField}
										autoComplete="off"
										icon={KgIcon}
										placeholder="Масса"
										isWithoutErrorText
									/>
								</div>
							</div>
						</div>
						<div className="req-form__column">
							<div className="req-form__cargo-field">
								<img src={SizeIcon} alt="weight-icon" />
								<div className="req-form__field_small">
									<Field
										name="volume"
										type="text"
										normalize={normalizeNumber}
										component={FormInputField}
										autoComplete="off"
										icon={VolumeIcon}
										placeholder="Объем"
										isWithoutErrorText
									/>
								</div>
							</div>
						</div>
						<div className="req-form__column">
							<div className="req-form__cargo-field">
								<img src={CountIcon} alt="count-icon" />
								<div className="req-form__field_small">
									<Field
										name="place"
										type="text"
										normalize={normalizeNumber}
										component={FormInputField}
										autoComplete="off"
										icon={PieceIcon}
										placeholder="Кол."
										isWithoutErrorText
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="req-form__row__error">
					<Field
						name="weight"
						component={({ meta }) => {
							if (meta.error && meta.touched) {
								return <div className="req-form__error-message">{meta.error}</div>;
							}

							return null;
						}}
					/>
					<Field
						name="volume"
						component={({ meta }) => {
							if (meta.error && meta.touched) {
								return <div className="req-form__error-message">{meta.error}</div>;
							}

							return null;
						}}
					/>
					<Field
						name="place"
						component={({ meta }) => {
							if (meta.error && meta.touched) {
								return <div className="req-form__error-message">{meta.error}</div>;
							}

							return null;
						}}
					/>
				</div>
				<div className="req-form__row features">
					{features.map(({ icon, name }) => (
						<Fragment key={icon}>
							<span className="req-form__text req-form__text_right" />
							<div className="req-form__checkbox">
								<Field name={icon} component={FormCheckboxField} toggle type="checkbox" />
							</div>
							<span className="req-form__label">{name}</span>
						</Fragment>
					))}
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Примечание</span>
					<div className="req-form__field">
						<Field
							name="comment"
							type="text"
							component={FormTextAreaField}
							autoComplete="off"
							placeholder="Укажите примечание о грузе"
							width="100%"
							resize="vertical"
						/>
					</div>
				</div>
			</section>
			<FieldArray
				name="points"
				component={PointsComponent}
				props={{
					partners,
					onPartnerChange,
					onPartnerSearchChange,
					partnerSearchValue,
					onAddPartnerButtonClick,
					addresses,
					onAddressChange,
					onAddressSearchChange,
					addressSearchValue,
					onAddAddressButtonClick,
					onPointChange,
					contacts,
					onContactChange,
					onContactSearchChange,
					contactSearchValue,
					onAddContactButtonClick,
					isPrr,
					onPointDelete,
					onPointAdd,
				}}
			/>
		</div>
	);
};

RequestFormView.propTypes = {
	partners: PropTypes.shape({
		0: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.number.isRequired,
				value: PropTypes.number.isRequired,
				text: PropTypes.string.isRequired,
			}),
		),
		1: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.number.isRequired,
				value: PropTypes.number.isRequired,
				text: PropTypes.string.isRequired,
			}),
		),
	}),
	addresses: PropTypes.shape({
		0: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.number.isRequired,
				value: PropTypes.number.isRequired,
				text: PropTypes.string.isRequired,
			}),
		),
		1: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.number.isRequired,
				value: PropTypes.number.isRequired,
				text: PropTypes.string.isRequired,
			}),
		),
	}),
	contacts: PropTypes.shape({
		0: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.number.isRequired,
				value: PropTypes.number.isRequired,
				text: PropTypes.string.isRequired,
			}),
		),
		1: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.number.isRequired,
				value: PropTypes.number.isRequired,
				text: PropTypes.string.isRequired,
			}),
		),
	}),
	modes: PropTypes.array.isRequired,
	types: PropTypes.array.isRequired,
	features: PropTypes.array.isRequired,
	onPartnerChange: PropTypes.func.isRequired,
	onAddressChange: PropTypes.func.isRequired,
	onContactChange: PropTypes.func.isRequired,
	isPrr: PropTypes.shape({ isPrr: PropTypes.bool, points: PropTypes.arrayOf(PropTypes.bool) }).isRequired,
	onPartnerSearchChange: PropTypes.func.isRequired,
	onAddPartnerButtonClick: PropTypes.func.isRequired,
	onAddAddressButtonClick: PropTypes.func.isRequired,
	onAddContactButtonClick: PropTypes.func.isRequired,
	onPointDelete: PropTypes.func.isRequired,
	onPointChange: PropTypes.func.isRequired,
	onAddressSearchChange: PropTypes.func.isRequired,
	onPointAdd: PropTypes.func.isRequired,
	partnerSearchValue: PropTypes.shape({ 0: PropTypes.string, 1: PropTypes.string }).isRequired,
	addressSearchValue: PropTypes.shape({ 0: PropTypes.string, 1: PropTypes.string }).isRequired,
	onContactSearchChange: PropTypes.func.isRequired,
	contactSearchValue: PropTypes.shape({ 0: PropTypes.string, 1: PropTypes.string }).isRequired,
	isValidationCheckboxDisabled: PropTypes.bool.isRequired,
	onTypeChange: PropTypes.func.isRequired,
};
