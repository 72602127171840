import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';
import { FormCheckboxField } from '../../../../../semantic-ui/components/form-checkbox-field';
import { LoginField } from '../../../../../semantic-ui/components/login-field';

export const LoginModalView = ({
	onForgotModalOpen,
	onFormSubmit,
	loginErrorMessage,
	hasLoginError,
	isUserAuthorizing,
}) => {
	return (
		<div className="login-modal">
			<header className="login-modal__header">
				<div className="login-modal__header-text">
					<h3 className="login-modal__title">Авторизация</h3>
					<h5 className="login-modal__text">Пожалуйста, войдите или зарегистрируйтесь</h5>
				</div>
			</header>
			<div className="login-modal__field">
				<Field
					name="email"
					hasError={hasLoginError}
					type="text"
					component={LoginField}
					label="Адрес электронной почты"
				/>
			</div>
			<div className="login-modal__field">
				<Field
					name="password"
					hasError={hasLoginError}
					type="password"
					component={LoginField}
					label="Пароль"
					error={loginErrorMessage}
				/>
			</div>
			<div className="login-modal__row">
				<div className="login-modal__checkbox-wrapper">
					<Field name="remember" type="checkbox" component={FormCheckboxField} />
					<span className="login-modal__label">Запомнить меня</span>
				</div>
				<div className="login-modal__link" onClick={onForgotModalOpen}>
					Забыли пароль?
				</div>
			</div>
			<Button
				className="login-modal__submit-button"
				onClick={onFormSubmit}
				loading={isUserAuthorizing}
				disabled={isUserAuthorizing}
			>
				Авторизация
			</Button>
			<div className="login-modal__footer">
				У вас нет учетной записи?{' '}
				<Link className="login-modal__link login-modal__link_bold" to="/register">
					Регистрация
				</Link>
			</div>
		</div>
	);
};

LoginModalView.propTypes = {
	onForgotModalOpen: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	loginErrorMessage: PropTypes.string.isRequired,
	hasLoginError: PropTypes.bool.isRequired,
	isUserAuthorizing: PropTypes.bool.isRequired,
};
