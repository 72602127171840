import React from 'react';
import PropTypes from 'prop-types';
import { SimpleTableComponent } from '../../../../components/simple-table';
import { PARTNER_TABLE_COLUMNS_NAMES } from '../../constants';
import { PartnerForm } from '../partner-form';

export const PartnerModalContent = ({ partnerInfo, isPartnerFormOpen, isPartnerInfoLoading }) => {
	return isPartnerFormOpen ? (
		<PartnerForm />
	) : (
		<SimpleTableComponent
			rows={partnerInfo}
			isTableDataLoading={isPartnerInfoLoading}
			columns={PARTNER_TABLE_COLUMNS_NAMES}
		/>
	);
};

PartnerModalContent.propTypes = {
	partnerInfo: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.shape({
					name: PropTypes.string,
					role: PropTypes.string,
				}),
			]).isRequired,
		}),
	),
	isPartnerFormOpen: PropTypes.bool.isRequired,
	isPartnerInfoLoading: PropTypes.bool.isRequired,
};
