/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { getFormSubmitErrors, getFormValues, isInvalid } from 'redux-form';
import { initialState } from './reducer';
import { DATE_TIME_FORMAT, FORM_NAME } from './constants';

export const selectDateCargoCollectionModalDomain = state => state.dateCargoCollectionModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'date_cargo_collection');
	});

export const getDataForCreateDateCargoCollectionSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues => formValues);

export const getReqNameSelector = () =>
	createSelector(selectDateCargoCollectionModalDomain, ({ requestInfo }) => {
		return `Заявка #${requestInfo?.id || '-'} от ${
			requestInfo?.created_at ? moment(requestInfo.created_at).format(DATE_TIME_FORMAT) : '-'
		}`;
	});

export const getModalStatusesSelector = () =>
	createSelector(selectDateCargoCollectionModalDomain, ({ requestInfo }) => {
		const collectStatuses = [];

		if (requestInfo?.tab && !isEmpty(requestInfo?.tab)) {
			collectStatuses.push(requestInfo?.tab);
		}
		if (requestInfo?.status && !isEmpty(requestInfo?.status)) {
			collectStatuses.push(requestInfo?.status);
		}

		return collectStatuses;
	});

export const getIsButtonsDisabledSelector = () =>
	createSelector(
		selectDateCargoCollectionModalDomain,
		({ isDateCargoCollectionCreating, isRequestInfoLoading }) =>
			isDateCargoCollectionCreating || isRequestInfoLoading,
	);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);

export const getFormErrorsSelector = () =>
	createSelector(getFormSubmitErrors(FORM_NAME), submit => {
		return Object.values(submit).map(item => (!item ? 'Заполните это поле' : item));
	});

export const getDateCargoFormInitialValuesSelector = () =>
	createSelector(selectDateCargoCollectionModalDomain, data => {
		if (data.requestInfo) {
			const { fetch_time_from, fetch_time_to, fetch_at } = data.requestInfo;

			return {
				fetch_time_from: fetch_time_from || '',
				fetch_time_to: fetch_time_to || '',
				fetch_at: fetch_at || '',
			};
		}

		return {};
	});
