import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { SimpleTableComponent } from '../../../../components/simple-table';
import { WAY_TABLE_COLUMNS_NAMES } from '../../constants';
import { getIsWayInfoLoadingSelector, getWayInfoSelector } from '../../selectors';
import { getWayInfoAction } from '../../actions';

const WaiInfoWrapper = ({ wayInfo, isWayInfoLoading }) => {
	return (
		<SimpleTableComponent rows={wayInfo} isTableDataLoading={isWayInfoLoading} columns={WAY_TABLE_COLUMNS_NAMES} />
	);
};

const mapStateToProps = createStructuredSelector({
	wayInfo: getWayInfoSelector(),
	isWayInfoLoading: getIsWayInfoLoadingSelector(),
});

const mapDispatchToProps = {
	onGetWayInfo: getWayInfoAction,
};

WaiInfoWrapper.propTypes = {
	onGetWayInfo: PropTypes.func.isRequired,
	wayInfo: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]),
		}),
	),
	isWayInfoLoading: PropTypes.bool.isRequired,
};
const WaysInfo = connect(mapStateToProps, mapDispatchToProps)(WaiInfoWrapper);

export { WaysInfo };
