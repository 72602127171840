export const transformToValidationErrors = errors => {
	return Object.entries(errors).reduce((result, [key, value]) => {
		return {
			...result,
			[key]: value[0],
		};
	}, {});
};

export const thousandSeparator = str => {
	const parts = `${str}`.split('.');

	const main = parts[0];

	const decimal = parts[1] ? `.${parts[1]}` : '';

	let formattedMain;
	if (main.length > 6) {
		formattedMain = main.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	} else {
		formattedMain = main;
	}

	return `${formattedMain}${decimal}`;
};

export const getCargoTime = (time1, time2) => {
	if (time1 || time2) {
		if (time1 && time2) {
			if (time1 === time2) {
				return time1;
			}

			return `${time1} - ${time2}`;
		}

		return time1 || time2;
	}

	return '';
};
