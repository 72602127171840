import React from 'react';
import { useHistory } from 'react-router';
import { Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './styles.less';

export const InfoIcon = ({ popupContent, link }) => {
	const history = useHistory();

	const handleClick = () => {
		if (link) {
			history.push(link);
		}
	};

	return (
		<Popup
			content={popupContent}
			trigger={<div onClick={handleClick} className="info-icon" />}
			position="top center"
			size="mini"
			inverted
			style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
		/>
	);
};

InfoIcon.propTypes = {
	popupContent: PropTypes.string.isRequired,
	link: PropTypes.string,
};
