export const filterMenuByRules = (array, rules) => {
	if (!rules) return array;

	return array.filter(item => {
		const href = item.href;

		if (href === '/home/information') return true;

		const matchingKey = Object.keys(rules).find(key => href.includes(key));

		return matchingKey && rules[matchingKey].access === true;
	});
};
