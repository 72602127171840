import { RESET_STATE, SET_CHATS_LIST, SET_IS_CHATS_LIST_LOADING } from './actions';

export const initialState = {
	isChatsListLoading: true,
	chatsList: [],
};

const chatsListReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_CHATS_LIST:
			return {
				...state,
				chatsList: payload,
			};
		case SET_IS_CHATS_LIST_LOADING:
			return {
				...state,
				isChatsListLoading: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default chatsListReducer;
