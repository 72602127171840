export const GET_REQ_DETAILS = 'reqs-details-modal/GET_REQ_DETAILS';
export const getReqDetailsAction = payload => ({
	type: GET_REQ_DETAILS,
	payload,
});

export const SET_REQ_DETAILS = 'reqs-details-modal/SET_REQ_DETAILS';
export const setReqDetailsAction = payload => ({
	type: SET_REQ_DETAILS,
	payload,
});

export const SET_IS_REQ_DETAILS_LOADING = 'reqs-details-modal/SET_IS_REQ_DETAILS_LOADING';
export const setIsReqDetailsLoadingAction = payload => ({
	type: SET_IS_REQ_DETAILS_LOADING,
	payload,
});

export const GET_REQ_DOCS = 'reqs-details-modal/GET_REQ_DOCS';
export const getReqDocsAction = id => ({
	type: GET_REQ_DOCS,
	id,
});

export const SET_REQ_DOCS = 'reqs-details-modal/SET_REQ_DOCS';
export const setReqDocsAction = payload => ({
	type: SET_REQ_DOCS,
	payload,
});

export const SET_IS_REQ_DOCS_LOADING = 'reqs-details-modal/SET_IS_REQ_DOCS_LOADING';
export const setIsReqDocsLoadingAction = payload => ({
	type: SET_IS_REQ_DOCS_LOADING,
	payload,
});

export const GET_EVENT_DATA = 'reqs-details-modal/GET_EVENT_DATA';
export const getEventDataAction = payload => ({
	type: GET_EVENT_DATA,
	payload,
});

export const SET_EVENT_DATA = 'reqs-details-modal/SET_EVENT_DATA';
export const setEventDataAction = payload => ({
	type: SET_EVENT_DATA,
	payload,
});

export const SET_IS_EVENT_DATA_LOADING = 'reqs-details-modal/SET_IS_EVENT_DATA_LOADING';
export const setIsEventDataLoadingAction = payload => ({
	type: SET_IS_EVENT_DATA_LOADING,
	payload,
});

export const RESET_STATE = 'reqs-details-modal/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});
