import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';

const EmailVerifyView = ({ emailVerifyInfo, onEmailResend }) => {
	return (
		<div className="email-verify-view">
			<div className="email-verify-view__content">
				<div>
					<h2 className="email-verify-view__title email-verify-view__title_secondary">
						Подтверждение адреса
					</h2>
					<h2 className="email-verify-view__title email-verify-view__title_primary">электронной почты</h2>
				</div>
				<p className="email-verify-view__text">{emailVerifyInfo?.message}</p>
				<div className="email-verify-view__overlay" />
				{emailVerifyInfo?.button && (
					<button onClick={onEmailResend} type="button" className="email-verify-view__button">
						{emailVerifyInfo?.button}
					</button>
				)}
			</div>
		</div>
	);
};

EmailVerifyView.propTypes = {
	emailVerifyInfo: PropTypes.object,
	onEmailResend: PropTypes.func.isRequired,
};

export { EmailVerifyView };
