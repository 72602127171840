import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';

const FormTextAreaField = ({
	input,
	placeholder,
	meta: { touched, error } = {},
	label,
	autoComplete,
	dark,
	height,
	resize,
	width,
	disabled,
	rows,
}) => (
	<div
		className={`input_row ${dark ? 'dark' : ''} ${error && touched ? 'error' : ''} ${disabled ? 'disabled' : ''}`}
		data-testid="form-text-area-field"
	>
		{label && <label htmlFor={label}>{label}</label>}
		<textarea
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...input}
			value={input.value}
			placeholder={placeholder}
			id={label}
			autoComplete={autoComplete}
			style={{ height, resize, width }}
			data-testid="form-text-area-field-component"
			rows={rows || 1}
			disabled={disabled}
		/>
		{touched && error && <span className="validation_error">{error}</span>}
	</div>
);

FormTextAreaField.propTypes = {
	input: PropTypes.object.isRequired,
	placeholder: PropTypes.string.isRequired,
	meta: PropTypes.object.isRequired,
	label: PropTypes.string,
	autoComplete: PropTypes.oneOf(['off']),
	dark: PropTypes.bool,
	height: PropTypes.string,
	resize: PropTypes.string,
	width: PropTypes.string,
	disabled: PropTypes.bool,
	rows: PropTypes.number,
};

export { FormTextAreaField };
