import {
	RESET_STATE,
	SET_IS_TRAILERS_LIST_LOADING,
	SET_PAGINATION,
	SET_TABLE_SEARCH,
	SET_TRAILERS_LIST,
} from './actions';

export const initialState = {
	trailersList: null,
	isTrailersListLoading: true, // если тут false, loader не устанавливается
	pagination: null,
	tableSearch: '',
};

const trailersContainerReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_TRAILERS_LIST:
			return {
				...state,
				trailersList: payload,
			};
		case SET_IS_TRAILERS_LIST_LOADING:
			return {
				...state,
				isTrailersListLoading: payload,
			};
		case SET_PAGINATION:
			return {
				...state,
				pagination: payload,
			};
		case SET_TABLE_SEARCH:
			return {
				...state,
				tableSearch: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default trailersContainerReducer;
