export const RESET_STATE = 'carrier-change-modal/RESET_STATE';
export const resetStateAction = payload => ({
	type: RESET_STATE,
	payload,
});

export const GET_REQUEST_INFO = 'carrier-change-modal/GET_REQUEST_INFO';
export const getRequestInfoAction = payload => ({
	type: GET_REQUEST_INFO,
	payload,
});

export const SET_REQUEST_INFO = 'carrier-change-modal/SET_REQUEST_INFO';
export const setRequestInfoAction = payload => ({
	type: SET_REQUEST_INFO,
	payload,
});

export const SET_IS_REQUEST_INFO_LOADING = 'carrier-change-modal/SET_IS_REQUEST_INFO_LOADING';
export const setIsRequestInfoLoadingAction = payload => ({
	type: SET_IS_REQUEST_INFO_LOADING,
	payload,
});

export const CHANGE_CARRIER = 'carrier-change-modal/CHANGE_CARRIER';
export const changeCarrierAction = payload => ({
	type: CHANGE_CARRIER,
	payload,
});

export const REJECTS_REQUEST = 'carrier-change-modal/REJECTS_REQUEST';
export const rejectsRequestAction = payload => ({
	type: REJECTS_REQUEST,
	payload,
});

export const SET_IS_CARRIER_CHANGING = 'carrier-change-modal/SET_IS_CARRIER_CHANGING';
export const setIsCarrierChangingAction = payload => ({
	type: SET_IS_CARRIER_CHANGING,
	payload,
});

export const GET_CARRIERS = 'carrier-change-modal/GET_CARRIERS';
export const getCarriersAction = payload => ({
	type: GET_CARRIERS,
	payload,
});

export const SET_CARRIERS = 'carrier-change-modal/SET_CARRIERS';
export const setCarriersAction = payload => ({
	type: SET_CARRIERS,
	payload,
});

export const SET_IS_CARRIERS_LOADING = 'carrier-change-modal/SET_IS_CARRIERS_LOADING';
export const setIsCarriersLoadingAction = payload => ({
	type: SET_IS_CARRIERS_LOADING,
	payload,
});
