import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Input, Loader, Table } from 'semantic-ui-react';
import './styles.less';
import TablePagination from '../../semantic-ui/components/table-pagination';

const TableComponent = ({
	headerText,
	onAddButtonClick,
	columns,
	rows,
	pagination,
	onSearch,
	isTableDataLoading,
	isWithoutTableHeader,
	isWithoutHeader,
	isWithoutFooter,
	searchInputValue = '',
	onSearchInputChange,
	onSearchInputClear,
	isChildren,
	isWithoutBoldFirstColumn,
	onCustomCellButtonClick,
}) => {
	return (
		<div className="table-container__wrapper">
			<div
				className={`${isWithoutHeader ? ' table-container__without-header ' : ''}${
					isChildren ? ' table-container_children ' : ''
				}${isWithoutBoldFirstColumn ? ' table-container__without-bold-first-column ' : ''}table-container`}
			>
				{!isWithoutHeader && !isChildren && (
					<div className="table-container__header">
						<div className="table-container__header-left-side">
							<div className="table-container__header-title">{headerText}</div>
							{onAddButtonClick && (
								<div className="table-container__header-button" onClick={onAddButtonClick} />
							)}
						</div>
						<div className="table-container__header-right-side">
							{onSearch && (
								<div className="table-container__header-right-side-search">
									<Input
										input={{
											onKeyDown: onSearch,
											value: searchInputValue,
											onChange: onSearchInputChange,
										}}
										placeholder="Поиск"
										icon={
											searchInputValue ? (
												<Icon name="remove" link onClick={onSearchInputClear} />
											) : null
										}
									/>
								</div>
							)}
						</div>
					</div>
				)}
				<div className="table-container__table">
					{isTableDataLoading ? (
						<Loader active size="big" inline />
					) : (
						<Table>
							{rows.length ? (
								<>
									{!isWithoutTableHeader && (
										<Table.Header fullWidth>
											<Table.Row>
												{columns.map(column => {
													const { name, customHeaderComponent: CustomHeaderComponent } =
														column;

													if (CustomHeaderComponent) {
														return (
															<Table.HeaderCell
																style={{
																	width: column.width,
																	minWidth: column.minWidth,
																	textAlign: column.textAlign,
																}}
																key={name}
															>
																<CustomHeaderComponent columnData={column} />
															</Table.HeaderCell>
														);
													}

													return (
														<Table.HeaderCell
															style={{
																width: column.width,
																minWidth: column.minWidth,
																textAlign: column.textAlign,
															}}
															key={name}
														>
															{name}
														</Table.HeaderCell>
													);
												})}
											</Table.Row>
										</Table.Header>
									)}
									<Table.Body>
										{rows.map(row => (
											<Table.Row key={row.id}>
												{columns.map(
													({ id, customComponent: CustomComponent, ...columnData }) => {
														if (CustomComponent) {
															return (
																<Table.Cell key={id}>
																	<CustomComponent
																		rowData={row}
																		columnId={id}
																		columnData={columnData}
																		onCustomCellButtonClick={
																			onCustomCellButtonClick
																		}
																	/>
																</Table.Cell>
															);
														}

														return <Table.Cell key={id}>{row[id]}</Table.Cell>;
													},
												)}
											</Table.Row>
										))}
									</Table.Body>
								</>
							) : (
								<Table.Body>
									<Table.Row>
										<Table.Cell colSpan={columns.length}>
											<div className="table-container__table-empty-message">
												<div className="table-container__table-empty-message-text">
													{headerText} не найдены
												</div>
											</div>
										</Table.Cell>
									</Table.Row>
								</Table.Body>
							)}
							{!isWithoutFooter && (
								<Table.Footer fullWidth>
									<Table.Row>
										<Table.Cell colSpan={columns.length} className="table-container__footer">
											<div className="table-container__table-footer" />
										</Table.Cell>
									</Table.Row>
								</Table.Footer>
							)}
						</Table>
					)}
				</div>
			</div>

			{pagination && <TablePagination pagination={pagination} isShort />}
		</div>
	);
};

TableComponent.propTypes = {
	headerText: PropTypes.string,
	onAddButtonClick: PropTypes.func,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			customComponent: PropTypes.elementType,
			width: PropTypes.string,
		}),
	),
	rows: PropTypes.arrayOf(PropTypes.object),
	pagination: PropTypes.shape({
		currentPage: PropTypes.number,
		totalPages: PropTypes.number.isRequired,
		total: PropTypes.number,
		from: PropTypes.number,
		to: PropTypes.number,
		isNextItem: PropTypes.bool,
		isPrevItem: PropTypes.bool,
		isFirstItem: PropTypes.bool,
		isLastItem: PropTypes.bool,
		onPageChange: PropTypes.func.isRequired,
		onSelectChange: PropTypes.func.isRequired,
		isPaginationTextHidden: PropTypes.bool,
	}),
	onSearch: PropTypes.func,
	isTableDataLoading: PropTypes.bool.isRequired,
	isWithoutTableHeader: PropTypes.bool,
	isWithoutHeader: PropTypes.bool,
	isWithoutFooter: PropTypes.bool,
	searchInputValue: PropTypes.string,
	onSearchInputChange: PropTypes.func,
	onSearchInputClear: PropTypes.func,
	isChildren: PropTypes.bool,
	isWithoutBoldFirstColumn: PropTypes.bool,
	onCustomCellButtonClick: PropTypes.func,
};

export { TableComponent };
