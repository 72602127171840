export const GET_COMPANY_INFO = 'companies-modal/GET_COMPANY_INFO';
export const getCompanyInfoAction = id => ({
	type: GET_COMPANY_INFO,
	id,
});

export const SET_COMPANY_INFO = 'companies-modal/SET_COMPANY_INFO';
export const setCompanyInfoAction = payload => ({
	type: SET_COMPANY_INFO,
	payload,
});

export const SET_IS_COMPANY_INFO_LOADING = 'companies-modal/SET_IS_COMPANY_INFO_LOADING';
export const setIsCompanyInfoLoadingAction = payload => ({
	type: SET_IS_COMPANY_INFO_LOADING,
	payload,
});

export const UPDATE_COMPANY = 'companies-modal/UPDATE_COMPANY';
export const updateCompanyAction = payload => ({
	type: UPDATE_COMPANY,
	payload,
});

export const SET_IS_COMPANY_SAVING = 'companies-modal/SET_IS_COMPANY_SAVING';
export const setIsCompanySavingAction = payload => ({
	type: SET_IS_COMPANY_SAVING,
	payload,
});

export const RESET_STATE = 'companies-modal/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const GET_COMPANIES_TYPES = 'companies-modal/GET_COMPANIES_TYPES';
export const getCompaniesTypesAction = () => ({
	type: GET_COMPANIES_TYPES,
});

export const SET_COMPANIES_TYPES = 'companies-modal/SET_COMPANIES_TYPES';
export const setCompaniesTypesAction = payload => ({
	type: SET_COMPANIES_TYPES,
	payload,
});
export const SET_IS_COMPANIES_TYPES_LOADING = 'companies-modal/SET_IS_COMPANIES_TYPES_LOADING';
export const setIsCompaniesTypesLoading = payload => ({
	type: SET_IS_COMPANIES_TYPES_LOADING,
	payload,
});

export const GET_COMPANIES = 'companies-modal/GET_COMPANIES';
export const getCompaniesAction = payload => ({
	type: GET_COMPANIES,
	payload,
});

export const SET_COMPANIES_SEARCH_VALUE = 'companies-modal/SET_COMPANIES_SEARCH_VALUE';
export const setCompaniesSearchValueAction = payload => ({
	type: SET_COMPANIES_SEARCH_VALUE,
	payload,
});

export const SET_COMPANIES = 'companies-modal/SET_COMPANIES';
export const setCompaniesAction = payload => ({
	type: SET_COMPANIES,
	payload,
});

export const SET_IS_COMPANIES_LOADING = 'companies-modal/SET_IS_COMPANIES_LOADING';
export const setIsCompaniesLoadingAction = payload => ({
	type: SET_IS_COMPANIES_LOADING,
	payload,
});
