import { Field } from 'redux-form';
import React from 'react';
import PropTypes from 'prop-types';
import { FormInputField } from '../form-input-field';
import './styles.less';

const FormDoublePhoneField = ({
	mainFieldName,
	additionalFieldName,
	isMainLoading,
	isAdditionalLoading,
	mainPlaceholder,
	additionalPlaceholder,
	mainNormalize,
	additionalNormalize,
}) => {
	return (
		<div className="form-double-phone-field">
			<Field
				name={mainFieldName}
				type="text"
				component={FormInputField}
				autoComplete="off"
				placeholder={mainPlaceholder || ''}
				loading={isMainLoading}
				normalize={mainNormalize}
			/>
			<Field
				name={additionalFieldName}
				type="text"
				component={FormInputField}
				autoComplete="off"
				placeholder={additionalPlaceholder || ''}
				loading={isAdditionalLoading}
				normalize={additionalNormalize}
			/>
		</div>
	);
};

FormDoublePhoneField.propTypes = {
	mainFieldName: PropTypes.string.isRequired,
	additionalFieldName: PropTypes.string.isRequired,
	isMainLoading: PropTypes.bool,
	isAdditionalLoading: PropTypes.bool,
	mainPlaceholder: PropTypes.string,
	additionalPlaceholder: PropTypes.string,
	mainNormalize: PropTypes.func,
	additionalNormalize: PropTypes.func,
};

export { FormDoublePhoneField };
