import {
	RESET_STATE,
	SET_ACTIVE_NAV_ITEM,
	SET_INFORMATION,
	SET_IS_INFORMATION_LOADING,
	SET_IS_INFO_COMPANY_CHECKED,
} from './actions';

export const initialState = {
	information: null,
	isInformationLoading: true,
	activeNavItem: 'Сборный груз',
	isInfoCompanyChecked: false,
};

const informationContainerReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_IS_INFORMATION_LOADING:
			return {
				...state,
				isInformationLoading: payload,
			};
		case SET_INFORMATION:
			return {
				...state,
				information: payload,
			};
		case SET_ACTIVE_NAV_ITEM:
			return {
				...state,
				activeNavItem: payload,
			};
		case SET_IS_INFO_COMPANY_CHECKED:
			return {
				...state,
				isInfoCompanyChecked: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default informationContainerReducer;
