/* eslint-disable no-mixed-spaces-and-tabs */
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { stopSubmit, touch } from 'redux-form';
import moment from 'moment';
import { notificationInit } from '../../modules/notifications/actions';
import { FORM_NAME, REQUEST_ERROR_MESSAGE } from './constants';
import {
	GET_REQ_INFO,
	SEND_CARGO_DELIVERY_FORM_ACTION,
	setIsFormSendingAction,
	setIsReqInfoLoadingAction,
	setReqInfoAction,
} from './actions';
import { transformToValidationErrors } from './utils';
import { getFormValuesSelector, getReqInfoSelector } from './selectors';
import { DISMISS_TIME } from '../../api/constants';
import { errorHandler } from '../../api/utils';
import { getSelectDoneRequest, updateSelectDoneRequest } from '../../api/requests';

export function* getReqInfoSaga({ payload: id }) {
	try {
		yield put(setIsReqInfoLoadingAction(true));

		const { data, message, toast } = yield call(getSelectDoneRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const newPoints = data.points.map(point => {
				return { ...point, done_at: moment(point.done_at).format('DD.MM.YYYY') };
			});

			yield put(
				setReqInfoAction({
					...data,
					points: newPoints,
					created_at: moment(data.created_at).format('DD.MM.YYYY'),
				}),
			);
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsReqInfoLoadingAction(false));
	}
}

export function* sendFormSaga({ payload: { activeReqId, idPoint, searchQuery } }) {
	try {
		yield put(setIsFormSendingAction(true));

		const dataForSave = yield select(getFormValuesSelector());

		if (!dataForSave?.[idPoint]) {
			yield put(touch(FORM_NAME, idPoint));
			yield put(stopSubmit(FORM_NAME, transformToValidationErrors({ [idPoint]: 'Заполните это поле' })));

			return;
		}

		const { data, message, toast, errors } = yield call(updateSelectDoneRequest, activeReqId, idPoint, {
			done_at: dataForSave[idPoint],
		});

		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link
							? {
									link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}`,
							  }
							: {}),
					}),
				);
			}
			const reqInfo = yield select(getReqInfoSelector());

			const newPoints = reqInfo.points.map(point => {
				if (point.id === data.id) return { ...data, done_at: moment(data.done_at).format('DD.MM.YYYY') };
				return point;
			});

			yield put(setReqInfoAction({ ...reqInfo, points: newPoints }));
		} else {
			if (errors) {
				yield put(touch(FORM_NAME, idPoint));
				yield put(stopSubmit(FORM_NAME, transformToValidationErrors({ [idPoint]: errors.done_at?.[0] })));
			}

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsFormSendingAction(false));
	}
}

export default function* cargoDeliveryModalSaga() {
	yield takeEvery(GET_REQ_INFO, getReqInfoSaga);
	yield takeEvery(SEND_CARGO_DELIVERY_FORM_ACTION, sendFormSaga);
}
