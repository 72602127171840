/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import { initialState } from './reducer';
import { FILTER_FORM_NAME, OPTIONS_KEYS_NAMES, OPTIONS_KEYS_VALUES } from './constants';
import { getFieldOptions } from './utils';

export const selectReqsFilterDomain = state => state.reqsFilterReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getAccordionIndexSelector = () =>
	createSelector(selectReqsFilterDomain, ({ accordionIndex }) => accordionIndex);

export const getAnalyticsAccordionIndexSelector = () =>
	createSelector(selectReqsFilterDomain, ({ analyticsAccordionIndex }) => analyticsAccordionIndex);

export const getFilterOptionsSelector = () =>
	createSelector(selectReqsFilterDomain, ({ filterOptions }) => {
		return Object.entries(filterOptions).reduce((result, [key, value]) => {
			if (!value || !OPTIONS_KEYS_NAMES[key]) {
				return result;
			}

			const fieldOptions = getFieldOptions(OPTIONS_KEYS_VALUES[key], OPTIONS_KEYS_NAMES[key], value);

			return {
				...result,
				[key]: ['to, exec'].includes(key)
					? fieldOptions
					: [{ key: 'all', text: 'Все', value: 'all' }, ...fieldOptions],
			};
		}, {});
	});

export const getIsFilterClearButtonViewSelector = () =>
	createSelector(getFormValues(FILTER_FORM_NAME), formValues => Boolean(formValues) && !isEmpty(formValues));

export const getIsAnalyticsClearButtonViewSelector = () =>
	createSelector(selectReqsFilterDomain, ({ analytics }) => Boolean(analytics?.some(item => item.isChecked)));

export const getAnalyticsSelector = () => createSelector(selectReqsFilterDomain, ({ analytics }) => analytics || []);
