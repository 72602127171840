export const GET_DICTIONARY_DATA = 'companies-dictionary/GET_DICTIONARY_DATA';
export const getDictionaryDataAction = payload => ({
	type: GET_DICTIONARY_DATA,
	payload,
});

export const SET_DICTIONARY_DATA = 'companies-dictionary/SET_DICTIONARY_DATA';
export const setDictionaryDataAction = payload => ({
	type: SET_DICTIONARY_DATA,
	payload,
});

export const SET_IS_DICTIONARY_DATA_LOADING = 'companies-dictionary/SET_IS_DICTIONARY_DATA_LOADING';
export const setIsDictionaryDataLoadingAction = payload => ({
	type: SET_IS_DICTIONARY_DATA_LOADING,
	payload,
});

export const SET_PAGINATION = 'companies-dictionary/SET_PAGINATION';
export const setPaginationAction = payload => ({
	type: SET_PAGINATION,
	payload,
});

export const SET_TABLE_SEARCH = 'companies-dictionary/SET_TABLE_SEARCH';
export const setTableSearchAction = payload => ({
	type: SET_TABLE_SEARCH,
	payload,
});

export const RESET_STATE = 'companies-dictionary/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});
