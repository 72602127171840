export const CREATE_CONTACT = 'contact-modal/CREATE_CONTACT';
export const createContactAction = payload => ({
	type: CREATE_CONTACT,
	payload,
});

export const GET_CONTACT_INFO = 'contact-modal/GET_CONTACT_INFO';
export const getContactInfoAction = payload => ({
	type: GET_CONTACT_INFO,
	payload,
});

export const SET_CONTACT_INFO = 'contact-modal/SET_CONTACT_INFO';
export const setContactInfoAction = payload => ({
	type: SET_CONTACT_INFO,
	payload,
});

export const SET_IS_CONTACT_INFO_LOADING = 'contact-modal/SET_IS_CONTACT_INFO_LOADING';
export const setIsContactInfoLoadingAction = payload => ({
	type: SET_IS_CONTACT_INFO_LOADING,
	payload,
});

export const DELETE_CONTACT = 'contact-modal/DELETE_CONTACT';
export const deleteContactAction = payload => ({
	type: DELETE_CONTACT,
	payload,
});

export const UPDATE_CONTACT = 'contact-modal/UPDATE_CONTACT';
export const updateContactAction = payload => ({
	type: UPDATE_CONTACT,
	payload,
});

export const SET_IS_CONTACT_UPDATING = 'contact-modal/SET_IS_CONTACT_UPDATING';
export const setIsContactUpdatingAction = payload => ({
	type: SET_IS_CONTACT_UPDATING,
	payload,
});

export const SET_IS_CONTACT_DELETING = 'contact-modal/SET_IS_CONTACT_DELETING';
export const setIsContactDeletingAction = payload => ({
	type: SET_IS_CONTACT_DELETING,
	payload,
});

export const RESET_STATE = 'contact-modal/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const SET_CONTACT_SEARCH_VALUE = 'contact-modal/SET_CONTACT_SEARCH_VALUE';
export const setContactSearchValueAction = payload => ({
	type: SET_CONTACT_SEARCH_VALUE,
	payload,
});
