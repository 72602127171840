import {
	RESET_STATE,
	SET_IS_DATE_CARGO_COLLECTION_CREATING,
	SET_IS_REQUEST_INFO_LOADING,
	SET_REQUEST_INFO,
} from './actions';

export const initialState = {
	isDateCargoCollectionCreating: false,
	isRequestInfoLoading: false,
	requestInfo: null,
};

const dateCargoCollectionModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_IS_DATE_CARGO_COLLECTION_CREATING:
			return {
				...state,
				isDateCargoCollectionCreating: payload,
			};
		case SET_IS_REQUEST_INFO_LOADING:
			return {
				...state,
				isRequestInfoLoading: payload,
			};
		case SET_REQUEST_INFO:
			return {
				...state,
				requestInfo: payload,
			};
		case RESET_STATE:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default dateCargoCollectionModalReducer;
