export const RESET_PASSWORD = 'reset-password/RESET_PASSWORD';
export const resetPasswordAction = payload => ({
	type: RESET_PASSWORD,
	payload,
});

export const SET_IS_RESET_PASSWORD_SUBMITTING = 'reset-password/SET_IS_RESET_PASSWORD_SUBMITTING';
export const setIsResetPasswordFormSubmittingAction = payload => ({
	type: SET_IS_RESET_PASSWORD_SUBMITTING,
	payload,
});
