/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import PropTypes from 'prop-types';
import { ModalWindow } from '../../../../semantic-ui/components/modal-window';
import ModalFooterButtons from '../../../../semantic-ui/components/modal-window/modal-footer-buttons';
import { TrailerContent } from '../trailer-content';
import { Audit } from '../../../../components/audit';

export const TrailersModalView = ({
	isModalShown,
	isTrailerAddFormOpen,
	isTrailerEditFormOpen,
	onNavItemClick,
	trailerInfo,
	isTrailerInfoLoading,
	onModalClose,
	onTrailerFormSubmit,
	onTrailerFormOpenButtonClick,
	trailerName,
	onTrailerDelete,
	omModalGoBack,
	isAvailable,
	isAuditTabShown,
	activeTrailerId,
	onKeyDown,
	tonnages,
	isTonnagesLoading,
}) => {
	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={onKeyDown}
			headerText={
				isTrailerEditFormOpen || isTrailerAddFormOpen
					? `${isTrailerEditFormOpen ? 'Редактор прицепа' : 'Новый прицеп'}`
					: trailerName
			}
			navItems={
				isTrailerEditFormOpen || isTrailerAddFormOpen
					? []
					: [
							{
								id: 'info',
								text: 'Информация',
								onClick: () => onNavItemClick('info'),
								isActive: !isAuditTabShown,
							},
							{
								id: 'audit',
								text: 'Изменения',
								onClick: () => onNavItemClick('audit'),
								isActive: isAuditTabShown,
							},
					  ]
			}
			modalContent={
				!isAuditTabShown ? (
					<TrailerContent
						trailerInfo={trailerInfo}
						isTrailerInfoLoading={isTrailerInfoLoading}
						isTrailerFormOpen={isTrailerEditFormOpen || isTrailerAddFormOpen}
						tonnages={tonnages}
						isTonnagesLoading={isTonnagesLoading}
					/>
				) : (
					<Audit entityName="trailers" entityId={activeTrailerId} />
				)
			}
			style={{ width: '895px' }}
			actionButtons={[
				{ type: 'close', onClick: isTrailerAddFormOpen ? omModalGoBack : onModalClose, text: 'profile' },
			]}
			footerButtons={
				<ModalFooterButtons
					leftButtons={
						!(isTrailerEditFormOpen || isTrailerAddFormOpen) && isAvailable.delete && !isAuditTabShown
							? [
									{
										onClick: onTrailerDelete,
										text: 'Удалить',
										color: 'warning',
										disabled: isTrailerInfoLoading,
									},
							  ]
							: []
					}
					rightButtons={
						isTrailerEditFormOpen || isTrailerAddFormOpen
							? [
									{
										onClick: onTrailerFormSubmit,
										text: 'Сохранить',
										color: 'primary',
										disabled: isTrailerInfoLoading,
									},
									{
										onClick: isTrailerAddFormOpen
											? omModalGoBack
											: () => onTrailerFormOpenButtonClick('cancel'),
										text: 'Отмена',
										color: 'secondary',
										disabled: isTrailerInfoLoading,
									},
							  ]
							: [
									...(isAvailable.update && !isAuditTabShown
										? [
												{
													onClick: () => onTrailerFormOpenButtonClick('edit'),
													text: 'Изменить',
													color: 'primary',
													disabled: isTrailerInfoLoading,
												},
										  ]
										: []),
									{
										onClick: onModalClose,
										text: 'Закрыть',
										color: 'secondary',
									},
							  ]
					}
				/>
			}
		/>
	);
};

TrailersModalView.propTypes = {
	trailerInfo: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]).isRequired,
		}),
	),
	isTrailerInfoLoading: PropTypes.bool.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	isTrailerAddFormOpen: PropTypes.bool.isRequired,
	isTrailerEditFormOpen: PropTypes.bool.isRequired,
	onTrailerFormOpenButtonClick: PropTypes.func.isRequired,
	onNavItemClick: PropTypes.func.isRequired,
	onModalClose: PropTypes.func.isRequired,
	onTrailerFormSubmit: PropTypes.func.isRequired,
	onTrailerDelete: PropTypes.func.isRequired,
	trailerName: PropTypes.string.isRequired,
	omModalGoBack: PropTypes.func.isRequired,
	isAvailable: PropTypes.objectOf(PropTypes.bool),
	isAuditTabShown: PropTypes.bool.isRequired,
	activeTrailerId: PropTypes.string,
	onKeyDown: PropTypes.func.isRequired,
	isTonnagesLoading: PropTypes.bool.isRequired,
	tonnages: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
};
