/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { CargoPickupFormView } from './cargo-pickup-form-view';
import { FORM_NAME } from '../../constants';
import { getFormInitialValuesSelector, getReqInfoSelector } from '../../selectors';
import { validate } from '../../utils';

const CargoPickupFormWrapper = ({ reqInfo }) => {
	return <CargoPickupFormView reqInfo={reqInfo} />;
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
})(CargoPickupFormWrapper);

const mapStateToProps = createStructuredSelector({
	initialValues: getFormInitialValuesSelector(),
	reqInfo: getReqInfoSelector(),
});

const CargoPickupForm = connect(mapStateToProps)(withForm);

CargoPickupFormWrapper.propTypes = {
	reqInfo: PropTypes.object, // todo
};

export { CargoPickupForm };
