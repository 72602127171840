import { CustomModalCell } from '../../components/custom-modal-cell';

export const FORM_NAME = 'cars-form';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const CAR_TABLE_COLUMNS_NAMES = [{ id: 'title' }, { id: 'value', customComponent: CustomModalCell }];

export const CAR_TABLE_ROWS_NAMES = {
	number: 'Гос. номер',
	vin: 'Vin номер',
	tonnage: 'Грузоподъемность',
	trailer: 'Прицеп по умолчанию',
	author: 'Автор',
};

export const FIELDS_FOR_VALIDATE = ['name', 'number'];

export const FORM_FIELDS = ['name', 'number', 'vin'];
