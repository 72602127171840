import React from 'react';
import { reduxForm, change } from 'redux-form';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UserFormView } from './user-form-view';
import { validate } from '../../utils';
import { FORM_NAME } from '../../constants';
import {
	getFormInitialValuesSelector,
	getIsDriverSelector,
	getIsOptionsListLoadingSelector,
	getIsUserInfoLoadingSelector,
	getIsUsersListLoadingSelector,
	getOptionsListSelector,
	getStatusToggleFormSelector,
	getUsersListSelector,
} from '../../selectors';

const UserFormWrapper = ({
	optionsList,
	isOptionsListLoading,
	isUserInfoLoading,
	isDriver,
	usersList,
	isUsersListLoading,
	isDisabled,
	onChangeField,
}) => {
	const history = useHistory();

	const handleCheckboxChange = field => {
		if (field === 'is_notify') {
			onChangeField(FORM_NAME, 'is_notify_todo', null);
		}
		onChangeField(FORM_NAME, 'is_notify_todo_company', null);
	};

	const handleAddUserRoleButtonClick = () => {
		history.push('/users?modal=user_role&mode=add');
	};

	return (
		<UserFormView
			optionsList={optionsList}
			isOptionsListLoading={isOptionsListLoading}
			onAddUserRoleButtonClick={handleAddUserRoleButtonClick}
			isUserInfoLoading={isUserInfoLoading}
			isDriver={isDriver}
			usersList={usersList}
			isUsersListLoading={isUsersListLoading}
			isDisabled={isDisabled}
			onCheckboxChange={handleCheckboxChange}
		/>
	);
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
	keepDirtyOnReinitialize: true,
})(UserFormWrapper);

const mapStateToProps = createStructuredSelector({
	optionsList: getOptionsListSelector(),
	isOptionsListLoading: getIsOptionsListLoadingSelector(),
	isUserInfoLoading: getIsUserInfoLoadingSelector(),
	isDriver: getIsDriverSelector(),
	usersList: getUsersListSelector(),
	isUsersListLoading: getIsUsersListLoadingSelector(),
	isDisabled: getStatusToggleFormSelector(),
	initialValues: getFormInitialValuesSelector(),
});

const mapDispatchToProps = {
	onChangeField: change,
};

const UserForm = connect(mapStateToProps, mapDispatchToProps)(withForm);

UserFormWrapper.propTypes = {
	optionsList: PropTypes.objectOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				text: PropTypes.string.isRequired,
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				key: PropTypes.string.isRequired,
			}),
		),
	),
	isOptionsListLoading: PropTypes.objectOf(PropTypes.bool.isRequired).isRequired,
	isUserInfoLoading: PropTypes.bool.isRequired,
	isDriver: PropTypes.bool.isRequired,
	usersList: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			key: PropTypes.number.isRequired,
			value: PropTypes.number.isRequired,
		}),
	).isRequired,
	isUsersListLoading: PropTypes.bool.isRequired,
	isDisabled: PropTypes.objectOf(PropTypes.bool).isRequired,
	onChangeField: PropTypes.func.isRequired,
};

export { UserForm };
