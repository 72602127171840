import { SubmissionError } from 'redux-form';
import { REQUIRED_FIELDS } from './constants';
import { goToElement } from '../../utils/goToElement';

export const normalizePhone = value => {
	if (!value) {
		return value;
	}

	if (value === '+') {
		return '';
	}

	const onlyNumbers = value.replace(/[^\d]/g, '');

	if (onlyNumbers[0] !== '7' && onlyNumbers.length === 1) {
		return `+7${onlyNumbers}`;
	}

	if (onlyNumbers.length <= 4) {
		return `+7${onlyNumbers.slice(1, 4)}`;
	}

	if (onlyNumbers.length <= 7) {
		return `+7(${onlyNumbers.slice(1, 4)})${onlyNumbers.slice(4)}`;
	}

	if (onlyNumbers.length <= 9) {
		return `+7(${onlyNumbers.slice(1, 4)})${onlyNumbers.slice(4, 7)}-${onlyNumbers.slice(7)}`;
	}

	return `+7(${onlyNumbers.slice(1, 4)})${onlyNumbers.slice(4, 7)}-${onlyNumbers.slice(7, 9)}-${onlyNumbers.slice(
		9,
		11,
	)}`;
};

export const validate = values => {
	REQUIRED_FIELDS.forEach(key => {
		const section = [key.split('.')[0]];

		const field = [key.split('.')[1]];

		if (!values?.[section]?.[field]) {
			goToElement(key);
			throw new SubmissionError({ [section]: { [field]: 'Заполните это поле' } });
		}

		if (values?.contact?.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values?.contact?.email)) {
			goToElement(key);
			throw new SubmissionError({ contact: { email: 'Не валидный email' } });
		}
	});
};

export const transformToValidationErrors = errors => {
	goToElement(Object.keys(errors)[0]);
	return Object.entries(errors).reduce((prev, [key, error]) => {
		const section = [key.split('.')[0]];

		const field = [key.split('.')[1]];

		if (!prev[section]) {
			prev[section] = {};
		}

		prev[section][field] = error;

		return prev;
	}, {});
};
