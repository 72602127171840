/* eslint-disable no-mixed-spaces-and-tabs */
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { stopSubmit, touch } from 'redux-form';
import { notificationInit } from '../../modules/notifications/actions';
import { FORM_NAME, REQUEST_ERROR_MESSAGE, REQUIRED_FIELDS } from './constants';
import {
	GET_REQ_INFO,
	SEND_CARGO_PICKUP_FORM_ACTION,
	setIsFormSendingAction,
	setIsReqInfoLoadingAction,
	setReqInfoAction,
} from './actions';
import { transformToValidationErrors } from './utils';
import { getFormIsInvalidSelector, getFormValuesSelector } from './selectors';
import { DISMISS_TIME } from '../../api/constants';
import { setPaginationAction } from '../details/actions';
import { getRawPaginationSelector } from '../details/selectors';
import { errorHandler } from '../../api/utils';
import { MOCK_DATA } from './mock-constants';
import { setReqsAction } from '../reqs-list/actions';
import { getReqsSelector } from '../reqs-list/selectors';

export function* getReqInfoSaga({ payload: id }) {
	try {
		yield put(setIsReqInfoLoadingAction(true));

		const { data, message, toast } = yield call(
			() =>
				Promise.resolve({
					data: MOCK_DATA,
					message: [],
				}),
			id,
		);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setReqInfoAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsReqInfoLoadingAction(false));
	}
}

export function* sendFormSaga({ payload: { redirect, searchQuery } }) {
	try {
		yield put(setIsFormSendingAction(true));

		const dataForSave = yield select(getFormValuesSelector());

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const { data, message, toast, errors } = yield call(
			() =>
				Promise.resolve({
					data: MOCK_DATA,
					message: [],
				}),
			dataForSave,
		);

		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link
							? {
									link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}`,
							  }
							: {}),
					}),
				);
			}

			const reqs = yield select(getReqsSelector());

			let newReqs = [];

			if (data.routes.includes(window.location.pathname)) {
				const updatedReq = {
					...data,
					link: `${window.location.pathname}?${searchQuery ? `${searchQuery}&` : ''}modal=reqs_details&id=${
						data.id
					}`,
					additionalPoints: data.points.length - 2,
					renderPoints: [data.points[0], data.points.at(-1)],
				};

				newReqs = reqs.map(req => {
					return req.id === data.id ? updatedReq : req;
				});
			} else {
				newReqs = reqs.filter(req => {
					return req.id !== data.id;
				});

				const pagination = yield select(getRawPaginationSelector());

				if (pagination) {
					yield put(setPaginationAction({ ...pagination, total: pagination.total - 1 }));
				}
			}

			yield put(setReqsAction(newReqs));

			redirect();
		} else {
			if (errors) {
				yield put(touch(FORM_NAME, ...REQUIRED_FIELDS));
				yield put(stopSubmit(FORM_NAME, transformToValidationErrors(errors)));
			}

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsFormSendingAction(false));
	}
}

export default function* cargoPickupModalSaga() {
	yield takeEvery(GET_REQ_INFO, getReqInfoSaga);
	yield takeEvery(SEND_CARGO_PICKUP_FORM_ACTION, sendFormSaga);
}
