import {
	RESET_STATE,
	SET_DOCUMENTS,
	SET_FILES,
	SET_FORM_ERRORS,
	SET_IS_DOCUMENTS_LOADING,
	SET_IS_FORM_SENDING,
} from './actions';

export const initialState = {
	documents: [],
	isDocumentsLoading: false,
	isFormSending: false,
	files: {},
	formErrors: {},
};

const documentAttachmentModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_IS_FORM_SENDING:
			return {
				...state,
				isFormSending: payload,
			};
		case SET_IS_DOCUMENTS_LOADING:
			return {
				...state,
				isDocumentsLoading: payload,
			};
		case SET_DOCUMENTS:
			return {
				...state,
				documents: payload,
			};
		case SET_FILES:
			return {
				...state,
				files: payload,
			};
		case SET_FORM_ERRORS:
			return {
				...state,
				formErrors: payload,
			};
		case RESET_STATE:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default documentAttachmentModalReducer;
