import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { ReqStatusActionsView } from './req-status-view';

export const ReqStatusActionsComponent = ({ status, actions, onActionButtonClick, reqId }) => {
	const { search } = useLocation();

	const query = new URLSearchParams(search);

	return (
		<ReqStatusActionsView
			status={status}
			actions={actions}
			onActionButtonClick={onActionButtonClick}
			reqId={reqId}
			query={query.toString()}
		/>
	);
};

ReqStatusActionsComponent.propTypes = {
	status: PropTypes.shape({
		name: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
		description: PropTypes.string,
		link: PropTypes.string,
	}),
	actions: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.bool), PropTypes.array]),
	onActionButtonClick: PropTypes.func.isRequired,
	reqId: PropTypes.number.isRequired,
};
