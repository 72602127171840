/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import './styles.less';
import { Popup } from 'semantic-ui-react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { CustomDatePicker } from '../../../../../semantic-ui/components/custom-datepicker';
import { FormCheckboxField } from '../../../../../semantic-ui/components/form-checkbox-field';
import { CargoPickupBadge } from '../../cargo-pickup-badge';

export const CargoPickupFormView = ({ reqInfo }) => {
	return (
		<div className="cargo-pickup-form">
			<header className="cargo-pickup-form__header">
				<span className="cargo-pickup-form__header-text cargo-pickup-form__header-text_min-weight ">
					<span className="cargo-pickup-form__header-text cargo-pickup-form__header-text_bold">{`Реестр № ${reqInfo.rosterNumber}`}</span>
					{` от ${reqInfo.rosterDate}`}
				</span>
				<span className="cargo-pickup-form__header-text">
					{`${reqInfo.companyName} `}
					<span className="cargo-pickup-form__header-text cargo-pickup-form__header-text_small">
						{reqInfo.companyAddress}
					</span>
				</span>
			</header>

			<section className="cargo-pickup-form__date-section">
				<div className="cargo-pickup-form__date-field">
					<Field
						name="date"
						type="text"
						component={CustomDatePicker}
						autoComplete="off"
						placeholder=" . . / . . / . . . ."
						dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
					/>
				</div>
				<span className="cargo-pickup-form__date-text">Уĸажите дату забора груза</span>
			</section>
			<ul className="cargo-pickup-form__route-list">
				{reqInfo.cargo?.map(el => {
					return (
						<li key={el.id} className="cargo-pickup-form__route-item">
							<header className="cargo-pickup-form__route-header">
								<Popup
									content="Выберите забранный груз"
									trigger={
										<div className="cargo-pickup-form__checkbox-wrapper">
											<Field
												name={`check.${el.id}`}
												type="checkbox"
												component={FormCheckboxField}
											/>
										</div>
									}
									position="right center"
									size="mini"
									inverted
									style={{
										textAlign: 'center',
										fontSize: 12,
										fontWeight: '500',
										marginLeft: '12px',
									}}
								/>
								<span className="cargo-pickup-form__route-title">{el.companyName}</span>
								<span className="cargo-pickup-form__route-title cargo-pickup-form__route-title_small">
									{el.companyAddress}
								</span>
							</header>
							<div className="cargo-pickup-form__route-info-field">
								<div className="cargo-pickup-form__route-info-item">
									<CargoPickupBadge text={el.kg} isSmall />
									<span className="cargo-pickup-form__route-info-text">кг</span>
								</div>
								<div className="cargo-pickup-form__route-info-item">
									<CargoPickupBadge text={el.volume} isSmall />
									<span className="cargo-pickup-form__route-info-text">м3</span>
								</div>
								<div className="cargo-pickup-form__route-info-item">
									<CargoPickupBadge text={el.temperature} isSmall />
									<span className="cargo-pickup-form__route-info-text">°C</span>
								</div>
								<div className="cargo-pickup-form__route-info-item">
									<CargoPickupBadge text={el.deliveryDate} />
									<span className="cargo-pickup-form__route-info-text">дата доставки</span>
								</div>
							</div>
							<span className="cargo-pickup-form__route-info-special cargo-pickup-form__route-info-text">
								{`Особые отметки ${el.specialMarks}`}
							</span>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

CargoPickupFormView.propTypes = {
	reqInfo: PropTypes.object, // todo
};
