export const getLocalStorageItem = key => {
	try {
		const item = localStorage.getItem(key);

		if (item) {
			return JSON.parse(item);
		}

		return null;
	} catch (e) {
		// eslint-disable-next-line no-console
		console.warn(e);
		return null;
	}
};

export const setLocalStorageItem = (key, item) => localStorage.setItem(key, JSON.stringify(item));

export const deleteLocalStorageItem = key => localStorage.removeItem(key);

export const cleanLocalStorage = () => localStorage.clear();
