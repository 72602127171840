import {
	RESET_STATE,
	SET_IS_RULES_LOADING,
	SET_IS_USER_ROLE_DELETING,
	SET_IS_USER_ROLE_INFO_LOADING,
	SET_IS_USER_ROLE_SAVING,
	SET_RULES,
	SET_USER_ROLE_INFO,
} from './actions';

export const initialState = {
	userRoleInfo: null,
	isUserRoleInfoLoading: false,
	isUserRoleDeleting: false,
	isUserRoleSaving: false,
	isRulesLoading: false,
	rules: [],
};

const usersRolesModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_USER_ROLE_INFO:
			return {
				...state,
				userRoleInfo: payload,
			};
		case SET_IS_USER_ROLE_INFO_LOADING:
			return {
				...state,
				isUserRoleInfoLoading: payload,
			};
		case SET_IS_USER_ROLE_DELETING:
			return {
				...state,
				isUserRoleDeleting: payload,
			};
		case SET_IS_USER_ROLE_SAVING:
			return {
				...state,
				isUserRoleSaving: payload,
			};
		case SET_RULES:
			return {
				...state,
				rules: payload,
			};
		case SET_IS_RULES_LOADING:
			return {
				...state,
				isRulesLoading: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default usersRolesModalReducer;
