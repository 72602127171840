import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { RightHeaderContentView } from './components/right-header-content-view';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import {
	getIsChatsListShownShownSelector,
	getIsNotificationDropdownShownSelector,
	getIsProfileDropdownShownSelector,
	getUserDataSelector,
} from './selectors';
import {
	getNotificationsListAction,
	setIsChatsListShownAction,
	setIsNotificationDropdownShownAction,
	setIsProfileDropdownShownAction,
} from './actions';
import { logout } from '../../utils/logout';
import { getChatsNotificationsCountSelector, getNotificationsCountSelector } from '../profile/selectors';
import { getLocalStorageItem } from '../../utils/localStorage';

const RightHeaderContentWrapper = ({
	isProfileDropdownShown,
	isNotificationDropdownShown,
	onSetIsProfileDropdownShown,
	onSetIsNotificationDropdownShown,
	userData,
	onGetNotificationsList,
	notificationsCount,
	onSetIsChatsListShown,
	isChatsListShownShown,
	chatsNotificationsCount,
}) => {
	useInjectReducer({ key: 'rightHeaderContentReducer', reducer });
	useInjectSaga({ key: 'rightHeaderContentSaga', saga });

	const handleNotificationMouseClick = ({ target }) => {
		const container = document.querySelector('.notifications-list');

		if (isNotificationDropdownShown && !container?.contains(target)) {
			onSetIsNotificationDropdownShown(false);
			document.removeEventListener('mouseup', handleNotificationMouseClick);
		}
	};

	const handleProfileMouseClick = ({ target }) => {
		const container = document.querySelector('.right-header-content__profile');

		if (isProfileDropdownShown && !container?.contains(target)) {
			onSetIsProfileDropdownShown(false);
			document.removeEventListener('mouseup', handleProfileMouseClick);
		}
	};

	const handleChatsListMouseClick = ({ target }) => {
		const container = document.querySelector('.chats-list');

		if (isChatsListShownShown && !container?.contains(target)) {
			onSetIsChatsListShown(false);
			document.removeEventListener('mouseup', handleChatsListMouseClick);
		}
	};

	useEffect(() => {
		if (isNotificationDropdownShown) {
			document.addEventListener('mouseup', handleNotificationMouseClick);
		}
	}, [isNotificationDropdownShown]);

	useEffect(() => {
		if (isProfileDropdownShown) {
			document.addEventListener('mouseup', handleProfileMouseClick);
		}
	}, [isProfileDropdownShown]);

	useEffect(() => {
		if (isChatsListShownShown) {
			document.addEventListener('mouseup', handleChatsListMouseClick);
		}
	}, [isChatsListShownShown]);

	useEffect(() => {
		if (isNotificationDropdownShown) {
			onGetNotificationsList();
		}
	}, [isNotificationDropdownShown]);

	const history = useHistory();

	const handleProfileButtonClick = () => {
		onSetIsProfileDropdownShown(!isProfileDropdownShown);
		if (isNotificationDropdownShown) {
			onSetIsNotificationDropdownShown(false);
		}
	};

	const handleNotificationsButtonClick = () => {
		onSetIsNotificationDropdownShown(!isNotificationDropdownShown);
		if (isProfileDropdownShown) {
			onSetIsProfileDropdownShown(false);
		}
	};

	const handleMailButtonClick = () => {
		onSetIsChatsListShown(!isChatsListShownShown);
		if (isProfileDropdownShown) {
			onSetIsProfileDropdownShown(false);
		}

		if (isNotificationDropdownShown) {
			onSetIsNotificationDropdownShown(false);
		}
	};

	const handleNavItemClick = id => {
		switch (id) {
			case 'profile':
				history.push('?modal=profile');
				onSetIsProfileDropdownShown(false);
				break;
			case 'company':
				history.push(`?modal=profile_company&id=${getLocalStorageItem('user_data').companyId}`);
				onSetIsProfileDropdownShown(false);
				break;
			case 'logout':
				logout();
				break;
			default:
				break;
		}
	};

	return (
		<RightHeaderContentView
			onNotificationsButtonClick={handleNotificationsButtonClick}
			onMailButtonClick={handleMailButtonClick}
			onProfileButtonClick={handleProfileButtonClick}
			onNavItemClick={handleNavItemClick}
			isProfileDropdownShown={isProfileDropdownShown}
			isNotificationDropdownShown={isNotificationDropdownShown}
			isChatsListShownShown={isChatsListShownShown}
			userData={userData}
			notificationsCount={notificationsCount}
			chatsNotificationsCount={chatsNotificationsCount}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	isProfileDropdownShown: getIsProfileDropdownShownSelector(),
	isNotificationDropdownShown: getIsNotificationDropdownShownSelector(),
	userData: getUserDataSelector(),
	notificationsCount: getNotificationsCountSelector(),
	isChatsListShownShown: getIsChatsListShownShownSelector(),
	chatsNotificationsCount: getChatsNotificationsCountSelector(),
});

const mapDispatchToProps = {
	onSetIsProfileDropdownShown: setIsProfileDropdownShownAction,
	onSetIsNotificationDropdownShown: setIsNotificationDropdownShownAction,
	onGetNotificationsList: getNotificationsListAction,
	onSetIsChatsListShown: setIsChatsListShownAction,
};

const RightHeaderContent = connect(mapStateToProps, mapDispatchToProps)(RightHeaderContentWrapper);

RightHeaderContentWrapper.propTypes = {
	isProfileDropdownShown: PropTypes.bool.isRequired,
	isNotificationDropdownShown: PropTypes.bool.isRequired,
	onSetIsProfileDropdownShown: PropTypes.func.isRequired,
	onSetIsNotificationDropdownShown: PropTypes.func.isRequired,
	onGetNotificationsList: PropTypes.func.isRequired,
	notificationsCount: PropTypes.number.isRequired,
	onSetIsChatsListShown: PropTypes.func.isRequired,
	isChatsListShownShown: PropTypes.bool.isRequired,
	chatsNotificationsCount: PropTypes.number.isRequired,
	userData: PropTypes.shape({
		last_name: PropTypes.string.isRequired,
		first_name: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
	}),
};

export default RightHeaderContent;
