import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

const ContactsCustomModalCell = ({ rowData }) => {
	if (rowData.id === 'phone') {
		return (
			<a className="contacts-custom-modal-cell__link" href={`tel:${rowData.value}`}>
				{rowData.value}
			</a>
		);
	}

	if (rowData.id === 'email') {
		if (rowData.value === '---') {
			return rowData.value;
		}

		return (
			<a className="contacts-custom-modal-cell__link" href={`mailto:${rowData.value}`}>
				{rowData.value}
			</a>
		);
	}

	if (rowData.id === 'author') {
		return (
			<span className="contacts-custom-modal-cell__author">
				<b>{rowData.value.name}</b>
				<small>
					<i>{rowData.value.role}</i>
				</small>
			</span>
		);
	}

	if (rowData.id === 'address') {
		const { search, pathname } = useLocation();

		const query = new URLSearchParams(search);
		query.set('modal', 'address');
		query.set('addressId', rowData.value.id);

		return (
			<Link to={rowData.value.id ? `${pathname}?${query}` : '#'} className="contacts-custom-modal-cell__link">
				{rowData.value.address}
			</Link>
		);
	}

	return rowData.value || '-';
};

ContactsCustomModalCell.propTypes = {
	rowData: PropTypes.object.isRequired,
	columnId: PropTypes.string.isRequired,
};

export { ContactsCustomModalCell };
