/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import './styles.less';
import { Field } from 'redux-form';
import { KgIcon, PieceIcon, SizeTextIcon } from '../../../../components/text-icon';
import WeightIcon from '../../../../components/icons/weight-icon.svg';
import CountIcon from '../../../../components/icons/count-icon.svg';
import AddButton from '../../../../components/images/add-button.svg';
import LengthIcon from '../../../../components/icons/length-icon.svg';
import WidthIcon from '../../../../components/icons/width-icon.svg';
import HeightIcon from '../../../../components/icons/height-icon.svg';
import { FormInputField } from '../../../../semantic-ui/components/form-input-field';
import { normalizeNumber } from '../../../../utils/normalizeNumber';

export const SizeComponent = ({ fields, onSizeChange, onSizeDelete }) => {
	if (fields.length === 0) {
		fields.push({});
	}

	return fields.map((name, index) => {
		const pointIndex = name.includes('point') ? 1 : undefined;

		return (
			<section className="size-component" key={name}>
				{index !== 0 && (
					<div
						className="size-component__close"
						onClick={() => {
							onSizeDelete(index, pointIndex);
							fields.remove(index);
						}}
					/>
				)}
				<div className="size-component__row">
					<span className="size-component__text size-component__text_right">
						Габаритные характеристики места № {index + 1}
					</span>
					<div className="size-component__row size-component__row_double">
						<div className="size-component__row-with-icons">
							<div className="size-component__column">
								<div className="size-component__cargo-field">
									<img src={CountIcon} alt="count-icon" />
									<div className="size-component__field_medium">
										<Field
											name={`${name}.place`}
											type="text"
											normalize={normalizeNumber}
											component={FormInputField}
											autoComplete="off"
											icon={PieceIcon}
											placeholder="Кол."
											isWithoutErrorText
											onChange={e =>
												onSizeChange(
													normalizeNumber(e.target.value),
													index,
													'place',
													pointIndex,
												)
											}
										/>
									</div>
								</div>
							</div>
							<div className="size-component__column">
								<div className="size-component__cargo-field">
									<img src={WeightIcon} alt="weight-icon" />
									<div className="size-component__field_medium">
										<Field
											name={`${name}.weight`}
											type="text"
											normalize={normalizeNumber}
											component={FormInputField}
											autoComplete="off"
											icon={KgIcon}
											placeholder="Масса"
											isWithoutErrorText
											onChange={e =>
												onSizeChange(
													normalizeNumber(e.target.value),
													index,
													'weight',
													pointIndex,
												)
											}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="size-component__row-with-icons">
							<div className="size-component__column">
								<div className="size-component__cargo-field">
									<img src={LengthIcon} alt="length-icon" style={{ width: '19px' }} />
									<div className="size-component__field_small">
										<Field
											name={`${name}.length`}
											type="text"
											normalize={normalizeNumber}
											component={FormInputField}
											autoComplete="off"
											icon={SizeTextIcon}
											placeholder="Дл."
											isWithoutErrorText
											onChange={e =>
												onSizeChange(
													normalizeNumber(e.target.value),
													index,
													'length',
													pointIndex,
												)
											}
										/>
									</div>
								</div>
							</div>
							<div className="size-component__column">
								<div className="size-component__cargo-field">
									<img src={WidthIcon} alt="width-icon" style={{ width: '19px' }} />
									<div className="size-component__field_small">
										<Field
											name={`${name}.width`}
											type="text"
											normalize={normalizeNumber}
											component={FormInputField}
											autoComplete="off"
											icon={SizeTextIcon}
											placeholder="Шир."
											isWithoutErrorText
											onChange={e =>
												onSizeChange(
													normalizeNumber(e.target.value),
													index,
													'width',
													pointIndex,
												)
											}
										/>
									</div>
								</div>
							</div>
							<div className="size-component__column">
								<div className="size-component__cargo-field">
									<img src={HeightIcon} alt="height-icon" style={{ width: '19px' }} />
									<div className="size-component__field_small">
										<Field
											name={`${name}.height`}
											type="text"
											normalize={normalizeNumber}
											component={FormInputField}
											autoComplete="off"
											icon={SizeTextIcon}
											placeholder="Выс."
											isWithoutErrorText
											onChange={e =>
												onSizeChange(
													normalizeNumber(e.target.value),
													index,
													'height',
													pointIndex,
												)
											}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="size-component__error">
					<Field
						name={`${name}.place`}
						component={({ meta }) => {
							if (meta.error && meta.touched) {
								return <span className="size-component__error-message">{meta.error}</span>;
							}

							return null;
						}}
					/>
					<Field
						name={`${name}.weight`}
						component={({ meta }) => {
							if (meta.error && meta.touched) {
								return <span className="size-component__error-message">{meta.error}</span>;
							}

							return null;
						}}
					/>
					<Field
						name={`${name}.length`}
						component={({ meta }) => {
							if (meta.error && meta.touched) {
								return <span className="size-component__error-message">{meta.error}</span>;
							}

							return null;
						}}
					/>
					<Field
						name={`${name}.width`}
						component={({ meta }) => {
							if (meta.error && meta.touched) {
								return <span className="size-component__error-message">{meta.error}</span>;
							}

							return null;
						}}
					/>
					<Field
						name={`${name}.height`}
						component={({ meta }) => {
							if (meta.error && meta.touched) {
								return <span className="size-component__error-message">{meta.error}</span>;
							}

							return null;
						}}
					/>
				</div>
				{index === fields.length - 1 && (
					<div className="size-component__button-row">
						<div className="size-component__button-row-text">Добавить дополнительный тип места</div>
						<img src={AddButton} alt="add-button" onClick={() => fields.push({})} />
					</div>
				)}
			</section>
		);
	});
};
