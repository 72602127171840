import {
	RESET_STATE,
	SET_CARRIERS,
	SET_IS_CARRIERS_LOADING,
	SET_IS_CARRIER_CHANGING,
	SET_IS_REQUEST_INFO_LOADING,
	SET_REQUEST_INFO,
} from './actions';

export const initialState = {
	isRequestInfoLoading: false,
	requestInfo: null,
	carriers: [],
	isCarriersLoading: false,
	isCarriersChanging: false,
};

const carrierChangeModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_IS_REQUEST_INFO_LOADING:
			return {
				...state,
				isRequestInfoLoading: payload,
			};
		case SET_REQUEST_INFO:
			return {
				...state,
				requestInfo: payload,
			};
		case SET_CARRIERS:
			return {
				...state,
				carriers: payload,
			};
		case SET_IS_CARRIERS_LOADING:
			return {
				...state,
				isCarriersLoading: payload,
			};
		case SET_IS_CARRIER_CHANGING:
			return {
				...state,
				isCarriersChanging: payload,
			};
		case RESET_STATE:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default carrierChangeModalReducer;
