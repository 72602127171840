import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { notificationInit } from '../../modules/notifications/actions';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { DISMISS_TIME } from '../../api/constants';
import { GET_AUDIT_DATA, setAuditDataAction, setIsAuditDataLoadingAction } from './actions';
import { getAuditDataRequest } from '../../api/requests';
import { errorHandler } from '../../api/utils';

export function* getAuditDataSaga({ payload: { entityName, entityId } }) {
	try {
		yield put(setIsAuditDataLoadingAction(true));

		const { message, toast, reports } = yield call(getAuditDataRequest, entityName, entityId);

		if (reports) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setAuditDataAction(reports?.html || null));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsAuditDataLoadingAction(false));
	}
}

export default function* auditComponentSaga() {
	yield takeEvery(GET_AUDIT_DATA, getAuditDataSaga);
}
