import { createSelector } from 'reselect';
import { getFormValues, isInvalid } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import { initialState } from './reducer';
import { PARTNER_TABLE_ROWS_NAMES, PARTNER_FORM_NAME } from './constants';

export const selectPartnerModalDomain = state => state.partnerModalReducer || initialState;

export const selectRouterDomain = state => state.router || {};

export const getIsPartnerInfoLoadingSelector = () =>
	createSelector(
		selectPartnerModalDomain,
		({ isPartnerInfoLoading, isPartnerDeleting, isPartnerUpdating }) =>
			isPartnerInfoLoading || isPartnerDeleting || isPartnerUpdating,
	);

export const getPartnerInfoSelector = () =>
	createSelector(selectPartnerModalDomain, ({ partnerInfo }) => {
		return Object.entries(PARTNER_TABLE_ROWS_NAMES).reduce((result, [key, value]) => {
			if (key === 'author') {
				result.push({
					id: key,
					title: value,
					value: {
						name: `${partnerInfo?.user?.last_name || ''} ${partnerInfo?.user?.first_name || ''}`,
						role: partnerInfo?.user?.role?.name || '',
					},
				});

				return result;
			}

			result.push({ id: key, title: value, value: partnerInfo[key] ? String(partnerInfo[key]) : '---' });

			return result;
		}, []);
	});

export const getFormInitialValuesSelector = () =>
	createSelector(selectPartnerModalDomain, getFormValues(PARTNER_FORM_NAME), ({ partnerInfo }, formValues) => ({
		...partnerInfo,
		...formValues,
	}));

export const getPartnerNameSelector = () =>
	createSelector(selectPartnerModalDomain, ({ partnerInfo }) => partnerInfo?.name || '-');

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'partner');
	});

export const getIsAuditTabShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'partner' && query?.tab === 'audit');
	});

export const getActivePartnerIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => (query?.modal === 'partner' ? query?.id : null));

export const getIsPartnerAddFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'partner' && query?.mode === 'add');
	});

export const getIsPartnerEditFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'partner' && Boolean(query?.id) && query?.mode === 'edit');
	});

export const getPartnerFormValuesSelector = () =>
	createSelector(getFormValues(PARTNER_FORM_NAME), formValues => formValues);

export const getPartnerSearchValueSelector = () =>
	createSelector(selectPartnerModalDomain, ({ partnerSearchValue }) => partnerSearchValue);

export const getIsPartnersOptionsLoadingSelector = () =>
	createSelector(selectPartnerModalDomain, ({ isPartnersOptionsLoading }) => isPartnersOptionsLoading);

export const getPartnersSelector = () =>
	createSelector(selectPartnerModalDomain, ({ partnersOptions }) => partnersOptions);

export const getPartnersOptionsSelector = () =>
	createSelector(selectPartnerModalDomain, ({ partnersOptions }) =>
		partnersOptions.map(partner => ({
			key: `${partner.data.inn}${partner.data.kpp}`,
			value: partner.data.inn,
			data: {
				title: partner.value,
				address: `${partner.data?.inn || ''} ${partner.data?.address?.value || ''}`,
				deleted: partner.data?.state?.status === 'LIQUIDATED',
			},
		})),
	);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(PARTNER_FORM_NAME), isInvalid => isInvalid);
