/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { Loader } from 'semantic-ui-react';
import './styles.less';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { CustomDatePicker } from '../../../../../semantic-ui/components/custom-datepicker';
import { CargoDeliveryBadge } from '../../cargo-delivery-badge';

export const CargoDeliveryFormView = ({ reqInfo, isLoading, onFormSubmit, onDocumentAdd }) => {
	return (
		<div className="cargo-delivery-form">
			{isLoading ? (
				<Loader active size="big" />
			) : (
				<>
					{!reqInfo ? (
						<div className="cargo-delivery-form__no-data-message">Нет данных</div>
					) : (
						<>
							<header className="cargo-delivery-form__header">
								<span className="cargo-delivery-form__header-text cargo-delivery-form__header-text_min-weight">
									<span className="cargo-delivery-form__header-text cargo-delivery-form__header-text_bold">{`Заявка № ${reqInfo.id}`}</span>
									{` от ${reqInfo.created_at}`}
								</span>
								<span className="cargo-delivery-form__header-text">
									{`${reqInfo.company.name} ${reqInfo.company.address}`}
								</span>
							</header>
							<ul className="cargo-delivery-form__route-list">
								{reqInfo.points.map(point => {
									return (
										<li key={point.id} className="cargo-delivery-form__route-item">
											<section className="cargo-delivery-form__route-row cargo-delivery-form__header-route-row cargo-delivery-form__route-row_top">
												<div className="cargo-delivery-form__inner-route-row">
													<span className="cargo-delivery-form__route-title">
														{`#${point.id}, ${point.company}, ${point.address}`}
													</span>
													<div className="cargo-delivery-form__date-section">
														<span className="cargo-delivery-form__route-title cargo-delivery-form__route-title_small">
															Дата доставĸи груза
														</span>
														<div className="cargo-delivery-form__date-field">
															{!point.actions.setDone ? (
																<CargoDeliveryBadge text={point.done_at} />
															) : (
																<Field
																	name={`${point.id}`}
																	type="text"
																	component={CustomDatePicker}
																	autoComplete="off"
																	placeholder=" . . / . . / . . . ."
																	dateFormat={[
																		'dd.MM.yyyy',
																		'dd-MM-yyyy',
																		'dd/MM/yyyy',
																	]}
																	isWithoutErrorText
																/>
															)}
														</div>
													</div>
												</div>
												<Field
													name={`${point.id}`}
													type="text"
													component={({ meta }) => {
														if (meta.error && meta.touched) {
															return (
																<span className="cargo-delivery-form__error-text">
																	{meta.error}
																</span>
															);
														}

														return null;
													}}
												/>
											</section>
											<section className="cargo-delivery-form__route-row cargo-delivery-form__route-row_bottom">
												<div className="cargo-delivery-form__route-info-field">
													<div className="cargo-delivery-form__route-info-item">
														<CargoDeliveryBadge text={point.weight} isSmall />
														<span className="cargo-delivery-form__route-title cargo-delivery-form__route-title_small">
															кг
														</span>
													</div>
													<div className="cargo-delivery-form__route-info-item">
														<CargoDeliveryBadge text={point.volume} isSmall />
														<span className="cargo-delivery-form__route-title cargo-delivery-form__route-title_small">
															м3
														</span>
													</div>
												</div>
												{point.actions.setDone && (
													<div
														className="cargo-delivery-form__button"
														onClick={() => onFormSubmit(point.id)}
													>
														Груз доставлен
													</div>
												)}
												{point.actions.setDocuments && (
													<div
														className="cargo-delivery-form__button"
														onClick={() => onDocumentAdd(point.id)}
													>
														Приĸрепить доĸ-ты
													</div>
												)}
											</section>
										</li>
									);
								})}
							</ul>
						</>
					)}
				</>
			)}
		</div>
	);
};

CargoDeliveryFormView.propTypes = {
	reqInfo: PropTypes.shape({
		id: PropTypes.number.isRequired,
		company: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			inn: PropTypes.string.isRequired,
			ogrn: PropTypes.string.isRequired,
			address: PropTypes.string.isRequired,
			type: PropTypes.shape({
				id: PropTypes.number.isRequired,
				name: PropTypes.string.isRequired,
				company_count: PropTypes.number.isRequired,
			}).isRequired,
			contact: PropTypes.shape({
				id: PropTypes.number.isRequired,
				last_name: PropTypes.string.isRequired,
				first_name: PropTypes.string.isRequired,
				middle_name: PropTypes.string,
				email: PropTypes.string.isRequired,
				phone: PropTypes.string.isRequired,
				extension: PropTypes.string,
			}).isRequired,
		}).isRequired,
		points: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number.isRequired,
				company: PropTypes.string.isRequired,
				address: PropTypes.string.isRequired,
				date: PropTypes.string.isRequired,
				weight: PropTypes.number.isRequired,
				volume: PropTypes.number.isRequired,
				place: PropTypes.number.isRequired,
				done_at: PropTypes.string,
				actions: PropTypes.shape({
					setDone: PropTypes.bool.isRequired,
					setDocuments: PropTypes.bool.isRequired,
				}),
			}).isRequired,
		).isRequired,
		created_at: PropTypes.string.isRequired,
		updated_at: PropTypes.string.isRequired,
	}),
	isLoading: PropTypes.bool.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	onDocumentAdd: PropTypes.func.isRequired,
};
