import { SET_IS_RESET_PASSWORD_SUBMITTING } from './actions';

export const initialState = {
	isResetPasswordFormSubmitting: false,
};

const resetPasswordReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_IS_RESET_PASSWORD_SUBMITTING:
			return {
				...state,
				isResetPasswordFormSubmitting: payload,
			};
		default:
			return state;
	}
};

export default resetPasswordReducer;
