import {
	SET_ACTIVE_SUBMENUS,
	SET_IS_MENU_OPEN,
	SET_IS_USER_PERMISSIONS_LOADING,
	SET_USER_PERMISSIONS,
} from './actions';

export const initialState = {
	userPermissions: {},
	activeSubmenus: [],
	isMenuOpen: false,
	isUserPermissionsLoading: false,
};

const sidebarReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_USER_PERMISSIONS:
			return {
				...state,
				userPermissions: payload,
			};
		case SET_IS_USER_PERMISSIONS_LOADING:
			return {
				...state,
				isUserPermissionsLoading: payload,
			};
		case SET_ACTIVE_SUBMENUS:
			return {
				...state,
				activeSubmenus: payload,
			};
		case SET_IS_MENU_OPEN:
			return {
				...state,
				isMenuOpen: payload,
			};
		default:
			return state;
	}
};

export default sidebarReducer;
