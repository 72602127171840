import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

const PageContentWithNav = ({ pageNavigationItems, pageContent, isInnerRouter }) => {
	const history = useHistory();

	return (
		<div className="page-content-with-nav">
			<div className="page-content-with-nav__nav">
				<div className="page-content-with-nav__nav-line" />
				{pageNavigationItems.map(item => {
					let isActive = false;
					if (item.href === '/reqs/combined') {
						isActive = history.location.pathname === item.href;
					}

					if (isInnerRouter) {
						isActive = history.location.pathname === item.href;
					} else {
						isActive = history.location.pathname.includes(item.href);
					}

					return (
						<Link
							className={`page-content-with-nav__nav-item${
								isActive ? ' page-content-with-nav__nav-item_active' : ''
							}`}
							to={item.href}
							key={item.href}
						>
							{item.text}
							{item.count && (
								<span
									className={`page-content-with-nav__nav-item-count${
										isActive ? ' page-content-with-nav__nav-item-count_active' : ''
									}`}
								>
									{item.count}
								</span>
							)}
						</Link>
					);
				})}
			</div>
			{pageContent}
		</div>
	);
};

PageContentWithNav.propTypes = {
	pageNavigationItems: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			href: PropTypes.string.isRequired,
			count: PropTypes.number,
		}),
	).isRequired,
	pageContent: PropTypes.node,
	isInnerRouter: PropTypes.bool,
};

export { PageContentWithNav };
