import {
	RESET_STATE,
	SET_CONTACT_INFO,
	SET_CONTACT_SEARCH_VALUE,
	SET_IS_CONTACT_DELETING,
	SET_IS_CONTACT_INFO_LOADING,
	SET_IS_CONTACT_UPDATING,
} from './actions';

export const initialState = {
	isContactInfoLoading: false,
	isContactDeleting: false,
	isContactUpdating: false,
	contactInfo: [],
	contactSearchValue: '',
};

const contactModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_CONTACT_INFO:
			return {
				...state,
				contactInfo: payload,
			};
		case SET_IS_CONTACT_INFO_LOADING:
			return {
				...state,
				isContactInfoLoading: payload,
			};
		case SET_CONTACT_SEARCH_VALUE:
			return {
				...state,
				contactSearchValue: payload,
			};
		case SET_IS_CONTACT_DELETING:
			return {
				...state,
				isContactDeleting: payload,
			};
		case SET_IS_CONTACT_UPDATING:
			return {
				...state,
				isContactUpdating: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default contactModalReducer;
