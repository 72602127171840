import { CustomModalCell } from '../../components/custom-modal-cell';

export const FORM_NAME = 'way-form';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const WAY_TABLE_COLUMNS_NAMES = [
	{ id: 'title' },
	{ id: 'value', customComponent: CustomModalCell },
	{ id: 'date' },
];

export const WAY_TABLE_ROWS_NAMES = {
	is_regular: 'Регулярный маршрут',
	period: 'Периодичность маршрута',
	address_from: 'Адрес отгрузки',
	weight: 'Свободная масса',
	volume: 'Свободный объем',
	place: 'Свободно мест',
	temperature: 'Температурный режим',
	author: 'Автор',
};

export const REQUIRED_FIELDS = ['name', 'address_from', 'address_to', 'weight', 'volume'];
