import {
	RESET_STATE,
	SET_DETAILS_OPTIONS,
	SET_DETAIL_INFO,
	SET_DETAIL_SEARCH_VALUE,
	SET_IS_DETAILS_OPTIONS_LOADING,
	SET_IS_DETAIL_DELETING,
	SET_IS_DETAIL_INFO_LOADING,
	SET_IS_DETAIL_UPDATING,
} from './actions';

export const initialState = {
	isDetailInfoLoading: false,
	isDetailsOptionsLoading: false,
	isDetailDeleting: false,
	isDetailUpdating: false,
	detailInfo: [],
	detailSearchValue: '',
	detailsOptions: [],
};

const detailModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_DETAIL_INFO:
			return {
				...state,
				detailInfo: payload,
			};
		case SET_IS_DETAIL_INFO_LOADING:
			return {
				...state,
				isDetailInfoLoading: payload,
			};
		case SET_DETAIL_SEARCH_VALUE:
			return {
				...state,
				detailSearchValue: payload,
			};
		case SET_DETAILS_OPTIONS:
			return {
				...state,
				detailsOptions: payload,
			};
		case SET_IS_DETAILS_OPTIONS_LOADING:
			return {
				...state,
				isDetailsOptionsLoading: payload,
			};
		case SET_IS_DETAIL_DELETING:
			return {
				...state,
				isDetailDeleting: payload,
			};
		case SET_IS_DETAIL_UPDATING:
			return {
				...state,
				isDetailUpdating: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default detailModalReducer;
