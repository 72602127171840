/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import {
	getActiveReqIdSelector,
	getCurrentDateSelector,
	getIsMessagesLoadingSelector,
	getMessageTextSelector,
	getMessagesCountSelector,
	getMessagesSelector,
} from './selectors';
import { addNewMessageAction, getMessagesAction, resetStateAction, setMessageTextAction } from './actions';
import { ChatModalView } from './components/chat-view';

const ChatModalWrapper = ({
	onResetState,
	currentDate,
	messages,
	onSetMessageText,
	messageText,
	onGetMessages,
	activeReqId,
	onAddNewMessage,
	messagesCount,
	isMessagesLoading,
}) => {
	useInjectSaga({ key: 'chatModalSaga', saga });
	useInjectReducer({ key: 'chatModalReducer', reducer });

	useEffect(() => {
		const modal = document.querySelector('.chat-modal__content');

		if (modal && modal.clientHeight !== modal.scrollHeight) {
			modal.scrollTo(0, modal.scrollHeight);
		}
	}, [messagesCount]);

	useEffect(() => {
		onGetMessages(activeReqId);

		return () => {
			onResetState();
		};
	}, []);

	const handleTextChange = e => {
		onSetMessageText(e.target.value);
	};

	const handleMessageSend = () => {
		if (!messageText.trim()) {
			return;
		}

		onAddNewMessage({ messageText, id: activeReqId });

		onSetMessageText('');
	};

	const handleKeyPress = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleMessageSend();
		}
	};

	return (
		<ChatModalView
			currentDate={currentDate}
			messages={messages}
			onTextChange={handleTextChange}
			onMessageSend={handleMessageSend}
			onKeyPress={handleKeyPress}
			messageText={messageText}
			isMessagesLoading={isMessagesLoading}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	currentDate: getCurrentDateSelector(),
	messages: getMessagesSelector(),
	messageText: getMessageTextSelector(),
	activeReqId: getActiveReqIdSelector(),
	messagesCount: getMessagesCountSelector(),
	isMessagesLoading: getIsMessagesLoadingSelector(),
});

const mapDispatchToProps = {
	onResetState: resetStateAction,
	onSetMessageText: setMessageTextAction,
	onGetMessages: getMessagesAction,
	onAddNewMessage: addNewMessageAction,
};

const ChatModal = connect(mapStateToProps, mapDispatchToProps)(ChatModalWrapper);

ChatModalWrapper.propTypes = {
	onResetState: PropTypes.func.isRequired,
	currentDate: PropTypes.string.isRequired,
	onSetMessageText: PropTypes.func.isRequired,
	messageText: PropTypes.string.isRequired,
	messages: PropTypes.arrayOf(
		PropTypes.shape({
			date: PropTypes.string,
			messages: PropTypes.arrayOf(
				PropTypes.shape({
					author: PropTypes.string.isRequired,
					companyName: PropTypes.string.isRequired,
					id: PropTypes.number.isRequired,
					isMy: PropTypes.bool.isRequired,
					isRead: PropTypes.bool.isRequired,
					isSend: PropTypes.bool.isRequired,
					role: PropTypes.string.isRequired,
					text: PropTypes.string.isRequired,
					time: PropTypes.string.isRequired,
				}),
			),
		}),
	).isRequired,
	onGetMessages: PropTypes.func.isRequired,
	activeReqId: PropTypes.string,
	onAddNewMessage: PropTypes.func.isRequired,
	messagesCount: PropTypes.number.isRequired,
	isMessagesLoading: PropTypes.bool.isRequired,
};

export default ChatModal;
