export const GET_ADDRESSES = 'addresses/GET_ADDRESSES';
export const getAddressesAction = payload => ({
	type: GET_ADDRESSES,
	payload,
});

export const SET_ADDRESSES = 'addresses/SET_ADDRESSES';
export const setAddressesAction = payload => ({
	type: SET_ADDRESSES,
	payload,
});

export const SET_IS_ADDRESSES_LOADING = 'addresses/SET_IS_ADDRESSES_LOADING';
export const setIsAddressesLoadingAction = payload => ({
	type: SET_IS_ADDRESSES_LOADING,
	payload,
});

export const SET_ADDRESSES_SEARCH_VALUE = 'addresses/SET_ADDRESSES_SEARCH_VALUE';
export const setAddressesSearchValueAction = payload => ({
	type: SET_ADDRESSES_SEARCH_VALUE,
	payload,
});

export const SET_PAGINATION = 'addresses/SET_PAGINATION';
export const setPaginationAction = payload => ({
	type: SET_PAGINATION,
	payload,
});

export const RESET_STATE = 'addresses/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const GET_PARTNER_ADDRESSES = 'addresses/GET_PARTNER_ADDRESSES';
export const getPartnerAddressesAction = payload => ({
	type: GET_PARTNER_ADDRESSES,
	payload,
});

export const SET_PARTNER_ADDRESSES = 'addresses/SET_PARTNER_ADDRESSES';
export const setPartnerAddressesAction = payload => ({
	type: SET_PARTNER_ADDRESSES,
	payload,
});
