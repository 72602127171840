export const URL = process.env.REACT_APP_API_URL;

export const DADATA_URL = process.env.REACT_APP_DADATA_URL;

export const DADATA_TOKEN = process.env.REACT_APP_DADATA_TOKEN; // todo: add to env

export const DISMISS_TIME = 6000;

export const UNKNOWN_ERROR_MESSAGE = 'Произошла неизвестная ошибка';

export const UNKNOWN_ERROR_TITLE = 'Ошибка';
