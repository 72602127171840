import React from 'react';
import './styles.less';

const KgIcon = (
	<i className="text-icon">
		<span className="text-icon input-group-text">кг</span>
	</i>
);

const VolumeIcon = (
	<i className="text-icon">
		<span className="text-icon input-group-text">м³</span>
	</i>
);

const PieceIcon = (
	<i className="text-icon">
		<span className="text-icon input-group-text">шт</span>
	</i>
);

const PriceIcon = (
	<i className="text-icon">
		<span className="text-icon input-group-text">р.</span>
	</i>
);

const SizeTextIcon = (
	<i className="text-icon">
		<span className="text-icon input-group-text">см</span>
	</i>
);

export { KgIcon, VolumeIcon, PieceIcon, PriceIcon, SizeTextIcon };
