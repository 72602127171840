/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { change, reduxForm, submit } from 'redux-form';
import { useHistory, useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { ModalWindow } from '../../../../semantic-ui/components/modal-window';
import ModalFooterButtons from '../../../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ADD_ADDRESS_FORM_NAME } from '../../constants';
import { AddressFormView } from './address-form-view';
import {
	createAddressAction,
	getAddressFormOptionsAction,
	setAddressFormOptionsAction,
	setAddressFormSearchValueAction,
} from '../../actions';
import {
	getFormattedAddressFormOptionsSelector,
	getAddressFormSearchValueSelector,
	getIsAddressFormOptionsLoadingSelector,
	getAddressFormOptionsSelector,
} from '../../selectors';
import { validateAddressModal } from '../../utils';

const AddressModalWrapper = ({
	addressFormOptions,
	isAddressFormOptionsLoading,
	addressFormSearchValue,
	onFormSubmit,
	onCreateAddress,
	onSetAddressFormSearchValue,
	onGetAddressFormOptions,
	onFieldChange,
	addresses,
	onSetAddressFormOptions,
}) => {
	const history = useHistory();

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const handleAddressSearchChange = ({ target: { value } }) => {
		onSetAddressFormSearchValue(value);
		onGetAddressFormOptions();
	};

	const handleAddressChange = value => {
		const currentAddress = addresses.find(address => address.value === value);

		if (currentAddress) {
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'address', currentAddress.value);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'city_type_full', currentAddress.data.city_type_full);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'city_type', currentAddress.data.city_type);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'city', currentAddress.data.city);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'city_with_type', currentAddress.data.city_with_type);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'city_kladr_id', currentAddress.data.city_kladr_id);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'city_fias_id', currentAddress.data.city_fias_id);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'street_type_full', currentAddress.data.street_type_full);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'country', currentAddress.data.country);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'kladr_id', currentAddress.data.kladr_id);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'region', currentAddress.data.region);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'region_kladr_id', currentAddress.data.region_kladr_id);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'street_type', currentAddress.data.street_type);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'street', currentAddress.data.street);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'street_with_type', currentAddress.data.street_with_type);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'street_kladr_id', currentAddress.data.street_kladr_id);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'street_fias_id', currentAddress.data.street_fias_id);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'settlement_type_full', currentAddress.data.settlement_type_full);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'settlement_type', currentAddress.data.settlement_type);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'settlement', currentAddress.data.settlement);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'settlement_with_type', currentAddress.data.settlement_with_type);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'settlement_kladr_id', currentAddress.data.settlement_kladr_id);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'settlement_fias_id', currentAddress.data.settlement_fias_id);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'house_type_full', currentAddress.data.house_type_full);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'house_type', currentAddress.data.house_type);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'house', currentAddress.data.house);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'house_kladr_id', currentAddress.data.house_kladr_id);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'house_fias_id', currentAddress.data.house_fias_id);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'block_type_full', currentAddress.data.block_type_full);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'block_type', currentAddress.data.block_type);
			onFieldChange(ADD_ADDRESS_FORM_NAME, 'block', currentAddress.data.block);

			onSetAddressFormSearchValue(currentAddress.value);
		}
	};

	const handleModalClose = () => {
		onSetAddressFormOptions([]);
		onSetAddressFormSearchValue('');
		query.set('modal', 'request');

		history.push(`${pathname}?${query}`);
	};

	const handleAddressFormSubmit = async () => {
		await onFormSubmit(ADD_ADDRESS_FORM_NAME);

		onCreateAddress({ redirect: history.push, searchQuery: query.toString() });
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleAddressFormSubmit();
		}
	};

	return (
		<ModalWindow
			isModalShown
			onKeyDown={handleKeyDown}
			headerText="Новый адрес"
			modalContent={
				<AddressFormView
					addressFormOptions={addressFormOptions}
					isAddressFormOptionsLoading={isAddressFormOptionsLoading}
					onAddressSearchChange={handleAddressSearchChange}
					onAddressChange={handleAddressChange}
					addressFormSearchValue={addressFormSearchValue}
				/>
			}
			style={{ width: '900px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'request-address' }]}
			footerButtons={
				<ModalFooterButtons
					leftButtons={[]}
					rightButtons={[
						{ onClick: handleAddressFormSubmit, text: 'Сохранить', color: 'primary' },
						{ onClick: handleModalClose, text: 'Отмена', color: 'secondary' },
					]}
				/>
			}
		/>
	);
};

const withForm = reduxForm({
	form: ADD_ADDRESS_FORM_NAME,
	enableReinitialize: true,
	onSubmit: validateAddressModal,
})(AddressModalWrapper);

const mapStateToProps = createStructuredSelector({
	addressFormOptions: getFormattedAddressFormOptionsSelector(),
	addressFormSearchValue: getAddressFormSearchValueSelector(),
	isAddressFormOptionsLoading: getIsAddressFormOptionsLoadingSelector(),
	addresses: getAddressFormOptionsSelector(),
});

const mapDispatchToProps = {
	onFormSubmit: submit,
	onFieldChange: change,
	onSetAddressFormSearchValue: setAddressFormSearchValueAction,
	onGetAddressFormOptions: getAddressFormOptionsAction,
	onCreateAddress: createAddressAction,
	onSetAddressFormOptions: setAddressFormOptionsAction,
};

const AddressModal = connect(mapStateToProps, mapDispatchToProps)(withForm);

AddressModalWrapper.propTypes = {
	addressFormOptions: PropTypes.array.isRequired,
	addresses: PropTypes.array.isRequired,
	isAddressFormOptionsLoading: PropTypes.bool.isRequired,
	addressFormSearchValue: PropTypes.string.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	onCreateAddress: PropTypes.func.isRequired,
	onSetAddressFormSearchValue: PropTypes.func.isRequired,
	onGetAddressFormOptions: PropTypes.func.isRequired,
	onFieldChange: PropTypes.func.isRequired,
	onSetAddressFormOptions: PropTypes.func.isRequired,
};

export { AddressModal };
