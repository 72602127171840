/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { getFormValues, isInvalid } from 'redux-form';
import { initialState } from './reducer';
import { DATE_TIME_FORMAT, FORM_NAME } from './constants';

export const driverChangeModalDomain = state => state.driverChangeModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'driver_change');
	});

export const getDataForSaveSelector = () => createSelector(getFormValues(FORM_NAME), formValues => formValues);

export const getReqNameSelector = () =>
	createSelector(driverChangeModalDomain, ({ requestInfo }) => {
		return `Заявка #${requestInfo?.id || '-'} от ${
			requestInfo?.created_at ? moment(requestInfo.created_at).format(DATE_TIME_FORMAT) : '-'
		}`;
	});

export const getModalStatusesSelector = () =>
	createSelector(driverChangeModalDomain, ({ requestInfo }) => {
		const collectStatuses = [];

		if (requestInfo?.tab && !isEmpty(requestInfo?.tab)) {
			collectStatuses.push(requestInfo?.tab);
		}
		if (requestInfo?.status && !isEmpty(requestInfo?.status)) {
			collectStatuses.push(requestInfo?.status);
		}

		return collectStatuses;
	});

export const getIsButtonsDisabledSelector = () =>
	createSelector(
		driverChangeModalDomain,
		({ isDriverChanging, isRequestInfoLoading }) => isDriverChanging || isRequestInfoLoading,
	);

export const getIsDriversLoadingSelector = () =>
	createSelector(driverChangeModalDomain, ({ isDriversLoading }) => isDriversLoading);

export const getDriversOptionsSelector = () => createSelector(driverChangeModalDomain, ({ drivers }) => drivers);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);
