import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';
import { SpecifyFlightFormView } from './specify-flight-form-view';
import { FORM_NAME } from '../../constants';
import { validate } from '../../utils';
import { getFormInitialValuesSelector, getIsFlightInfoLoadingSelector } from '../../selectors';

const SpecifyFlightFormWrapper = ({ isFlightInfoLoading }) => {
	return <SpecifyFlightFormView isLoading={isFlightInfoLoading} />;
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
})(SpecifyFlightFormWrapper);

const mapStateToProps = createStructuredSelector({
	initialValues: getFormInitialValuesSelector(),
	isFlightInfoLoading: getIsFlightInfoLoadingSelector(),
});

const SpecifyFlightForm = connect(mapStateToProps)(withForm);

SpecifyFlightFormWrapper.propTypes = {
	isFlightInfoLoading: PropTypes.bool.isRequired,
};

export { SpecifyFlightForm };
