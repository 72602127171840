import { ContactsCustomModalCell } from './components/contacts-custom-modal-cell';

export const CONTACT_FORM_NAME = 'contact-form';

export const REQUIRED_FIELDS = ['last_name', 'first_name', 'email', 'phone'];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const CONTACT_TABLE_COLUMNS_NAMES = [{ id: 'title' }, { id: 'value', customComponent: ContactsCustomModalCell }];

export const CONTACT_TABLE_ROWS_NAMES = {
	email: 'Почта',
	phone: 'Телефон',
	address: 'Адрес',
	is_default: 'Использовать по умолчанию',
	author: 'Автор',
};
