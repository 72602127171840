import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, clearSubmitErrors, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { FORM_NAME } from '../../constants';
import { WayFormView } from './components/way-form-view';
import { getDictionaryDataSelector } from '../../../dictionaries/periods/selectors';
import {
	getAddressesOptionsSelector,
	getAddressesSearchValueSelector,
	getAddressesSelector,
	getFormInitialValuesSelector,
	getFormValuesSelector,
	getIsAddressesLoadingSelector,
	getIsWayInfoLoadingSelector,
	getModesSelector,
	getSelectedModesSelector,
} from '../../selectors';
import { getDictionaryDataAction } from '../../../dictionaries/periods/actions';
import { getAddressesAction, setAddressesAction, setAddressesSearchValueAction } from '../../actions';
import { validate } from '../../utils';
import { setWindowInfoAction } from '../../../alert-modal/actions';

const WaysFormWrapper = ({
	addressesOptions,
	isAddressesLoading,
	addressesSearchValue,
	onAddressSearchChange,
	onGetAddresses,
	onPeriodSearchChange,
	formValues,
	periodsData,
	onFormFieldChange,
	onSetAddresses,
	addresses,
	modes,
	isWayInfoLoading,
	selectedModesLength,
	onSetWindowInfo,
	onClearSubmitErrors,
}) => {
	useEffect(() => {
		if (selectedModesLength > 1) {
			onSetWindowInfo({ type: 'confirm', title: 'Внимание', text: 'Вы выбрали несколько режимов перевозки' });
		}
	}, [selectedModesLength]);

	const periodOptions = useMemo(
		() => [
			{ text: 'Нет', value: 'none', key: 'none' },
			...periodsData.map(el => ({ ...el, value: el.text, key: String(el.id) })),
		],
		[periodsData],
	);

	const handleSearchChange = (value, name) => {
		onFormFieldChange(FORM_NAME, name, value.target.value);
		onGetAddresses({ searchValue: value.target.value, name });
		onAddressSearchChange({ ...addressesSearchValue, [name]: value.target.value });
	};

	const handleAddressChange = (value, name) => {
		onFormFieldChange(FORM_NAME, name, value);
		onAddressSearchChange({ ...addressesSearchValue, [name]: value });
	};

	const handlePeriodChange = useCallback(
		value => {
			onPeriodSearchChange(value);
		},
		[onPeriodSearchChange],
	);

	const handleAddressDelete = name => {
		const newAddressesSearch = { ...addressesSearchValue };

		const newAddressesOptions = { ...addresses };

		delete newAddressesSearch[name];
		delete newAddressesOptions[name];

		onAddressSearchChange(newAddressesSearch);
		onSetAddresses(newAddressesOptions);
	};

	const handleModeChange = () => {
		onClearSubmitErrors(FORM_NAME);
	};

	return (
		<WayFormView
			addressesOptions={addressesOptions}
			isAddressesLoading={isAddressesLoading}
			onAddressSearchChange={handleSearchChange}
			onAddressChange={handleAddressChange}
			addressesSearchValue={addressesSearchValue}
			isRegular={formValues.is_regular}
			periodsData={periodOptions}
			onPeriodsChange={handlePeriodChange}
			onAddressDelete={handleAddressDelete}
			modes={modes}
			isWayInfoLoading={isWayInfoLoading}
			onModeChange={handleModeChange}
		/>
	);
};

WaysFormWrapper.propTypes = {
	addressesOptions: PropTypes.objectOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.string.isRequired,
				value: PropTypes.string.isRequired,
				text: PropTypes.string,
				data: PropTypes.shape({
					title: PropTypes.string.isRequired,
					address: PropTypes.string.isRequired,
					deleted: PropTypes.bool,
				}),
			}),
		),
	).isRequired,
	isAddressesLoading: PropTypes.objectOf(PropTypes.bool).isRequired,
	addressesSearchValue: PropTypes.object.isRequired,
	onAddressSearchChange: PropTypes.func.isRequired,
	onGetAddresses: PropTypes.func.isRequired,
	onPeriodSearchChange: PropTypes.func,
	formValues: PropTypes.object,
	periodsData: PropTypes.array,
	onFormFieldChange: PropTypes.func.isRequired,
	onSetAddresses: PropTypes.func.isRequired,
	addresses: PropTypes.object.isRequired,
	modes: PropTypes.array.isRequired,
	isWayInfoLoading: PropTypes.bool.isRequired,
	selectedModesLength: PropTypes.number.isRequired,
	onSetWindowInfo: PropTypes.func.isRequired,
	onClearSubmitErrors: PropTypes.func.isRequired,
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
})(WaysFormWrapper);

const mapStateToProps = createStructuredSelector({
	initialValues: getFormInitialValuesSelector(),
	addressesOptions: getAddressesOptionsSelector(),
	isAddressesLoading: getIsAddressesLoadingSelector(),
	addressesSearchValue: getAddressesSearchValueSelector(),
	formValues: getFormValuesSelector(),
	periodsData: getDictionaryDataSelector(),
	addresses: getAddressesSelector(),
	modes: getModesSelector(),
	isWayInfoLoading: getIsWayInfoLoadingSelector(),
	selectedModesLength: getSelectedModesSelector(),
});

const mapDispatchToProps = {
	onGetAddresses: getAddressesAction,
	onAddressSearchChange: setAddressesSearchValueAction,
	onFormFieldChange: change,
	onGetPeriodsData: getDictionaryDataAction,
	onSetAddresses: setAddressesAction,
	onSetWindowInfo: setWindowInfoAction,
	onClearSubmitErrors: clearSubmitErrors,
};

const WayFormContainer = connect(mapStateToProps, mapDispatchToProps)(withForm);

export { WayFormContainer };
