import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { getMenuItems } from './utils';
import BUTTONS from './pages.json';
import { getRequestsCountSelector } from '../../containers/profile/selectors';
import { getCountSelector } from '../../containers/reqs-list/selectors';

export const selectSidebarPageDomain = state => state.sidebarReducer || initialState;
export const selectRouterPageDomain = state => state.router || initialState;

export const getActiveSubmenusSelector = () =>
	createSelector(selectSidebarPageDomain, ({ activeSubmenus }) => activeSubmenus);

export const getMenuItemsSelector = () =>
	createSelector(
		selectSidebarPageDomain,
		selectRouterPageDomain,
		getRequestsCountSelector(),
		getCountSelector(),
		({ activeSubmenus }, { location }, profileReqsCount, reqsCount) => {
			// если открыть страницу не на вкладке "заявки", то берем количество из профиля
			const count = reqsCount?.total || profileReqsCount;

			return getMenuItems(BUTTONS, location.pathname, activeSubmenus, count);
		},
	);

export const getIsUserPermissionsLoadingSelector = () =>
	createSelector(selectSidebarPageDomain, ({ isUserPermissionsLoading }) => isUserPermissionsLoading);

export const getIsSubmenuOpenSelector = () =>
	createSelector(
		selectSidebarPageDomain,
		({ activeSubmenu, isUserPermissionsLoading }) => activeSubmenu.length > 0 && !isUserPermissionsLoading,
	);
