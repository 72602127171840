export const GET_USERS_ROLES_LIST = 'users-roles/GET_USERS_ROLES_LIST';
export const getUsersRolesListAction = payload => ({
	type: GET_USERS_ROLES_LIST,
	payload,
});

export const SET_USERS_ROLES_LIST = 'users-roles/SET_USERS_ROLES_LIST';
export const setUsersRolesListAction = payload => ({
	type: SET_USERS_ROLES_LIST,
	payload,
});

export const SET_PAGINATION = 'users-roles/SET_PAGINATION';
export const setPaginationAction = payload => ({
	type: SET_PAGINATION,
	payload,
});

export const SET_IS_USERS_ROLES_LIST_LOADING = 'users-roles/SET_IS_USERS_ROLES_LIST_LOADING';
export const setIsUsersRolesListLoadingAction = payload => ({
	type: SET_IS_USERS_ROLES_LIST_LOADING,
	payload,
});
export const SET_TABLE_SEARCH = 'users-roles/SET_TABLE_SEARCH';
export const setTableSearchAction = payload => ({
	type: SET_TABLE_SEARCH,
	payload,
});

export const RESET_STATE = 'users-roles/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});
