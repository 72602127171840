/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { getLocalStorageItem } from '../../utils/localStorage';
import { removeModalQuery } from '../../utils/removeModalQuery';

export const selectRightHeaderContentDomain = state => state.rightHeaderContentReducer || initialState;

export const getIsProfileDropdownShownSelector = () =>
	createSelector(selectRightHeaderContentDomain, ({ isProfileDropdownShown }) => isProfileDropdownShown);

export const getIsNotificationDropdownShownSelector = () =>
	createSelector(selectRightHeaderContentDomain, ({ isNotificationDropdownShown }) => isNotificationDropdownShown);

export const getIsChatsListShownShownSelector = () =>
	createSelector(selectRightHeaderContentDomain, ({ isChatsListShown }) => isChatsListShown);

export const getNotificationsListSelector = () =>
	createSelector(selectRightHeaderContentDomain, ({ notificationsList }) => notificationsList);

export const getNotificationsSelector = () =>
	createSelector(selectRightHeaderContentDomain, ({ notificationsList }) => {
		const query = new URLSearchParams(removeModalQuery(window.location.search));

		return notificationsList.map(item => {
			if (item.link) {
				if (item.link.indexOf('?') !== -1) {
					return { ...item, link: `${item?.link}&${query.toString()}` };
				}
			}

			return item;
		});
	});

export const getIsNotificationsListLoadingSelector = () =>
	createSelector(selectRightHeaderContentDomain, ({ isNotificationsListLoading }) => isNotificationsListLoading);

export const getUserDataSelector = () =>
	createSelector(selectRightHeaderContentDomain, ({ userData }) => {
		const localStorageUserData = getLocalStorageItem('user_data');

		return userData || localStorageUserData || { first_name: '-', last_name: '-', email: '-' };
	});

export const getUserCompanyTypeSelector = () =>
	createSelector(selectRightHeaderContentDomain, ({ userData }) => {
		const localStorageUserData = getLocalStorageItem('user_data');

		return userData?.company_type || localStorageUserData?.company_type || '';
	});
