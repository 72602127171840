import { call, put, select, take, takeEvery, fork } from 'redux-saga/effects';
import { notificationInit } from '../../modules/notifications/actions';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { getNotificationsListRequest, markNotificationRequest } from '../../api/requests';
import {
	GET_NOTIFICATIONS_LIST,
	MARK_NOTIFICATION,
	setIsNotificationsListLoadingAction,
	setNotificationsListAction,
} from './actions';
import { DISMISS_TIME } from '../../api/constants';
import { removeModalQuery } from '../../utils/removeModalQuery';
import { createChatBadgeUpdateChannels, createNotificationsChannels } from '../../api/sockets';
import { getCompanyIdSelector, getNotificationsCountSelector, getUserIdSelector } from '../profile/selectors';
import { setChatsNotificationsCountAction, setNotificationsCountAction } from '../profile/actions';
import { errorHandler } from '../../api/utils';

export function* getNotificationsListSaga() {
	try {
		yield put(setIsNotificationsListLoadingAction(true));

		const { data, message } = yield call(getNotificationsListRequest);

		if (data) {
			yield put(setNotificationsListAction(data.filter(item => item.stored)));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsNotificationsListLoadingAction(false));
	}
}

export function* markNotificationSaga({ payload: id }) {
	try {
		const { success, message } = yield call(markNotificationRequest, id);

		if (success) {
			const notificationsCount = yield select(getNotificationsCountSelector());

			yield put(setNotificationsCountAction(notificationsCount - 1));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export function* socketNotificationsSaga() {
	const userId = yield select(getUserIdSelector());

	const channel = yield call(() => createNotificationsChannels(userId));

	if (!channel) return;

	while (true) {
		const toast = yield take(channel);

		const query = new URLSearchParams(removeModalQuery(window.location.search));

		if (toast?.link) {
			yield put(
				notificationInit({
					id: toast.id,
					dismissAfter: DISMISS_TIME,
					...toast,
					...{
						link: `${toast?.link}${
							query.toString() && toast?.link.indexOf('?') !== -1 ? `&${query.toString()}` : ''
						}`,
					},
				}),
			);
		} else {
			yield put(
				notificationInit({
					id: toast.id,
					dismissAfter: DISMISS_TIME,
					...toast,
					...{},
				}),
			);
		}

		const notificationsCount = yield select(getNotificationsCountSelector());
		if (toast.stored) {
			yield put(setNotificationsCountAction(notificationsCount + 1));
		}
	}
}

export function* socketChatCountUpdateSaga() {
	const companyId = yield select(getCompanyIdSelector());

	const channel = yield call(() => createChatBadgeUpdateChannels(companyId));

	if (!channel) return;

	while (true) {
		const data = yield take(channel);
		yield put(setChatsNotificationsCountAction(data?.count));
	}
}

export default function* rightHeaderContentSaga() {
	yield takeEvery(GET_NOTIFICATIONS_LIST, getNotificationsListSaga);
	yield takeEvery(MARK_NOTIFICATION, markNotificationSaga);
}
