import {
	RESET_STATE,
	SET_CAR_INFO,
	SET_IS_CAR_DELETING,
	SET_IS_CAR_INFO_LOADING,
	SET_IS_CAR_SAVING,
	SET_IS_TONNAGES_LOADING,
	SET_IS_TRAILERS_LIST_LOADING,
	SET_TONNAGES,
	SET_TRAILERS_LIST,
} from './actions';

export const initialState = {
	carInfo: null,
	isCarInfoLoading: false,
	isCarDeleting: false,
	isCarSaving: false,
	trailersList: [],
	isTrailersListLoading: false,
	isTonnagesLoading: false,
	tonnages: [],
};

const carsModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_CAR_INFO:
			return {
				...state,
				carInfo: payload,
			};
		case SET_IS_CAR_INFO_LOADING:
			return {
				...state,
				isCarInfoLoading: payload,
			};
		case SET_IS_CAR_DELETING:
			return {
				...state,
				isCarDeleting: payload,
			};
		case SET_IS_CAR_SAVING:
			return {
				...state,
				isCarSaving: payload,
			};
		case SET_TRAILERS_LIST:
			return {
				...state,
				trailersList: payload,
			};
		case SET_IS_TRAILERS_LIST_LOADING:
			return {
				...state,
				isTrailersListLoading: payload,
			};
		case SET_TONNAGES:
			return {
				...state,
				tonnages: payload,
			};
		case SET_IS_TONNAGES_LOADING:
			return {
				...state,
				isTonnagesLoading: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default carsModalReducer;
