import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { ContactFormView } from './contact-form-view';
import { CONTACT_FORM_NAME } from '../../constants';
import { getFormInitialValuesSelector, getIsContactInfoLoadingSelector } from '../../selectors';
import { validate } from '../../utils';

const ContactFormWrapper = ({ isContactInfoLoading }) => {
	return <ContactFormView isContactInfoLoading={isContactInfoLoading} />;
};

const withForm = reduxForm({
	form: CONTACT_FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
})(ContactFormWrapper);

const mapStateToProps = createStructuredSelector({
	initialValues: getFormInitialValuesSelector(),
	isContactInfoLoading: getIsContactInfoLoadingSelector(),
});

ContactFormWrapper.propTypes = {
	isContactInfoLoading: PropTypes.bool.isRequired,
};

const ContactForm = connect(mapStateToProps)(withForm);

export { ContactForm };
