import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getLocalStorageItem } from '../../utils/localStorage';
import { CompanyValidateView } from './company-validate-view';

const CompanyValidate = () => {
	const history = useHistory();

	useEffect(() => {
		const user_data = getLocalStorageItem('user_data');

		if (history.location.pathname.includes('/company/validate') && user_data.isCompanyValidated) {
			history.push('/home/information');
		}
	}, []);

	return <CompanyValidateView />;
};

export { CompanyValidate };
