export const RESET_STATE = 'requests-list/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const GET_REQS = 'requests-list/GET_REQS';
export const getReqsAction = payload => ({
	type: GET_REQS,
	payload,
});

export const SET_REQS = 'requests-list/SET_REQS';
export const setReqsAction = payload => ({
	type: SET_REQS,
	payload,
});

export const SET_IS_REQS_LOADING = 'requests-list/SET_IS_REQS_LOADING';
export const setIsReqsLoadingAction = payload => ({
	type: SET_IS_REQS_LOADING,
	payload,
});

export const SET_PAGINATION = 'requests-list/SET_PAGINATION';
export const setPaginationAction = payload => ({
	type: 'requests-list/SET_PAGINATION',
	payload,
});

export const UPDATE_REQ = 'requests-list/UPDATE_REQ';
export const updateReqAction = payload => ({
	type: UPDATE_REQ,
	payload,
});

export const GET_REQS_WITH_FILTERS = 'requests-list/GET_REQS_WITH_FILTERS';
export const getReqsWithFiltersAction = payload => ({
	type: GET_REQS_WITH_FILTERS,
	payload,
});

export const SET_COUNT = 'requests-list/SET_COUNT';
export const setCountAction = payload => ({
	type: SET_COUNT,
	payload,
});

export const DELETE_REQ = 'requests-list/DELETE_REQ';
export const deleteReqAction = payload => ({
	type: DELETE_REQ,
	payload,
});

export const TAKE_REQ = 'requests-list/TAKE_REQ';
export const takeReqAction = payload => ({
	type: TAKE_REQ,
	payload,
});

export const SET_TIMER = 'requests-list/SET_TIMER';
export const setTimerAction = payload => ({
	type: SET_TIMER,
	payload,
});
