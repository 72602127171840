import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import './styles.less';
import { Field } from 'redux-form';
import { FormInputField } from '../../../../semantic-ui/components/form-input-field';
import { FormSelectField } from '../../../../semantic-ui/components/form-select-field';

export const TrailerForm = ({ isTrailerInfoLoading, tonnages, isTonnagesLoading }) => {
	return (
		<Grid className="trailer-form">
			<Grid.Row className="trailer-form__row">
				<Grid.Column width={6} className="trailer-form__column">
					Модель
				</Grid.Column>
				<Grid.Column width={7} className="trailer-form__column">
					<Field
						name="name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите модель"
						loading={isTrailerInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="trailer-form__row">
				<Grid.Column width={6} className="trailer-form__column">
					Гос. номер
				</Grid.Column>
				<Grid.Column width={7} className="trailer-form__column">
					<Field
						name="number"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите гос. номер"
						loading={isTrailerInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="trailer-form__row">
				<Grid.Column width={6} className="trailer-form__column">
					Vin номер
				</Grid.Column>
				<Grid.Column width={7} className="trailer-form__column">
					<Field
						name="vin"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите vin номер"
						loading={isTrailerInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="trailer-form__row">
				<Grid.Column width={6} className="trailer-form__column">
					Грузоподъемность
				</Grid.Column>
				<Grid.Column width={7} className="trailer-form__column trailer-form__column_with-select">
					<Field
						name="tonnage_id"
						type="text"
						component={FormSelectField}
						autoComplete="off"
						options={tonnages}
						search
						dropdownClassName="static"
						loading={isTonnagesLoading || isTrailerInfoLoading}
						disabled={isTonnagesLoading || isTrailerInfoLoading}
						noResultsMessage="Не найдено"
						placeholder="Укажите грузоподъемность прицепа (тн)"
						isWithoutSelectOnBlur
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

TrailerForm.propTypes = {
	isTrailerInfoLoading: PropTypes.bool.isRequired,
	isTonnagesLoading: PropTypes.bool.isRequired,
	tonnages: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
};
