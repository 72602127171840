import {
	RESET_STATE,
	SET_ID_OF_OPEN_MODAL,
	SET_IS_USER_AUTHORIZING,
	SET_LOGIN_ERROR_MESSAGE,
	SET_IS_FORGOT_FORM_SUBMITTING,
} from './actions';

export const initialState = {
	idOfOpenModal: 'login',
	isUserAuthorizing: false,
	isForgotFormSubmitting: false,
	loginErrorMessage: '',
};

const loginReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_ID_OF_OPEN_MODAL:
			return {
				...state,
				idOfOpenModal: payload,
			};
		case SET_IS_USER_AUTHORIZING:
			return {
				...state,
				isUserAuthorizing: payload,
			};
		case SET_IS_FORGOT_FORM_SUBMITTING:
			return {
				...state,
				isForgotFormSubmitting: payload,
			};
		case SET_LOGIN_ERROR_MESSAGE:
			return {
				...state,
				loginErrorMessage: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default loginReducer;
