import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { setActiveSubmenusAction } from './actions';
import { getActiveSubmenusSelector, getMenuItemsSelector } from './selectors';
import reducer from './reducer';
import { useInjectReducer } from '../../utils/injectReducer';
import { SidebarComponent } from './components/sidebar-view';
import { getFromStorage } from './utils';
import { getLocalStorageItem } from '../../utils/localStorage';

export const SideBarWrapper = ({ menuItems, activeSubmenus, onSetActiveSubmenus }) => {
	useInjectReducer({ key: 'sidebarReducer', reducer });

	const history = useHistory();

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	useEffect(() => {
		const menu = getFromStorage('menu');

		if (menu) {
			onSetActiveSubmenus(menu);
		}
	}, []);

	const handleMenuItemClick = (id, hasSubmenu, route, modal) => {
		const isSubmenuActive = activeSubmenus.includes(id);

		if (hasSubmenu && isSubmenuActive) {
			onSetActiveSubmenus(activeSubmenus.filter(item => item !== id));
		} else if (hasSubmenu) {
			onSetActiveSubmenus([...activeSubmenus, id]);
		}

		if (route) {
			history.push(route);
		}

		if (modal) {
			query.set('modal', modal);
			history.push(`${pathname}?${query}`);
		}
	};

	const handleLogoClick = () => {
		const user_data = getLocalStorageItem('user_data');

		if (!user_data.isCompanyValidated || !user_data.email_verified_at) return;

		history.push('/home/information');
	};

	return (
		<SidebarComponent sideBarData={menuItems} onMenuItemClick={handleMenuItemClick} onLogoClick={handleLogoClick} />
	);
};

const mapStateToProps = createStructuredSelector({
	menuItems: getMenuItemsSelector(),
	activeSubmenus: getActiveSubmenusSelector(),
});

const mapDispatchToProps = {
	onSetActiveSubmenus: setActiveSubmenusAction,
};

const SideBar = connect(mapStateToProps, mapDispatchToProps)(SideBarWrapper);

SideBarWrapper.propTypes = {
	menuItems: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			isActive: PropTypes.bool.isRequired,
			isSubmenuOpen: PropTypes.bool.isRequired,
			text: PropTypes.string.isRequired,
			route: PropTypes.string,
			count: PropTypes.number,
			subRoutes: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.string.isRequired,
					isActive: PropTypes.bool.isRequired,
					text: PropTypes.string.isRequired,
					route: PropTypes.string,
				}),
			),
		}),
	).isRequired,
	activeSubmenus: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
	onSetActiveSubmenus: PropTypes.func.isRequired,
};

export { SideBar };
