import { URL } from './constants';
import { getToken, makeRequest } from './utils';

export const getWaysListRequest = parameters =>
	makeRequest(
		`${URL}/api/ways`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getWayInfoRequest = id =>
	makeRequest(`${URL}/api/ways/${id}`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const deleteWayRequest = id =>
	makeRequest(`${URL}/api/ways/${id}`, {
		method: 'DELETE',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const updateWayRequest = (id, body) =>
	makeRequest(`${URL}/api/ways/${id}`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});

export const createWayRequest = body =>
	makeRequest(`${URL}/api/ways`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});
