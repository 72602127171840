import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { change, reduxForm, reset, submit } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { ProfileModalView } from './components/profile-modal-view';
import { useInjectReducer } from '../../utils/injectReducer';
import saga from './saga';
import reducer from './reducer';
import {
	getUserInfoAction,
	getUsersListAction,
	resetStateAction,
	saveProfileAction,
	setIsProfileFormOpenAction,
} from './actions';
import {
	getFormInitialValuesSelector,
	getIsModalShownSelector,
	getIsProfileFormOpenSelector,
	getIsUserInfoLoadingSelector,
	getIsUsersListLoadingSelector,
	getStatusToggleFormSelector,
	getUserInfoSelector,
	getUsersListSelector,
} from './selectors';
import { FORM_NAME } from './constants';
import { useInjectSaga } from '../../utils/injectSaga';
import { validate } from './utils';

export const ProfileModalWrapper = ({
	onGetUserInfo,
	userInfo,
	isUserInfoLoading,
	isProfileFormOpen,
	onSetIsProfileFormOpen,
	isModalShown,
	onSaveProfile,
	onFormSubmit,
	onResetForm,
	onGetUsersList,
	usersList,
	isUsersListLoading,
	isDisabled,
	onChangeField,
}) => {
	useInjectSaga({ key: 'profileSaga', saga });
	useInjectReducer({ key: 'profileModalReducer', reducer });

	const history = useHistory();

	useEffect(() => {
		if (isModalShown) {
			onGetUserInfo(history);
			onGetUsersList();
		}
	}, [isModalShown]);

	const handleProfileFormOpenButtonClick = isOpen => {
		onResetForm(FORM_NAME);
		onSetIsProfileFormOpen(isOpen);
	};

	const handleNavItemClick = () => {
		// todo хендлер клика по навигационной плашке
	};

	const handleModalClose = () => {
		onSetIsProfileFormOpen(false);
		history.goBack();
	};

	const handleProfileFormSubmit = async () => {
		await onFormSubmit(FORM_NAME);
		onSaveProfile({ redirect: handleModalClose });
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleProfileFormSubmit();
		}
	};

	const handleCheckboxChange = field => {
		if (field === 'is_notify') {
			onChangeField(FORM_NAME, 'is_notify_todo', null);
		}
		onChangeField(FORM_NAME, 'is_notify_todo_company', null);
	};

	return (
		<ProfileModalView
			isModalShown={isModalShown}
			userInfo={userInfo}
			isUserInfoLoading={isUserInfoLoading}
			isProfileFormOpen={isProfileFormOpen}
			onProfileFormOpenButtonClick={handleProfileFormOpenButtonClick}
			onNavItemClick={handleNavItemClick}
			onModalClose={handleModalClose}
			onProfileFormSubmit={handleProfileFormSubmit}
			onKeyDown={handleKeyDown}
			usersList={usersList}
			isUsersListLoading={isUsersListLoading}
			onCheckboxChange={handleCheckboxChange}
			isDisabled={isDisabled}
		/>
	);
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
	keepDirtyOnReinitialize: true,
})(ProfileModalWrapper);

const mapStateToProps = createStructuredSelector({
	userInfo: getUserInfoSelector(),
	isUserInfoLoading: getIsUserInfoLoadingSelector(),
	isProfileFormOpen: getIsProfileFormOpenSelector(),
	initialValues: getFormInitialValuesSelector(),
	isModalShown: getIsModalShownSelector(),
	usersList: getUsersListSelector(),
	isUsersListLoading: getIsUsersListLoadingSelector(),
	isDisabled: getStatusToggleFormSelector(),
});

const mapDispatchToProps = {
	onGetUserInfo: getUserInfoAction,
	onResetState: resetStateAction,
	onSetIsProfileFormOpen: setIsProfileFormOpenAction,
	onSaveProfile: saveProfileAction,
	onFormSubmit: submit,
	onResetForm: reset,
	onGetUsersList: getUsersListAction,
	onChangeField: change,
};

const ProfileModal = connect(mapStateToProps, mapDispatchToProps)(withForm);

ProfileModalWrapper.propTypes = {
	onGetUserInfo: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	isUserInfoLoading: PropTypes.bool.isRequired,
	isProfileFormOpen: PropTypes.bool.isRequired,
	onSetIsProfileFormOpen: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	onSaveProfile: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	initialValues: PropTypes.object,
	userInfo: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
			]).isRequired,
		}),
	),
	onResetForm: PropTypes.func.isRequired,
	onGetUsersList: PropTypes.func.isRequired,
	usersList: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			key: PropTypes.number.isRequired,
			value: PropTypes.number.isRequired,
		}),
	).isRequired,
	isUsersListLoading: PropTypes.bool.isRequired,
	isDisabled: PropTypes.objectOf(PropTypes.bool).isRequired,
	onChangeField: PropTypes.func.isRequired,
};

export default ProfileModal;
