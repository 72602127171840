import { CustomModalCell } from '../../../components/custom-modal-cell';

export const FORM_NAME = 'company-form';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const TABLE_COLUMNS_NAMES = [{ id: 'title' }, { id: 'value', customComponent: CustomModalCell }];

export const TABLE_ROWS_NAMES = {
	type: 'Тип компании',
	inn: 'ИНН',
	ogrn: 'ОГРН',
	address: 'Юридический адрес',
	contact: 'Контакты',
	contract_info: 'Договор',
	is_accredited: 'Компания аккредитована',
	is_validated: 'Компания валидирована',
};

export const FIELDS_FOR_VALIDATE = [
	'company.type_id',
	'company.inn',
	'company.ogrn',
	'company.address',
	'contact.last_name',
	'contact.first_name',
	'contact.middle_name',
	'contact.email',
	'contact.phone',
	'contact.contract',
	'contact.contract_date',
	'contact.is_accredited',
	'contact.is_validated',
];
