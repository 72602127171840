import { CustomCell } from '../../../components/custom-cell';

export const TABLE_COLUMNS = [
	{ name: '#', id: 'id', width: '113px', minWidth: '113px', customComponent: CustomCell },
	{ name: 'Название', id: 'name', width: '350px', customComponent: CustomCell },
	{ name: 'ИНН', id: 'inn', width: '200px' },
	{ name: 'ОГРН', id: 'ogrn', width: '200px' },
	{ name: 'Контакты', id: 'contact', customComponent: CustomCell },
];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const COMPANIES_NAV_PAGE_LIST = [{ text: 'Компании', href: '/companies' }];
