import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import FormCheckbox from '../../../../semantic-ui/components/form-checkbox';
import { updateContactDefaultAction } from '../../actions';
import { removeModalQuery } from '../../../../utils/removeModalQuery';

const ToggleCellWrapper = ({ rowData, columnId, onUpdateContactDefault }) => {
	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const addressId = pathname.includes('partners') ? query.get('addressId') : query.get('id');

	const handleCheckboxChange = () => {
		onUpdateContactDefault({
			addressId,
			contactId: rowData.id,
			value: !rowData[columnId],
			searchQuery: removeModalQuery(query.toString()),
		});
	};

	return <FormCheckbox toggle type="checkbox" checked={rowData[columnId]} onChange={handleCheckboxChange} />;
};

const mapDispatchToProps = {
	onUpdateContactDefault: updateContactDefaultAction,
};

ToggleCellWrapper.propTypes = {
	rowData: PropTypes.object.isRequired,
	columnId: PropTypes.string.isRequired,
	onUpdateContactDefault: PropTypes.func.isRequired,
};

const ToggleCell = connect(null, mapDispatchToProps)(ToggleCellWrapper);

export { ToggleCell };
