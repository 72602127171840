import { createSelector } from 'reselect';
import { getFormValues, isInvalid } from 'redux-form';
import { initialState } from './reducer';
import { FORGOT_FORM_NAME, LOGIN_FORM_NAME } from './constants';

export const selectLoginDomain = state => state.loginReducer || initialState;

export const getIdOfOpenModalSelector = () => createSelector(selectLoginDomain, ({ idOfOpenModal }) => idOfOpenModal);

export const getLoginFormIsInvalidSelector = () => createSelector(isInvalid(LOGIN_FORM_NAME), isInvalid => isInvalid);

export const getForgotFormIsInvalidSelector = () => createSelector(isInvalid(FORGOT_FORM_NAME), isInvalid => isInvalid);

export const getLoginFormDataSelector = () =>
	createSelector(getFormValues(LOGIN_FORM_NAME), formValues => {
		if (!formValues) {
			return {};
		}

		return formValues;
	});

export const getForgotFormDataSelector = () =>
	createSelector(getFormValues(FORGOT_FORM_NAME), formValues => {
		if (!formValues) {
			return {};
		}

		return {
			...formValues,
			...(formValues.remember ? { remember: true } : {}),
		};
	});

export const getLoginErrorMessageSelector = () =>
	createSelector(selectLoginDomain, ({ loginErrorMessage }) => loginErrorMessage);

export const getHasLoginErrorSelector = () =>
	createSelector(selectLoginDomain, ({ loginErrorMessage }) => Boolean(loginErrorMessage));

export const getIsUserAuthorizingSelector = () =>
	createSelector(selectLoginDomain, ({ isUserAuthorizing }) => isUserAuthorizing);

export const getIsForgotFormSubmittingSelector = () =>
	createSelector(selectLoginDomain, ({ isForgotFormSubmitting }) => isForgotFormSubmitting);
