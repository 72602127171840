/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import { getReqInfoAction, resetStateAction } from './actions';
import { getActiveReqIdSelector, getIsModalShownSelector } from './selectors';

import { CargoDeliveryForm } from './components/cargo-delivery-form';

const CargoDeliveryWrapper = ({ onGetReqInfo, onResetState, isModalShown, activeReqId }) => {
	useInjectSaga({ key: 'cargoDeliveryModalSaga', saga });
	useInjectReducer({ key: 'cargoDeliveryModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		return () => onResetState();
	}, []);

	useEffect(() => {
		if (activeReqId) onGetReqInfo(activeReqId);
	}, [activeReqId]);

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('mode');
		query.delete('id');
		query.delete('tab');

		onResetState();
		history.push(`${pathname}?${query}`);
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			headerText="Форма доставĸи груза"
			navItems={[]}
			modalContent={<CargoDeliveryForm />}
			style={{ width: '752px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'cargo-delivery' }]}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	isModalShown: getIsModalShownSelector(),
	activeReqId: getActiveReqIdSelector(),
});

const mapDispatchToProps = {
	onGetReqInfo: getReqInfoAction,
	onResetState: resetStateAction,
};

const CargoDeliveryModal = connect(mapStateToProps, mapDispatchToProps)(CargoDeliveryWrapper);

CargoDeliveryWrapper.propTypes = {
	onGetReqInfo: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	activeReqId: PropTypes.string,
};

export default CargoDeliveryModal;
