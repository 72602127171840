/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { getFormValues, isInvalid } from 'redux-form';
import { initialState } from './reducer';
import { FORM_NAME } from './constants';

export const selectRefusalReasonModalDomain = state => state.refusalReasonModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getIsReasonSendingSelector = () =>
	createSelector(selectRefusalReasonModalDomain, ({ isReasonSending }) => isReasonSending);

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'refusal_reason');
	});

export const getDataForRequestSelector = () => createSelector(getFormValues(FORM_NAME), formValues => formValues);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);
