/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { reduxForm, reset, submit } from 'redux-form';
import PropTypes from 'prop-types';
import { ADD_TRAILER_FORM_NAME } from '../../constants';
import ModalFooterButtons from '../../../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ModalWindow } from '../../../../semantic-ui/components/modal-window';
import {
	getIsAddTrailerModalShownSelector,
	getIsTonnagesLoadingSelector,
	getIsTrailerSavingSelector,
	getTonnagesSelector,
	getAddTrailerFormInitialValuesSelector,
} from '../../selectors';
import { createTrailerAction, getTonnagesAction } from '../../actions';
import { removeModalQuery } from '../../../../utils/removeModalQuery';
import { validateTrailerForm } from '../../utils';
import { TrailerFormView } from './trailer-form-view';

const TrailerModalWrapper = ({
	isModalShown,
	onCreateTrailer,
	onClearForm,
	onFormSubmit,
	isTrailerSaving,
	onGetTonnages,
	tonnages,
	isTonnagesLoading,
}) => {
	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		if (isModalShown) {
			onGetTonnages();
		}
	}, [isModalShown]);

	const handleModalClose = () => {
		query.set('modal', 'car_change');

		onClearForm();
		history.push(`${pathname}?${query}`);
	};

	const handleTrailerFormSubmit = async () => {
		await onFormSubmit(ADD_TRAILER_FORM_NAME);

		const modal = query.get('modal');

		const modalToAdd = modal === 'add-trailer-to-main-modal' ? 'main' : 'car';

		onCreateTrailer({
			redirect: () => {
				query.set('modal', modal === 'add-trailer-to-main-modal' ? 'car_change' : 'add-car-modal');

				onClearForm();
				history.push(`${pathname}?${query}`);
			},
			searchQuery: removeModalQuery(query.toString()),
			modalToAdd,
		});
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleTrailerFormSubmit();
		}
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText="Новый прицеп"
			modalContent={<TrailerFormView tonnages={tonnages} isTonnagesLoading={isTonnagesLoading} />}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'add-trailer' }]}
			footerButtons={
				<ModalFooterButtons
					rightButtons={[
						{
							onClick: handleTrailerFormSubmit,
							text: 'Сохранить',
							color: 'primary',
							disabled: isTrailerSaving,
						},
						{
							onClick: handleModalClose,
							text: 'Отмена',
							color: 'secondary',
							disabled: isTrailerSaving,
						},
					]}
				/>
			}
		/>
	);
};

const withForm = reduxForm({
	form: ADD_TRAILER_FORM_NAME,
	enableReinitialize: true,
	onSubmit: validateTrailerForm,
})(TrailerModalWrapper);

const mapStateToProps = createStructuredSelector({
	isModalShown: getIsAddTrailerModalShownSelector(),
	initialValues: getAddTrailerFormInitialValuesSelector(),
	isTrailerSaving: getIsTrailerSavingSelector(),
	tonnages: getTonnagesSelector(),
	isTonnagesLoading: getIsTonnagesLoadingSelector(),
});

const mapDispatchToProps = {
	onCreateTrailer: createTrailerAction,
	onClearForm: () => reset(ADD_TRAILER_FORM_NAME),
	onFormSubmit: submit,
	onGetTonnages: getTonnagesAction,
};

const TrailerModal = connect(mapStateToProps, mapDispatchToProps)(withForm);

TrailerModalWrapper.propTypes = {
	isModalShown: PropTypes.bool.isRequired,
	onCreateTrailer: PropTypes.func.isRequired,
	onClearForm: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	isTrailerSaving: PropTypes.bool.isRequired,
	onGetTonnages: PropTypes.func.isRequired,
	isTonnagesLoading: PropTypes.bool.isRequired,
	tonnages: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
};

export { TrailerModal };
