/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { ModalWindow } from '../../../../semantic-ui/components/modal-window';
import { CompanyToggleContent } from './company-toggle-content';
import { getCompanyTypeSelector, getIsCompanyToggleOpenSelector } from '../../selectors';
import { setCompanyTypeAction, setIsRegistrationFormOpenAction, setIsCompanyToggleOpenAction } from '../../actions';
import { TOGGLE_MODAL_STYLE } from '../../constants';

const CompanyToggleWrapper = ({
	isCompanyToggleOpen,
	onSetIsCompanyToggleOpen,
	onSetIsRegistrationFormOpen,
	companyType,
	onSetCompanyType,
}) => {
	const history = useHistory();

	const handleCompanyToggleClose = () => history.push('/login');

	const handleRegistrationFormOpen = () => {
		onSetIsRegistrationFormOpen(true);
		onSetIsCompanyToggleOpen(false);
	};

	const handleCompanyToggle = value => {
		if (companyType === value) return;

		onSetCompanyType(value);
	};

	return (
		<ModalWindow
			isModalShown={isCompanyToggleOpen}
			modalContent={
				<CompanyToggleContent
					onCompanyToggleClose={handleCompanyToggleClose}
					onRegistrationFormOpen={handleRegistrationFormOpen}
					onCompanyToggle={handleCompanyToggle}
					companyType={companyType}
				/>
			}
			style={TOGGLE_MODAL_STYLE}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	isCompanyToggleOpen: getIsCompanyToggleOpenSelector(),
	companyType: getCompanyTypeSelector(),
});

const mapDispatchToProps = {
	onSetIsCompanyToggleOpen: setIsCompanyToggleOpenAction,
	onSetIsRegistrationFormOpen: setIsRegistrationFormOpenAction,
	onSetCompanyType: setCompanyTypeAction,
};

CompanyToggleWrapper.propTypes = {
	isCompanyToggleOpen: PropTypes.bool.isRequired,
	onSetIsCompanyToggleOpen: PropTypes.func.isRequired,
	onSetIsRegistrationFormOpen: PropTypes.func.isRequired,
	onSetCompanyType: PropTypes.func.isRequired,
	companyType: PropTypes.oneOf([2, 3]).isRequired,
};

const CompanyToggle = connect(mapStateToProps, mapDispatchToProps)(CompanyToggleWrapper);

export { CompanyToggle };
