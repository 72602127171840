import {
	RESET_STATE,
	SET_PARTNERS,
	SET_PARTNERS_SEARCH_VALUE,
	SET_IS_PARTNERS_LOADING,
	SET_PAGINATION,
} from './actions';

export const initialState = {
	isPartnersLoading: false,
	partners: [],
	partnersSearchValue: '',
	pagination: null,
};

const partnersReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_PARTNERS:
			return {
				...state,
				partners: payload,
			};
		case SET_IS_PARTNERS_LOADING:
			return {
				...state,
				isPartnersLoading: payload,
			};
		case SET_PARTNERS_SEARCH_VALUE:
			return {
				...state,
				partnersSearchValue: payload,
			};
		case SET_PAGINATION:
			return {
				...state,
				pagination: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default partnersReducer;
