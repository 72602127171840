import { CustomCell } from '../../components/custom-cell';
import { ToggleCell } from './components/toggle-cell';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const CONTACTS_TABLE_COLUMNS = [
	{ name: '#', id: 'id', width: '113px', minWidth: '113px', customComponent: CustomCell },
	{ name: 'ФИО', id: 'name', width: '222px', customComponent: CustomCell },
	{ name: 'Почта', id: 'email', width: '222px' },
	{ name: 'Телефон', id: 'phone', width: '148px' },
	{ name: 'Использовать по умолчанию', id: 'is_default', customComponent: ToggleCell },
];
