/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { ModalActionButtons } from '../../../../../semantic-ui/components/modal-window/modal-action-buttons';

export const CompanyToggleContent = ({
	onCompanyToggleClose,
	onRegistrationFormOpen,
	onCompanyToggle,
	companyType,
}) => {
	return (
		<div className="company-toggle-content">
			<header className="company-toggle-content__header">
				<div className="company-toggle-content__header-text">
					<h3 className="company-toggle-content__title">Регистрация</h3>
					<h5 className="company-toggle-content__text">Пожалуйста, выберите тип компании</h5>
				</div>
				<div className="company-toggle-content__close-button">
					<ModalActionButtons
						buttons={[{ type: 'close', onClick: onCompanyToggleClose, text: 'company-toggle' }]}
					/>
				</div>
			</header>
			<div className="company-toggle-content__buttons-wrapper">
				<div
					className={`${
						companyType === 2 ? 'company-toggle-content__button_active ' : ''
					}company-toggle-content__button`}
					onClick={() => onCompanyToggle(2)}
				>
					Грузоотправитель
				</div>
				<div
					className={`${
						companyType === 3 ? 'company-toggle-content__button_active ' : ''
					}company-toggle-content__button`}
					onClick={() => onCompanyToggle(3)}
				>
					Грузоперевозчик
				</div>
			</div>
			<Button className="company-toggle-content__submit-button" onClick={onRegistrationFormOpen}>
				Продолжить
			</Button>
		</div>
	);
};

CompanyToggleContent.propTypes = {
	onCompanyToggleClose: PropTypes.func.isRequired,
	onRegistrationFormOpen: PropTypes.func.isRequired,
	onCompanyToggle: PropTypes.func.isRequired,
	companyType: PropTypes.oneOf([2, 3]).isRequired,
};
