import DocumentIcon from '../../components/icons/document-icon.svg';
import LinkIcon from '../../components/icons/link-icon.svg';
import ImgIcon from '../../components/icons/image-icon.svg';
import DownloadIcon from '../../components/icons/download-icon.svg';

export const DOCUMENTS_TAB_ICONS = {
	document: DocumentIcon,
	image: ImgIcon,
	registry: LinkIcon,
	download: DownloadIcon,
};

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';
