export const GET_USER_INFO = 'users-modal/GET_USER_INFO';
export const getUserInfoAction = id => ({
	type: GET_USER_INFO,
	id,
});

export const SET_USER_INFO = 'users-modal/SET_USER_INFO';
export const setUserInfoAction = payload => ({
	type: SET_USER_INFO,
	payload,
});

export const SET_IS_USER_INFO_LOADING = 'users-modal/SET_IS_USER_INFO_LOADING';
export const setIsUserInfoLoadingAction = payload => ({
	type: SET_IS_USER_INFO_LOADING,
	payload,
});

export const DELETE_USER = 'users-modal/DELETE_USER';
export const deleteUserAction = payload => ({
	type: DELETE_USER,
	payload,
});

export const CREATE_USER = 'users-modal/CREATE_USER';
export const createUserAction = payload => ({
	type: CREATE_USER,
	payload,
});

export const UPDATE_USER = 'users-modal/UPDATE_USER';
export const updateUserAction = payload => ({
	type: UPDATE_USER,
	payload,
});

export const SET_IS_USER_SAVING = 'users-modal/SET_IS_USER_SAVING';
export const setIsUserSavingAction = payload => ({
	type: SET_IS_USER_SAVING,
	payload,
});

export const SET_IS_USER_DELETING = 'users-modal/SET_IS_USER_DELETING';
export const setIsUserDeletingAction = payload => ({
	type: SET_IS_USER_DELETING,
	payload,
});

export const RESET_STATE = 'users-modal/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const GET_COMPANIES_LIST = 'users-modal/GET_COMPANIES_LIST';
export const getCompaniesListAction = payload => ({
	type: GET_COMPANIES_LIST,
	payload,
});

export const SET_COMPANIES_LIST = 'users-modal/SET_COMPANIES_LIST';
export const setCompaniesListAction = payload => ({
	type: SET_COMPANIES_LIST,
	payload,
});

export const SET_IS_COMPANIES_LIST_LOADING = 'users-modal/SET_IS_COMPANIES_LIST_LOADING';
export const setIsCompaniesListLoadingAction = payload => ({
	type: SET_IS_COMPANIES_LIST_LOADING,
	payload,
});

export const GET_USERS_ROLES_LIST = 'users-modal/GET_USERS_ROLES_LIST';
export const getUsersRolesListAction = payload => ({
	type: GET_USERS_ROLES_LIST,
	payload,
});

export const SET_USERS_ROLES_LIST = 'users-modal/SET_USERS_ROLES_LIST';
export const setUsersRolesListAction = payload => ({
	type: SET_USERS_ROLES_LIST,
	payload,
});

export const SET_IS_USERS_ROLES_LIST_LOADING = 'users-modal/SET_IS_USERS_ROLES_LIST_LOADING';
export const setIsUsersRolesListLoadingAction = payload => ({
	type: SET_IS_USERS_ROLES_LIST_LOADING,
	payload,
});

export const GET_USERS_LIST = 'users-modal/GET_USERS_LIST';
export const getUsersListAction = payload => ({
	type: GET_USERS_LIST,
	payload,
});

export const SET_USERS_LIST_IN_MODAL = 'users-modal/SET_USERS_LIST_IN_MODAL';
export const setUsersListInModalAction = payload => ({
	type: SET_USERS_LIST_IN_MODAL,
	payload,
});

export const SET_IS_USERS_LIST_IN_MODAL_LOADING = 'users-modal/SET_IS_USERS_LIST_IN_MODAL_LOADING';
export const setIsUsersListInModalLoadingAction = payload => ({
	type: SET_IS_USERS_LIST_IN_MODAL_LOADING,
	payload,
});
