import { SubmissionError } from 'redux-form';
import { FORGOT_FORM_REQUIRED_FIELDS, LOGIN_FORM_REQUIRED_FIELDS } from './constants';

const emailValidate = (key, value) => {
	if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
		throw new SubmissionError({ [key]: 'Не валидный email' });
	}
};

export const loginValidate = values => {
	LOGIN_FORM_REQUIRED_FIELDS.forEach(key => {
		if (!values?.[key]) {
			throw new SubmissionError({ [key]: 'Заполните это поле' });
		}

		if (key === 'email') {
			emailValidate(key, values[key]);
		}
	});
};

export const forgotValidate = values => {
	FORGOT_FORM_REQUIRED_FIELDS.forEach(key => {
		if (!values?.[key]) {
			throw new SubmissionError({ [key]: 'Заполните это поле' });
		}

		if (key === 'email') {
			emailValidate(key, values[key]);
		}
	});
};

export const transformToValidationErrors = errors => {
	return Object.entries(errors).reduce((prev, [key, error]) => {
		if (!prev[key]) {
			prev[key] = {};
		}

		prev[key] = error;

		return prev;
	}, {});
};
