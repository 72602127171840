export const SET_IS_COMPANY_TOGGLE_OPEN = 'registration/SET_IS_COMPANY_TOGGLE_OPEN';
export const setIsCompanyToggleOpenAction = payload => ({
	type: SET_IS_COMPANY_TOGGLE_OPEN,
	payload,
});

export const SET_IS_REGISTRATION_FORM_OPEN = 'registration/SET_IS_REGISTRATION_FORM_OPEN';
export const setIsRegistrationFormOpenAction = payload => ({
	type: SET_IS_REGISTRATION_FORM_OPEN,
	payload,
});

export const SET_COMPANY_TYPE = 'registration/SET_COMPANY_TYPE';
export const setCompanyTypeAction = payload => ({
	type: SET_COMPANY_TYPE,
	payload,
});

export const CREATE_USER = 'registration/CREATE_USER';
export const createUserAction = payload => ({
	type: CREATE_USER,
	payload,
});

export const SET_IS_USER_CREATING = 'registration/SET_IS_USER_CREATING';
export const setIsUserCreatingAction = payload => ({
	type: SET_IS_USER_CREATING,
	payload,
});

export const GET_COMPANIES = 'registration/GET_COMPANIES';
export const getCompaniesAction = payload => ({
	type: GET_COMPANIES,
	payload,
});

export const SET_COMPANIES_SEARCH_VALUE = 'registration/SET_COMPANIES_SEARCH_VALUE';
export const setCompaniesSearchValueAction = payload => ({
	type: SET_COMPANIES_SEARCH_VALUE,
	payload,
});

export const SET_COMPANIES = 'registration/SET_COMPANIES';
export const setCompaniesAction = payload => ({
	type: SET_COMPANIES,
	payload,
});

export const SET_IS_COMPANIES_LOADING = 'registration/SET_IS_COMPANIES_LOADING';
export const setIsCompaniesLoadingAction = payload => ({
	type: SET_IS_COMPANIES_LOADING,
	payload,
});

export const RESET_STATE = 'registration/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});
