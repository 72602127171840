import { URL } from './constants';
import { getToken, makeRequest } from './utils';

export const getMessagesRequest = id =>
	makeRequest(`${URL}/api/reqs/${id}/messages`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const addNewMessageRequest = (body, id) =>
	makeRequest(`${URL}/api/reqs/${id}/messages`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});
