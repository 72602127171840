import React from 'react';
import PropTypes from 'prop-types';
import { ReqFilterForm } from '../req-filter-form';
import { ReqAnalytics } from '../req-analitics';
import { CustomAccordion } from '../../../../semantic-ui/components/accordion';
import './styles.less';

export const ReqsFilterView = ({
	accordionIndex,
	onAccordionOpen,
	onSearchButtonClick,
	onSearchAnalyticsButtonClick,
	userCompanyType,
	formOptions,
	isFilterClearButtonView,
	onFilterClearButtonClick,
	onAnalyticsFieldChange,
	analytics,
	isAnalyticsClearButtonView,
	onAnalyticsClearButtonClick,
	analyticsAccordionIndex,
	onAnalyticsAccordionOpen,
	onCityCheckboxChange,
	onKeyDown,
}) => {
	return (
		<CustomAccordion
			className="reqs-filter"
			styled
			accordionIndex={accordionIndex}
			onAccordionOpen={onAccordionOpen}
			accordeonItems={[
				{
					title: 'Фильтр',
					Component: (
						<ReqFilterForm
							onSearchButtonClick={onSearchButtonClick}
							userCompanyType={userCompanyType}
							formOptions={formOptions}
							onKeyDown={onKeyDown}
						/>
					),
					isTitleIcon: true,
					isClearButtonView: isFilterClearButtonView,
					onClearButtonClick: onFilterClearButtonClick,
				},
				{
					title: 'Аналитика',
					Component: (
						<ReqAnalytics
							onSearchAnalyticsButtonClick={onSearchAnalyticsButtonClick}
							formOptions={formOptions?.from || []}
							onAnalyticsFieldChange={onAnalyticsFieldChange}
							analytics={analytics}
							analyticsAccordionIndex={analyticsAccordionIndex}
							onAnalyticsAccordionOpen={onAnalyticsAccordionOpen}
							onCityCheckboxChange={onCityCheckboxChange}
						/>
					),
					isTitleIcon: true,
					isWithSubAccordion: analytics && analytics.length > 0,
					isClearButtonView: isAnalyticsClearButtonView,
					onClearButtonClick: onAnalyticsClearButtonClick,
				},
			]}
		/>
	);
};

ReqsFilterView.propTypes = {
	accordionIndex: PropTypes.number.isRequired,
	onAccordionOpen: PropTypes.func.isRequired,
	onSearchButtonClick: PropTypes.func.isRequired,
	onSearchAnalyticsButtonClick: PropTypes.func.isRequired,
	userCompanyType: PropTypes.string.isRequired,
	formOptions: PropTypes.objectOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			}),
		),
	),
	isFilterClearButtonView: PropTypes.bool.isRequired,
	onFilterClearButtonClick: PropTypes.func.isRequired,
	onAnalyticsFieldChange: PropTypes.func.isRequired,
	analytics: PropTypes.arrayOf(
		PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array])),
	),
	isAnalyticsClearButtonView: PropTypes.bool.isRequired,
	onAnalyticsClearButtonClick: PropTypes.func.isRequired,
	analyticsAccordionIndex: PropTypes.arrayOf(PropTypes.number).isRequired,
	onAnalyticsAccordionOpen: PropTypes.func.isRequired,
	onCityCheckboxChange: PropTypes.func.isRequired,
	onKeyDown: PropTypes.func.isRequired,
};
