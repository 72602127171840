import {
	RESET_STATE,
	SET_WAY_INFO,
	SET_IS_WAY_DELETING,
	SET_IS_WAY_INFO_LOADING,
	SET_IS_WAY_SAVING,
	SET_IS_ADDRESSES_LOADING,
	SET_ADDRESSES_SEARCH_VALUE,
	SET_ADDRESSES,
	SET_IS_MODES_LOADING,
	SET_MODES,
} from './actions';

export const initialState = {
	wayInfo: null,
	isWayInfoLoading: false,
	isWayDeleting: false,
	isWaySaving: false,
	addresses: {},
	isAddressesLoading: {},
	isModesLoading: false,
	modes: [],
	addressesSearchValue: {},
};

const waysModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_WAY_INFO:
			return {
				...state,
				wayInfo: payload,
			};
		case SET_IS_WAY_INFO_LOADING:
			return {
				...state,
				isWayInfoLoading: payload,
			};
		case SET_IS_WAY_DELETING:
			return {
				...state,
				isWayDeleting: payload,
			};
		case SET_IS_WAY_SAVING:
			return {
				...state,
				isWaySaving: payload,
			};
		case SET_ADDRESSES:
			return {
				...state,
				addresses: payload,
			};
		case SET_ADDRESSES_SEARCH_VALUE:
			return {
				...state,
				addressesSearchValue: payload,
			};
		case SET_IS_ADDRESSES_LOADING:
			return {
				...state,
				isAddressesLoading: payload,
			};
		case SET_IS_MODES_LOADING:
			return {
				...state,
				isModesLoading: payload,
			};
		case SET_MODES:
			return {
				...state,
				modes: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default waysModalReducer;
