import { SET_IS_FORM_SENDING, SET_IS_SPECIFY_FLIGHT_INFO_LOADING, SET_SPECIFY_FLIGHT_INFO } from './actions';

export const initialState = {
	flightInfo: null,
	isFlightInfoLoading: false,
	isFormSending: false,
};

const specifyFlightModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_SPECIFY_FLIGHT_INFO:
			return {
				...state,
				flightInfo: payload,
			};
		case SET_IS_SPECIFY_FLIGHT_INFO_LOADING:
			return {
				...state,
				isFlightInfoLoading: payload,
			};

		case SET_IS_FORM_SENDING:
			return {
				...state,
				isFormSending: payload,
			};
		default:
			return state;
	}
};

export default specifyFlightModalReducer;
