import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';
import { Loader } from 'semantic-ui-react';

export const AuditContentView = ({ auditData, isAuditDataLoading }) => {
	return (
		<div className="audit-content">
			{isAuditDataLoading ? (
				<Loader active size="big" inline />
			) : (
				<>
					{auditData ? (
						<div className="audit-content__table" dangerouslySetInnerHTML={{ __html: auditData }} />
					) : (
						<div className="audit-content__empty-message">Изменения не найдены</div>
					)}
				</>
			)}
		</div>
	);
};

AuditContentView.propTypes = {
	auditData: PropTypes.string,
	isAuditDataLoading: PropTypes.bool.isRequired,
};
