export const getCargoShipperTabs = id => {
	return [
		{ tab: 'my', text: 'Новые', href: `/reqs/${id}/my` },
		{ tab: 'approval', text: 'На согласовании', href: `/reqs/${id}/approval` },
		{ tab: 'work', text: 'В работе', href: `/reqs/${id}/work` },
		{ tab: 'completed', text: 'Завершенные', href: `/reqs/${id}/completed` },
	];
};

export const getCargoReqTabs = id => {
	return [
		{ tab: '', text: 'Все', href: `/reqs/${id}` },
		{ tab: 'auction', text: 'Аукцион', href: `/reqs/${id}/auction` },
		{ tab: 'approval', text: 'На согласовании', href: `/reqs/${id}/approval` },
		{ tab: 'work', text: 'В работе', href: `/reqs/${id}/work` },
		{ tab: 'completed', text: 'Завершенные', href: `/reqs/${id}/completed` },
	];
};
