import { CustomModalCell } from '../../components/custom-modal-cell';

export const PROFILE_TABLE_ROWS_NAMES = {
	company: 'Компания',
	role: 'Роль',
	post: 'Должность',
	email: 'Почта',
	phone: 'Телефон',
	isNotify: 'Уведомления',
	author: 'Автор',
};

export const FORM_NAME = 'profile-form';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const FIELDS_FOR_VALIDATE = [
	'post',
	'last_name',
	'first_name',
	'email',
	'phone',
	'password',
	'password_confirmation',
	'redirect_ids',
];

export const PROFILE_TABLE_COLUMNS_NAMES = [{ id: 'title' }, { id: 'value', customComponent: CustomModalCell }];
