import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';
import { Loader } from 'semantic-ui-react';

export const ReqsEventTabView = ({ eventData, isEventDataLoading }) => {
	return (
		<div className="reqs-event-tab">
			{isEventDataLoading ? (
				<Loader active size="big" inline />
			) : (
				<>
					{eventData ? (
						// eslint-disable-next-line react/no-danger
						<div className="reqs-event-tab__table" dangerouslySetInnerHTML={{ __html: eventData }} />
					) : (
						<div className="reqs-event-tab__empty-message">События не найдены</div>
					)}
				</>
			)}
		</div>
	);
};

ReqsEventTabView.propTypes = {
	eventData: PropTypes.string,
	isEventDataLoading: PropTypes.bool.isRequired,
};
