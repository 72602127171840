import { SET_IS_REQS_LOADING, RESET_STATE, SET_REQS, SET_PAGINATION, SET_COUNT, SET_TIMER } from './actions';

export const initialState = {
	reqs: [],
	isReqsLoading: false,
	pagination: null,
	count: null,
	timer: null,
};

const requestsListContainerReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case RESET_STATE:
			return initialState;
		case SET_REQS:
			return { ...state, reqs: payload };
		case SET_IS_REQS_LOADING:
			return { ...state, isReqsLoading: payload };
		case SET_COUNT:
			return { ...state, count: payload };
		case SET_TIMER:
			return { ...state, timer: payload };
		case SET_PAGINATION:
			return {
				...state,
				pagination: payload,
			};
		default:
			return state;
	}
};

export default requestsListContainerReducer;
