import { goToElement } from '../../../utils/goToElement';

export const transformToValidationErrors = errors => {
	goToElement(Object.keys(errors)[0]);
	return Object.entries(errors).reduce((prev, [key, error]) => {
		const section = [key.split('.')[0]];

		const field = [key.split('.')[1]];

		if (!prev[section]) {
			prev[section] = {};
		}

		prev[section][field] = error;

		return prev;
	}, {});
};

export const normalizePhone = value => {
	if (!value) {
		return value;
	}

	if (value === '+') {
		return '';
	}

	const onlyNums = value.replace(/[^\d]/g, '');

	if (onlyNums[0] !== '7' && onlyNums.length === 1) {
		return `+7${onlyNums}`;
	}

	if (onlyNums.length <= 4) {
		return `+7${onlyNums.slice(1, 4)}`;
	}

	if (onlyNums.length <= 7) {
		return `+7(${onlyNums.slice(1, 4)})${onlyNums.slice(4)}`;
	}

	if (onlyNums.length <= 9) {
		return `+7(${onlyNums.slice(1, 4)})${onlyNums.slice(4, 7)}-${onlyNums.slice(7)}`;
	}

	return `+7(${onlyNums.slice(1, 4)})${onlyNums.slice(4, 7)}-${onlyNums.slice(7, 9)}-${onlyNums.slice(9, 11)}`;
};
