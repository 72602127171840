import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';

export const Layout = ({ children }) => {
	return <div className="main-layout">{children}</div>;
};

Layout.propTypes = {
	children: PropTypes.node,
};
