import { call, debounce, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { stopSubmit, touch } from 'redux-form';
import { getCompaniesSearchValueSelector, getDataForUpdateCompanySelector } from './selectors';
import { transformToValidationErrors } from './utils';
import {
	GET_COMPANIES,
	GET_COMPANIES_TYPES,
	GET_COMPANY_INFO,
	setCompaniesAction,
	setCompaniesTypesAction,
	setCompanyInfoAction,
	setIsCompaniesLoadingAction,
	setIsCompaniesTypesLoading,
	setIsCompanyInfoLoadingAction,
	setIsCompanySavingAction,
	UPDATE_COMPANY,
} from './actions';
import {
	getCompaniesBySearchRequest,
	getCompaniesTypesDictionaryRequest,
	getCompanyRequest,
	updateCompanyRequest,
} from '../../../api/requests';
import { notificationInit } from '../../../modules/notifications/actions';
import { DISMISS_TIME } from '../../../api/constants';
import { FIELDS_FOR_VALIDATE, FORM_NAME, REQUEST_ERROR_MESSAGE } from './constants';
import { getDictionaryDataSelector } from '../companies/selectors';
import { setDictionaryDataAction } from '../companies/actions';
import { errorHandler } from '../../../api/utils';

export function* getCompanyInfoSaga({ id }) {
	try {
		yield put(setIsCompanyInfoLoadingAction(true));

		const { data, message, toast } = yield call(getCompanyRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setCompanyInfoAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsCompanyInfoLoadingAction(false));
	}
}

export function* updateCompanySaga({ payload: { id, redirect, searchQuery } }) {
	try {
		yield put(setIsCompanySavingAction(true));

		const dataForSave = yield select(getDataForUpdateCompanySelector());

		const { data, errors, message, toast } = yield call(updateCompanyRequest, id, dataForSave);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const companies = yield select(getDictionaryDataSelector());

			const updatedCompany = {
				id: data.id,
				name: data.name || '-',
				inn: data.inn || '-',
				ogrn: data.ogrn || '-',
				contact: {
					phone: data.contact?.phone || '-',
					last_name: data.contact?.last_name || '-',
					first_name: data.contact?.first_name || '-',
				},
				link: `/companies?${searchQuery ? `${searchQuery}&` : ''}modal=company&id=${data.id}`,
			};

			yield put(
				setDictionaryDataAction(
					companies.map(company => {
						return company.id === data.id ? updatedCompany : company;
					}),
				),
			);

			yield put(setCompanyInfoAction(data));
			redirect();
		} else {
			yield put(touch(FORM_NAME, ...FIELDS_FOR_VALIDATE));
			yield put(stopSubmit(FORM_NAME, transformToValidationErrors(errors)));

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsCompanySavingAction(false));
	}
}

export function* getCompaniesTypesSaga() {
	try {
		yield put(setIsCompaniesTypesLoading(true));

		const { data, message, toast } = yield call(getCompaniesTypesDictionaryRequest, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const mappedData = data.map(item => ({ value: item.id, key: item.id, text: item.name }));

			yield put(setCompaniesTypesAction([{ value: '', text: 'Нет', key: 'null' }, ...mappedData]));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsCompaniesTypesLoading(false));
	}
}

export function* getCompaniesSaga() {
	try {
		yield put(setIsCompaniesLoadingAction(true));

		const searchQuery = yield select(getCompaniesSearchValueSelector());

		const response = yield call(getCompaniesBySearchRequest, searchQuery);

		if (response?.suggestions) {
			yield put(setCompaniesAction(response.suggestions));
		} else {
			throw new Error(response?.message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsCompaniesLoadingAction(false));
	}
}

export default function* trailersContainerSaga() {
	yield takeEvery(GET_COMPANY_INFO, getCompanyInfoSaga);
	yield takeEvery(UPDATE_COMPANY, updateCompanySaga);
	yield takeEvery(GET_COMPANIES_TYPES, getCompaniesTypesSaga);
	yield debounce(300, GET_COMPANIES, getCompaniesSaga);
}
