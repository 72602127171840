export const GET_WAYS_LIST = 'ways/GET_WAYS_LIST';
export const getWaysListAction = payload => ({
	type: GET_WAYS_LIST,
	payload,
});

export const SET_WAYS_LIST = 'ways/SET_WAYS_LIST';
export const setWaysListAction = payload => ({
	type: SET_WAYS_LIST,
	payload,
});

export const SET_PAGINATION = 'ways/SET_PAGINATION';
export const setPaginationAction = payload => ({
	type: SET_PAGINATION,
	payload,
});

export const SET_IS_WAYS_LIST_LOADING = 'ways/SET_IS_WAYS_LIST_LOADING';
export const setIsWaysListLoadingAction = payload => ({
	type: SET_IS_WAYS_LIST_LOADING,
	payload,
});
export const SET_TABLE_SEARCH = 'ways/SET_TABLE_SEARCH';
export const setTableSearchAction = payload => ({
	type: SET_TABLE_SEARCH,
	payload,
});

export const RESET_STATE = 'ways/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});
