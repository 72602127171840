import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';

const ModalFooterButtons = ({ leftButtons, rightButtons }) => {
	return (
		<div className="modal-footer-buttons">
			<div className="modal-footer-buttons__left">
				{leftButtons &&
					leftButtons.map(({ text, color, onClick, disabled, loading }) => (
						<div
							key={text}
							className={`modal-footer-buttons__button modal-footer-buttons__button-${color}${
								disabled ? ' modal-footer-buttons__button-disabled' : ''
							}`}
							onClick={onClick}
						>
							<>
								{loading && <Loader active />}
								{text}
							</>
						</div>
					))}
			</div>
			<div className="modal-footer-buttons__right">
				{rightButtons &&
					rightButtons.map(({ text, color, onClick, disabled, loading }) => (
						<div
							key={text}
							className={`modal-footer-buttons__button modal-footer-buttons__button-${color}${
								disabled ? ' modal-footer-buttons__button-disabled' : ''
							}`}
							onClick={onClick}
						>
							<>
								{loading && <Loader active />}
								{text}
							</>
						</div>
					))}
			</div>
		</div>
	);
};

ModalFooterButtons.propTypes = {
	leftButtons: PropTypes.arrayOf(
		PropTypes.shape({
			color: PropTypes.oneOf(['primary', 'secondary', 'warning', 'success']),
			onClick: PropTypes.func.isRequired,
			text: PropTypes.string.isRequired,
			disabled: PropTypes.bool,
			loading: PropTypes.bool,
		}),
	),
	rightButtons: PropTypes.arrayOf(
		PropTypes.shape({
			color: PropTypes.oneOf(['primary', 'secondary', 'warning', 'success']),
			onClick: PropTypes.func.isRequired,
			text: PropTypes.string.isRequired,
			disabled: PropTypes.bool,
			loading: PropTypes.bool,
		}),
	).isRequired,
};

export default ModalFooterButtons;
