import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const selectPartnersDomain = state => state.partnersReducer || initialState;

export const getIsPartnersLoadingSelector = () =>
	createSelector(selectPartnersDomain, ({ isPartnersLoading }) => isPartnersLoading);

export const getPartnersSelector = () => createSelector(selectPartnersDomain, ({ partners }) => partners);

export const getPartnersSearchValueSelector = () =>
	createSelector(selectPartnersDomain, ({ partnersSearchValue }) => partnersSearchValue);

export const getRawPaginationSelector = () => createSelector(selectPartnersDomain, ({ pagination }) => pagination);

export const getPaginationSelector = () =>
	createSelector(selectPartnersDomain, ({ pagination }) => {
		if (!pagination || pagination?.total === 0) {
			return null;
		}

		return {
			currentPage: pagination.current_page,
			totalPages: pagination.last_page,
			perPage: pagination.per_page,
			total: pagination.total,
			from: pagination.current_page * pagination.per_page - (pagination.per_page - 1),
			to:
				pagination.current_page === pagination.last_page
					? pagination.total
					: pagination.current_page * pagination.per_page,
			isNextItem: true,
			isPrevItem: true,
			isLastItem: false,
			isFirstItem: false,
		};
	});
