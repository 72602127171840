/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import { thousandSeparator } from '../../../reqs-filter/utils';
import WeightIcon from '../../../../components/icons/weight-icon.svg';
import CountIcon from '../../../../components/icons/count-icon.svg';
import SizeIcon from '../../../../components/icons/size-icon.svg';
import LengthIcon from '../../../../components/icons/length-icon.svg';
import WidthIcon from '../../../../components/icons/width-icon.svg';
import HeightIcon from '../../../../components/icons/height-icon.svg';
import { KgIcon, VolumeIcon, PieceIcon, SizeTextIcon } from '../../../../components/text-icon';
import { StatusIndicator } from '../../../../components/status-indicator';

const ReqsCustomModalCell = ({ rowData }) => {
	if (rowData.id === 'customer') {
		return (
			<span className="reqs-custom-modal-cell__cell">
				<div className="reqs-custom-modal-cell__cell_bold">{rowData.value.name}</div>
				<div className="reqs-custom-modal-cell__cell_thin">{rowData.value.code}</div>
			</span>
		);
	}

	if (rowData.id === 'address_from' || rowData.id.includes('address_to')) {
		return (
			<div className="reqs-custom-modal-cell__address">
				<div className="reqs-custom-modal-cell__address-block">
					<div className="reqs-custom-modal-cell__address-name">{rowData.value.name}</div>
					{rowData.value?.inn && (
						<div className="reqs-custom-modal-cell__address-address">{rowData.value.inn}</div>
					)}
					<div className="reqs-custom-modal-cell__address-address">{rowData.value.address}</div>
				</div>
				<div className="reqs-custom-modal-cell__address-block">
					<div className="reqs-custom-modal-cell__address-block-item">
						<span className="reqs-custom-modal-cell__address-label">Режим работы:</span>{' '}
						{rowData.value.time}
					</div>
					<div className="reqs-custom-modal-cell__address-block-item">
						{rowData.value?.status_info?.status && (
							<div className="reqs-custom-modal-cell__address-status-indicator">
								<StatusIndicator
									status={rowData.value.status_info.status}
									description={rowData.value.status_info.description}
									position="top center"
								/>
							</div>
						)}
						<span className="reqs-custom-modal-cell__address-label">
							{rowData.id === 'address_from' ? 'Дата отгрузки:' : 'Доставить до: '}
						</span>{' '}
						{rowData.value.cargo_date}
					</div>
				</div>
			</div>
		);
	}

	if (rowData.id === 'bid') {
		return (
			<span className="reqs-custom-modal-cell__cell">
				<div className="reqs-custom-modal-cell__cell_bold">{rowData.value.count}</div>
				<div className="reqs-custom-modal-cell__cell_thin">{rowData.value.date}</div>
			</span>
		);
	}

	if (['author', 'tk', 'driver', 'car', 'trailer', 'offer_author'].includes(rowData.id)) {
		return (
			<span className="reqs-custom-modal-cell__cell">
				<div className="reqs-custom-modal-cell__cell_bold">{rowData.value.title}</div>
				<div className="reqs-custom-modal-cell__cell_thin">{rowData.value.text}</div>
			</span>
		);
	}

	if (['weight', 'volume', 'place', 'cost'].includes(rowData.id)) {
		return <span className="reqs-custom-modal-cell__cell">{thousandSeparator(rowData.value)}</span>;
	}

	if (rowData.id === 'sum' && rowData?.value?.sum) {
		return <span className="reqs-custom-modal-cell__cell">{thousandSeparator(rowData.value.sum)}</span>;
	}

	if (rowData.id === 'features') {
		return (
			<div className="reqs-custom-modal-cell__features">
				{Array.isArray(rowData.value) ? (
					rowData.value.map(feature => (
						<Popup
							key={feature.id}
							content={feature.name}
							trigger={
								<div className="reqs-custom-modal-cell__feature">
									<div
										className={`reqs-custom-modal-cell__icon reqs-custom-modal-cell__icon_${feature.icon}`}
									/>
								</div>
							}
							position="top center"
							size="mini"
							inverted
							style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
						/>
					))
				) : (
					<span className="reqs-custom-modal-cell__cell">{rowData.value}</span>
				)}
			</div>
		);
	}

	if (rowData.id.includes('cargo')) {
		return (
			<div className="reqs-custom-modal-cell__cargo">
				{rowData.value.weight !== null && (
					<div className="reqs-custom-modal-cell__cargo-item">
						<img className="reqs-custom-modal-cell__cargo-image" src={WeightIcon} alt="weight-icon" />
						<div className="reqs-custom-modal-cell__cargo-field">{rowData.value.weight}</div>
						{KgIcon}
					</div>
				)}
				{rowData.value.volume !== null && (
					<div className="reqs-custom-modal-cell__cargo-item">
						<img className="reqs-custom-modal-cell__cargo-image" src={SizeIcon} alt="weight-icon" />
						<div className="reqs-custom-modal-cell__cargo-field">{rowData.value.volume}</div>
						{VolumeIcon}
					</div>
				)}
				{rowData.value.place !== null && (
					<div className="reqs-custom-modal-cell__cargo-item">
						<img className="reqs-custom-modal-cell__cargo-image" src={CountIcon} alt="weight-icon" />
						<div className="reqs-custom-modal-cell__cargo-field">{rowData.value.place}</div>
						{PieceIcon}
					</div>
				)}
			</div>
		);
	}

	if (rowData.id.includes('doneAt')) {
		return (
			<div className="reqs-custom-modal-cell__done-at-block-item">
				{rowData.value?.doneInfo?.status && (
					<div className="reqs-custom-modal-cell__done-at-status-indicator">
						<StatusIndicator
							status={rowData.value.doneInfo.status}
							description={rowData.value.doneInfo.description}
							position="top center"
						/>
					</div>
				)}
				<span className="reqs-custom-modal-cell__done-at-label">{rowData.value.doneAt}</span>
			</div>
		);
	}

	if (rowData.id.includes('size')) {
		return (
			<div className="reqs-custom-modal-cell__size">
				<div className="reqs-custom-modal-cell__size-row">
					{rowData.value.place !== null && (
						<div className="reqs-custom-modal-cell__cargo-item">
							<img className="reqs-custom-modal-cell__cargo-image" src={CountIcon} alt="place-icon" />
							<div className="reqs-custom-modal-cell__cargo-field">{rowData.value.place}</div>
							{PieceIcon}
						</div>
					)}
					{rowData.value.weight !== null && (
						<div className="reqs-custom-modal-cell__cargo-item">
							<img className="reqs-custom-modal-cell__cargo-image" src={WeightIcon} alt="weight-icon" />
							<div className="reqs-custom-modal-cell__cargo-field">{rowData.value.weight}</div>
							{KgIcon}
						</div>
					)}
				</div>
				<div className="reqs-custom-modal-cell__size-row">
					{rowData.value.length !== null && (
						<div className="reqs-custom-modal-cell__cargo-item">
							<img className="reqs-custom-modal-cell__cargo-image" src={LengthIcon} alt="length-icon" />
							<div className="reqs-custom-modal-cell__cargo-field">{rowData.value.length}</div>
							{SizeTextIcon}
						</div>
					)}
					{rowData.value.width !== null && (
						<div className="reqs-custom-modal-cell__cargo-item">
							<img className="reqs-custom-modal-cell__cargo-image" src={WidthIcon} alt="width-icon" />
							<div className="reqs-custom-modal-cell__cargo-field">{rowData.value.width}</div>
							{SizeTextIcon}
						</div>
					)}
					{rowData.value.height !== null && (
						<div className="reqs-custom-modal-cell__cargo-item">
							<img className="reqs-custom-modal-cell__cargo-image" src={HeightIcon} alt="height-icon" />
							<div className="reqs-custom-modal-cell__cargo-field">{rowData.value.height}</div>
							{SizeTextIcon}
						</div>
					)}
				</div>
			</div>
		);
	}

	return rowData.value || '---';
};

ReqsCustomModalCell.propTypes = {
	rowData: PropTypes.object.isRequired,
	columnId: PropTypes.string.isRequired,
};

export { ReqsCustomModalCell };
