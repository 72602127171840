/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CargoDeliveryFormView } from './cargo-delivery-form-view';
import { FORM_NAME } from '../../constants';
import { getActiveReqIdSelector, getIsReqInfoLoadingSelector, getReqInfoSelector } from '../../selectors';
import { validate } from '../../utils';
import { removeModalQuery } from '../../../../utils/removeModalQuery';
import { sendCargoDeliveryFormAction } from '../../actions';

const CargoDeliveryFormWrapper = ({ reqInfo, isReqInfoLoading, onSendCargoDeliveryForm, activeReqId }) => {
	const { search, pathname } = useLocation();

	const history = useHistory();

	const query = new URLSearchParams(search);

	const handleFormSubmit = idPoint => {
		onSendCargoDeliveryForm({ activeReqId, idPoint, searchQuery: removeModalQuery(query.toString()) });
	};

	const handleDocumentAdd = id => {
		query.set('modal', 'document_attachment_form');
		query.set('pointId', id);
		history.push(`${pathname}?${query}`);
	};

	return (
		<CargoDeliveryFormView
			reqInfo={reqInfo}
			isLoading={isReqInfoLoading}
			onFormSubmit={handleFormSubmit}
			onDocumentAdd={handleDocumentAdd}
		/>
	);
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
})(CargoDeliveryFormWrapper);

const mapStateToProps = createStructuredSelector({
	reqInfo: getReqInfoSelector(),
	isReqInfoLoading: getIsReqInfoLoadingSelector(),
	activeReqId: getActiveReqIdSelector(),
});

const mapDispatchToProps = {
	onSendCargoDeliveryForm: sendCargoDeliveryFormAction,
};

const CargoDeliveryForm = connect(mapStateToProps, mapDispatchToProps)(withForm);

CargoDeliveryFormWrapper.propTypes = {
	reqInfo: PropTypes.shape({
		id: PropTypes.number.isRequired,
		company: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			inn: PropTypes.string.isRequired,
			ogrn: PropTypes.string.isRequired,
			address: PropTypes.string.isRequired,
			type: PropTypes.shape({
				id: PropTypes.number.isRequired,
				name: PropTypes.string.isRequired,
				company_count: PropTypes.number.isRequired,
			}).isRequired,
			contact: PropTypes.shape({
				id: PropTypes.number.isRequired,
				last_name: PropTypes.string.isRequired,
				first_name: PropTypes.string.isRequired,
				middle_name: PropTypes.string,
				email: PropTypes.string.isRequired,
				phone: PropTypes.string.isRequired,
				extension: PropTypes.string,
			}).isRequired,
		}).isRequired,
		points: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number.isRequired,
				company: PropTypes.string.isRequired,
				address: PropTypes.string.isRequired,
				date: PropTypes.string.isRequired,
				weight: PropTypes.number.isRequired,
				volume: PropTypes.number.isRequired,
				place: PropTypes.number.isRequired,
				done_at: PropTypes.string,
				actions: PropTypes.shape({
					setDone: PropTypes.bool.isRequired,
					setDocuments: PropTypes.bool.isRequired,
				}),
			}).isRequired,
		).isRequired,
		created_at: PropTypes.string.isRequired,
		updated_at: PropTypes.string.isRequired,
	}),
	isReqInfoLoading: PropTypes.bool.isRequired,
	onSendCargoDeliveryForm: PropTypes.func.isRequired,
	activeReqId: PropTypes.string,
};

export { CargoDeliveryForm };
