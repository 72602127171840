import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { stopSubmit, touch } from 'redux-form';
import { notificationInit } from '../../modules/notifications/actions';
import { RESET_PASSWORD_FORM_NAME, REQUEST_ERROR_MESSAGE, REQUIRED_FIELDS } from './constants';
import { transformToValidationErrors } from './utils';
import { getFormDataSelector, getFormIsInvalidSelector } from './selectors';
import { resetPasswordRequest } from '../../api/requests';
import { RESET_PASSWORD, setIsResetPasswordFormSubmittingAction } from './actions';
import { DISMISS_TIME } from '../../api/constants';
import { errorHandler } from '../../api/utils';

export function* resetPasswordSaga({ payload: { redirect, token, email } }) {
	try {
		yield put(setIsResetPasswordFormSubmittingAction(true));

		const isInvalid = yield select(getFormIsInvalidSelector());

		const formData = yield select(getFormDataSelector());

		if (isInvalid) return;

		const { message, toast, errors } = yield call(resetPasswordRequest, { ...formData, token, email });

		if (toast) {
			yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));

			redirect('/login');
		} else {
			if (errors) {
				yield put(touch(RESET_PASSWORD_FORM_NAME, ...REQUIRED_FIELDS));
				yield put(stopSubmit(RESET_PASSWORD_FORM_NAME, transformToValidationErrors(errors)));
			}
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsResetPasswordFormSubmittingAction(false));
	}
}

export default function* resetPasswordFormSaga() {
	yield takeEvery(RESET_PASSWORD, resetPasswordSaga);
}
