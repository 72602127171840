export const GET_TRAILERS_LIST = 'trailers/GET_TRAILERS_LIST';
export const getTrailersListAction = payload => ({
	type: GET_TRAILERS_LIST,
	payload,
});

export const SET_TRAILERS_LIST = 'trailers/SET_TRAILERS_LIST';
export const setTrailersListAction = payload => ({
	type: SET_TRAILERS_LIST,
	payload,
});

export const SET_PAGINATION = 'trailers/SET_PAGINATION';
export const setPaginationAction = payload => ({
	type: SET_PAGINATION,
	payload,
});

export const SET_IS_TRAILERS_LIST_LOADING = 'trailers/SET_IS_TRAILERS_LIST_LOADING';
export const setIsTrailersListLoadingAction = payload => ({
	type: SET_IS_TRAILERS_LIST_LOADING,
	payload,
});

export const SET_TABLE_SEARCH = 'trailers/SET_TABLE_SEARCH';
export const setTableSearchAction = payload => ({
	type: SET_TABLE_SEARCH,
	payload,
});

export const RESET_STATE = 'trailers/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});
