export const GET_CARS_LIST = 'cars/GET_CARS_LIST';
export const getCarsListAction = payload => ({
	type: GET_CARS_LIST,
	payload,
});

export const SET_CARS_LIST = 'cars/SET_CARS_LIST';
export const setCarsListAction = payload => ({
	type: SET_CARS_LIST,
	payload,
});

export const SET_PAGINATION = 'cars/SET_PAGINATION';
export const setPaginationAction = payload => ({
	type: SET_PAGINATION,
	payload,
});

export const SET_IS_CARS_LIST_LOADING = 'cars/SET_IS_CARS_LIST_LOADING';
export const setIsCarsListLoadingAction = payload => ({
	type: SET_IS_CARS_LIST_LOADING,
	payload,
});
export const SET_TABLE_SEARCH = 'cars/SET_TABLE_SEARCH';
export const setTableSearchAction = payload => ({
	type: SET_TABLE_SEARCH,
	payload,
});

export const RESET_STATE = 'cars/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});
