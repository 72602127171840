import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { RequestsInfoView } from './requests-info-view';
import { getIsInformationLoadingSelector, getReqInfoSelector } from '../../selectors';

const RequestsInfo = ({ reqInfo, isInformationLoading }) => {
	return <RequestsInfoView reqInfo={reqInfo} isInformationLoading={isInformationLoading} />;
};

const mapStateToProps = createStructuredSelector({
	reqInfo: getReqInfoSelector(),
	isInformationLoading: getIsInformationLoadingSelector(),
});

const RequestsInfoComponent = connect(mapStateToProps)(RequestsInfo);

RequestsInfo.propTypes = {
	reqInfo: PropTypes.shape({
		in_work: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		on_way: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		done: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}).isRequired,
	isInformationLoading: PropTypes.bool.isRequired,
};

export { RequestsInfoComponent };
