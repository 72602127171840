/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useMemo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { TABLE_COLUMNS_NAMES } from './constants';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import {
	getActiveReqIdSelector,
	getIsAuditTabShownSelector,
	getIsChatTabShownSelector,
	getIsDocsTabShownSelector,
	getIsEventTabShownSelector,
	getIsModalShownSelector,
	getIsReqDetailsLoadingSelector,
	getIsReqDocsLoadingSelector,
	getLastRowStatusSelector,
	getModalActionsSelector,
	getModalStatusesSelector,
	getReqDetailsSelector,
	getReqDocsSelector,
	getReqNameSelector,
	getReqTabsSelector,
} from './selectors';
import { getEventDataAction, getReqDetailsAction, getReqDocsAction, resetStateAction } from './actions';
import { removeModalQuery } from '../../utils/removeModalQuery';
import { BUTTONS_TO_MODALS, BUTTONS_TO_ROUTES } from '../reqs-list/constants';
import { deleteReqAction, takeReqAction, updateReqAction } from '../reqs-list/actions';
import { setWindowInfoAction } from '../alert-modal/actions';
import { Audit } from '../../components/audit';
import { ReqsDocumentsTab } from './components/reqs-documents-tab';
import ChatModal from '../chat-modal';
import { ReqsEventTab } from './components/reqs-event-tab';
import { ReqTableComponent } from './components/req-table';

const ReqsDetailsModalWrapper = ({
	onGetReqDetails,
	isReqDetailsLoading,
	reqDetails,
	reqName,
	onResetState,
	isModalShown,
	activeReqId,
	modalStatuses,
	onReqTake,
	modalActions,
	onDeleteReq,
	onUpdateReq,
	onSetWindowInfo,
	lastRowStatus,
	isAuditTabShown,
	isDocsTabShown,
	isChatTabShown,
	isEventTabShown,
	reqDocs,
	isReqDocsLoading,
	onGetReqDocs,
	onGetEventData,
	reqTabs,
}) => {
	useInjectSaga({ key: 'reqsDetailsModalSaga', saga });
	useInjectReducer({ key: 'reqsDetailsModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	const modal = query.get('modal');

	const mode = query.get('mode');

	const tab = query.get('tab');

	useEffect(() => {
		if (activeReqId) onGetReqDetails({ id: activeReqId, searchQuery: query.toString() });
	}, [activeReqId]);

	useEffect(() => {
		if (isDocsTabShown) onGetReqDocs(activeReqId);
	}, [isDocsTabShown]);

	useEffect(() => {
		if (isEventTabShown) onGetEventData(activeReqId);
	}, [isEventTabShown]);

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('mode');
		query.delete('id');
		query.delete('tab');

		onResetState();
		history.push(`${pathname}?${query}`);
	};

	const handleRequestUpdate = (field, path) => {
		onUpdateReq({
			field: BUTTONS_TO_ROUTES[field] ? BUTTONS_TO_ROUTES[field] : field,
			id: activeReqId,
			searchQuery: removeModalQuery(query.toString()),
			redirect: handleModalClose,
			pathname: path,
		});
	};

	const handleButtonClick = field => {
		const path = pathname.split('/').slice(0, 3).join('/');

		if (BUTTONS_TO_MODALS[field]) {
			query.set('modal', BUTTONS_TO_MODALS[field]);
			query.set('id', activeReqId);

			if (field === 'recreate') {
				query.set('mode', 'recreate');
			}

			history.push(`${pathname}?${query}`);
			return;
		}

		if (field === 'destroy') {
			const path = pathname.split('/').slice(0, 3).join('/');

			onSetWindowInfo({
				type: 'delete',
				title: 'Вы уверены?',
				text: 'Вы не сможете это отменить',
				button: {
					type: 'success',
					text: 'Да, удалить',
					onClick: () => onDeleteReq({ id: activeReqId, redirect: handleModalClose, pathname: path }),
				},
			});
			return;
		}

		if (['close', 'revoke'].includes(field)) {
			onSetWindowInfo({
				type: 'delete',
				title: 'Вы уверены?',
				text: 'Вы не сможете это отменить',
				button: {
					type: 'success',
					text: field === 'revoke' ? 'Да, отозвать' : 'Да, аннулировать',
					onClick: () => handleRequestUpdate(field, path),
				},
			});
			return;
		}

		if (field === 'take') {
			onReqTake({ id: activeReqId, searchQuery: removeModalQuery(query.toString()), redirect: handleModalClose });
			return;
		}

		handleRequestUpdate(field, path);
	};

	const handleGetLeftButtons = () => {
		return tab
			? []
			: modalActions.left.map(button => ({
					...button,
					disabled: isReqDetailsLoading,
					onClick: () => handleButtonClick(button.key),
			  }));
	};

	const handleGetRightButtons = () => {
		return [
			...(!tab
				? modalActions.right.map(button => ({
						...button,
						disabled: isReqDetailsLoading,
						onClick: () => handleButtonClick(button.key),
				  }))
				: []),
			{
				onClick: handleModalClose,
				text: 'Закрыть',
				color: 'secondary',
			},
		];
	};

	const handleNavItemClick = modal => {
		if (modal === 'reqs_details') {
			query.delete('tab');
		}

		if (modal === 'audit') {
			query.set('tab', 'audit');
		}

		if (modal === 'docs') {
			query.set('tab', 'docs');
		}

		if (modal === 'chat') {
			query.set('tab', 'chat');
		}

		if (modal === 'event') {
			query.set('tab', 'event');
		}

		history.push(`${pathname}?${query}`);
	};

	const modalTabContent = useMemo(() => {
		if (isAuditTabShown) {
			return <Audit entityName="reqs" entityId={activeReqId} />;
		}

		if (isDocsTabShown) {
			return <ReqsDocumentsTab documentsData={reqDocs} isDocumentsLoading={isReqDocsLoading} />;
		}

		if (isChatTabShown) {
			return <ChatModal />;
		}

		if (isEventTabShown) {
			return <ReqsEventTab />;
		}

		return null;
	}, [tab, isAuditTabShown, isDocsTabShown, isChatTabShown, isEventTabShown, reqDocs, isReqDocsLoading]);

	const tabs = useMemo(() => {
		const tabs = [
			{
				id: 'info',
				text: 'Информация',
				onClick: () => handleNavItemClick('reqs_details'),
				isActive: modal === 'reqs_details' && !mode && !tab,
			},
		];

		if (reqTabs.documents) {
			tabs.push({
				id: 'docs',
				text: 'Документы',
				onClick: () => handleNavItemClick('docs'),
				isActive: isDocsTabShown && !mode,
			});
		}

		if (reqTabs.messages) {
			tabs.push({
				id: 'chat',
				text: 'Чат',
				onClick: () => handleNavItemClick('chat'),
				isActive: isChatTabShown && !mode,
			});
		}

		if (reqTabs.audit) {
			tabs.push({
				id: 'audit',
				text: 'Изменения',
				onClick: () => handleNavItemClick('audit'),
				isActive: isAuditTabShown && !mode,
			});
		}

		if (reqTabs.event) {
			tabs.push({
				id: 'event',
				text: 'События',
				onClick: () => handleNavItemClick('event'),
				isActive: isEventTabShown && !mode,
			});
		}

		return tabs;
	});

	return (
		<ModalWindow
			isModalShown={isModalShown}
			headerText={reqName}
			navItems={tabs}
			modalContent={
				!tab ? (
					<ReqTableComponent
						rows={reqDetails}
						isTableDataLoading={isReqDetailsLoading}
						columns={TABLE_COLUMNS_NAMES}
						lastRowStatus={lastRowStatus}
					/>
				) : (
					modalTabContent
				)
			}
			headerStatuses={modalStatuses}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'reqs_details' }]}
			footerButtons={
				<ModalFooterButtons
					leftButtons={handleGetLeftButtons()}
					rightButtons={handleGetRightButtons()}
					isAuditTabShown={isAuditTabShown}
				/>
			}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	reqDetails: getReqDetailsSelector(),
	isReqDetailsLoading: getIsReqDetailsLoadingSelector(),
	reqName: getReqNameSelector(),
	isModalShown: getIsModalShownSelector(),
	activeReqId: getActiveReqIdSelector(),
	modalStatuses: getModalStatusesSelector(),
	modalActions: getModalActionsSelector(),
	lastRowStatus: getLastRowStatusSelector(),
	isAuditTabShown: getIsAuditTabShownSelector(),
	isDocsTabShown: getIsDocsTabShownSelector(),
	isChatTabShown: getIsChatTabShownSelector(),
	isEventTabShown: getIsEventTabShownSelector(),
	reqDocs: getReqDocsSelector(),
	isReqDocsLoading: getIsReqDocsLoadingSelector(),
	reqTabs: getReqTabsSelector(),
});

const mapDispatchToProps = {
	onGetReqDetails: getReqDetailsAction,
	onResetState: resetStateAction,
	onReqTake: takeReqAction,
	onDeleteReq: deleteReqAction,
	onUpdateReq: updateReqAction,
	onSetWindowInfo: setWindowInfoAction,
	onGetReqDocs: getReqDocsAction,
	onGetEventData: getEventDataAction,
};

const ReqsDetailsModal = connect(mapStateToProps, mapDispatchToProps)(ReqsDetailsModalWrapper);

ReqsDetailsModalWrapper.propTypes = {
	onGetReqDetails: PropTypes.func.isRequired,
	reqDetails: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.shape({ group: PropTypes.string }),
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				value: PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.shape({
						code: PropTypes.string,
						name: PropTypes.string,
						sub_value: PropTypes.shape({ code: PropTypes.string, name: PropTypes.string }),
					}),
				]).isRequired,
			}),
		]),
	),
	isReqDetailsLoading: PropTypes.bool.isRequired,
	reqName: PropTypes.string.isRequired,
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	activeReqId: PropTypes.string,
	modalStatuses: PropTypes.arrayOf(PropTypes.shape({ color: PropTypes.string, name: PropTypes.string })).isRequired,
	onReqTake: PropTypes.func.isRequired,
	modalActions: PropTypes.shape({
		left: PropTypes.arrayOf(
			PropTypes.shape({
				color: PropTypes.oneOf(['primary', 'secondary', 'warning', 'success']),
				text: PropTypes.string.isRequired,
				key: PropTypes.string.isRequired,
			}),
		),
		right: PropTypes.arrayOf(
			PropTypes.shape({
				color: PropTypes.oneOf(['primary', 'secondary', 'warning', 'success']),
				text: PropTypes.string.isRequired,
				key: PropTypes.string.isRequired,
			}),
		).isRequired,
	}).isRequired,
	onDeleteReq: PropTypes.func.isRequired,
	onUpdateReq: PropTypes.func.isRequired,
	onSetWindowInfo: PropTypes.func.isRequired,
	lastRowStatus: PropTypes.string.isRequired,
	isAuditTabShown: PropTypes.bool.isRequired,
	isDocsTabShown: PropTypes.bool.isRequired,
	isChatTabShown: PropTypes.bool.isRequired,
	isEventTabShown: PropTypes.bool.isRequired,
	reqDocs: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			documents: PropTypes.arrayOf(
				PropTypes.shape({
					name: PropTypes.string.isRequired,
					link: PropTypes.string,
					icon: PropTypes.string.isRequired,
					signed: PropTypes.string,
					generated: PropTypes.string,
					attached: PropTypes.string,
				}),
			).isRequired,
		}),
	).isRequired,
	isReqDocsLoading: PropTypes.bool.isRequired,
	onGetReqDocs: PropTypes.func.isRequired,
	onGetEventData: PropTypes.func.isRequired,
	reqTabs: PropTypes.shape({
		audit: PropTypes.bool,
		documents: PropTypes.bool,
		messages: PropTypes.bool,
		event: PropTypes.bool,
	}).isRequired,
};

export default ReqsDetailsModal;
