import { createSelector } from 'reselect';
import { getFormValues, isInvalid } from 'redux-form';
import { initialState } from './reducer';
import { REGISTRATION_FORM_NAME } from './constants';

export const selectRegistrationDomain = state => state.registrationReducer || initialState;

export const getIsCompanyToggleOpenSelector = () =>
	createSelector(selectRegistrationDomain, ({ isCompanyToggleOpen }) => isCompanyToggleOpen);

export const getIsRegistrationFormOpenSelector = () =>
	createSelector(selectRegistrationDomain, ({ isRegistrationFormOpen }) => isRegistrationFormOpen);

export const getIsUserCreatingSelector = () =>
	createSelector(selectRegistrationDomain, ({ isUserCreating }) => isUserCreating);

export const getCompanyTypeSelector = () => createSelector(selectRegistrationDomain, ({ companyType }) => companyType);

export const getIsCompaniesLoadingSelector = () =>
	createSelector(selectRegistrationDomain, ({ isCompaniesLoading }) => isCompaniesLoading);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(REGISTRATION_FORM_NAME), isInvalid => isInvalid);

export const getCompaniesSelector = () => createSelector(selectRegistrationDomain, ({ companies }) => companies);

export const getCompaniesSearchValueSelector = () =>
	createSelector(selectRegistrationDomain, ({ companiesSearchValue }) => companiesSearchValue);

export const getCompaniesOptionsSelector = () =>
	createSelector(selectRegistrationDomain, ({ companies }) =>
		companies.map(company => ({
			key: `${company.data.inn}${company.data.kpp}`,
			value: company.data.inn,
			data: {
				title: company.value,
				address: `${company.data?.kpp || ''} ${company.data?.address?.value || ''}`,
				deleted: company.data?.state?.status === 'LIQUIDATED',
			},
		})),
	);

export const getFormDataSelector = () =>
	createSelector(selectRegistrationDomain, getFormValues(REGISTRATION_FORM_NAME), ({ companyType }, formValues) => {
		if (!formValues) {
			return {};
		}

		return {
			...formValues,
			company: { ...formValues.company, type_id: companyType },
			user: { ...formValues.user, role_id: 1 },
			contact: formValues.contact,
		};
	});
