import React from 'react';
import PropTypes from 'prop-types';
import { thousandSeparator } from '../../../../utils';
import './styles.less';
import FormCheckbox from '../../../../../../semantic-ui/components/form-checkbox';

export const CityItem = ({ item, isSubItem, onCityCheckboxChange, onAccordionOpen }) => {
	return (
		<div className={`city-item${isSubItem ? ' city-item__sub' : ''}`}>
			<div className="city-item__header" onClick={onAccordionOpen}>
				<FormCheckbox type="checkbox" onChange={onCityCheckboxChange} checked={item.isChecked} />
				{item.name}
			</div>
			<div className="city-item__info">
				<span>{item.count}</span>заявок
				<span>{thousandSeparator(item.weight)}</span>кг
				<span>{thousandSeparator(item.volume)}</span>м3
				{item.sum && (
					<>
						<span>{thousandSeparator(item.sum)}</span>₽
					</>
				)}
			</div>
		</div>
	);
};

CityItem.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		volume: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		sum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		isChecked: PropTypes.bool,
	}),
	isSubItem: PropTypes.bool,
	onCityCheckboxChange: PropTypes.func,
	onAccordionOpen: PropTypes.func.isRequired,
};
