import { SET_FOUND_REQS, SET_SEARCH_VALUE } from './actions';

export const initialState = {
	foundReqs: [],
	searchValue: '',
};

const headerSearchReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_FOUND_REQS:
			return { ...state, foundReqs: payload };
		case SET_SEARCH_VALUE:
			return { ...state, searchValue: payload };
		default:
			return state;
	}
};

export default headerSearchReducer;
