import React from 'react';
import PropTypes from 'prop-types';
import { CompanyForm } from '../company-form';
import { SimpleTableComponent } from '../../../../../components/simple-table';
import { TABLE_COLUMNS_NAMES } from '../../constants';

export const CompanyContent = ({
	companyInfo,
	isCompanyInfoLoading,
	isCompanyFormOpen,
	isCompaniesTypesLoading,
	companiesTypes,
	companiesOptions,
	onCompanySearchChange,
	onCompanyChange,
	companiesSearchValue,
	isCompaniesLoading,
	isProfileCompanyForm,
}) => {
	return isCompanyFormOpen ? (
		<CompanyForm
			isCompaniesTypesLoading={isCompaniesTypesLoading}
			companiesTypes={companiesTypes}
			isCompanyInfoLoading={isCompanyInfoLoading}
			companiesOptions={companiesOptions}
			onCompanySearchChange={onCompanySearchChange}
			onCompanyChange={onCompanyChange}
			companiesSearchValue={companiesSearchValue}
			isCompaniesLoading={isCompaniesLoading}
			isProfileCompanyForm={isProfileCompanyForm}
		/>
	) : (
		<SimpleTableComponent
			rows={companyInfo}
			isTableDataLoading={isCompanyInfoLoading}
			columns={TABLE_COLUMNS_NAMES}
		/>
	);
};

CompanyContent.propTypes = {
	companyInfo: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]).isRequired,
		}),
	),
	isCompanyInfoLoading: PropTypes.bool.isRequired,
	isCompanyFormOpen: PropTypes.bool.isRequired,
	isCompaniesTypesLoading: PropTypes.bool.isRequired,
	companiesTypes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
	companiesOptions: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string,
			data: PropTypes.shape({
				title: PropTypes.string.isRequired,
				address: PropTypes.string.isRequired,
				deleted: PropTypes.bool,
			}),
		}),
	).isRequired,
	isCompaniesLoading: PropTypes.bool.isRequired,
	onCompanySearchChange: PropTypes.func.isRequired,
	onCompanyChange: PropTypes.func.isRequired,
	companiesSearchValue: PropTypes.string.isRequired,
	isProfileCompanyForm: PropTypes.bool.isRequired,
};
