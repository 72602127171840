import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { ModalActionButtons } from '../../../../../semantic-ui/components/modal-window/modal-action-buttons';

const NotificationsListView = ({ onCloseNotification, notifications, isNotificationsListLoading, onLinkClick }) => {
	return (
		<ul className="notifications-list">
			{isNotificationsListLoading ? (
				<Loader active />
			) : (
				<>
					{notifications.length === 0 && (
						<div className="notifications-list__empty-list-message">Уведомлений нет</div>
					)}
					{notifications.length > 0 &&
						notifications.map(notification => (
							<div key={notification.id} className="notifications-list__item">
								<div className="notifications-list__content">
									<div className="notifications-list__content-header">
										<div
											className={`notifications-list__status notifications-list__status_${notification.type}`}
										/>
										<div
											className="notifications-list__link"
											onClick={() => onLinkClick(notification.link, notification.id)}
										>
											{notification.title}
										</div>
									</div>
									<p className="notifications-list__message">{notification.message}</p>
								</div>
								<div className="notifications-list__close-button">
									<ModalActionButtons
										buttons={[
											{
												type: 'close',
												onClick: () => onCloseNotification(notification.id),
												text: notification.id,
											},
										]}
									/>
								</div>
							</div>
						))}
				</>
			)}
		</ul>
	);
};

NotificationsListView.propTypes = {
	onCloseNotification: PropTypes.func.isRequired,
	notifications: PropTypes.array.isRequired,
	isNotificationsListLoading: PropTypes.bool.isRequired,
	onLinkClick: PropTypes.func.isRequired,
};

export { NotificationsListView };
