import Echo from 'laravel-echo';
import 'pusher-js';

import { eventChannel } from 'redux-saga';
import { getToken } from './utils';

export const createNotificationsChannels = userId => {
	const subscribeNotifications = emitter => {
		window.Echo.private(`users.${userId}`).notification(notification => {
			emitter(notification);
		});

		return () => {
			window.Echo.leaveChannel(`private-users.${userId}`);
		};
	};

	return eventChannel(subscribeNotifications);
};

export const createReqUpdateChannels = companyId => {
	const subscribeReqUpdate = emitter => {
		window.Echo.join(`companies.${companyId}`).listen('.update-req', data => {
			emitter(data);
		});

		return () => {
			window.Echo.leaveChannel(`presence-companies.${companyId}`);
		};
	};

	return eventChannel(subscribeReqUpdate);
};

export const createReqsUpdateChannels = companyId => {
	const subscribeReqsUpdate = emitter => {
		window.Echo.join(`companies.${companyId}`).listen('.update-reqs', data => {
			emitter(data);
		});

		return () => {
			window.Echo.leaveChannel(`presence-companies.${companyId}`);
		};
	};

	return eventChannel(subscribeReqsUpdate);
};

export const createReqTableUpdateChannels = companyId => {
	const subscribeReqTableUpdate = emitter => {
		window.Echo.join(`companies.${companyId}`).listen('.update-req-table', data => {
			emitter(data);
		});

		return () => {
			window.Echo.leaveChannel(`presence-companies.${companyId}`);
		};
	};

	return eventChannel(subscribeReqTableUpdate);
};

export const createReqBadgesUpdateChannels = companyId => {
	const subscribeReqBadgesUpdate = emitter => {
		window.Echo.join(`companies.${companyId}`).listen('.update-req-badges', data => {
			emitter(data);
		});

		return () => {
			window.Echo.leaveChannel(`presence-companies.${companyId}`);
		};
	};

	return eventChannel(subscribeReqBadgesUpdate);
};

export const createChatBadgeUpdateChannels = companyId => {
	const subscribeChatBadgeUpdate = emitter => {
		window.Echo.join(`companies.${companyId}`).listen('.update-chat-badge', data => {
			emitter(data);
		});

		return () => {
			window.Echo.leaveChannel(`presence-companies.${companyId}`);
		};
	};

	return eventChannel(subscribeChatBadgeUpdate);
};

export const createReqMessageCreateChannels = companyId => {
	const subscribeReqMessageCreate = emitter => {
		window.Echo.join(`companies.${companyId}`).listen('.create-req-message', data => {
			emitter(data);
		});

		return () => {
			window.Echo.leaveChannel(`presence-companies.${companyId}`);
		};
	};

	return eventChannel(subscribeReqMessageCreate);
};

export const createReqMessagesUpdateChannels = userId => {
	const subscribeReqMessagesUpdate = emitter => {
		window.Echo.join(`users.${userId}`).listen('.update-req-messages', data => {
			emitter(data);
		});

		return () => {
			window.Echo.leaveChannel(`presence-users.${userId}`);
		};
	};

	return eventChannel(subscribeReqMessagesUpdate);
};

export const createTimerUpdateChannels = companyId => {
	const subscribeTimerUpdate = emitter => {
		window.Echo.join(`companies.${companyId}`).listen('.update-timer', data => {
			emitter(data);
		});

		return () => {
			window.Echo.leaveChannel(`presence-companies.${companyId}`);
		};
	};

	return eventChannel(subscribeTimerUpdate);
};

export const createSocketConnection = () => {
	window.Echo = new Echo({
		broadcaster: process.env.REACT_APP_WS_BROADCASTER,
		key: process.env.REACT_APP_WS_PUSHER_KEY,
		cluster: process.env.REACT_APP_WS_CLUSTER,
		wsHost: process.env.REACT_APP_WS_HOST,
		wsPort: process.env.REACT_APP_WS_PORT,
		forceTLS: Boolean(process.env.REACT_APP_WS_FORCE_TLS),
		enabledTransports: ['ws'],
		auth: {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		},
		authEndpoint: process.env.REACT_APP_WS_ENDPOINT,
	});
};
