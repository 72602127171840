/* eslint-disable react/jsx-props-no-spreading,jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.less';
import { Icon } from 'semantic-ui-react';
import { FormInputField } from '../form-input-field';

const FormSelect = ({
	label,
	loading,
	icon,
	options,
	noResultsMessage,
	onSearchChange,
	searchValue,
	onItemChange,
	meta: { error, touched } = {},
	...restProps
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const inputRef = useRef();

	const handleOutsideClick = event => {
		if (inputRef.current && !inputRef.current.contains(event.target)) {
			setIsModalOpen(false);
		}
	};

	useEffect(() => {
		document.body.addEventListener('mousedown', handleOutsideClick);
		return () => {
			document.body.removeEventListener('mousedown', handleOutsideClick);
		};
	}, []);

	const handleFocus = () => {
		setIsModalOpen(true);
	};

	const handleChange = value => {
		if (onItemChange) {
			onItemChange(value);
			setIsModalOpen(false);
		}
	};

	return (
		<div ref={inputRef} className={`${icon ? 'form-select_with-icon ' : ''}form-select`}>
			{label && <label htmlFor={label}>{label}</label>}

			<div className="form-select__content">
				<div className={`${icon ? 'form-select__input_with-icon ' : ''}form-select__input`}>
					<FormInputField
						loading={loading}
						{...{
							...restProps,
							input: {
								...restProps.input,
								onFocus: handleFocus,
								onChange: onSearchChange,
								value: searchValue,
							},
							meta: { error, touched },
						}}
					/>
				</div>

				<div className={`${icon ? 'form-select__menu_with-icon ' : ''}form-select__menu`}>
					{isModalOpen && !options?.length && (
						<div className="form-select__menu-item">{noResultsMessage}</div>
					)}
					{isModalOpen && options?.length > 0 && (
						<>
							<div className="form-select__menu-item">Выберите вариант или продолжите ввод</div>
							{options.map(({ key, value, text, data }) => {
								if (data) {
									return (
										<div
											key={key}
											onClick={() => handleChange(value)}
											className="form-select__menu-item"
										>
											<span
												className={`${data.deleted ? 'form-select__item-title_deleted ' : ''}${
													data.isBold ? 'form-select__item-title_bold ' : ''
												}form-select__item-title`}
											>
												{data.title}
											</span>
											<span className="form-select__address">{data.address}</span>
										</div>
									);
								}

								return (
									<div
										key={key}
										onClick={() => handleChange(value)}
										className="form-select__menu-item"
									>
										{text}
									</div>
								);
							})}
						</>
					)}
				</div>

				{icon && (
					<span className="form-select__icon">
						<Icon name={icon} />
					</span>
				)}
			</div>
		</div>
	);
};

FormSelect.propTypes = {
	meta: PropTypes.object,
	loading: PropTypes.bool,
	icon: PropTypes.string,
	label: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string,
			data: PropTypes.shape({
				title: PropTypes.string.isRequired,
				address: PropTypes.string.isRequired,
				deleted: PropTypes.bool,
				isBold: PropTypes.bool,
			}),
		}),
	),
	noResultsMessage: PropTypes.string,
	onSearchChange: PropTypes.func,
	searchValue: PropTypes.string,
	onItemChange: PropTypes.func,
};

export { FormSelect };
