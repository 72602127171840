import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';

const AlertModalView = ({ title, text, buttons, type }) => {
	return (
		<div className="alert-modal">
			<div className={`alert-modal__img alert-modal__img_${type}`}>
				{type === 'warning' ? (
					<span className="alert-modal__icon alert-modal__icon_warning">!</span>
				) : (
					<div className="alert-modal__icon-wrapper">
						<div className={`alert-modal__icon alert-modal__icon_left alert-modal__icon_${type}`} />
						<div className={`alert-modal__icon alert-modal__icon_right alert-modal__icon_${type}`} />
					</div>
				)}
			</div>
			<h2 className="alert-modal__title">{title}</h2>
			<span className="alert-modal__text">{text}</span>
			<div className="alert-modal__buttons-wrapper">
				{buttons.map(({ type, text, onClick }) => (
					<button
						// eslint-disable-next-line jsx-a11y/no-autofocus
						autoFocus={type === 'success'}
						onClick={onClick}
						key={text}
						type="button"
						className={`alert-modal__button alert-modal__button_${type}`}
					>
						{text}
					</button>
				))}
			</div>
		</div>
	);
};

AlertModalView.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	buttons: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
			onClick: PropTypes.func.isRequired,
		}),
	).isRequired,
	type: PropTypes.string.isRequired,
};

export { AlertModalView };
