import { RESET_STATE, SET_USERS_LIST, SET_IS_USERS_LIST_LOADING, SET_PAGINATION, SET_TABLE_SEARCH } from './actions';

export const initialState = {
	usersList: null,
	isUsersListLoading: false,
	pagination: null,
	tableSearch: '',
};

const usersContainerReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_USERS_LIST:
			return {
				...state,
				usersList: payload,
			};
		case SET_IS_USERS_LIST_LOADING:
			return {
				...state,
				isUsersListLoading: payload,
			};
		case SET_PAGINATION:
			return {
				...state,
				pagination: payload,
			};
		case SET_TABLE_SEARCH:
			return {
				...state,
				tableSearch: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default usersContainerReducer;
