import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AuditContentView } from './components/audit-content';
import { getAuditDataAction, resetStateAction } from './actions';
import { getAuditDataSelector, getIsAuditDataLoadingSelector } from './selectors';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import saga from './saga';

const AuditContentWrapper = ({ entityName, entityId, auditData, isAuditDataLoading, onGetAuditData, onResetState }) => {
	useInjectSaga({ key: 'auditComponentSaga', saga });
	useInjectReducer({ key: 'auditComponentReducer', reducer });

	useEffect(() => {
		onGetAuditData({ entityName, entityId });

		return () => {
			onResetState();
		};
	}, []);

	return <AuditContentView auditData={auditData} isAuditDataLoading={isAuditDataLoading} />;
};

const mapStateToProps = createStructuredSelector({
	auditData: getAuditDataSelector(),
	isAuditDataLoading: getIsAuditDataLoadingSelector(),
});

const mapDispatchToProps = {
	onGetAuditData: getAuditDataAction,
	onResetState: resetStateAction,
};

AuditContentWrapper.propTypes = {
	auditData: PropTypes.string,
	isAuditDataLoading: PropTypes.bool.isRequired,
	onGetAuditData: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	entityName: PropTypes.string.isRequired,
	entityId: PropTypes.string.isRequired,
};

const Audit = connect(mapStateToProps, mapDispatchToProps)(AuditContentWrapper);

export { Audit };
