/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, submit } from 'redux-form';
import { useHistory, useParams } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { ResetPasswordView } from './components/reset-password-view';
import { RESET_PASSWORD_MODAL_STYLE, RESET_PASSWORD_FORM_NAME } from './constants';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { resetPasswordAction } from './actions';
import { validate } from './utils';
import { getIsResetPasswordFormSubmittingSelector } from './selectors';

export const ResetPasswordWrapper = ({ onFormSubmit, onResetPassword, isResetPasswordFormSubmitting }) => {
	useInjectSaga({ key: 'resetPasswordFormSaga', saga });
	useInjectReducer({ key: 'resetPasswordReducer', reducer });

	const history = useHistory();

	const { token } = useParams();

	const query = useMemo(() => new URLSearchParams(history.location.search), []);

	const handleConfirmButtonClick = async () => {
		await onFormSubmit(RESET_PASSWORD_FORM_NAME);
		onResetPassword({ redirect: history.push, token, email: query.get('email') });
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleConfirmButtonClick();
		}
	};

	useEffect(() => {
		if (!token || !query.get('email')) {
			history.push('/login');
		}
	});

	return (
		<ModalWindow
			isModalShown
			onKeyDown={handleKeyDown}
			modalContent={
				<ResetPasswordView
					onConfirmButtonClick={handleConfirmButtonClick}
					isResetPasswordFormSubmitting={isResetPasswordFormSubmitting}
				/>
			}
			style={RESET_PASSWORD_MODAL_STYLE}
		/>
	);
};

const withForm = reduxForm({
	form: RESET_PASSWORD_FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
})(ResetPasswordWrapper);

const mapStateToProps = createStructuredSelector({
	isResetPasswordFormSubmitting: getIsResetPasswordFormSubmittingSelector(),
});

const mapDispatchToProps = {
	onFormSubmit: submit,
	onResetPassword: resetPasswordAction,
};

const ResetPassword = connect(mapStateToProps, mapDispatchToProps)(withForm);

ResetPasswordWrapper.propTypes = {
	onFormSubmit: PropTypes.func.isRequired,
	onResetPassword: PropTypes.func.isRequired,
	isResetPasswordFormSubmitting: PropTypes.bool.isRequired,
};

export default ResetPassword;
