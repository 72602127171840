import { RESET_STATE, SET_FORM_INFO } from './actions';

export const initialState = {
	emailVerifyInfo: {},
};

const emailVerifyContainerReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_FORM_INFO:
			return {
				...state,
				emailVerifyInfo: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default emailVerifyContainerReducer;
