import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { change, stopSubmit, touch } from 'redux-form';
import { notificationInit } from '../../modules/notifications/actions';
import {
	DELETE_TRAILER,
	GET_TONNAGES,
	GET_TRAILER_INFO,
	SAVE_TRAILER,
	setIsTonnagesLoadingAction,
	setIsTrailerDeletingAction,
	setIsTrailerInfoLoadingAction,
	setIsTrailerSavingAction,
	setTonnagesAction,
	setTrailerInfoAction,
	UPDATE_TRAILER,
} from './actions';
import {
	deleteTrailerRequest,
	getTonnagesDictionaryRequest,
	getTrailerInfoRequest,
	saveNewTrailerRequest,
	updateTrailerRequest,
} from '../../api/requests';
import { CAR_FORM_NAME, FORM_FIELDS, FORM_NAME, REQUEST_ERROR_MESSAGE } from './constants';
import { getDataForSaveTrailerSelector, getFormIsInvalidSelector } from './selectors';
import { transformToValidationErrors } from './utils';
import { DISMISS_TIME } from '../../api/constants';
import { getRawPaginationSelector, getTrailersListSelector } from '../trailers/selectors';
import { setPaginationAction, setTrailersListAction } from '../trailers/actions';
import { errorHandler } from '../../api/utils';

export function* getTrailerInfoSaga({ id }) {
	try {
		yield put(setIsTrailerInfoLoadingAction(true));

		const { data, message, toast } = yield call(getTrailerInfoRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setTrailerInfoAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsTrailerInfoLoadingAction(false));
	}
}

export function* deleteTrailerSaga({ payload: { id, redirect } }) {
	try {
		yield put(setIsTrailerDeletingAction(true));

		const { data, message, toast } = yield call(deleteTrailerRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const trailers = yield select(getTrailersListSelector());
			yield put(setTrailersListAction(trailers.filter(({ id }) => id !== data.id)));

			const pagination = yield select(getRawPaginationSelector());
			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total - 1 }));
			}

			redirect();
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsTrailerDeletingAction(false));
	}
}

export function* saveTrailerSaga({ payload: { redirect, resetState, searchQuery } }) {
	try {
		yield put(setIsTrailerSavingAction(true));

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const dataForSave = yield select(getDataForSaveTrailerSelector());

		const { data, errors, message, toast } = yield call(saveNewTrailerRequest, dataForSave);

		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}` } : {}),
					}),
				);
			}

			yield put(setTrailerInfoAction(data));

			const trailers = yield select(getTrailersListSelector());
			yield put(
				setTrailersListAction([
					{
						...data,
						link: `/home/trailers?${searchQuery ? `${searchQuery}&` : ''}modal=trailer&id=${data.id}`,
					},
					...trailers,
				]),
			);

			const pagination = yield select(getRawPaginationSelector());
			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total + 1 }));
			}

			yield put(change(CAR_FORM_NAME, 'trailer', data.id));

			redirect();
			resetState();
		} else {
			yield put(touch(FORM_NAME, ...FORM_FIELDS));
			yield put(stopSubmit(FORM_NAME, transformToValidationErrors(errors)));

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsTrailerSavingAction(false));
	}
}

export function* updateTrailerSaga({ payload: { id, redirect, searchQuery } }) {
	try {
		yield put(setIsTrailerSavingAction(true));

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const dataForSave = yield select(getDataForSaveTrailerSelector());

		const { data, errors, message, toast } = yield call(updateTrailerRequest, id, dataForSave);
		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}` } : {}),
					}),
				);
			}

			const trailers = yield select(getTrailersListSelector());

			const updatedTrailer = {
				...data,
				link: `/home/trailers?${searchQuery ? `${searchQuery}&` : ''}modal=trailer&id=${data.id}`,
			};

			yield put(
				setTrailersListAction(
					trailers.map(trailer => {
						return trailer.id === data.id ? updatedTrailer : trailer;
					}),
				),
			);

			yield put(setTrailerInfoAction(data));

			redirect();
		} else {
			yield put(touch(FORM_NAME, ...FORM_FIELDS));
			yield put(stopSubmit(FORM_NAME, transformToValidationErrors(errors)));

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsTrailerSavingAction(false));
	}
}

export function* getTonnagesSaga() {
	try {
		yield put(setIsTonnagesLoadingAction(true));

		const { data, message, toast } = yield call(getTonnagesDictionaryRequest, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setTonnagesAction([
					{ text: 'Нет', value: 'none', key: 'none' },
					...data.map(tonnage => ({
						key: String(tonnage.id),
						value: String(tonnage.id),
						text: tonnage.name,
					})),
				]),
			);
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsTonnagesLoadingAction(false));
	}
}

export default function* trailersContainerSaga() {
	yield takeEvery(GET_TRAILER_INFO, getTrailerInfoSaga);
	yield takeEvery(DELETE_TRAILER, deleteTrailerSaga);
	yield takeEvery(SAVE_TRAILER, saveTrailerSaga);
	yield takeEvery(UPDATE_TRAILER, updateTrailerSaga);
	yield takeEvery(GET_TONNAGES, getTonnagesSaga);
}
