export const CREATE_PARTNER = 'partner-modal/CREATE_PARTNER';
export const createPartnerAction = payload => ({
	type: CREATE_PARTNER,
	payload,
});

export const GET_PARTNER_INFO = 'partner-modal/GET_PARTNER_INFO';
export const getPartnerInfoAction = payload => ({
	type: GET_PARTNER_INFO,
	payload,
});

export const SET_PARTNER_INFO = 'partner-modal/SET_PARTNER_INFO';
export const setPartnerInfoAction = payload => ({
	type: SET_PARTNER_INFO,
	payload,
});

export const SET_IS_PARTNER_INFO_LOADING = 'partner-modal/SET_IS_PARTNER_INFO_LOADING';
export const setIsPartnerInfoLoadingAction = payload => ({
	type: SET_IS_PARTNER_INFO_LOADING,
	payload,
});

export const DELETE_PARTNER = 'partner-modal/DELETE_PARTNER';
export const deletePartnerAction = payload => ({
	type: DELETE_PARTNER,
	payload,
});

export const UPDATE_PARTNER = 'partner-modal/UPDATE_PARTNER';
export const updatePartnerAction = payload => ({
	type: UPDATE_PARTNER,
	payload,
});

export const SET_IS_PARTNER_UPDATING = 'partner-modal/SET_IS_PARTNER_UPDATING';
export const setIsPartnerUpdatingAction = payload => ({
	type: SET_IS_PARTNER_UPDATING,
	payload,
});

export const SET_IS_PARTNER_DELETING = 'partner-modal/SET_IS_PARTNER_DELETING';
export const setIsPartnerDeletingAction = payload => ({
	type: SET_IS_PARTNER_DELETING,
	payload,
});

export const RESET_STATE = 'partner-modal/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const SET_PARTNER_SEARCH_VALUE = 'partner-modal/SET_PARTNER_SEARCH_VALUE';
export const setPartnerSearchValueAction = payload => ({
	type: SET_PARTNER_SEARCH_VALUE,
	payload,
});

export const GET_PARTNERS_OPTIONS = 'partner-modal/GET_PARTNERS_OPTIONS';
export const getPartnersOptionsAction = payload => ({
	type: GET_PARTNERS_OPTIONS,
	payload,
});

export const SET_PARTNERS_OPTIONS = 'partner-modal/SET_PARTNERS_OPTIONS';
export const setPartnersOptionsAction = payload => ({
	type: SET_PARTNERS_OPTIONS,
	payload,
});

export const SET_IS_PARTNERS_OPTIONS_LOADING = 'partner-modal/SET_IS_PARTNERS_OPTIONS_LOADING';
export const setIsPartnersOptionsLoadingAction = payload => ({
	type: SET_IS_PARTNERS_OPTIONS_LOADING,
	payload,
});
