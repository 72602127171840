import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';
import { StatusIndicator } from '../status-indicator';

export const Badge = ({ text, isSmall, indicator }) => {
	return (
		<div className={`badge${isSmall ? ' badge_small' : ''}`}>
			{indicator && (
				<StatusIndicator status={indicator.status} description={indicator.description} position="top center" />
			)}
			{text}
		</div>
	);
};

Badge.propTypes = {
	text: PropTypes.string.isRequired,
	isSmall: PropTypes.bool,
	indicator: PropTypes.shape({
		status: PropTypes.string,
		description: PropTypes.string,
	}),
};
