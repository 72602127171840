export const SEND_FORM = 'document-attachment-form/SEND_FORM';
export const sendFormAction = payload => ({
	type: SEND_FORM,
	payload,
});

export const SET_IS_FORM_SENDING = 'document-attachment-form/SET_IS_FORM_SENDING';
export const setIsFormSendingAction = payload => ({
	type: SET_IS_FORM_SENDING,
	payload,
});

export const RESET_STATE = 'document-attachment-form/RESET_STATE';
export const resetStateAction = payload => ({
	type: RESET_STATE,
	payload,
});

export const GET_DOCUMENTS = 'document-attachment-form/GET_DOCUMENTS';
export const getDocumentsAction = payload => ({
	type: GET_DOCUMENTS,
	payload,
});

export const SET_DOCUMENTS = 'document-attachment-form/SET_DOCUMENTS';
export const setDocumentsAction = payload => ({
	type: SET_DOCUMENTS,
	payload,
});

export const SET_IS_DOCUMENTS_LOADING = 'document-attachment-form/SET_IS_DOCUMENTS_LOADING';
export const setIsDocumentsLoadingAction = payload => ({
	type: SET_IS_DOCUMENTS_LOADING,
	payload,
});

export const SET_FILES = 'document-attachment-form/SET_FILES';
export const setFilesAction = payload => ({
	type: SET_FILES,
	payload,
});

export const SET_FORM_ERRORS = 'document-attachment-form/SET_FORM_ERRORS';
export const setFormErrorsAction = payload => ({
	type: SET_FORM_ERRORS,
	payload,
});
