export const GET_DETAILS = 'details/GET_DETAILS';
export const getDetailsAction = payload => ({
	type: GET_DETAILS,
	payload,
});

export const SET_DETAILS = 'details/SET_DETAILS';
export const setDetailsAction = payload => ({
	type: SET_DETAILS,
	payload,
});

export const SET_IS_DETAILS_LOADING = 'details/SET_IS_DETAILS_LOADING';
export const setIsDetailsLoadingAction = payload => ({
	type: SET_IS_DETAILS_LOADING,
	payload,
});

export const SET_DETAILS_SEARCH_VALUE = 'details/SET_DETAILS_SEARCH_VALUE';
export const setDetailsSearchValueAction = payload => ({
	type: SET_DETAILS_SEARCH_VALUE,
	payload,
});

export const UPDATE_DETAIL_DEFAULT = 'details/UPDATE_DETAIL_DEFAULT';
export const updateDetailDefaultAction = payload => ({
	type: UPDATE_DETAIL_DEFAULT,
	payload,
});

export const SET_PAGINATION = 'details/SET_PAGINATION';
export const setPaginationAction = payload => ({
	type: SET_PAGINATION,
	payload,
});

export const RESET_STATE = 'details/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});
