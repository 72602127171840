import React from 'react';
import PropTypes from 'prop-types';
import { SimpleTableComponent } from '../../../../components/simple-table';
import { CONTACT_TABLE_COLUMNS_NAMES } from '../../constants';
import { ContactForm } from '../contact-form';

export const ContactModalContent = ({ contactInfo, isContactFormOpen, isContactInfoLoading }) => {
	return isContactFormOpen ? (
		<ContactForm />
	) : (
		<SimpleTableComponent
			rows={contactInfo}
			isTableDataLoading={isContactInfoLoading}
			columns={CONTACT_TABLE_COLUMNS_NAMES}
		/>
	);
};

ContactModalContent.propTypes = {
	contactInfo: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.shape({
					name: PropTypes.string,
					role: PropTypes.string,
				}),
			]).isRequired,
		}),
	),
	isContactFormOpen: PropTypes.bool.isRequired,
	isContactInfoLoading: PropTypes.bool.isRequired,
};
