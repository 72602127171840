export const GET_USER_PERMISSION = 'sidebar/GET_USER_PERMISSION';
export const getUserPermissionsAction = () => ({
	type: GET_USER_PERMISSION,
});

export const SET_USER_PERMISSIONS = 'sidebar/SET_USER_PERMISSIONS';
export const setUserPermissionsAction = payload => ({
	type: SET_USER_PERMISSIONS,
	payload,
});

export const SET_IS_USER_PERMISSIONS_LOADING = 'sidebar/SET_IS_USER_PERMISSIONS_LOADING';
export const setIsUserPermissionsLoadingAction = payload => ({
	type: SET_IS_USER_PERMISSIONS_LOADING,
	payload,
});

export const SET_ACTIVE_SUBMENUS = 'sidebar/SET_ACTIVE_SUBMENUS';
export const setActiveSubmenusAction = payload => ({
	type: SET_ACTIVE_SUBMENUS,
	payload,
});

export const SET_IS_MENU_OPEN = 'sidebar/SET_IS_MENU_OPEN';
export const setIsMenuOpenAction = payload => ({
	type: SET_IS_MENU_OPEN,
	payload,
});
