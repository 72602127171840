import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { Field } from 'redux-form';
import { ModalActionButtons } from '../../../../../semantic-ui/components/modal-window/modal-action-buttons';
import { LoginField } from '../../../../../semantic-ui/components/login-field';
import ArrowIcon from '../../../../../components/images/arrow-left-icon.svg';

export const ForgotModalView = ({ onForgotModalClose, onFormSubmit, isForgotFormSubmitting }) => {
	return (
		<div className="forgot-modal">
			<div className="forgot-modal__action-buttons">
				<div className="forgot-modal__back-button" onClick={onForgotModalClose}>
					<img src={ArrowIcon} alt="arrow left" />
					<span className="forgot-modal__back-text">Назад</span>
				</div>
				<ModalActionButtons buttons={[{ type: 'close', onClick: onForgotModalClose, text: 'forgot' }]} />
			</div>
			<header className="forgot-modal__header">
				<div className="forgot-modal__header-text">
					<h3 className="forgot-modal__title">Забыли пароль?</h3>
					<h5 className="forgot-modal__text">
						Введите свой зарегистрированный адрес электронной почты. Мы вышлем ссылку для сброса вашего
						пароля.
					</h5>
				</div>
			</header>
			<div className="forgot-modal__field">
				<Field name="email" type="text" component={LoginField} label="Адрес электронной почты" />
			</div>
			<Button
				className="forgot-modal__submit-button"
				onClick={onFormSubmit}
				loading={isForgotFormSubmitting}
				disabled={isForgotFormSubmitting}
			>
				Отправить ссылку для сброса пароля
			</Button>
		</div>
	);
};

ForgotModalView.propTypes = {
	onForgotModalClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	isForgotFormSubmitting: PropTypes.bool.isRequired,
};
