export const GET_REQ_INFO = 'cargo-pickup-modal/GET_REQ_INFO';
export const getReqInfoAction = payload => ({
	type: GET_REQ_INFO,
	payload,
});

export const SET_REQ_INFO = 'cargo-pickup-modal/SET_REQ_INFO';
export const setReqInfoAction = payload => ({
	type: SET_REQ_INFO,
	payload,
});

export const SET_IS_REQ_INFO_LOADING = 'cargo-pickup-modal/SET_IS_REQ_INFO_LOADING';
export const setIsReqInfoLoadingAction = payload => ({
	type: SET_IS_REQ_INFO_LOADING,
	payload,
});

export const RESET_STATE = 'cargo-pickup-modal/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const SET_IS_FORM_SENDING = 'cargo-pickup-modal/SET_IS_FORM_SENDING';
export const setIsFormSendingAction = () => ({
	type: SET_IS_FORM_SENDING,
});

export const SEND_CARGO_PICKUP_FORM_ACTION = 'cargo-pickup-modal/SEND_CARGO_PICKUP_FORM_ACTION';
export const sendCargoPickupFormAction = payload => ({
	type: SEND_CARGO_PICKUP_FORM_ACTION,
	payload,
});
