import { RESET_STATE, SET_IS_MESSAGES_LOADING, SET_MESSAGES, SET_MESSAGE_TEXT } from './actions';

export const initialState = {
	messages: [],
	messageText: '',
	isMessagesLoading: false,
};

const chatModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_MESSAGE_TEXT:
			return {
				...state,
				messageText: payload,
			};
		case SET_MESSAGES:
			return {
				...state,
				messages: payload,
			};
		case SET_IS_MESSAGES_LOADING:
			return {
				...state,
				isMessagesLoading: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default chatModalReducer;
