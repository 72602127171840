import { createSelector } from 'reselect';
import { getFormValues, isInvalid } from 'redux-form';
import { RESET_PASSWORD_FORM_NAME } from './constants';
import { initialState } from './reducer';

export const selectResetPasswordDomain = state => state.resetPasswordReducer || initialState;

export const getIsResetPasswordFormSubmittingSelector = () =>
	createSelector(selectResetPasswordDomain, ({ isResetPasswordFormSubmitting }) => isResetPasswordFormSubmitting);

export const getFormIsInvalidSelector = () =>
	createSelector(isInvalid(RESET_PASSWORD_FORM_NAME), isInvalid => isInvalid);

export const getFormDataSelector = () =>
	createSelector(getFormValues(RESET_PASSWORD_FORM_NAME), formValues => formValues || {});
