import {
	RESET_STATE,
	SET_EVENT_DATA,
	SET_IS_EVENT_DATA_LOADING,
	SET_IS_REQ_DETAILS_LOADING,
	SET_IS_REQ_DOCS_LOADING,
	SET_REQ_DETAILS,
	SET_REQ_DOCS,
} from './actions';

export const initialState = {
	reqDetails: null,
	isReqDetailsLoading: false,
	reqDocs: null,
	isReqDocsLoading: false,
	eventData: null,
	isEventDataLoading: false,
};

const reqsDetailsModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_REQ_DETAILS:
			return {
				...state,
				reqDetails: payload,
			};
		case SET_IS_REQ_DETAILS_LOADING:
			return {
				...state,
				isReqDetailsLoading: payload,
			};
		case SET_REQ_DOCS:
			return {
				...state,
				reqDocs: payload,
			};
		case SET_IS_REQ_DOCS_LOADING:
			return {
				...state,
				isReqDocsLoading: payload,
			};
		case SET_EVENT_DATA:
			return {
				...state,
				eventData: payload,
			};
		case SET_IS_EVENT_DATA_LOADING:
			return {
				...state,
				isEventDataLoading: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default reqsDetailsModalReducer;
