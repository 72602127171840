import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { notificationInit } from '../../modules/notifications/actions';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { DISMISS_TIME } from '../../api/constants';
import {
	GET_EVENT_DATA,
	GET_REQ_DETAILS,
	GET_REQ_DOCS,
	setEventDataAction,
	setIsEventDataLoadingAction,
	setIsReqDetailsLoadingAction,
	setIsReqDocsLoadingAction,
	setReqDetailsAction,
	setReqDocsAction,
} from './actions';
import { getReqDetailsRequest, getReqDocsRequest, getReqEventRequest } from '../../api/requests';
import { errorHandler } from '../../api/utils';
import { removeModalQuery } from '../../utils/removeModalQuery';

export function* getReqsDetailsSaga({ payload: { id, searchQuery } }) {
	try {
		yield put(setIsReqDetailsLoadingAction(true));

		const { data, message, toast } = yield call(getReqDetailsRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			let updateData = data;

			if (data?.offer?.link?.url) {
				updateData = {
					...data,
					offer: {
						...data.offer,
						link: {
							...data.offer.link,
							url: `${data.offer.link.url}${
								removeModalQuery(searchQuery) ? `&${removeModalQuery(searchQuery)}` : ''
							}`,
						},
					},
				};
			}

			yield put(setReqDetailsAction(updateData));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsReqDetailsLoadingAction(false));
	}
}

export function* getReqsDocsSaga({ id }) {
	try {
		yield put(setIsReqDocsLoadingAction(true));

		const { data, message, toast } = yield call(getReqDocsRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setReqDocsAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsReqDocsLoadingAction(false));
	}
}

export function* getEventDataSaga({ payload: id }) {
	try {
		yield put(setIsEventDataLoadingAction(true));

		const { message, toast, reports } = yield call(getReqEventRequest, id);

		if (reports) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setEventDataAction(reports?.html || null));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsEventDataLoadingAction(false));
	}
}

export default function* reqsDetailsModalSaga() {
	yield takeEvery(GET_REQ_DETAILS, getReqsDetailsSaga);
	yield takeEvery(GET_REQ_DOCS, getReqsDocsSaga);
	yield takeEvery(GET_EVENT_DATA, getEventDataSaga);
}
