import TrailersContainer from '../../containers/trailers';
import CarsContainer from '../../containers/cars';
import AddressesContainer from '../../containers/addresses';
import PartnersContainer from '../../containers/partners';
import InformationContainer from '../../containers/information';
import WaysContainer from '../../containers/ways';

export const pageList = {
	information: {
		id: 'information',
		path: '/home/information',
		title: 'Информация',
		component: InformationContainer,
		exact: false,
	},
	trailers: {
		id: 'trailers',
		path: '/home/trailers',
		title: 'Прицепы',
		component: TrailersContainer,
		exact: false,
	},
	// Раздел временно скрыт по задаче LOG-310
	// details: {
	// 	id: 'details',
	// 	path: '/home/details',
	// 	title: 'Реквизиты',
	// 	component: DetailsContainer,
	// 	exact: false,
	// },
	cars: {
		id: 'cars',
		path: '/home/cars',
		title: 'Машины',
		component: CarsContainer,
		exact: false,
	},
	addresses: {
		id: 'addresses',
		path: '/home/addresses',
		title: 'Адреса',
		component: AddressesContainer,
		exact: false,
	},
	partners: {
		id: 'partners',
		path: '/home/partners',
		title: 'Контрагенты',
		component: PartnersContainer,
		exact: false,
	},
	ways: {
		id: 'ways',
		path: '/home/ways',
		title: 'Маршруты',
		component: WaysContainer,
		exact: false,
	},
};
