import { SubmissionError } from 'redux-form';
import { REQUIRED_FIELDS } from './constants';

export const validate = values => {
	REQUIRED_FIELDS.forEach(key => {
		if (!values?.[key]) {
			throw new SubmissionError({ [key]: 'Заполните это поле' });
		}
	});
};

export const transformToValidationErrors = errors => {
	return Object.entries(errors).reduce((prev, [key, error]) => {
		if (!prev[key]) {
			prev[key] = {};
		}

		prev[key] = error;

		return prev;
	}, {});
};
