import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { EMAIL_RESEND, EMAIL_VERIFY, GET_FORM_INFO, setFormInfoAction } from './actions';
import { emailResendRequest, emailVerifyRequest, getEmailVerifyInfoRequest } from '../../api/requests';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { errorHandler } from '../../api/utils';
import { getUserInfoSelector } from './selectors';
import { setUserInfoAction } from '../profile/actions';
import { getLocalStorageItem, setLocalStorageItem } from '../../utils/localStorage';

export function* getFormInfoSaga({ payload: redirect }) {
	try {
		const { data, message } = yield call(getEmailVerifyInfoRequest);

		if (data) {
			if (data.email_verified_at) {
				const user_data = getLocalStorageItem('user_data');

				setLocalStorageItem('user_data', { ...user_data, email_verified_at: data.email_verified_at });

				if (!user_data.isCompanyValidated) {
					redirect('/company/validate');
				} else {
					redirect('/home/information');
				}
			} else {
				yield put(setFormInfoAction(data));
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export function* emailResendSaga({ payload: redirect }) {
	try {
		const { data, message } = yield call(emailResendRequest);

		if (data) {
			if (data.email_verified_at) {
				const userInfo = yield select(getUserInfoSelector());

				yield put(setUserInfoAction({ ...userInfo, email_verified_at: data.email_verified_at }));

				const user_data = getLocalStorageItem('user_data');

				setLocalStorageItem('user_data', { ...user_data, email_verified_at: data.email_verified_at });
				if (!user_data.isCompanyValidated) {
					redirect('/company/validate');
				} else {
					redirect('/home/information');
				}
			} else {
				yield put(setFormInfoAction(data));
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export function* emailVerifySaga({ payload: { id, hash, redirect } }) {
	try {
		const { data, message } = yield call(emailVerifyRequest, id, hash);

		if (data) {
			if (data.email_verified_at) {
				const userInfo = yield select(getUserInfoSelector());

				yield put(setUserInfoAction({ ...userInfo, email_verified_at: data.email_verified_at }));

				const user_data = getLocalStorageItem('user_data');

				setLocalStorageItem('user_data', { ...user_data, email_verified_at: data.email_verified_at });

				if (!user_data.isCompanyValidated) {
					redirect('/company/validate');
				} else {
					redirect('/home/information');
				}
			} else {
				yield put(setFormInfoAction(data));
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export default function* emailVerifyContainerSaga() {
	yield takeEvery(GET_FORM_INFO, getFormInfoSaga);
	yield takeEvery(EMAIL_RESEND, emailResendSaga);
	yield takeEvery(EMAIL_VERIFY, emailVerifySaga);
}
