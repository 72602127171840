import { createSelector } from 'reselect';
import { getFormValues, isInvalid } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import { initialState } from './reducer';
import { FORM_NAME } from './constants';

export const selectCargoDeliveryModalDomain = state => state.cargoDeliveryModalReducer || initialState;

export const selectRouterDomain = state => state.router || {};

export const getIsReqInfoLoadingSelector = () =>
	createSelector(
		selectCargoDeliveryModalDomain,
		({ isReqInfoLoading, isFormSending }) => isReqInfoLoading || isFormSending,
	);

export const getReqInfoSelector = () =>
	createSelector(selectCargoDeliveryModalDomain, ({ reqInfo }) => {
		return reqInfo;
	});

export const getFormInitialValuesSelector = () =>
	createSelector(selectCargoDeliveryModalDomain, getFormValues(FORM_NAME), ({ reqInfo }, formValues) => {
		return { ...reqInfo, ...formValues };
	});

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'cargo_delivery');
	});

export const getActiveReqIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return query?.modal === 'cargo_delivery' ? query?.id : null;
	});

export const getFormValuesSelector = () => createSelector(getFormValues(FORM_NAME), formValues => formValues);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);
