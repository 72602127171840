import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory, useLocation } from 'react-router';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { ChatsListView } from './components/chats-list-view';
import { getChatsListAction } from './actions';
import { getChatsListSelector, getIsChatsListLoadingSelector } from './selectors';
import { setIsChatsListShownAction } from '../right-header-content/actions';

const ChatsListWrapper = ({ onGetChatsList, chatsList, isChatsListLoading, onSetIsChatsListShown }) => {
	useInjectSaga({ key: 'chatsListSaga', saga });
	useInjectReducer({ key: 'chatsListReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		onGetChatsList();
	}, []);

	const handleChatsListClose = () => {
		onSetIsChatsListShown(false);
	};

	const handleGoToReq = id => {
		query.set('modal', 'reqs_details');
		query.set('tab', 'chat');
		query.set('id', id);

		history.push(`${pathname}?${query}`);
		handleChatsListClose();
	};

	return (
		<ChatsListView
			chatsList={chatsList}
			onGoToReq={handleGoToReq}
			isChatsListLoading={isChatsListLoading}
			onChatsListClose={handleChatsListClose}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	chatsList: getChatsListSelector(),
	isChatsListLoading: getIsChatsListLoadingSelector(),
});

const mapDispatchToProps = {
	onGetChatsList: getChatsListAction,
	onSetIsChatsListShown: setIsChatsListShownAction,
};

ChatsListWrapper.propTypes = {
	onGetChatsList: PropTypes.func.isRequired,
	chatsList: PropTypes.arrayOf(
		PropTypes.shape({
			badge: PropTypes.number.isRequired,
			date: PropTypes.string.isRequired,
			id: PropTypes.number.isRequired,
			message: PropTypes.string.isRequired,
		}),
	).isRequired,
	isChatsListLoading: PropTypes.bool.isRequired,
	onSetIsChatsListShown: PropTypes.func.isRequired,
};

const ChatsList = connect(mapStateToProps, mapDispatchToProps)(ChatsListWrapper);

export { ChatsList };
