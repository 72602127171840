/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import PropTypes from 'prop-types';
import { change, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { PartnerFormView } from './partner-form-view';
import { PARTNER_FORM_NAME } from '../../constants';
import {
	getPartnerSearchValueSelector,
	getPartnersOptionsSelector,
	getPartnersSelector,
	getFormInitialValuesSelector,
	getIsPartnersOptionsLoadingSelector,
	getIsPartnerInfoLoadingSelector,
} from '../../selectors';
import { getPartnersOptionsAction, setPartnerSearchValueAction } from '../../actions';
import { validate } from '../../utils';

const PartnerFormWrapper = ({
	partnersOptions,
	isPartnersLoading,
	partnersSearchValue,
	onSetPartnerSearchValue,
	onGetPartnersOptions,
	partners,
	onFieldChange,
	isPartnerInfoLoading,
}) => {
	const handlePartnerSearchChange = ({ target: { value } }) => {
		onSetPartnerSearchValue(value);
		onGetPartnersOptions();
	};

	const handlePartnerChange = value => {
		const currentPartner = partners.find(({ data }) => data.inn === value);

		if (currentPartner) {
			onFieldChange(PARTNER_FORM_NAME, 'name', currentPartner.value);
			onFieldChange(PARTNER_FORM_NAME, 'inn', currentPartner.data?.inn);
			onFieldChange(PARTNER_FORM_NAME, 'ogrn', currentPartner.data?.ogrn);
			onFieldChange(PARTNER_FORM_NAME, 'address', currentPartner.data?.address?.value);
			onSetPartnerSearchValue(currentPartner.value);
		}
	};

	return (
		<PartnerFormView
			partnersOptions={partnersOptions}
			isPartnersLoading={isPartnersLoading}
			onPartnerSearchChange={handlePartnerSearchChange}
			onPartnerChange={handlePartnerChange}
			partnersSearchValue={partnersSearchValue}
			isPartnerInfoLoading={isPartnerInfoLoading}
		/>
	);
};

const withForm = reduxForm({
	form: PARTNER_FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
})(PartnerFormWrapper);

const mapStateToProps = createStructuredSelector({
	initialValues: getFormInitialValuesSelector(),
	partners: getPartnersSelector(),
	partnersOptions: getPartnersOptionsSelector(),
	partnersSearchValue: getPartnerSearchValueSelector(),
	isPartnersLoading: getIsPartnersOptionsLoadingSelector(),
	isPartnerInfoLoading: getIsPartnerInfoLoadingSelector(),
});

const mapDispatchToProps = {
	onSetPartnerSearchValue: setPartnerSearchValueAction,
	onGetPartnersOptions: getPartnersOptionsAction,
	onFieldChange: change,
};

const PartnerForm = connect(mapStateToProps, mapDispatchToProps)(withForm);

PartnerFormWrapper.propTypes = {
	partnersOptions: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string,
			data: PropTypes.shape({
				title: PropTypes.string.isRequired,
				address: PropTypes.string.isRequired,
				deleted: PropTypes.bool,
			}),
		}),
	).isRequired,
	isPartnersLoading: PropTypes.bool.isRequired,
	onSetPartnerSearchValue: PropTypes.func.isRequired,
	onGetPartnersOptions: PropTypes.func.isRequired,
	partnersSearchValue: PropTypes.string.isRequired,
	partners: PropTypes.array.isRequired,
	onFieldChange: PropTypes.func.isRequired,
	isPartnerInfoLoading: PropTypes.bool.isRequired,
};

export { PartnerForm };
