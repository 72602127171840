export const GET_USER_INFO = 'profile/GET_USER_INFO';
export const getUserInfoAction = payload => ({
	type: GET_USER_INFO,
	payload,
});

export const SET_USER_INFO = 'profile/SET_USER_INFO';
export const setUserInfoAction = payload => ({
	type: SET_USER_INFO,
	payload,
});

export const SET_IS_USER_INFO_LOADING = 'profile/SET_IS_USER_INFO_LOADING';
export const setIsUserInfoLoadingAction = payload => ({
	type: SET_IS_USER_INFO_LOADING,
	payload,
});

export const SET_IS_PROFILE_FORM_OPEN = 'profile/SET_IS_PROFILE_FORM_OPEN';
export const setIsProfileFormOpenAction = payload => ({
	type: SET_IS_PROFILE_FORM_OPEN,
	payload,
});

export const RESET_STATE = 'profile/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const SAVE_PROFILE = 'profile/SAVE_PROFILE';
export const saveProfileAction = payload => ({
	type: SAVE_PROFILE,
	payload,
});

export const SET_IS_PROFILE_SAVING = 'profile/SET_IS_PROFILE_SAVING';
export const setIsProfileSavingAction = payload => ({
	type: SET_IS_PROFILE_SAVING,
	payload,
});

export const SET_USER_RULES = 'profile/SET_USER_RULES';
export const setUserRulesAction = payload => ({
	type: SET_USER_RULES,
	payload,
});

export const SET_NOTIFICATIONS_COUNT = 'profile/SET_NOTIFICATIONS_COUNT';
export const setNotificationsCountAction = payload => ({
	type: SET_NOTIFICATIONS_COUNT,
	payload,
});

export const SET_CHATS_NOTIFICATIONS_COUNT = 'profile/SET_CHATS_NOTIFICATIONS_COUNT';
export const setChatsNotificationsCountAction = payload => ({
	type: SET_CHATS_NOTIFICATIONS_COUNT,
	payload,
});

export const GET_USERS_LIST = 'profile/GET_USERS_LIST';
export const getUsersListAction = payload => ({
	type: GET_USERS_LIST,
	payload,
});

export const SET_USERS_LIST = 'profile/SET_USERS_LIST';
export const setUsersListAction = payload => ({
	type: SET_USERS_LIST,
	payload,
});

export const SET_IS_USERS_LIST_LOADING = 'profile/SET_IS_USERS_LIST_LOADING';
export const setIsUsersListLoadingAction = payload => ({
	type: SET_IS_USERS_LIST_LOADING,
	payload,
});
