import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { getPartnersAction, resetStateAction, setPartnersSearchValueAction } from './actions';
import {
	getPartnersSearchValueSelector,
	getPartnersSelector,
	getIsPartnersLoadingSelector,
	getPaginationSelector,
} from './selectors';
import { TableComponent } from '../../components/table-component';
import { PARTNERS_TABLE_COLUMNS } from './constants';
import { getIsAvailablePartnersButtonsSelector } from '../profile/selectors';
import { removeModalQuery } from '../../utils/removeModalQuery';

const PartnersContainerWrapper = ({
	onGetPartners,
	partners,
	isPartnersLoading,
	partnersSearchValue,
	onSetPartnersSearchValue,
	onResetState,
	pagination,
	isAvailable,
}) => {
	useInjectSaga({ key: 'partnersSaga', saga });
	useInjectReducer({ key: 'partnersReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		onGetPartners(removeModalQuery(query.toString()));

		return () => onResetState();
	}, []);

	useEffect(() => {
		if (query.has('search')) {
			onSetPartnersSearchValue(query.get('search').toString());
		}
	}, []);

	const handleAddButtonClick = () => {
		query.set('modal', 'partner');
		query.set('mode', 'add');

		history.push(`${pathname}?${query}`);
	};

	const handleKeyPress = ({ key }) => {
		if (key === 'Enter') {
			query.set('page', 1);

			if (partnersSearchValue) {
				query.set('search', partnersSearchValue);
			} else {
				query.delete('search');
			}

			history.push(`${pathname}?${query}`);
			onGetPartners(query.toString());
		}
	};

	const handleSearchInputChange = ({ target: { value } }) => onSetPartnersSearchValue(value);

	const handleSearchInputClear = () => {
		onSetPartnersSearchValue('');

		query.delete('search');
		history.push(`${pathname}?${query}`);
		onGetPartners(query.toString());
	};

	const handlePageChange = (_, { activePage }) => {
		query.set('page', activePage);
		history.push(`${pathname}?${query}`);
		onGetPartners(query.toString());
	};

	const handleSelectChange = (_, { value }) => {
		query.set('page', 1);
		if (value === 'all') {
			query.delete('per_page');
			history.push(`${pathname}?${query}`);
		} else {
			query.set('per_page', value);
			history.push(`${pathname}?${query}`);
		}
		onGetPartners(query.toString());
	};

	return (
		<TableComponent
			headerText="Контрагенты"
			onAddButtonClick={isAvailable.create ? handleAddButtonClick : null}
			columns={PARTNERS_TABLE_COLUMNS}
			rows={partners}
			onSearch={handleKeyPress}
			isTableDataLoading={isPartnersLoading}
			pagination={
				!pagination
					? null
					: { ...pagination, onPageChange: handlePageChange, onSelectChange: handleSelectChange }
			}
			searchInputValue={partnersSearchValue}
			onSearchInputChange={handleSearchInputChange}
			onSearchInputClear={handleSearchInputClear}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	isPartnersLoading: getIsPartnersLoadingSelector(),
	partners: getPartnersSelector(),
	partnersSearchValue: getPartnersSearchValueSelector(),
	pagination: getPaginationSelector(),
	isAvailable: getIsAvailablePartnersButtonsSelector(),
});

const mapDispatchToProps = {
	onGetPartners: getPartnersAction,
	onSetPartnersSearchValue: setPartnersSearchValueAction,
	onResetState: resetStateAction,
};

PartnersContainerWrapper.propTypes = {
	onGetPartners: PropTypes.func.isRequired,
	isPartnersLoading: PropTypes.bool.isRequired,
	partners: PropTypes.array.isRequired,
	partnersSearchValue: PropTypes.string.isRequired,
	onSetPartnersSearchValue: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	pagination: PropTypes.shape({
		currentPage: PropTypes.number,
		totalPages: PropTypes.number.isRequired,
		total: PropTypes.number,
		from: PropTypes.number,
		to: PropTypes.number,
		isNextItem: PropTypes.bool,
		isPrevItem: PropTypes.bool,
		isFirstItem: PropTypes.bool,
		isLastItem: PropTypes.bool,
	}),
	isAvailable: PropTypes.objectOf(PropTypes.bool),
};

const PartnersContainer = connect(mapStateToProps, mapDispatchToProps)(PartnersContainerWrapper);

export default PartnersContainer;
