/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import PropTypes from 'prop-types';
import { change, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { DetailFormView } from './detail-form-view';
import { DETAIL_FORM_NAME } from '../../constants';
import {
	getDetailSearchValueSelector,
	getDetailsOptionsSelector,
	getDetailsSelector,
	getFormInitialValuesSelector,
	getIsDetailInfoLoadingSelector,
	getIsDetailsOptionsLoadingSelector,
} from '../../selectors';
import { getDetailsOptionsAction, setDetailSearchValueAction } from '../../actions';
import { validate } from '../../utils';

const DetailFormWrapper = ({
	detailsOptions,
	isDetailsLoading,
	detailsSearchValue,
	onSetDetailSearchValue,
	onGetDetailsOptions,
	details,
	onFieldChange,
	isDetailInfoLoading,
}) => {
	const handleDetailSearchChange = ({ target: { value } }) => {
		onSetDetailSearchValue(value);
		onGetDetailsOptions();
	};

	const handleDetailChange = value => {
		const currentDetail = details.find(({ data }) => data.bic === value);

		if (currentDetail) {
			onFieldChange(DETAIL_FORM_NAME, 'name', currentDetail.value);
			onFieldChange(DETAIL_FORM_NAME, 'bic', currentDetail.data?.bic);
			onFieldChange(DETAIL_FORM_NAME, 'corr', currentDetail.data?.correspondent_account);
			onSetDetailSearchValue(currentDetail.value);
		}
	};

	return (
		<DetailFormView
			detailsOptions={detailsOptions}
			isDetailsLoading={isDetailsLoading}
			onDetailSearchChange={handleDetailSearchChange}
			onDetailChange={handleDetailChange}
			detailsSearchValue={detailsSearchValue}
			isDetailInfoLoading={isDetailInfoLoading}
		/>
	);
};

const withForm = reduxForm({
	form: DETAIL_FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
})(DetailFormWrapper);

const mapStateToProps = createStructuredSelector({
	initialValues: getFormInitialValuesSelector(),
	details: getDetailsSelector(),
	detailsOptions: getDetailsOptionsSelector(),
	detailsSearchValue: getDetailSearchValueSelector(),
	isDetailsLoading: getIsDetailsOptionsLoadingSelector(),
	isDetailInfoLoading: getIsDetailInfoLoadingSelector(),
});

const mapDispatchToProps = {
	onSetDetailSearchValue: setDetailSearchValueAction,
	onGetDetailsOptions: getDetailsOptionsAction,
	onFieldChange: change,
};

const DetailForm = connect(mapStateToProps, mapDispatchToProps)(withForm);

DetailFormWrapper.propTypes = {
	detailsOptions: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string,
			data: PropTypes.shape({
				title: PropTypes.string.isRequired,
				address: PropTypes.string.isRequired,
				deleted: PropTypes.bool,
			}),
		}),
	).isRequired,
	isDetailsLoading: PropTypes.bool.isRequired,
	onSetDetailSearchValue: PropTypes.func.isRequired,
	onGetDetailsOptions: PropTypes.func.isRequired,
	detailsSearchValue: PropTypes.string.isRequired,
	details: PropTypes.array.isRequired,
	onFieldChange: PropTypes.func.isRequired,
	isDetailInfoLoading: PropTypes.bool.isRequired,
};

export { DetailForm };
