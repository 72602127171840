import { put, takeEvery, call, fork } from 'redux-saga/effects';
import { errorHandler } from '../../api/utils';
import { searchReqsRequest } from '../../api/reqs-requests';
import { SEARCH_REQS, setFoundReqsAction } from './actions';
import { REQUEST_ERROR_MESSAGE } from './constants';

export function* searchRequestsSaga({ payload: search }) {
	try {
		const { data, message } = yield call(searchReqsRequest, `value=${search}`);

		if (data) {
			yield put(setFoundReqsAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export default function* headerSearchSaga() {
	yield takeEvery(SEARCH_REQS, searchRequestsSaga);
}
