import { SET_WINDOW_INFO } from './actions';

export const initialState = {
	isWindowOpen: false,
	windowInfo: null,
};

const alertModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_WINDOW_INFO:
			return {
				...state,
				windowInfo: payload,
			};
		default:
			return state;
	}
};

export default alertModalReducer;
