import { SubmissionError } from 'redux-form';
import { goToElement } from '../../utils/goToElement';
import { REQUIRED_FIELDS } from './constants';

export const transformToValidationErrors = errors => {
	return Object.entries(errors).reduce((result, [key, value]) => {
		if (key === 'points.0.address') {
			goToElement('address_from');

			return { ...result, address_from: value[0] };
		}

		if (key.includes('points')) {
			const keyArr = key.split('.');

			if (keyArr?.[1] === '0') {
				return result;
			}

			const field = result.address_to ? { address_to: [...result.address_to] } : { address_to: [] };

			field.address_to[Number(keyArr?.[1]) - 1] = {
				...field.address_to[Number(keyArr?.[1]) - 1],
				[keyArr?.[2]]: value[0],
			};

			return { ...result, address_to: field.address_to };
		}

		return { ...result, [key]: value[0] };
	}, {});
};

export const validate = values => {
	REQUIRED_FIELDS.forEach(key => {
		if (key === 'address_to') {
			values?.address_to?.forEach(({ address }, index) => {
				if (!address) {
					const field = { address_to: [] };
					field.address_to[index] = { address: 'Заполните это поле' };
					goToElement(`address_to[${index}].address`);
					throw new SubmissionError(field);
				}
			});
		}

		if (['volume', 'weight'].includes(key) && (values?.[key] === 0 || values?.[key] === '0')) {
			goToElement(key);
			throw new SubmissionError({ [key]: 'Некорректное значение' });
		}

		if (values?.[key] === undefined || values?.[key] === '') {
			goToElement(key);
			throw new SubmissionError({ [key]: 'Заполните это поле' });
		}
	});

	if (!values?.modes?.filter(item => item)?.length) {
		goToElement('mode_ids');
		throw new SubmissionError({ mode_ids: 'Поле температурный режим обязательно.' });
	}
};
