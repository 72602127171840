export const RESET_STATE = 'chat-modal/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const SET_MESSAGE_TEXT = 'chat-modal/SET_MESSAGE_TEXT';
export const setMessageTextAction = payload => ({
	type: SET_MESSAGE_TEXT,
	payload,
});

export const GET_MESSAGES = 'chat-modal/GET_MESSAGES';
export const getMessagesAction = payload => ({
	type: GET_MESSAGES,
	payload,
});

export const SET_MESSAGES = 'chat-modal/SET_MESSAGES';
export const setMessagesAction = payload => ({
	type: SET_MESSAGES,
	payload,
});

export const SET_IS_MESSAGES_LOADING = 'chat-modal/SET_IS_MESSAGES_LOADING';
export const setIsMessagesLoadingAction = payload => ({
	type: SET_IS_MESSAGES_LOADING,
	payload,
});

export const ADD_NEW_MESSAGE = 'chat-modal/ADD_NEW_MESSAGE';
export const addNewMessageAction = payload => ({
	type: ADD_NEW_MESSAGE,
	payload,
});
