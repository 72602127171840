import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ReqsEventTabView } from './reqs-event-tab-view';
import { getEventDataSelector, getIsEventDataLoadingSelector } from '../../selectors';

const ReqsEventTabWrapper = ({ eventData, isEventDataLoading }) => {
	return <ReqsEventTabView eventData={eventData} isEventDataLoading={isEventDataLoading} />;
};

ReqsEventTabWrapper.propTypes = {
	eventData: PropTypes.string,
	isEventDataLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
	eventData: getEventDataSelector(),
	isEventDataLoading: getIsEventDataLoadingSelector(),
});

const ReqsEventTab = connect(mapStateToProps)(ReqsEventTabWrapper);

export { ReqsEventTab };
