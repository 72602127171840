import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';

export const Content = ({ children }) => {
	return <div className="main-content">{children}</div>;
};

Content.propTypes = {
	children: PropTypes.node,
};
