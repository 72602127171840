import {
	RESET_STATE,
	SET_CHATS_NOTIFICATIONS_COUNT,
	SET_IS_PROFILE_FORM_OPEN,
	SET_IS_PROFILE_SAVING,
	SET_IS_USERS_LIST_LOADING,
	SET_IS_USER_INFO_LOADING,
	SET_NOTIFICATIONS_COUNT,
	SET_USERS_LIST,
	SET_USER_INFO,
	SET_USER_RULES,
} from './actions';

export const initialState = {
	userInfo: null,
	isUserInfoLoading: false,
	isProfileFormOpen: false,
	isProfileSaving: false,
	userRules: {},
	notificationsCount: 0,
	chatsNotificationsCount: 0,
	usersList: [],
	isUsersListLoading: false,
};

const profileModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_USER_INFO:
			return {
				...state,
				userInfo: payload,
			};
		case SET_IS_USER_INFO_LOADING:
			return {
				...state,
				isUserInfoLoading: payload,
			};
		case SET_IS_PROFILE_FORM_OPEN:
			return {
				...state,
				isProfileFormOpen: payload,
			};
		case SET_IS_PROFILE_SAVING:
			return {
				...state,
				isProfileSaving: payload,
			};
		case SET_USER_RULES:
			return {
				...state,
				userRules: payload,
			};
		case SET_NOTIFICATIONS_COUNT:
			return {
				...state,
				notificationsCount: payload,
			};
		case SET_CHATS_NOTIFICATIONS_COUNT:
			return {
				...state,
				chatsNotificationsCount: payload,
			};
		case SET_USERS_LIST:
			return {
				...state,
				usersList: payload,
			};
		case SET_IS_USERS_LIST_LOADING:
			return {
				...state,
				isUsersListLoading: payload,
			};

		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default profileModalReducer;
