/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const selectUsersContainerDomain = state => state.usersContainerReducer || initialState;

export const getIsUsersListLoadingSelector = () =>
	createSelector(selectUsersContainerDomain, ({ isUsersListLoading }) => isUsersListLoading);

export const getUsersListSelector = () =>
	createSelector(selectUsersContainerDomain, ({ usersList }) => {
		if (!usersList || usersList.length === 0) {
			return [];
		}

		return usersList.map(user => ({
			...user,
			name:
				user.first_name && user.last_name
					? `${user.first_name}${user.middle_name ? ` ${user.middle_name}` : ''} ${user.last_name}`
					: '-',
			role: user.role?.name || '-',
			post: user.post || '-',
			phone: user.phone ? `${user.phone}${user.extension ? `,${user.extension}` : ''}` : '-',
			email: user.email || '-',
		}));
	});

export const getPaginationSelector = () =>
	createSelector(selectUsersContainerDomain, ({ pagination }) => {
		if (!pagination || pagination?.total === 0) {
			return null;
		}

		return {
			currentPage: pagination.current_page,
			totalPages: pagination.last_page,
			perPage: pagination.per_page,
			total: pagination.total,
			from: pagination.current_page * pagination.per_page - (pagination.per_page - 1),
			to:
				pagination.current_page === pagination.last_page
					? pagination.total
					: pagination.current_page * pagination.per_page,
			isNextItem: true,
			isPrevItem: true,
			isLastItem: false,
			isFirstItem: false,
		};
	});

export const getTableSearchSelector = () =>
	createSelector(selectUsersContainerDomain, ({ tableSearch }) => tableSearch);

export const getRawPaginationSelector = () =>
	createSelector(selectUsersContainerDomain, ({ pagination }) => pagination);

export const getUnformattedUsersListSelector = () =>
	createSelector(selectUsersContainerDomain, ({ usersList }) => usersList);
