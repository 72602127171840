export const GET_USER_ROLE_INFO = 'users-roles-modal/GET_USER_ROLE_INFO';
export const getUserRoleInfoAction = id => ({
	type: GET_USER_ROLE_INFO,
	id,
});

export const SET_USER_ROLE_INFO = 'users-roles-modal/SET_USER_ROLE_INFO';
export const setUserRoleInfoAction = payload => ({
	type: SET_USER_ROLE_INFO,
	payload,
});

export const SET_IS_USER_ROLE_INFO_LOADING = 'users-roles-modal/SET_IS_USER_ROLE_INFO_LOADING';
export const setIsUserRoleInfoLoadingAction = payload => ({
	type: SET_IS_USER_ROLE_INFO_LOADING,
	payload,
});

export const DELETE_USER_ROLE = 'users-roles-modal/DELETE_USER_ROLE';
export const deleteUserRoleAction = payload => ({
	type: DELETE_USER_ROLE,
	payload,
});

export const CREATE_USER_ROLE = 'users-roles-modal/CREATE_USER_ROLE';
export const createUserRoleAction = payload => ({
	type: CREATE_USER_ROLE,
	payload,
});

export const UPDATE_USER_ROLE = 'users-roles-modal/UPDATE_USER_ROLE';
export const updateUserRoleAction = payload => ({
	type: UPDATE_USER_ROLE,
	payload,
});

export const SET_IS_USER_ROLE_SAVING = 'users-roles-modal/SET_IS_USER_ROLE_SAVING';
export const setIsUserRoleSavingAction = payload => ({
	type: SET_IS_USER_ROLE_SAVING,
	payload,
});

export const SET_IS_USER_ROLE_DELETING = 'users-roles-modal/SET_IS_USER_ROLE_DELETING';
export const setIsUserRoleDeletingAction = payload => ({
	type: SET_IS_USER_ROLE_DELETING,
	payload,
});

export const GET_RULES = 'users-roles-modal/GET_RULES';
export const getRulesAction = payload => ({
	type: GET_RULES,
	payload,
});

export const SET_RULES = 'users-roles-modal/SET_RULES';
export const setRulesAction = payload => ({
	type: SET_RULES,
	payload,
});

export const SET_IS_RULES_LOADING = 'users-roles-modal/SET_IS_RULES_LOADING';
export const setIsRulesLoadingAction = payload => ({
	type: SET_IS_RULES_LOADING,
	payload,
});

export const RESET_STATE = 'users-roles-modal/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});
