export const GET_CONTACTS = 'contacts/GET_CONTACTS';
export const getContactsAction = payload => ({
	type: GET_CONTACTS,
	payload,
});

export const SET_CONTACTS = 'contacts/SET_CONTACTS';
export const setContactsAction = payload => ({
	type: SET_CONTACTS,
	payload,
});

export const SET_IS_CONTACTS_LOADING = 'contacts/SET_IS_CONTACTS_LOADING';
export const setIsContactsLoadingAction = payload => ({
	type: SET_IS_CONTACTS_LOADING,
	payload,
});

export const SET_CONTACTS_SEARCH_VALUE = 'contacts/SET_CONTACTS_SEARCH_VALUE';
export const setContactsSearchValueAction = payload => ({
	type: SET_CONTACTS_SEARCH_VALUE,
	payload,
});

export const UPDATE_CONTACT_DEFAULT = 'contacts/UPDATE_CONTACT_DEFAULT';
export const updateContactDefaultAction = payload => ({
	type: UPDATE_CONTACT_DEFAULT,
	payload,
});

export const SET_PAGINATION = 'contacts/SET_PAGINATION';
export const setPaginationAction = payload => ({
	type: SET_PAGINATION,
	payload,
});

export const RESET_STATE = 'contacts/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});
