export const CREATE_DETAIL = 'detail-modal/CREATE_DETAIL';
export const createDetailAction = payload => ({
	type: CREATE_DETAIL,
	payload,
});

export const GET_DETAIL_INFO = 'detail-modal/GET_DETAIL_INFO';
export const getDetailInfoAction = payload => ({
	type: GET_DETAIL_INFO,
	payload,
});

export const SET_DETAIL_INFO = 'detail-modal/SET_DETAIL_INFO';
export const setDetailInfoAction = payload => ({
	type: SET_DETAIL_INFO,
	payload,
});

export const SET_IS_DETAIL_INFO_LOADING = 'detail-modal/SET_IS_DETAIL_INFO_LOADING';
export const setIsDetailInfoLoadingAction = payload => ({
	type: SET_IS_DETAIL_INFO_LOADING,
	payload,
});

export const DELETE_DETAIL = 'detail-modal/DELETE_DETAIL';
export const deleteDetailAction = payload => ({
	type: DELETE_DETAIL,
	payload,
});

export const UPDATE_DETAIL = 'detail-modal/UPDATE_DETAIL';
export const updateDetailAction = payload => ({
	type: UPDATE_DETAIL,
	payload,
});

export const SET_IS_DETAIL_UPDATING = 'detail-modal/SET_IS_DETAIL_UPDATING';
export const setIsDetailUpdatingAction = payload => ({
	type: SET_IS_DETAIL_UPDATING,
	payload,
});

export const SET_IS_DETAIL_DELETING = 'detail-modal/SET_IS_DETAIL_DELETING';
export const setIsDetailDeletingAction = payload => ({
	type: SET_IS_DETAIL_DELETING,
	payload,
});

export const RESET_STATE = 'detail-modal/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const SET_DETAIL_SEARCH_VALUE = 'detail-modal/SET_DETAIL_SEARCH_VALUE';
export const setDetailSearchValueAction = payload => ({
	type: SET_DETAIL_SEARCH_VALUE,
	payload,
});

export const GET_DETAILS_OPTIONS = 'detail-modal/GET_DETAILS_OPTIONS';
export const getDetailsOptionsAction = payload => ({
	type: GET_DETAILS_OPTIONS,
	payload,
});

export const SET_DETAILS_OPTIONS = 'detail-modal/SET_DETAILS_OPTIONS';
export const setDetailsOptionsAction = payload => ({
	type: SET_DETAILS_OPTIONS,
	payload,
});

export const SET_IS_DETAILS_OPTIONS_LOADING = 'detail-modal/SET_IS_DETAILS_OPTIONS_LOADING';
export const setIsDetailsOptionsLoadingAction = payload => ({
	type: SET_IS_DETAILS_OPTIONS_LOADING,
	payload,
});
