import {
	RESET_STATE,
	SET_ADDRESSES_OPTIONS,
	SET_ADDRESS_INFO,
	SET_ADDRESS_SEARCH_VALUE,
	SET_IS_ADDRESSES_OPTIONS_LOADING,
	SET_IS_ADDRESS_CREATING,
	SET_IS_ADDRESS_DELETING,
	SET_IS_ADDRESS_INFO_LOADING,
	SET_IS_ADDRESS_UPDATING,
} from './actions';

export const initialState = {
	isAddressInfoLoading: false,
	isAddressesOptionsLoading: false,
	isAddressDeleting: false,
	isAddressUpdating: false,
	isAddressCreating: false,
	addressInfo: [],
	addressSearchValue: '',
	addressesOptions: [],
	contactsSearchValue: '',
	contacts: [],
};

const addressesModalsReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_ADDRESS_INFO:
			return {
				...state,
				addressInfo: payload,
			};
		case SET_IS_ADDRESS_INFO_LOADING:
			return {
				...state,
				isAddressInfoLoading: payload,
			};
		case SET_ADDRESS_SEARCH_VALUE:
			return {
				...state,
				addressSearchValue: payload,
			};
		case SET_ADDRESSES_OPTIONS:
			return {
				...state,
				addressesOptions: payload,
			};
		case SET_IS_ADDRESSES_OPTIONS_LOADING:
			return {
				...state,
				isAddressesOptionsLoading: payload,
			};
		case SET_IS_ADDRESS_DELETING:
			return {
				...state,
				isAddressDeleting: payload,
			};
		case SET_IS_ADDRESS_UPDATING:
			return {
				...state,
				isAddressUpdating: payload,
			};
		case SET_IS_ADDRESS_CREATING:
			return {
				...state,
				isAddressCreating: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default addressesModalsReducer;
