import React from 'react';
import PropTypes from 'prop-types';
import { TABLE_COLUMNS_NAMES } from '../../constants';
import { SimpleTableComponent } from '../../../../components/simple-table';
import { UserForm } from '../user-form';

export const UserContent = ({ userInfo, isUserInfoLoading, isUserFormOpen }) => {
	return isUserFormOpen ? (
		<UserForm />
	) : (
		<SimpleTableComponent rows={userInfo} isTableDataLoading={isUserInfoLoading} columns={TABLE_COLUMNS_NAMES} />
	);
};

UserContent.propTypes = {
	userInfo: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.shape({
					name: PropTypes.string,
					role: PropTypes.string,
					id: PropTypes.number,
				}),
			]).isRequired,
		}),
	),
	isUserInfoLoading: PropTypes.bool.isRequired,
	isUserFormOpen: PropTypes.bool.isRequired,
};
