export const GET_DICTIONARY_DATA = 'companies-types-dictionary/GET_DICTIONARY_DATA';
export const getDictionaryDataAction = () => ({
	type: GET_DICTIONARY_DATA,
});

export const SET_DICTIONARY_DATA = 'companies-types-dictionary/SET_DICTIONARY_DATA';
export const setDictionaryDataAction = payload => ({
	type: SET_DICTIONARY_DATA,
	payload,
});

export const SET_IS_DICTIONARY_DATA_LOADING = 'companies-types-dictionary/SET_IS_DICTIONARY_DATA_LOADING';
export const setIsDictionaryDataLoadingAction = payload => ({
	type: SET_IS_DICTIONARY_DATA_LOADING,
	payload,
});

export const RESET_STATE = 'companies-types-dictionary/RESET_STATE';
export const resetStateAction = payload => ({
	type: RESET_STATE,
	payload,
});
