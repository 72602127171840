export const MOCK_DATA = {
	rosterNumber: '000001',
	rosterDate: '12.12.2024',
	companyName: 'OOO "РФАРМ"',
	companyAddress: 'г. Москва, Филёвский бульвар, 40',
	cargo: [
		{
			companyName: 'АО "БИОКАД"',
			companyAddress: 'г. Смоленск, ул. Смоленская, д. 4',
			kg: '1400',
			volume: '15',
			temperature: '8-25, 2-8, 2-15',
			deliveryDate: '14 / 10 / 2024',
			specialMarks: '',
			id: 1,
		},
		{
			companyName: 'АО "БИОКАД"',
			companyAddress: 'г. Смоленск, ул. Смоленская, д. 4',
			kg: '1400',
			volume: '15',
			temperature: '8-25, 2-8, 2-15',
			deliveryDate: '14 / 10 / 2024',
			specialMarks: '',
			id: 2,
		},
		{
			companyName: 'АО "БИОКАД"',
			companyAddress: 'г. Смоленск, ул. Смоленская, д. 4',
			kg: '1400',
			volume: '15',
			temperature: '8-25, 2-8, 2-15',
			deliveryDate: '14 / 10 / 2024',
			specialMarks: '',
			id: 3,
		},
	],
};
