import React from 'react';
import './styles.less';
import { TodoComponent } from '../todo';
import { RequestsInfoComponent } from '../requests-info';
import { AccountsInfoComponent } from '../accounts-info';

export const InfoWrapper = () => {
	return (
		<div className="info-wrapper">
			<section className="info-wrapper__right-section">
				<TodoComponent />
			</section>
			<section className="info-wrapper__left-section">
				<RequestsInfoComponent />
				<AccountsInfoComponent />
			</section>
		</div>
	);
};
