import {
	RESET_STATE,
	SET_USER_INFO,
	SET_IS_USER_DELETING,
	SET_IS_USER_INFO_LOADING,
	SET_IS_USER_SAVING,
	SET_IS_COMPANIES_LIST_LOADING,
	SET_COMPANIES_LIST,
	SET_USERS_ROLES_LIST,
	SET_IS_USERS_ROLES_LIST_LOADING,
	SET_USERS_LIST_IN_MODAL,
	SET_IS_USERS_LIST_IN_MODAL_LOADING,
} from './actions';

export const initialState = {
	userInfo: null,
	isUserInfoLoading: false,
	isUserDeleting: false,
	isUserSaving: false,
	companiesList: [],
	isCompaniesListLoading: false,
	usersRolesList: [],
	isUsersRolesListLoading: false,
	usersList: [],
	isUsersListLoading: false,
};

const usersModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_USER_INFO:
			return {
				...state,
				userInfo: payload,
			};
		case SET_IS_USER_INFO_LOADING:
			return {
				...state,
				isUserInfoLoading: payload,
			};
		case SET_IS_USER_DELETING:
			return {
				...state,
				isUserDeleting: payload,
			};
		case SET_IS_USER_SAVING:
			return {
				...state,
				isUserSaving: payload,
			};
		case SET_COMPANIES_LIST:
			return {
				...state,
				companiesList: payload,
			};
		case SET_IS_COMPANIES_LIST_LOADING:
			return {
				...state,
				isCompaniesListLoading: payload,
			};
		case SET_USERS_ROLES_LIST:
			return {
				...state,
				usersRolesList: payload,
			};
		case SET_IS_USERS_ROLES_LIST_LOADING:
			return {
				...state,
				isUsersRolesListLoading: payload,
			};
		case SET_USERS_LIST_IN_MODAL:
			return {
				...state,
				usersList: payload,
			};
		case SET_IS_USERS_LIST_IN_MODAL_LOADING:
			return {
				...state,
				isUsersListLoading: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default usersModalReducer;
