import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';

const StatusButton = ({ button, onClick, width }) => {
	return (
		<div
			key={button.name}
			className={`status-button status-button_${button.color}`}
			onClick={onClick}
			style={{ width }}
		>
			{button.name}
		</div>
	);
};

StatusButton.propTypes = {
	button: PropTypes.shape({
		name: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
	}).isRequired,
	onClick: PropTypes.func.isRequired,
	width: PropTypes.string,
};

export default StatusButton;
