import React from 'react';
import { Grid } from 'semantic-ui-react';
import './styles.less';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormInputField } from '../../../../semantic-ui/components/form-input-field';
import { FormSelectField } from '../../../../semantic-ui/components/form-select-field';

export const CarForm = ({
	trailersList,
	isTrailersListLoading,
	onAddTrailerButtonClick,
	isCarInfoLoading,
	tonnages,
	isTonnagesLoading,
}) => {
	return (
		<Grid className="car-form">
			<Grid.Row className="car-form__row">
				<Grid.Column width={6} className="car-form__column">
					Модель
				</Grid.Column>
				<Grid.Column width={7} className="car-form__column">
					<Field
						name="name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите модель"
						loading={isCarInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="car-form__row">
				<Grid.Column width={6} className="car-form__column">
					Гос. номер
				</Grid.Column>
				<Grid.Column width={7} className="car-form__column">
					<Field
						name="number"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите гос. номер"
						loading={isCarInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="car-form__row">
				<Grid.Column width={6} className="car-form__column">
					Vin номер
				</Grid.Column>
				<Grid.Column width={7} className="car-form__column">
					<Field
						name="vin"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите vin номер"
						loading={isCarInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="car-form__row">
				<Grid.Column width={6} className="car-form__column">
					Прицеп по умолчанию
				</Grid.Column>
				<Grid.Column width={7} className="car-form__column">
					<div className="car-form__column-with-button">
						<div className="car-form__field-with-button">
							<Field
								name="trailer"
								type="text"
								component={FormSelectField}
								autoComplete="off"
								options={trailersList}
								search
								dropdownClassName="static"
								loading={isTrailersListLoading || isCarInfoLoading}
								noResultsMessage="Не найдено"
							/>
						</div>
						<div className="car-form__add-button" onClick={onAddTrailerButtonClick} />
					</div>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="car-form__row">
				<Grid.Column width={6} className="car-form__column">
					Грузоподъемность
				</Grid.Column>
				<Grid.Column width={7} className="car-form__column car-form__column_with-select">
					<Field
						name="tonnage_id"
						type="text"
						component={FormSelectField}
						autoComplete="off"
						options={tonnages}
						search
						dropdownClassName="static"
						loading={isTonnagesLoading || isCarInfoLoading}
						disabled={isTonnagesLoading || isCarInfoLoading}
						noResultsMessage="Не найдено"
						placeholder="Укажите грузоподъемность прицепа (тн)"
						isWithoutSelectOnBlur
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

CarForm.propTypes = {
	trailersList: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			key: PropTypes.string.isRequired,
		}),
	),
	isTrailersListLoading: PropTypes.bool.isRequired,
	onAddTrailerButtonClick: PropTypes.func.isRequired,
	isCarInfoLoading: PropTypes.bool.isRequired,
	isTonnagesLoading: PropTypes.bool.isRequired,
	tonnages: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
};
