import { CustomCell } from '../../components/custom-cell';

export const TABLE_COLUMNS = [
	{ name: '#', id: 'id', width: '113px', minWidth: '113px', customComponent: CustomCell },
	{ name: 'ФИО', id: 'name', width: '390px', customComponent: CustomCell },
	{ name: 'Роль', id: 'role', width: '260px' },
	{ name: 'Должность', id: 'post', width: '260px' },
	{ name: 'Телефон', id: 'phone', width: '260px', customComponent: CustomCell },
	{ name: 'Почта', id: 'email' },
];

export const NAV_PAGE_LIST = [{ text: 'Пользователи', href: '/users' }];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';
