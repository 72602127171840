export const LOGIN_FORM_NAME = 'login-form';

export const LOGIN_FORM_REQUIRED_FIELDS = ['email', 'password'];

export const LOGIN_MODAL_STYLE = { maxWidth: '505px', minHeight: '427px' };

export const FORGOT_MODAL_STYLE = { maxWidth: '505px', minHeight: '373px' };

export const FORGOT_FORM_NAME = 'forgot-form';

export const FORGOT_FORM_REQUIRED_FIELDS = ['email'];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const COMPANIES_TYPES_BY_ID = {
	1: 'shipper',
	2: 'shipper',
	3: 'carrier',
};
