import {
	RESET_STATE,
	SET_DICTIONARY_DATA,
	SET_IS_DICTIONARY_DATA_LOADING,
	SET_PAGINATION,
	SET_TABLE_SEARCH,
} from './actions';

export const initialState = {
	dictionaryData: null,
	isDictionaryDataLoading: false,
	pagination: null,
	tableSearch: '',
};

const companiesDictionaryReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_DICTIONARY_DATA:
			return {
				...state,
				dictionaryData: payload,
			};
		case SET_IS_DICTIONARY_DATA_LOADING:
			return {
				...state,
				isDictionaryDataLoading: payload,
			};
		case SET_PAGINATION:
			return {
				...state,
				pagination: payload,
			};
		case SET_TABLE_SEARCH:
			return {
				...state,
				tableSearch: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default companiesDictionaryReducer;
