export const getFieldOptions = (value, text, data) =>
	data.map((item, index) => ({ key: `${item[value]}${index}`, text: item[text], value: String(item[value]) }));

export const thousandSeparator = str => {
	const parts = `${str}`.split('.');

	const main = parts[0];

	const len = main.length;
	let output = '';
	let i = len - 1;

	while (i >= 0) {
		output = main.charAt(i) + output;
		if ((len - i) % 3 === 0 && i > 0) {
			output = ` ${output}`;
		}
		--i;
	}

	if (parts.length > 1) {
		output += `.${parts[1]}`;
	}

	return output;
};

export const flattenObject = (obj, prefix = '') => {
	const result = {};

	// eslint-disable-next-line no-restricted-syntax
	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const value = obj[key];

			const propName = prefix ? `${prefix}.${key}` : `filter.${key}`;

			if (typeof value === 'object' && !Array.isArray(value)) {
				const flattened = flattenObject(value, propName);
				Object.assign(result, flattened);
			} else if (Array.isArray(value)) {
				const filteredValues = value.filter(item => item !== 'all');
				if (filteredValues.length > 0) {
					result[propName] = filteredValues.join(',');
				}
			} else if (value !== 'all') {
				result[propName] = value;
			}
		}
	}

	return result;
};

export const scrollToTop = () => {
	window.scrollTo({
		top: 0,
		left: 0,
		behavior: 'smooth',
	});
};
