import React from 'react';
import { Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './styles.less';

const FileUploader = ({
	onFileUploadedChange,
	links,
	onUploadedFileDelete,
	isOnlyOneFileToUpload = false,
	acceptFileTypes = [],
}) => {
	return (
		<>
			<div className="file-uploader">
				<div className="file-uploader__wrapper">
					<div className="file-uploader__file">
						{!(isOnlyOneFileToUpload && links?.length >= 1) && (
							<Input
								data-testid="file-uploader-input"
								onChange={onFileUploadedChange}
								type="file"
								id="input__file"
								accept={acceptFileTypes.join(',')}
								multiple={!isOnlyOneFileToUpload}
							/>
						)}
						<div
							data-testid="file-uploader-icon-wrapper"
							className={`file-uploader__icon-wrapper ${
								isOnlyOneFileToUpload && links?.length >= 1
									? 'file-uploader__icon-wrapper_disabled'
									: ''
							}`}
						/>
					</div>
					<div className="file-uploader__files">
						{links?.map(({ name }) => (
							<div data-testid="file-uploader" className="file-uploader__text" key={name}>
								{name}
								{onUploadedFileDelete && (
									<div
										className="file-uploader__delete-icon-wrapper"
										onClick={e => onUploadedFileDelete(e, name)}
									/>
								)}
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

FileUploader.propTypes = {
	onFileUploadedChange: PropTypes.func,
	/* Функция добавления файла */
	onUploadedFileDelete: PropTypes.func,
	/* Функция удаления файла */
	links: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
		}),
	),
	/* Список загруженных файлов */
	isOnlyOneFileToUpload: PropTypes.bool,
	/* Возможно ли загружать больше 1 файла */
	acceptFileTypes: PropTypes.arrayOf(PropTypes.string),
	/* Возможные типы файлов для загрузки */
};

export { FileUploader };
