export const CHANGE_DRIVER = 'driver-change-modal/CHANGE_DRIVER';
export const changeDriverAction = payload => ({
	type: CHANGE_DRIVER,
	payload,
});

export const SET_IS_DRIVER_CHANGING = 'driver-change-modal/SET_IS_DRIVER_CHANGING';
export const setIsDriverChangingAction = payload => ({
	type: SET_IS_DRIVER_CHANGING,
	payload,
});

export const RESET_STATE = 'driver-change-modal/RESET_STATE';
export const resetStateAction = payload => ({
	type: RESET_STATE,
	payload,
});

export const GET_REQUEST_INFO = 'driver-change-modal/GET_REQUEST_INFO';
export const getRequestInfoAction = payload => ({
	type: GET_REQUEST_INFO,
	payload,
});

export const SET_REQUEST_INFO = 'driver-change-modal/SET_REQUEST_INFO';
export const setRequestInfoAction = payload => ({
	type: SET_REQUEST_INFO,
	payload,
});

export const SET_IS_REQUEST_INFO_LOADING = 'driver-change-modal/SET_IS_REQUEST_INFO_LOADING';
export const setIsRequestInfoLoadingAction = payload => ({
	type: SET_IS_REQUEST_INFO_LOADING,
	payload,
});

export const GET_DRIVERS = 'driver-change-modal/GET_DRIVERS';
export const getDriversAction = payload => ({
	type: GET_DRIVERS,
	payload,
});

export const SET_DRIVERS = 'driver-change-modal/SET_DRIVERS';
export const setDriversAction = payload => ({
	type: SET_DRIVERS,
	payload,
});

export const SET_IS_DRIVERS_LOADING = 'driver-change-modal/SET_IS_DRIVERS_LOADING';
export const setIsDriversLoadingAction = payload => ({
	type: SET_IS_DRIVERS_LOADING,
	payload,
});
