import {
	RESET_STATE,
	SET_ADDRESSES,
	SET_ADDRESSES_SEARCH_VALUE,
	SET_IS_ADDRESSES_LOADING,
	SET_PAGINATION,
} from './actions';

export const initialState = {
	addresses: [],
	addressesSearchValue: '',
	pagination: null,
	isAddressesLoading: false,
};

const addressesReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_ADDRESSES:
			return {
				...state,
				addresses: payload,
			};
		case SET_IS_ADDRESSES_LOADING:
			return {
				...state,
				isAddressesLoading: payload,
			};
		case SET_ADDRESSES_SEARCH_VALUE:
			return {
				...state,
				addressesSearchValue: payload,
			};
		case SET_PAGINATION:
			return {
				...state,
				pagination: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default addressesReducer;
