export const SEARCH_REQS = 'header-search/SEARCH_REQS';
export const searchReqsAction = payload => ({
	type: SEARCH_REQS,
	payload,
});

export const SET_SEARCH_VALUE = 'header-search/SET_SEARCH_VALUE';
export const setSearchValueAction = payload => ({
	type: SET_SEARCH_VALUE,
	payload,
});

export const SET_FOUND_REQS = 'header-search/SET_FOUND_REQS';
export const setFoundReqsAction = payload => ({
	type: SET_FOUND_REQS,
	payload,
});
