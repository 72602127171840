import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';
import { Loader } from 'semantic-ui-react';
import { DOCUMENTS_TAB_ICONS } from '../../constants';

export const ReqsDocumentsTab = ({ documentsData, isDocumentsLoading }) => {
	return (
		<div className="reqs-documents-tab">
			{isDocumentsLoading ? (
				<Loader active size="big" />
			) : (
				<>
					{documentsData.length > 0 ? (
						documentsData.map(({ name, documents }) => (
							<div className="reqs-documents-tab__section" key={name}>
								{name && <div className="reqs-documents-tab__section-name">{name}</div>}
								<div className="reqs-documents-tab__section-docs">
									{documents.map(({ icon, name, link, signed, generated, attached, description }) => (
										<div
											className={`reqs-documents-tab__section-docs-item${
												!link ? ' reqs-documents-tab__section-docs-item_disabled' : ''
											}`}
											key={name}
										>
											<div className="reqs-documents-tab__section-docs-item-info">
												<div className="reqs-documents-tab__doc-col-wrapper">
													<div className="reqs-documents-tab__doc-col_first">
														<img src={DOCUMENTS_TAB_ICONS[icon]} alt={icon} />
														<div className="reqs-documents-tab__doc-name">
															{name}
															{description && (
																<div className="reqs-documents-tab__doc-description">
																	{description}
																</div>
															)}
														</div>
													</div>
												</div>
												<div className="reqs-documents-tab__doc-col_second">
													{(signed || generated || attached) && (
														<>
															{signed && (
																<div className="reqs-documents-tab__doc-col-item">
																	<span className="reqs-documents-tab__doc-col-item_bold">
																		Подписано:{' '}
																	</span>
																	{signed}
																</div>
															)}
															{generated && (
																<div className="reqs-documents-tab__doc-col-item">
																	<span className="reqs-documents-tab__doc-col-item_bold">
																		Сформировано:{' '}
																	</span>
																	{generated}
																</div>
															)}
															{attached && (
																<div className="reqs-documents-tab__doc-col-item">
																	<span className="reqs-documents-tab__doc-col-item_bold">
																		Прикреплено:{' '}
																	</span>
																	{attached}
																</div>
															)}
														</>
													)}
												</div>
											</div>
											<div className="reqs-documents-tab__doc-col_third">
												<a
													href={link}
													className="reqs-documents-tab__doc-col-link"
													target="_blank"
													rel="noopener noreferrer"
												>
													<img src={DOCUMENTS_TAB_ICONS.download} alt="download" />
												</a>
											</div>
										</div>
									))}
								</div>
							</div>
						))
					) : (
						<div className="reqs-documents-tab__no-data">Данные не найдены</div>
					)}
				</>
			)}
		</div>
	);
};

ReqsDocumentsTab.propTypes = {
	documentsData: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			documents: PropTypes.arrayOf(
				PropTypes.shape({
					name: PropTypes.string.isRequired,
					link: PropTypes.string,
					icon: PropTypes.string.isRequired,
					signed: PropTypes.string,
					generated: PropTypes.string,
					attached: PropTypes.string,
				}),
			).isRequired,
		}),
	).isRequired,
	isDocumentsLoading: PropTypes.bool.isRequired,
};
