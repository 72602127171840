export const GET_INFORMATION = 'information/GET_INFORMATION';
export const getInformationAction = payload => ({
	type: GET_INFORMATION,
	payload,
});

export const SET_INFORMATION = 'information/SET_INFORMATION';
export const setInformationAction = payload => ({
	type: SET_INFORMATION,
	payload,
});

export const SET_ACTIVE_NAV_ITEM = 'information/SET_ACTIVE_NAV_ITEM';
export const setActiveNavItemAction = payload => ({
	type: SET_ACTIVE_NAV_ITEM,
	payload,
});

export const SET_IS_INFORMATION_LOADING = 'information/SET_IS_INFORMATION_LOADING';
export const setIsInformationLoadingAction = payload => ({
	type: SET_IS_INFORMATION_LOADING,
	payload,
});

export const SET_IS_INFO_COMPANY_CHECKED = 'information/SET_IS_INFO_COMPANY_CHECKED';
export const setIsInfoCompanyCheckedAction = payload => ({
	type: SET_IS_INFO_COMPANY_CHECKED,
	payload,
});

export const RESET_STATE = 'information/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});
