import { createSelector } from 'reselect';
import { getFormValues, isInvalid } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import { initialState } from './reducer';
import { DETAIL_TABLE_ROWS_NAMES, DETAIL_FORM_NAME } from './constants';

export const selectDetailModalDomain = state => state.detailModalReducer || initialState;

export const selectRouterDomain = state => state.router || {};

export const getIsDetailInfoLoadingSelector = () =>
	createSelector(
		selectDetailModalDomain,
		({ isDetailInfoLoading, isDetailDeleting, isDetailUpdating }) =>
			isDetailInfoLoading || isDetailDeleting || isDetailUpdating,
	);

export const getDetailInfoSelector = () =>
	createSelector(selectDetailModalDomain, ({ detailInfo }) =>
		Object.entries(DETAIL_TABLE_ROWS_NAMES).reduce((result, [key, value]) => {
			if (key === 'author') {
				result.push({
					id: key,
					title: value,
					value: {
						name: `${detailInfo?.user?.last_name || ''} ${detailInfo?.user?.first_name || ''}`,
						role: detailInfo?.user?.role?.name || '',
					},
				});

				return result;
			}

			if (key === 'is_default') {
				result.push({ id: key, title: value, value: detailInfo[key] ? 'Да' : 'Нет' });
			} else {
				result.push({ id: key, title: value, value: detailInfo[key] ? String(detailInfo[key]) : '---' });
			}

			return result;
		}, []),
	);

export const getFormInitialValuesSelector = () =>
	createSelector(selectDetailModalDomain, getFormValues(DETAIL_FORM_NAME), ({ detailInfo }, formValues) => {
		if (isEmpty(detailInfo) && isEmpty(formValues)) {
			return { is_default: true };
		}

		return { ...detailInfo, ...formValues };
	});

export const getDetailNameSelector = () =>
	createSelector(selectDetailModalDomain, ({ detailInfo }) => detailInfo?.name || '-');

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'detail');
	});

export const getActiveDetailIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => (query?.modal === 'detail' ? query?.id : null));

export const getIsDetailAddFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'detail' && query?.mode === 'add');
	});

export const getIsDetailEditFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'detail' && Boolean(query?.id) && query?.mode === 'edit');
	});

export const getIsAuditTabShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'detail' && query?.tab === 'audit');
	});

export const getDetailFormValuesSelector = () =>
	createSelector(getFormValues(DETAIL_FORM_NAME), formValues => formValues);

export const getDetailSearchValueSelector = () =>
	createSelector(selectDetailModalDomain, ({ detailSearchValue }) => detailSearchValue);

export const getIsDetailsOptionsLoadingSelector = () =>
	createSelector(selectDetailModalDomain, ({ isDetailsOptionsLoading }) => isDetailsOptionsLoading);

export const getDetailsSelector = () => createSelector(selectDetailModalDomain, ({ detailsOptions }) => detailsOptions);

export const getDetailsOptionsSelector = () =>
	createSelector(selectDetailModalDomain, ({ detailsOptions }) =>
		detailsOptions.map(detail => ({
			key: detail.data.bic,
			value: detail.data.bic,
			data: {
				title: detail.value,
				address: `${detail.data?.bic || ''} ${detail.data?.address?.value || ''}`,
				deleted: detail.data?.state?.status === 'LIQUIDATED',
			},
		})),
	);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(DETAIL_FORM_NAME), isInvalid => isInvalid);
