export const SEND_REASON = 'refusal-reason/SEND_REASON';
export const sendReasonAction = payload => ({
	type: SEND_REASON,
	payload,
});

export const SET_IS_REASON_SENDING = 'refusal-reason/SET_IS_REASON_SENDING';
export const setIsReasonSendingAction = payload => ({
	type: SET_IS_REASON_SENDING,
	payload,
});
