import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { notificationInit } from '../../modules/notifications/actions';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { GET_CHATS_LIST, setIsChatsListLoadingAction, setChatsListAction } from './actions';
import { DISMISS_TIME } from '../../api/constants';
import { errorHandler } from '../../api/utils';
import { getChatsListRequest } from '../../api/requests';

export function* getChatsListSaga() {
	try {
		yield put(setIsChatsListLoadingAction(true));

		const { data, message, toast } = yield call(getChatsListRequest);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setChatsListAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsChatsListLoadingAction(false));
	}
}

export default function* chatsListSaga() {
	yield takeEvery(GET_CHATS_LIST, getChatsListSaga);
}
