import React, { Fragment } from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import arrowIcon from '../../../../components/icons/chat-arrow.svg';
import checkIcon from '../../../../components/icons/check.svg';
import checkGreenIcon from '../../../../components/icons/check-green.svg';

const ChatModalView = ({ messages, onTextChange, onMessageSend, messageText, onKeyPress, isMessagesLoading }) => {
	return (
		<div className="chat-modal">
			{isMessagesLoading && <Loader active />}
			<div className="chat-modal__content">
				<div className="chat-modal__messages">
					{messages.map(message => (
						<Fragment key={message.date}>
							<span className="chat-modal__date">{message.date}</span>
							{message.messages.map(item => (
								<div
									key={item.id}
									className={`${
										item.isMy ? 'chat-modal__message_my ' : 'chat-modal__message_foreign '
									}chat-modal__message`}
								>
									<div className="chat-modal__message-content">
										{!item.isMy && (
											<>
												<span className="chat-modal__text chat-modal__text_small">
													{item.companyName}
												</span>
												<span className="chat-modal__text chat-modal__text_small">
													<span className="chat-modal__text chat-modal__text_bold chat-modal__text_small">
														{item.author} / {item.role}
													</span>{' '}
												</span>
											</>
										)}

										<span
											className="chat-modal__text chat-modal__text_message"
											dangerouslySetInnerHTML={{ __html: item.text }}
										/>
									</div>
									<span className="chat-modal__time">{item.time}</span>
									{item.isMy && (
										<>
											<img
												className="chat-modal__check-icon"
												src={item.isRead ? checkGreenIcon : checkIcon}
												alt="check"
											/>
											{item.isMy && item.isSend && (
												<img
													className="chat-modal__check-icon chat-modal__check-icon_last"
													src={item.isRead ? checkGreenIcon : checkIcon}
													alt="check"
												/>
											)}
										</>
									)}
								</div>
							))}
						</Fragment>
					))}
				</div>
			</div>
			<div className="chat-modal__footer">
				<div className="chat-modal__field">
					<input
						onChange={onTextChange}
						value={messageText}
						onKeyDown={onKeyPress}
						type="text"
						placeholder="Сообщение..."
						className="chat-modal__input"
					/>
				</div>
				<div className="chat-modal__icon" onClick={onMessageSend}>
					<img src={arrowIcon} alt="arrow" />
				</div>
			</div>
		</div>
	);
};

ChatModalView.propTypes = {
	currentDate: PropTypes.string.isRequired,
	onTextChange: PropTypes.func.isRequired,
	onMessageSend: PropTypes.func.isRequired,
	messageText: PropTypes.string.isRequired,
	onKeyPress: PropTypes.func.isRequired,
	isMessagesLoading: PropTypes.bool.isRequired,
	messages: PropTypes.arrayOf(
		PropTypes.shape({
			date: PropTypes.string,
			messages: PropTypes.arrayOf(
				PropTypes.shape({
					author: PropTypes.string.isRequired,
					companyName: PropTypes.string.isRequired,
					id: PropTypes.number.isRequired,
					isMy: PropTypes.bool.isRequired,
					isRead: PropTypes.bool.isRequired,
					isSend: PropTypes.bool.isRequired,
					role: PropTypes.string.isRequired,
					text: PropTypes.string.isRequired,
					time: PropTypes.string.isRequired,
				}),
			),
		}),
	).isRequired,
};

export { ChatModalView };
