/*
 *
 * Book items list component
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Notification } from '../../semantic-ui/components/notification';
import { getNotificationSelector } from '../../modules/notifications/selectors';

import './styles.less';

const NotificationsList = ({ notifications }) => (
	<div className={notifications?.length === 0 ? 'notifications_hidden notifications' : 'notifications'}>
		<ul className="notifications__list">
			{notifications.map(item => (
				<li key={item.id}>
					<Notification notificationInfo={item} />
				</li>
			))}
		</ul>
	</div>
);

NotificationsList.propTypes = {
	notifications: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = createStructuredSelector({
	notifications: getNotificationSelector(),
});

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const NotificationList = compose(withConnect)(NotificationsList);

export { NotificationList };
