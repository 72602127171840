import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { initialState } from './reducer';

export const selectDocumentAttachmentModalDomain = state => state.documentAttachmentModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'document_attachment_form');
	});

export const getDocumentsSelector = () =>
	createSelector(selectDocumentAttachmentModalDomain, ({ documents }) => {
		return documents;
	});

export const getFilesSelector = () =>
	createSelector(selectDocumentAttachmentModalDomain, ({ files }) => {
		return files;
	});

export const getDataForSaveSelector = () =>
	createSelector(selectDocumentAttachmentModalDomain, ({ files, documents }) => {
		const formData = new FormData();

		Object.entries(files || {}).forEach(([key, value]) => {
			const currentDoc = documents.find(doc => doc.key === key);
			value.forEach(file => {
				formData.append(currentDoc?.is_multiple ? `${key}[]` : key, file.file, file.name);
			});
		});

		return formData;
	});

export const getFormErrorsSelector = () =>
	createSelector(selectDocumentAttachmentModalDomain, ({ formErrors }) => {
		return formErrors;
	});

export const getIsFormLoadingSelector = () =>
	createSelector(selectDocumentAttachmentModalDomain, ({ isDocumentsLoading, isFormSending }) => {
		return isDocumentsLoading || isFormSending;
	});
