export const GET_CHATS_LIST = 'chats-list/GET_CHATS_LIST';
export const getChatsListAction = payload => ({
	type: GET_CHATS_LIST,
	payload,
});

export const SET_CHATS_LIST = 'chats-list/SET_CHATS_LIST';
export const setChatsListAction = payload => ({
	type: SET_CHATS_LIST,
	payload,
});

export const SET_IS_CHATS_LIST_LOADING = 'chats-list/SET_IS_CHATS_LIST_LOADING';
export const setIsChatsListLoadingAction = payload => ({
	type: SET_IS_CHATS_LIST_LOADING,
	payload,
});

export const RESET_STATE = 'chats-list/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});
