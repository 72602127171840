import React from 'react';
import PropTypes from 'prop-types';
import { CAR_TABLE_COLUMNS_NAMES } from '../../constants';
import { SimpleTableComponent } from '../../../../components/simple-table';
import { CarForm } from '../car-form';

export const CarContent = ({
	carInfo,
	isCarInfoLoading,
	isCarFormOpen,
	trailersList,
	isTrailersListLoading,
	onAddTrailerButtonClick,
	tonnages,
	isTonnagesLoading,
}) => {
	return isCarFormOpen ? (
		<CarForm
			trailersList={trailersList}
			isTrailersListLoading={isTrailersListLoading}
			onAddTrailerButtonClick={onAddTrailerButtonClick}
			isCarInfoLoading={isCarInfoLoading}
			tonnages={tonnages}
			isTonnagesLoading={isTonnagesLoading}
		/>
	) : (
		<SimpleTableComponent rows={carInfo} isTableDataLoading={isCarInfoLoading} columns={CAR_TABLE_COLUMNS_NAMES} />
	);
};

CarContent.propTypes = {
	carInfo: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.shape({
					name: PropTypes.string,
					role: PropTypes.string,
				}),
			]).isRequired,
		}),
	),
	isCarInfoLoading: PropTypes.bool.isRequired,
	isCarFormOpen: PropTypes.bool.isRequired,
	trailersList: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			key: PropTypes.string.isRequired,
		}),
	),
	isTrailersListLoading: PropTypes.bool.isRequired,
	onAddTrailerButtonClick: PropTypes.func.isRequired,
	isTonnagesLoading: PropTypes.bool.isRequired,
	tonnages: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
};
