import { URL } from './constants';
import { getToken, makeRequest } from './utils';

export const getRequestInfoRequest = id =>
	makeRequest(`${URL}/api/reqs/${id}`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getRequestInfoWsRequest = id =>
	makeRequest(`${URL}/api/reqs/${id}/ws`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const searchReqsRequest = parameters =>
	makeRequest(
		`${URL}/api/reqs/search`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);
