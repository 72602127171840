import { DADATA_TOKEN, DADATA_URL, URL } from './constants';
import { getToken, makeRequest } from './utils';

export const getPartnersRequest = parameters =>
	makeRequest(
		`${URL}/api/partners`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getPartnerInfoRequest = id =>
	makeRequest(`${URL}/api/partners/${id}`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const createPartnerRequest = payload =>
	makeRequest(`${URL}/api/partners`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});

export const updatePartnerRequest = (id, payload) =>
	makeRequest(`${URL}/api/partners/${id}`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});

export const deletePartnerRequest = id =>
	makeRequest(`${URL}/api/partners/${id}`, {
		method: 'DELETE',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getPartnersBySearchRequest = payload =>
	makeRequest(`${DADATA_URL}/suggest/party`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Token ${DADATA_TOKEN}`,
		},
		body: JSON.stringify({ query: payload }),
	});

export const getPartnerAddressesRequest = (parameters, partnerId) =>
	makeRequest(
		`${URL}/api/partners/${partnerId}/addresses`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getPartnerAddressInfoRequest = (partnerId, addressId) =>
	makeRequest(`${URL}/api/partners/${partnerId}/addresses/${addressId}`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const createPartnerAddressRequest = (payload, partnerId) =>
	makeRequest(`${URL}/api/partners/${partnerId}/addresses`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});

export const updatePartnerAddressRequest = (payload, partnerId, addressId) =>
	makeRequest(`${URL}/api/partners/${partnerId}/addresses/${addressId}`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});

export const deletePartnerAddressRequest = (partnerId, addressId) =>
	makeRequest(`${URL}/api/partners/${partnerId}/addresses/${addressId}`, {
		method: 'DELETE',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});
