import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';

export const CargoDeliveryBadge = ({ text }) => {
	return <div className="cargo-delivery-badge">{text}</div>;
};

CargoDeliveryBadge.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
