import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getTabsSelector } from './selectors';
import { PageContentWithNav } from '../../components/page-content-with-nav';
import RequestsListContainer from '../reqs-list';

const ReqsMineContainerWrapper = ({ tabs }) => {
	return <PageContentWithNav pageNavigationItems={tabs} pageContent={<RequestsListContainer />} isInnerRouter />;
};

ReqsMineContainerWrapper.propTypes = {
	tabs: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = createStructuredSelector({
	tabs: getTabsSelector(),
});

const ReqsMineContainer = connect(mapStateToProps)(ReqsMineContainerWrapper);

export default ReqsMineContainer;
