import { SubmissionError } from 'redux-form';
import { FIELDS_FOR_VALIDATE } from './constants';

export const transformToValidationErrors = errors => {
	return Object.entries(errors).reduce((result, [key, value]) => {
		return {
			...result,
			[key]: value[0],
		};
	}, {});
};

export const filterTruePropertiesToArray = obj => {
	const filteredArray = [];
	// eslint-disable-next-line no-restricted-syntax
	for (const key in obj) {
		if (obj[key] === true) {
			filteredArray.push(key);
		}
	}

	return filteredArray;
};

export const validate = values => {
	FIELDS_FOR_VALIDATE.forEach(key => {
		if (!values?.[key]) {
			throw new SubmissionError({ [key]: 'Заполните это поле' });
		}
	});
};
