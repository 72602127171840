import { createSelector } from 'reselect';
import { getLocalStorageItem } from '../../utils/localStorage';

export const getIsCompanyValidateSelector = () =>
	createSelector(
		state => state.profileModalReducer?.userInfo,
		userInfo => {
			if (!userInfo) {
				const user_data = getLocalStorageItem('user_data');

				return user_data && user_data.isCompanyValidated;
			}

			return Boolean(userInfo?.company?.is_validated);
		},
	);

export const getIsEmailVerifySelector = () =>
	createSelector(
		state => state.profileModalReducer?.userInfo,
		userInfo => {
			if (!userInfo) {
				const user_data = getLocalStorageItem('user_data');

				return user_data && user_data?.email_verified_at;
			}

			return Boolean(userInfo?.email_verified_at);
		},
	);
