import { createSelector } from 'reselect';
import { getFromStorage } from '../../components/sidebar/utils';
import { getCargoReqTabs, getCargoShipperTabs } from './constants';
import { getCountSelector } from '../reqs-list/selectors';

export const getTabsSelector = () =>
	createSelector(getCountSelector(), count => {
		const data = getFromStorage('user_data');

		const urlArray = window.location.pathname.split('/');

		const id = urlArray[2];

		const tabs = data?.company_type === 'shipper' ? getCargoShipperTabs(id) : getCargoReqTabs(id);

		if (count) {
			return tabs.map(tab => {
				if (count[id]?.[tab.tab]) {
					tab.count = count[id]?.[tab.tab];
				}

				return tab;
			});
		}

		return tabs;
	});
