import { CustomModalCell } from '../../components/custom-modal-cell';

export const DETAIL_FORM_NAME = 'detail-form';

export const REQUIRED_FIELDS = ['name', 'bic', 'corr', 'account'];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const DETAIL_TABLE_COLUMNS_NAMES = [{ id: 'title' }, { id: 'value', customComponent: CustomModalCell }];

export const DETAIL_TABLE_ROWS_NAMES = {
	bic: 'Бик',
	corr: 'Корр. счет',
	account: 'Расчетный счет',
	is_default: 'Использовать по умолчанию',
	author: 'Автор',
};
