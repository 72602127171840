import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';

const ReqsListTimerView = ({ text, time }) => {
	return (
		<div className="reqs-list-timer">
			{text}
			{time}
		</div>
	);
};

ReqsListTimerView.propTypes = {
	text: PropTypes.string.isRequired,
	time: PropTypes.string.isRequired,
};

export { ReqsListTimerView };
