import React from 'react';
import { Link } from 'react-router-dom';
import './styles.less';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';

export const AccountsInfoView = ({ accountsInfo, isInformationLoading }) => {
	return (
		<div className="accounts-info">
			<header className="accounts-info__header">Информация о счетах</header>
			<ul className="accounts-info__info-list">
				{isInformationLoading ? (
					<Loader active size="big" />
				) : (
					<>
						{accountsInfo.length > 0 &&
							accountsInfo.map(list => (
								<li key={list.text} className="accounts-info__info-list-item">
									{list.link ? (
										<Link
											to={list.link}
											className="accounts-info__info-list-text accounts-info__info-list-text_link"
										>
											{list.text}
										</Link>
									) : (
										<span className="accounts-info__info-list-text">{list.text}</span>
									)}

									<span className="accounts-info__info-list-text accounts-info__info-list-text_accent">
										{list.count}
									</span>
								</li>
							))}
					</>
				)}
			</ul>
		</div>
	);
};

AccountsInfoView.propTypes = {
	accountsInfo: PropTypes.arrayOf(
		PropTypes.shape({
			count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			link: PropTypes.string,
			text: PropTypes.string,
		}),
	).isRequired,
	isInformationLoading: PropTypes.bool.isRequired,
};
