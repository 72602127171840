/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import moment from 'moment';
import { initialState } from './reducer';
import { DATE_FORMAT } from './constants';

export const selectChatModalDomain = state => state.chatModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getCurrentDateSelector = () => () => moment().format('DD.MM.YYYY');

export const getRawMessagesSelector = () => createSelector(selectChatModalDomain, ({ messages }) => messages);

export const getMessagesCountSelector = () =>
	createSelector(selectChatModalDomain, ({ messages }) =>
		messages.reduce((acc, item) => acc + item.messages.length, 0),
	);

export const getMessagesSelector = () =>
	createSelector(selectChatModalDomain, ({ messages }) => {
		return messages.map(message => ({
			date: moment(message.date).format(DATE_FORMAT),
			messages: message.messages.map(item => ({
				id: item.id,
				companyName: item.company || '',
				author: item.name || '',
				role: item?.post || '',
				text: item.message,
				time: moment(item.created_at).format('HH:mm'),
				isMy: item.is_my,
				isSend: item.is_send,
				isRead: item.is_read,
			})),
		}));
	});

export const getMessageTextSelector = () => createSelector(selectChatModalDomain, ({ messageText }) => messageText);

export const getIsMessagesLoadingSelector = () =>
	createSelector(selectChatModalDomain, ({ isMessagesLoading }) => isMessagesLoading);

export const getActiveReqIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return query?.modal === 'reqs_details' ? query?.id : null;
	});
