import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CompanyToggle } from './components/company-toggle';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import { resetStateAction } from './actions';
import { RegistrationForm } from './components/registration-form';

export const RegistrationModalWrapper = ({ onResetState }) => {
	useInjectSaga({ key: 'registrationSaga', saga });
	useInjectReducer({ key: 'registrationReducer', reducer });

	useEffect(() => {
		return () => onResetState();
	});

	return (
		<>
			<CompanyToggle />
			<RegistrationForm />
		</>
	);
};

const mapDispatchToProps = {
	onResetState: resetStateAction,
};

const RegistrationModal = connect(null, mapDispatchToProps)(RegistrationModalWrapper);

RegistrationModalWrapper.propTypes = {
	onResetState: PropTypes.func.isRequired,
};

export default RegistrationModal;
