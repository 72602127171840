import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { notificationInit } from '../../modules/notifications/actions';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { getAddressesRequest } from '../../api/requests';
import { getPartnerAddressesRequest } from '../../api/partners-requests';
import {
	GET_ADDRESSES,
	GET_PARTNER_ADDRESSES,
	setAddressesAction,
	setIsAddressesLoadingAction,
	setPaginationAction,
} from './actions';
import { DISMISS_TIME } from '../../api/constants';
import { errorHandler } from '../../api/utils';

export function* getAddressesSaga({ payload: { searchQuery } }) {
	try {
		yield put(setIsAddressesLoadingAction(true));

		const { data, message, meta, toast } = yield call(
			getAddressesRequest,
			searchQuery.replace('search', '').replace('addressesSearch', 'search'),
		);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const query = new URLSearchParams(searchQuery);
			query.delete('modal');
			query.delete('id');
			query.delete('contactId');

			yield put(
				setAddressesAction(
					data?.map(address => ({
						...address,
						link: `/home/addresses?${query ? `${query}&` : ''}modal=address&id=${address.id}`,
						marker: `https://yandex.ru/maps/?pt=${address.geo_lon},${address.geo_lat}&z=18&l=map`,
					})),
				),
			);

			if (meta) {
				yield put(
					setPaginationAction({
						current_page: meta.current_page,
						per_page: meta.per_page,
						from: meta.from,
						last_page: meta.last_page,
						total: meta.total,
					}),
				);
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsAddressesLoadingAction(false));
	}
}

export function* getPartnerAddressesSaga({ payload: { searchQuery, id } }) {
	try {
		yield put(setIsAddressesLoadingAction(true));

		const str = new URLSearchParams(searchQuery);
		str.delete('page');

		const { data, message, meta, toast } = yield call(
			getPartnerAddressesRequest,
			str.toString().replace('search', '').replace('addressesSearch', 'search'),
			id,
		);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const query = new URLSearchParams(searchQuery);
			query.delete('modal');

			yield put(
				setAddressesAction(
					data?.map(address => ({
						...address,
						link: `/home/partners?${query ? `${query}&` : ''}modal=address&addressId=${address.id}`,
						marker: `https://yandex.ru/maps/?pt=${address.geo_lon},${address.geo_lat}&z=18&l=map`,
					})),
				),
			);

			if (meta) {
				yield put(
					setPaginationAction({
						current_page: meta.current_page,
						per_page: meta.per_page,
						from: meta.from,
						last_page: meta.last_page,
						total: meta.total,
					}),
				);
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsAddressesLoadingAction(false));
	}
}

export default function* addressesSaga() {
	yield takeEvery(GET_ADDRESSES, getAddressesSaga);
	yield takeEvery(GET_PARTNER_ADDRESSES, getPartnerAddressesSaga);
}
