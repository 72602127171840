import { SET_DICTIONARY_DATA, SET_IS_DICTIONARY_DATA_LOADING } from './actions';

export const initialState = {
	dictionaryData: null,
	isDictionaryDataLoading: false,
};

const typesDictionaryReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_DICTIONARY_DATA:
			return {
				...state,
				dictionaryData: payload,
			};
		case SET_IS_DICTIONARY_DATA_LOADING:
			return {
				...state,
				isDictionaryDataLoading: payload,
			};
		default:
			return state;
	}
};

export default typesDictionaryReducer;
