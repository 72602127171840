import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { GET_INFORMATION, setInformationAction, setIsInformationLoadingAction } from './actions';
import { errorHandler } from '../../api/utils';
import { getInformationRequest } from '../../api/requests';
import { DISMISS_TIME } from '../../api/constants';
import { notificationInit } from '../../modules/notifications/actions';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { getIsInfoCompanyCheckedSelector } from './selectors';

export function* getInformationSaga() {
	try {
		yield put(setIsInformationLoadingAction(true));

		const isInfoCompanyChecked = yield select(getIsInfoCompanyCheckedSelector());

		const { data, message, toast } = yield call(getInformationRequest, isInfoCompanyChecked ? '?receive=all' : '');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setInformationAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsInformationLoadingAction(false));
	}
}

export default function* informationContainerSaga() {
	yield takeEvery(GET_INFORMATION, getInformationSaga);
}
