import UserIcon from '../../components/images/user-icon.svg';
import LogoutIcon from '../../components/images/logout-icon.svg';
import CompanyIcon from '../../components/images/company-icon.svg';

export const PROFILE_NAV_ITEMS = [
	{
		text: 'Компания',
		icon: CompanyIcon,
		id: 'company',
	},
	{
		text: 'Профиль',
		icon: UserIcon,
		id: 'profile',
	},
	{
		text: 'Выйти',
		icon: LogoutIcon,
		id: 'logout',
	},
];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';
