export const GET_REQ_INFO = 'cargo-delivery-modal/GET_REQ_INFO';
export const getReqInfoAction = payload => ({
	type: GET_REQ_INFO,
	payload,
});

export const SET_REQ_INFO = 'cargo-delivery-modal/SET_REQ_INFO';
export const setReqInfoAction = payload => ({
	type: SET_REQ_INFO,
	payload,
});

export const SET_IS_REQ_INFO_LOADING = 'cargo-delivery-modal/SET_IS_REQ_INFO_LOADING';
export const setIsReqInfoLoadingAction = payload => ({
	type: SET_IS_REQ_INFO_LOADING,
	payload,
});

export const RESET_STATE = 'cargo-delivery-modal/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const SET_IS_FORM_SENDING = 'cargo-delivery-modal/SET_IS_FORM_SENDING';
export const setIsFormSendingAction = payload => ({
	type: SET_IS_FORM_SENDING,
	payload,
});

export const SEND_CARGO_DELIVERY_FORM_ACTION = 'cargo-delivery-modal/SEND_CARGO_DELIVERY_FORM_ACTION';
export const sendCargoDeliveryFormAction = payload => ({
	type: SEND_CARGO_DELIVERY_FORM_ACTION,
	payload,
});
